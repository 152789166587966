import CloseIcon from "@mui/icons-material/Close";
import { Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { groupHealthAddDTO } from "../../../../../Services/DTO/ProductConfigurator/GroupHealthDTO";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  api_url: string;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
  refetchData?: Function;
}

const AddCategory: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  api_url,
  shortcutDisable,
  setShortcutDisable,
  refetchData,
}) => {
  const [addCategoryStep, setAddCategoryStep] = useState<groupHealthAddDTO[]>([
    {
      category: {
        name: "",
        warnName: false,
        description: "",
        warnDescription: false,
      },
    },
  ]);
  const updateMasterStateArray = (attrName: any, value: any) => {
    const array_data: any = [...addCategoryStep];
    if (attrName[1] === "name") {
      array_data[attrName[0]].category.name = value;
    } else if (attrName[1] === "description") {
      array_data[attrName[0]].category.description = value;
    }
    setAddCategoryStep(array_data);
  };

  const addCategory = () => {
    let docArray = [...addCategoryStep];
    let checkwarning = false;
    // Check if document fields are empty and set warning flag accordingly
    docArray = docArray.map((e: any) => ({
      category: {
        name: e.category.name,
        warnName:
          e.category.name === "" || e.category.name.length < 2
            ? ((checkwarning = true), true)
            : false,
        description: e.category.description,
        warnDescription:
          e.category.description === "" || e.category.description.length < 2
            ? ((checkwarning = true), true)
            : false,
      },
    }));
    setAddCategoryStep(docArray);

    let dto = docArray?.map((item: any) => {
      return {
        category: {
          name: item.category.name,
          description: item.category.description,
        },
      };
    });

    let hasError = false;
    docArray?.map((item: any) => {
      if (item?.category.warnName) {
        return (hasError = true);
      } else if (item?.category.warnDescription) {
        return (hasError = true);
      }
    });

    const onSuccess = (res: any) => {
      refetchData && refetchData()
      value_update(attrName, false);
      setAddCategoryStep([
        {
          category: {
            name: "",
            warnName: false,
            description: "",
            warnDescription: false,
          },
        },
      ]);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    if (!hasError) {
      GROUP_SUPER_TOPUP.createCatageory(api_url, dto, onSuccess, {}, onError);
    }
  };

  const removePopup = () => {
    value_update(attrName, false);
    setAddCategoryStep([
      {
        category: {
          name: "",
          warnName: false,
          description: "",
          warnDescription: false,
        },
      },
    ]);
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      // backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center" className="mt-0">
          <Grid xs={10}>
            <h4 className="mb-3">Add New Category</h4>
            <p>You can add multiple categories at a time.</p>
          </Grid>
          <Grid xs={2} className="pt-3 text-right">
            <CloseIcon onClick={removePopup} />
          </Grid>
          <Grid xs={12} className="text-right">
            <Link
              className="greenBtn addfile ml-0"
              underline="none"
              onClick={() => {
                setAddCategoryStep((addCategoryStep) => [
                  ...addCategoryStep,
                  {
                    category: {
                      name: "",
                      warnName: false,
                      description: "",
                      warnDescription: false,
                    },
                  },
                ]);
              }}
            >
              Add More
            </Link>
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area lgScroll">
          {addCategoryStep.map((data, index) => (
            <Grid key={index} container spacing={3}>
              <Grid xs={9}>
                <p className="labelTitle">{index + 1}. Category</p>
              </Grid>
              <Grid xs={3} className="text-right">
                {index === 0 ? null : (
                  <Link
                    className="redBtn delete ml-0"
                    underline="none"
                    onClick={() => {
                      setAddCategoryStep((addCategoryStep) =>
                        addCategoryStep.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    Remove
                  </Link>
                )}
              </Grid>
              <Grid xs={8}>
                <RKTextField
                  class_name="inputField"
                  title={"Category Name"}
                  value={data.category.name}
                  attrName={[index, "name"]}
                  value_update={updateMasterStateArray}
                  warn_status={data.category.warnName}
                  error_messg={
                    data.category.name.length === 0
                      ? "Enter Name"
                      : data.category.name.length < 2
                      ? "Enter Min. 2 characters"
                      : ""
                  }
                  required
                />
              </Grid>
              <Grid xs={12}>
                <TextAreaField
                  class_name="inputField multiline"
                  title={"Brief Description"}
                  value={data.category.description}
                  attrName={[index, "description"]}
                  value_update={updateMasterStateArray}
                  warn_status={data.category.warnDescription}
                  alphanumeric={true}
                  specialcharacter={true}
                  error_messg={
                    data.category.description.length === 0
                      ? "Enter Description"
                      : data.category.description.length < 2
                      ? "Enter Min. 2 characters"
                      : ""
                  }
                  required
                />
              </Grid>
            </Grid>
          ))}
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="footer_sec">
            <Button
              variant="contained"
              className="save_btn"
              onClick={addCategory}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default AddCategory;
