import Active from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { employeeDTO } from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../formLayout.scss";
import OverViewTab from "./OverViewTab";
interface TStatusCode {
  key: string;
  value: string;
  color: string;
}

function EmployeeDetails() {
  const windowURL = window.location.search;
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [priorityStatus, setPriorityStatus] = useState(32);
  const [employementStatus, setemployementStatus] = useState(37);
  const [employeeBaseLocationId, setemployeeBaseLocationId] = useState("");
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [addPolicy, setAddPolicy] = useState(false);
  const [addNominee, setAddNominee] = useState(false);
  const [addEcard, setAddEcard] = useState(false);
  const [fullName, setFullName] = useState("");
  const [relationEmployee, setRelationEmployee] = useState("Brother");
  const [nomineeDob, setNomineeDob] = useState<Date | null>(new Date());
  const [age, setAge] = useState("25");
  const [selectPolicy, setSelectPolicy] = useState("PB22345780");
  const [healthEcard, setAddHealthEcard] = useState("PB223674780");
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [selectedMemberType, setselectedMemberType] = useState<any>("");
  const [selectedMemberDOB, setselectedMemberDOB] = useState<any>("");
  const [statusCode, setstatusCode] = useState<Array<TStatusCode>>([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [dateData, setDateData] = useState({
    createdAt: "",
    updatedAt: "",
    employeeId: "",
    _id: "",
  });
  const [prioritystatusData, setprioritystatusData] = useState<any>([]);
  const [firstNameChar, setFirstNameChar] = useState("");
  const relationEmployee_data = [
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
  ];
  const selectPolicy_data = [
    { key: "PB22345780", value: "PB22345780" },
    { key: "PB223457802", value: "PB223457802" },
    { key: "PB223457803", value: "PB223457803" },
  ];
  const [selectedClient, setSelectedClient] = useState<{
    id: string;
    label: string;
  }>({ id: "", label: "" });
  const [empSalary, setEmpSalary] = useState<string>("");
  const [empSalaryBracket, setEmpSalaryBracket] = useState<string>("");
  const [empDepartment, setEmpDepartment] = useState<string>("");
  const [empDesignation, setEmpDesignation] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const [relatedEmpId, setRelatedEmpId] = useState<string>("");
  const [policyMemberRelationId, setPolicyMemberRelationId] =
    useState<string>("");

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "_id") {
      getStatusCode(value);
    } else if (attrName === "Member Type") {
      setselectedMemberType(value);
    } else if (attrName === setPriorityStatus) {
      setPriorityStatus(value);
      update_status(value, "MEMBER");
    } else {
      attrName(value);
    }
  };
  let timeout: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      setEmployeeId(id ?? "");
      get_employee_layout(id ?? "");
      get_status();  
    };
    timeout = setTimeout(delayedApiCall, 900);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, type: string) => {
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id ? id : "",
      type,
      usertypeinfo.toLowerCase()
    );
  };

  const getStatusCode = (id: string) => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      setstatusCode(
        data.map((item: TStatusCode) => {
          return {
            ...item,
            key: item.key.toUpperCase(),
            value: item.value.toUpperCase(),
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log("Status list", err);
    };

    EMPLOYEE_SERVICES.get_status_data(onSuccess, onError, id);
  };

  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      getStatusCode(res.data.data[0]._id);
      setDateData({
        createdAt: res.data.data[0].createdAt,
        updatedAt: res.data.data[0].updatedAt,
        employeeId: res.data.data[0].employeeId,
        _id: res.data.data[0]._id,
      });
      setPriorityStatus(res.data.data[0].status.value);
      setemployementStatus(res.data.data[0]?.employement_status.value);

      let newData = res.data.data[0].layout.map(
        (dataMainsection: employeeDTO) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map((dataColumn) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children;
                    newColumn.children = dataColumn.children.map(
                      (dataInput) => {
                        if (dataInput.field_type === "searchLocation") {
                          let temp: any = dataInput;
                          setemployeeBaseLocationId(temp.value_id);
                        }
                        if (dataInput?.field_lable === "Member Type") {
                          setselectedMemberType(dataInput.value);
                        }
                        if (dataInput?.field_lable === "First Name") {
                          setFirstNameChar(dataInput.value + "jakas");
                        }
                        if (dataInput?.field_lable === "Date Of Birth") {
                          setselectedMemberDOB(dataInput.value);
                        }
                        if (dataInput.field_type === "hideid") {
                          return {
                            ...dataInput,
                            field_lable: "hide",
                            field_type: "hide",
                          };
                        } else {
                          return { ...dataInput };
                        }
                      }
                    );
                    return newColumn;
                  });
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );
    };
    const onError = (err: any) => {};
    EMPLOYEE_SERVICES.employee_detail_layout(id, onSuccess, onError);
  };

  //for policy Tab Table Data
  const employee_table_data = [
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Health",
      basic_config: "1E+1S+1C+1C+1C+1C",
      individual_config: "Nill",
      checkbox_status: false,
      check_status: "",
      insurer: "HDFC Ergo",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Term",
      basic_config: "1E+1S+1C+1C+1C",
      individual_config: "1P+1P",
      checkbox_status: false,
      check_status: "",
      insurer: "Star",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Personal Accident",
      basic_config: "1E+1S+1C+1C",
      individual_config: "1PIL+1PIL",
      checkbox_status: false,
      check_status: "",
      insurer: "Bajaj Allianz",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Critical Illness",
      basic_config: "1E+1S+1C",
      individual_config: "1P+1P+1PIL+1PIL",
      checkbox_status: false,
      check_status: "",
      insurer: "Royal Sundaram",
    },
  ];
  // nominee health ecard table

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>
          <span className="detailIcon">{firstNameChar.charAt(0)}</span>
          <div>
            <h3>
              Edit {memberId} - {firstNameChar}
            </h3>
            <p>
              {date_time_format(dateData.createdAt, user_type_dateFormat, tz)}
            </p>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div>
            {/* <Tooltip title="Remove">
              <Link
                underline="none"
                className="actionLinks red_icon"
                onClick={() => {
                  setRemoveStatus(true);
                }}
              >
                <DeleteOutlineIcon />
              </Link>
            </Tooltip> */}
          </div>

          {/* <div> */}
          {/* <Tooltip title="Global Edit">
            <Link
              className="actionLinks"
              onClick={() => {

                navigate(`${ALL_ROUTES.EDIT_EMPLOYEE}?id=${id}`);
              }}>

              <BorderColorIcon />
            </Link>
          </Tooltip> */}
          {/* </div> */}
          {/* Status Dropdown */}

          <div id={`_${priorityStatus.toString()}`}>
            <div
              className="status_dropdown"
              id={`_${priorityStatus.toString()}`}
            >
              {priorityStatus === 32 ? (
                <Active id={`_${priorityStatus.toString()}`} />
              ) : priorityStatus === 33 ? (
                <Inactive id={`_${priorityStatus.toString()}`} />
              ) : priorityStatus === 34 ? (
                <Void id={`_${priorityStatus.toString()}`} />
              ) : null}
              <span>
                {
                  prioritystatusData.find(
                    (data: any) => data.label === priorityStatus
                  )?.value
                }
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Box>
            <Grid container spacing={3}>
              <Grid xs className="rightSection h-100">
                <Box>
                  {viewTabsStatus === "overview" ? (
                    <OverViewTab
                      value_update={updateMasterState}
                      setSelectedClient={setSelectedClient}
                      setEmpSalary={setEmpSalary}
                      setEmpSalaryBracket={setEmpSalaryBracket}
                      setEmpDepartment={setEmpDepartment}
                      setEmpDesignation={setEmpDesignation}
                      setPropsMemberId={setMemberId}
                      setRelatedEmpId={setRelatedEmpId}
                      setPolicyMemberRelationId={setPolicyMemberRelationId}
                      memName={`${memberId}-${firstNameChar}`}
                    />
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <PostQueryForm
        attrName={setPostQueryForm}
        open_status={postQueryForm}
        value_update={updateMasterState}
      />
      <BulkUploadForm
        open_status={uploadFile}
        attrName={setUploadFile}
        value_update={updateMasterState}
      />

      <Modal open={deleteStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setDeleteStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are about to Delete this Attatchment.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={removeStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setRemoveStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are want to remove this Contacts.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* policies add policy popup */}

      <SlidingPanel
        type={"right"}
        isOpen={addPolicy}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddPolicy(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Add Policy</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area pr-5">
                <Grid container spacing={3} className="mb-1">
                  <Grid xs={12}>
                    <div className="addPolicy_info_section">
                      <p className="addPolicy_info_p">
                        Client ID : <span>CL00001</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <h5 className="policy_subheading mt-4">Select Policy</h5>
                  </Grid>
                  <Grid xs={12}>
                    <div className="policy_table">
                      <div
                        className="policy_table_inner"
                        style={{ overflow: "hidden" }}
                      >
                        <ul className="heading">
                          <li>Choose Policy</li>
                          <li>Active Policy Number</li>
                          <li>Product Type</li>
                          <li>Insurer</li>
                          <li>Basic configuration</li>
                          <li>Individual configuration</li>
                        </ul>

                        {employee_table_data.map((data, index) => (
                          <ul className="subheading">
                            <li>
                              <div className="Checkbox">
                                <input type="checkbox" name="check" />
                                <label className="employee"></label>
                              </div>
                            </li>
                            <li>{data.policy_no}</li>
                            <li>{data.product_type}</li>
                            <li>{data.insurer}</li>
                            <li>{data.basic_config}</li>
                            <li>{data.individual_config}</li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddPolicy(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      {/* Nominee add policy popup */}
      <SlidingPanel
        type={"right"}
        isOpen={addNominee}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddNominee(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Fill Nominee Details</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Full Name"
                      value={fullName}
                      attrName={setFullName}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Relationship with employee"
                      value={relationEmployee}
                      attrName={setRelationEmployee}
                      value_update={updateMasterState}
                      dropdown_data={relationEmployee_data}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title="Date of Birth"
                      value={nomineeDob}
                      attrName={setNomineeDob}
                      value_update={updateMasterState}
                      error_message="Please enter Date of Birth"
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Age"
                      value={age}
                      attrName={setAge}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddNominee(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      {/* health ecard policy popup */}
      <SlidingPanel
        type={"right"}
        isOpen={addEcard}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddEcard(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Add Health/E-Card Details</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area ">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Select Policy"
                      value={selectPolicy}
                      attrName={setSelectPolicy}
                      value_update={updateMasterState}
                      dropdown_data={selectPolicy_data}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Enter Health/E-Card Number"
                      value={healthEcard}
                      attrName={setAddHealthEcard}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid xs={10} className="inputField uploadFile">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Upload Document"
                      variant="outlined"
                      className="textarea"
                    />
                  </Grid>
                  <Grid xs={2}>
                    <Button variant="contained" className="browsebtn">
                      Browse
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddEcard(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </Box>
  );
}
export default EmployeeDetails;
