import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModulePermissionAccessControl from "../../../../../../Components/Broker/ModulePermissionAccessControl/ModulePermissionAccessControl";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../../formLayout.scss";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { useAppSelector } from "../../../../../../Store/hooks";

function EditCreateRole() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const navigate = useNavigate();
  const roleInformation = useRef(null);
  const rolename = useRef(null);
  const modulePermission = useRef(null);
  const [roleName, setRoleName] = useState("");
  const [perviouRoleName, setPerviousRoleName] = useState({
    parentId: "",
    rolename: "",
  });
  const [priorityStatus, setPriorityStatus] = useState(15);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [accessData, setAccessData] = useState<any[]>([]);
  const [perrsmission, setPerrsmission] = useState("");
  const [role_warn, setRole_warn] = useState<boolean>(false);
  const [sectionStatus, SetSectionStatus] = useState<string>("roleInformation");
  const [subSection, setSubSection] = useState<string>("rolename");
  const [id, setId] = useState<string>("");
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, seteditPermission] = useState(false);
  const [statusPermission, setstatusPermission] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    seteditPermission(BROKER?.AccessControl?.edit === undefined ? false : BROKER?.AccessControl?.edit);
    setstatusPermission(BROKER?.AccessControl?.status === undefined ? false : BROKER?.AccessControl?.status);
  }, []);
  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Role is being used,Can't Inactive it. ");
      } else {
        update_status(value, id);
        setPriorityStatus(value);

      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    ACCESS_LEVEL_SERVICES.isBeingUsed(onSuccess, onError, id);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setPriorityStatus) {
      isBeingUsed(id, value);
    } else {
      attrName(value);
    }
  };
  useEffect(() => {
    get_status();
  }, []);
  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    user_data(id ?? "");
  }, []);
  const getData = (attr: any, data: any) => {
    setAccessData(data);
  };
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // update
  const update_data = () => {
    if (disableSave) return;
    if (!editPermission) return toast.error("Update permission not allowed")
    let data: boolean = false;
    data =
      roleName.length === 0 || perviouRoleName.rolename.length === 0
        ? true : false;
    setRole_warn(data);
    if (data === false) {
      let params = {
        _id: id,
        roleName: roleName,
        previousRoleName: perviouRoleName.rolename,
        parentsId: perviouRoleName.parentId,
        perrsmission: accessData,
      };
      const onSuccess = (res: any) => {
        toast.success(res.data.message);
        navigate(-1);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        console.log("err", err);
      };
      setDisableSave(true);
      ACCESS_LEVEL_SERVICES.user_access_control_update(
        params,
        onSuccess,
        onError
      );
    }
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const user_data = (id: string) => {
    const onSuccess = (res: any) => {

      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setRoleName(res.data.data[0].roleName);
      const newData = [...res.data.data[0].perrsmission];
      // remove DeveloperSpace, DataAdmin, PortalSetup

      const lastItem = newData.length - 1;
      newData[lastItem].child = newData[lastItem].child?.filter((module: any) => {
        const moduleName = module.data.module_key;
        return (
          moduleName !== "DeveloperSpace" &&
          moduleName !== "DataAdmin" &&
          moduleName !== "PortalSetup"
        );
      });

      // remove EmailSetting
      // newData[lastItem].child[0].child = newData[lastItem].child[0].child.filter((module: any) => {
      //   const moduleKey = module.data.module_key;
      //   return moduleKey !== "EmailSetting";
      // })

      // remove Template
      // const newData2 = [...newData];
      newData[lastItem].child[3].child = newData[lastItem].child[3].child?.filter(
        (module: any) => {
          const moduleKey = module.data.module_key;
          return moduleKey !== "Template";
        }
      );

      const newData3 = newData?.filter((item: any) => item?.data?.module_key !== "Renewals")
        ?.filter((item: any) => item?.data?.module_key !== "Reports")

      // setAccessData(res.data.data[0].pe rrsmission);
      setAccessData(newData3);
      setPerviousRoleName({
        rolename: res.data.data[0].roleName,
        parentId: res.data.data[0].parentsId,
      });
    };
    const onError = (err: any) => {
      console.log(".......,,", err);
    };
    ACCESS_LEVEL_SERVICES.access_control_user_data_by_id(
      id,
      onSuccess,
      onError
    );
  };
  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status_access_control(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.update_status_access_control(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Edit Role</h3>
            </div>
          </Grid>
          <Grid
            xs={6}
            textAlign={"right"}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <div id={`_${priorityStatus.toString()}`}>
              <div
                className="status_dropdown"
                id={`_${priorityStatus.toString()}`}
              >
                {priorityStatus === 15 ? (
                  <CheckCircleIcon id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 16 ? (
                  <HighlightOffIcon id={`_${priorityStatus.toString()}`} />
                ) : null}

                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled={!statusPermission}
                />
              </div>
            </div>
          </Grid>{" "}
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection">
                <Link
                  className={
                    sectionStatus === "roleInformation"
                      ? "active sectionLink roleInformation"
                      : "sectionLink roleInformation"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(roleInformation);
                    SetSectionStatus("roleInformation");
                  }}
                >
                  <span> Role Information</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "rolename"
                          ? "inner_link active rolename"
                          : "inner_link  rolename"
                      }
                      onClick={() => {
                        handleClicks(subSection);
                        scrollToSection(rolename);
                        setSubSection("rolename");
                      }}
                    >
                      Role Name
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        subSection === "modulePermission"
                          ? "inner_link active modulePermission"
                          : "inner_link modulePermission"
                      }
                      onClick={() => {
                        setSubSection("modulePermission");
                        handleClicks(subSection);
                        scrollToSection(modulePermission);
                      }}
                    >
                      Module Permission
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs className="rightSection">
                <Box className="detailSection" ref={roleInformation}>
                  <div
                    onMouseEnter={() => {
                      setSubSection("rolename");
                    }}
                    ref={rolename}
                  >
                    <div className="sectionTitle">
                      <h4>Role Name</h4>
                    </div>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Role Name</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          validation_type="name"
                          class_name="inputField"
                          title={"Role Name"}
                          value={roleName}
                          attrName={setRoleName}
                          value_update={updateMasterState}
                          warn_status={role_warn}
                          disabled={!editPermission}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div onMouseEnter={() => { setSubSection("modulePermission"); }} ref={modulePermission} >
                    <ModulePermissionAccessControl
                      value_update={getData}
                      dataArray={accessData === undefined ? [] : accessData}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained" disabled={disableSave}
              className="submitBtn"
              onClick={update_data}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EditCreateRole;
