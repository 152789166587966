import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import {
  addInsurerDTO,
  emailTemplateDTO,
  locationDTO,
  physicalFormDTO,
} from "../../../../../../Services/DTO/ProductConfigurator/AddInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../../../Services/ProductConfigurator/AddNewInsurer";
import {
  get_city_list,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../../formLayout.scss";
import { maximumage_data, minimumage_data } from "../dropDown_data";
import CountryStateCity from "../../../../../../Components/Common/CountryStateCity/CountryStateCity";
import { toast } from "react-toastify";

function AddNewInsurer() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const navigate = useNavigate();
  const [addInsurer, setAddInsurer] = useState<addInsurerDTO>({
    type: "GENERAL",
    insurerBasicInfo: {
      logo: "",
      name: { value: "", warning: false },
      email: { value: "", warning: false },
      networkCount: { value: "", warning: false },
      website: { value: "", warning: false },
      contactNumber: { value: "", warning: false },
    },
    headOfficeAddressDetails: {
      locationName: { value: "", warning: false },
      type: { value: "", warning: false },
      street: { value: "", warning: false },
      area: { value: "", warning: false },
      city: { value: "", warning: false },
      state: { value: "", warning: false },
      zip: { value: "", warning: false },
      country: { value: "", warning: false },
    },
    ageBand: {
      min: { value: "", warning: false },
      max: { value: "", warning: false },
    },
    location: [
      {
        name: "",
        type: "",
        address: "",
        areaname: "",
        city: "",
        state: "",
        zip: null,
        country: "",
        contactId: "",
        warnName: false,
        warnType: false,
        warnAddress: false,
        warnArea: false,
        warnCity: false,
        warnState: false,
        warnZip: false,
        warnCountry: false,
        warnContactId: false,
      },
    ],
    emailTemplate: [
      {
        title: "",
        module: "",
        subject: "",
        respondentEmail: "",
        sendersEmail: "",
        body: "",
        describe: "",
        attachmentEnable: false,
        warnTitle: false,
        warnModule: false,
        warnSubject: false,
        warnRespondentEmail: false,
        warnSendersEmail: false,
      },
    ],
    physicalForm: [
      {
        title: "",
        product: "",
        description: "",
        type: "",
        subType: "",
        attachment: "",
        warnTitle: false,
        warnProduct: false,
        warnDescription: false,
        warnType: false,
        warnSubType: false,
        warnAttachment: false,
      },
    ],
  });
  const [physicalForm, setPhysicalForm] = useState<physicalFormDTO>({
    title: "",
    product: "",
    description: "",
    type: "",
    subType: "",
    attachment: "",
    warnTitle: false,
    warnProduct: false,
    warnDescription: false,
    warnType: false,
    warnSubType: false,
    warnAttachment: false,
  });
  const [locationPopupDetail, setLocationPopupDetail] = useState<locationDTO>({
    id: Math.random() + 1,
    name: "",
    type: "",
    address: "",
    areaname: "",
    city: "",
    state: "",
    zip: null,
    country: "",
    contactId: "",
    warnName: false,
    warnType: false,
    warnAddress: false,
    warnArea: false,
    warnCity: false,
    warnState: false,
    warnZip: false,
    warnCountry: false,
    warnContactId: false,
  });
  const [addTemplateData, setAddTemplateData] = useState<emailTemplateDTO>({
    title: "",
    module: "",
    subject: "",
    respondentEmail: "",
    sendersEmail: "",
    body: "",
    describe: "",
    attachmentEnable: false,
    warnTitle: false,
    warnModule: false,
    warnSubject: false,
    warnRespondentEmail: false,
    warnSendersEmail: false,
  });

  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning: boolean;
  }>({ logo: "", warning: false });

  const [countryData, setCountryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);

  const [stateData, setStateData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);

  const [cityData, setCityData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [countryId, setcountryId] = React.useState("");

  const [sectionStatus, SetSectionStatus] =
    React.useState<string>("personalDetails");
  const [subSection, setSubSection] = React.useState("basicinfo");

  const personalDetails = useRef(null);
  const address = useRef(null);
  const AgeBands = useRef(null);
  const basicinfo = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  let timeout: any;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
    };
    timeout = setTimeout(delayedApiCall, 900);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  //update the form fields

  const updateMasterState = (attrName: any, value: any) => {
    let mainstate = attrName[0];
    let key = attrName[1];
    if (mainstate === "locationPopupDetail") {
      setLocationPopupDetail({
        ...locationPopupDetail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else if (mainstate === "addTemplateData") {
      setAddTemplateData({
        ...addTemplateData,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else if (mainstate === "physicalForm") {
      setPhysicalForm({
        ...physicalForm,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      if (key === "contactNumber") {
        setAddInsurer({
          ...addInsurer,
          insurerBasicInfo: {
            ...addInsurer.insurerBasicInfo,
            [key]: {
              value: value,
              warning: !validateMobileNumber(value),
            },
          },
        });
      } else if (addInsurer.insurerBasicInfo.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          insurerBasicInfo: {
            ...addInsurer.insurerBasicInfo,
            [key]: {
              value: value,
              warning: value.toString().length > 0 ? false : true,
            },
          },
        });
      } else if (addInsurer.headOfficeAddressDetails.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          headOfficeAddressDetails: {
            ...addInsurer.headOfficeAddressDetails,
            [key]: {
              value: value,
              warning: value.toString().length > 0 ? false : true,
            },
          },
        });
        if (key === "country") {
          setAddInsurer({
            ...addInsurer,
            headOfficeAddressDetails: {
              ...addInsurer.headOfficeAddressDetails,
              country: {
                value: value.label,
                warning: value.toString().length > 0 ? false : true,
              },
              ["state"]: {
                value: "",
                // warning: value.toString().length > 0 ? false : true,
                warning: true,
              },
              ["city"]: {
                value: "",
                // warning: value.toString().length > 0 ? false : true,
                warning: true,
              },
            },
          });
          get_state_list(value, (cb: any) => {
            setStateData(cb);
          });
          setcountryId(value.label);
        } else if (key === "state") {
          get_city_list_via_name(countryId, value.label, (cb: any) => {
            setCityData(cb);
          });
          setAddInsurer({
            ...addInsurer,
            headOfficeAddressDetails: {
              ...addInsurer.headOfficeAddressDetails,

              ["state"]: {
                value: value.label,
                warning: value.toString().length > 0 ? false : true,
              },
              ["city"]: {
                // value: value.label === "N.A." ? "N.A." : "",
                // warning: value.toString().length > 0 ? false : true,
                value: "",
                warning: true,
              },
            },
          });
        }
      } else if (addInsurer.ageBand.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          ageBand: {
            ...addInsurer.ageBand,
            [key]: {
              value: value,
              warning: value.toString().length > 0 ? false : true,
            },
          },
        });
      }
    }
  };

  // VALIDATE FORM
  const validate_form = () => {
    if (disableSave) return;
    displayLogo.warning = displayLogo.logo === "" ? true : false;
    let data: any = addInsurer;
    // data.insurerBasicInfo.logo.warning =
    //   data.insurerBasicInfo.logo.value === "" ? true : false;
    data.insurerBasicInfo.name.warning =
      addInsurer.insurerBasicInfo.name.value.length === 0 ? true : false;
    data.insurerBasicInfo.networkCount.warning =
      addInsurer.insurerBasicInfo.networkCount.value.length === 0
        ? true
        : false;
    data.insurerBasicInfo.email.warning = !validateEmail(
      addInsurer.insurerBasicInfo.email.value
    );

    data.insurerBasicInfo.website.warning = !validateUrl(
      addInsurer.insurerBasicInfo.website.value
    );

    data.insurerBasicInfo.contactNumber.warning = !validateMobileNumber(
      addInsurer.insurerBasicInfo.contactNumber.value
    );

    data.headOfficeAddressDetails.locationName.warning =
      addInsurer.headOfficeAddressDetails.locationName.value.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.type.warning =
      addInsurer.headOfficeAddressDetails.type.value.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.street.warning =
      addInsurer.headOfficeAddressDetails.street.value.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.city.warning =
      addInsurer.headOfficeAddressDetails.city.value.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.state.warning =
      addInsurer.headOfficeAddressDetails.state.value.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.country.warning =
      addInsurer.headOfficeAddressDetails.country.value.length === 0
        ? true
        : false;
    // data.headOfficeAddressDetails.area.warning =
    //   addInsurer.headOfficeAddressDetails.area.value.length === 0
    //     ? true
    //     : false;
    data.headOfficeAddressDetails.zip.warning =
      addInsurer.headOfficeAddressDetails.zip.value.length === 0 ? true : false;
    // data.ageBand.min.warning =
    //   addInsurer.ageBand.min.value.length === 0 ? true : false;
    // data.ageBand.max.warning =
    //   addInsurer.ageBand.max.value.length === 0 ? true : false;
    setAddInsurer({ ...data });
    if (
      displayLogo.warning === false &&
      data.insurerBasicInfo.name.warning === false &&
      data.insurerBasicInfo.email.warning === false &&
      data.insurerBasicInfo.website.warning === false &&
      data.insurerBasicInfo.contactNumber.warning === false &&
      data.headOfficeAddressDetails.locationName.warning === false &&
      data.headOfficeAddressDetails.type.warning === false &&
      data.headOfficeAddressDetails.street.warning === false &&
      data.headOfficeAddressDetails.city.warning === false &&
      data.headOfficeAddressDetails.state.warning === false &&
      data.headOfficeAddressDetails.country.warning === false &&
      data.headOfficeAddressDetails.area.warning === false &&
      data.headOfficeAddressDetails.zip.warning === false &&
      data.headOfficeAddressDetails.locationName.warning === false
      // data.ageBand.min.warning === false &&
      // data.ageBand.max.warning === false
    ) {
      const typeInsurer = sessionStorage.getItem("typeInsurer");
      const onSuccess = (res: any) => {
        navigate(ALL_ROUTES.PRODUCT_CONFIG_INSURERS);
      };
      const onError = (res: any) => {
        setDisableSave(false);
        // {"status":"failed","errors":[{"param":"email","message":"should be valid!"}]}
        toast.error(
          res.response.data.errors[0].param +
            " " +
            res.response.data.errors[0].message
        );
      };
      setDisableSave(true);
      ADD_NEW_INSURER.createAddInsurer(
        {
          type: typeInsurer,
          insurerBasicInfo: {
            logo: addInsurer.insurerBasicInfo.logo,
            name: addInsurer.insurerBasicInfo.name.value,
            email: addInsurer.insurerBasicInfo.email.value,
            networkCount: 10,
            contactNumber: parseFloat(
              addInsurer.insurerBasicInfo.contactNumber.value
            ),
            website: `https://${addInsurer.insurerBasicInfo.website.value}`,
          },
          headOfficeAddressDetails: {
            locationName:
              addInsurer.headOfficeAddressDetails.locationName.value,
            type: addInsurer.headOfficeAddressDetails.type.value,
            street: addInsurer.headOfficeAddressDetails.street.value,
            area: addInsurer.headOfficeAddressDetails.area.value,
            city: addInsurer.headOfficeAddressDetails.city.value.label,
            state: addInsurer.headOfficeAddressDetails.state.value,
            zip: addInsurer.headOfficeAddressDetails.zip.value,
            country: addInsurer.headOfficeAddressDetails.country.value,
          },

          location: [],
          emailTemplate: [],
          physicalForm: [],
        },
        onSuccess,
        {},
        onError
      );
    }
  };

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Add New Insurer</h3>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        ></Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "personalDetails"
                    ? "active sectionLink personalDetails"
                    : "sectionLink personalDetails"
                }
                onClick={() => {
                  scrollToSection(personalDetails);
                  SetSectionStatus("personalDetails");
                }}
              >
                <span> Basic Info</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basicinfo"
                        ? `inner_link active`
                        : `inner_link`
                    }
                    onClick={() => {
                      setSubSection("basicinfo");
                      handleClicks(subSection);
                      scrollToSection(basicinfo);
                      SetSectionStatus("personalDetails");
                    }}
                  >
                    Basic info
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "address"
                        ? `inner_link active`
                        : `inner_link`
                    }
                    onClick={() => {
                      setSubSection("address");
                      handleClicks(subSection);
                      scrollToSection(address);
                      SetSectionStatus("personalDetails");
                    }}
                  >
                    Address Details
                  </Link>
                </li>
              </ul>
              {/* 
              <Link
                className={
                  sectionStatus === "AgeBands"
                    ? "active sectionLink AgeBands"
                    : "sectionLink AgeBands"
                }
                onClick={() => {
                  scrollToSection(AgeBands);
                  SetSectionStatus("AgeBands");
                }}
              >
                <span>Age Bands</span>
              </Link> */}
            </Grid>
            <Grid xs className="rightSection">
              <>
                <Box className="detailSection" ref={personalDetails}>
                  <div className="sectionTitle mb-0">
                    <h4>Basic Info</h4>
                  </div>
                  <div ref={basicinfo}>
                    <Grid container spacing={3}>
                      <Grid xs={12} sx={{ paddingBottom: "16px" }}>
                        <Box className="upload-logo">
                          <Grid
                            container
                            sx={{ paddingLeft: 0 }}
                            className="mt-3"
                          >
                            <Grid xs={12}>
                              <img
                                src={
                                  displayLogo.logo
                                    ? displayLogo.logo
                                    : "images/preview-logo-img.svg"
                                }
                                className="upload_company_logo"
                              />
                              <Link className="upload_img" component="label">
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                  style={{ zIndex: "99" }}
                                  onChange={(e: any) => {
                                    let displaydata: any = displayLogo;
                                    displaydata.warning =
                                      displayLogo.logo !== "" ? true : false;
                                    setDisplayLogo({ ...displaydata });
                                    const file = e.target.files[0];
                                    // Check if file size exceeds a certain limit (e.g., 2MB)
                                    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
                                    if (file.size > maxSizeInBytes) {
                                      // File size exceeds limit
                                      toast.warn(
                                        "Logo size should be less then 2 MB"
                                      );
                                      return;
                                    }

                                    uploadImage(
                                      file,
                                      (base64Image: any, base64String: any) => {
                                        setAddInsurer((prevState) => ({
                                          ...prevState,
                                          insurerBasicInfo: {
                                            ...prevState.insurerBasicInfo,
                                            logo: base64Image,
                                          },
                                        }));
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                      }
                                    );
                                  }}
                                />
                                <img src="images/upload_logo_icon.svg" alt="" />
                                <span>Upload Logo</span>

                                <div className="mt-1">
                                  <span>Maximum size of logo is 2 MB*</span>
                                </div>
                                <div
                                  className="img-error"
                                  style={{
                                    color: "#eb5757",
                                    fontSize: "12px",
                                    marginTop: "4px",
                                  }}
                                >
                                  {displayLogo.warning === true
                                    ? "Upload a logo"
                                    : ""}
                                </div>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Basic info</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={4} lg={6} xl={4}>
                        <RKTextField
                          validation_type="name"
                          specialcharacter
                          alphanumeric
                          class_name="inputField"
                          title="Insurer Name"
                          value={addInsurer.insurerBasicInfo.name.value}
                          attrName={[addInsurer.insurerBasicInfo, "name"]}
                          value_update={updateMasterState}
                          warn_status={addInsurer.insurerBasicInfo.name.warning}
                          required={true}
                          charcterlimit={100}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid sm={4} md={4} lg={3} xl={2}>
                        <RKTextField
                          validation_type="url"
                          class_name="inputField"
                          title="Website URL"
                          value={addInsurer.insurerBasicInfo.website.value}
                          attrName={[addInsurer.insurerBasicInfo, "website"]}
                          value_update={updateMasterState}
                          warn_status={
                            addInsurer.insurerBasicInfo.website.warning
                          }
                          error_messg={
                            addInsurer.insurerBasicInfo.website.value.length ===
                            0
                              ? "Enter Website"
                              : validateUrl(
                                  addInsurer.insurerBasicInfo.website.value
                                ) === false
                              ? "Enter valid Url"
                              : ""
                          }
                          required={true}
                        />
                      </Grid>
                      <Grid sm={4} md={4} lg={3} xl={2}>
                        <RKTextField
                          validation_type="email"
                          class_name="inputField"
                          title="Email"
                          value={addInsurer.insurerBasicInfo.email.value}
                          attrName={[addInsurer.insurerBasicInfo, "email"]}
                          value_update={updateMasterState}
                          warn_status={
                            addInsurer.insurerBasicInfo.email.warning
                          }
                          error_messg={
                            addInsurer.insurerBasicInfo.email.value.length === 0
                              ? "Enter  Email"
                              : validateEmail(
                                  addInsurer.insurerBasicInfo.email.value
                                ) === false
                              ? "Enter valid Email"
                              : ""
                          }
                          required={true}
                        />
                      </Grid>
                      <Grid sm={4} md={4} lg={3} xl={2}>
                        <RKTextField
                          validation_type="numeric"
                          class_name="inputField"
                          title="Contact Number"
                          charcterlimit={10}
                          value={
                            addInsurer.insurerBasicInfo.contactNumber.value
                          }
                          attrName={[
                            addInsurer.insurerBasicInfo,
                            "contactNumber",
                          ]}
                          value_update={updateMasterState}
                          warn_status={
                            addInsurer.insurerBasicInfo.contactNumber.warning
                          }
                          error_messg={"Enter Contact Number"}
                          required={true}
                        />
                      </Grid>
                    </Grid>

                    <div ref={address}>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          <h5 className="subHeading">
                            Head Office Address Details
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={6} md={6} lg={6} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Location Name"
                            value={
                              addInsurer.headOfficeAddressDetails.locationName
                                .value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "locationName",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.locationName
                                .warning
                            }
                            required={true}
                            error_messg="Enter Location Name"
                          />
                        </Grid>
                        <Grid xs={6} md={6} lg={6} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Location Type"
                            value={
                              addInsurer.headOfficeAddressDetails.type.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "type",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.type.warning
                            }
                            error_messg="Enter Location Type"
                            required={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={6} md={6} lg={6} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Building/Street Name"
                            value={
                              addInsurer.headOfficeAddressDetails.street.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "street",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.street.warning
                            }
                            required={true}
                          />
                        </Grid>
                        <Grid xs={6} md={6} lg={6} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Area Name"
                            value={
                              addInsurer.headOfficeAddressDetails.area.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "area",
                            ]}
                            value_update={updateMasterState}
                            // warn_status={
                            //   addInsurer.headOfficeAddressDetails.area.warning
                            // }
                            // required={true}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Country"
                            value={
                              addInsurer.headOfficeAddressDetails.country.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "country",
                            ]}
                            value_update={updateMasterState}
                            data={countryData}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.country
                                .warning
                            }
                            error_message="Select Country"
                            required={true}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="State"
                            value={
                              addInsurer.headOfficeAddressDetails.state.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "state",
                            ]}
                            value_update={updateMasterState}
                            data={stateData}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.state.warning
                            }
                            error_message="Select State"
                            required={true}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="City"
                            value={
                              addInsurer.headOfficeAddressDetails.city.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "city",
                            ]}
                            value_update={updateMasterState}
                            data={cityData}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.city.warning
                            }
                            error_message="Select City"
                            required={true}
                          />
                        </Grid>
                        {/* <CountryStateCity value_update={updateMasterState} /> */}

                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <RKTextField
                            title="Pincode"
                            validation_type="numeric"
                            class_name="inputField"
                            charcterlimit={6}
                            value={
                              addInsurer.headOfficeAddressDetails.zip.value
                            }
                            attrName={[
                              addInsurer.headOfficeAddressDetails,
                              "zip",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer.headOfficeAddressDetails.zip.warning
                            }
                            required={true}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Box>
                {/* <Box className="detailSection" ref={AgeBands}>
                  <div className="sectionTitle mb-0">
                    <h4>Age Band for Children</h4>
                  </div>
                  <Grid container spacing={3} className="mt-2">
                    <Grid xs={6} md={6} lg={4} xl={3}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Minimum Age"
                        value={addInsurer.ageBand.min.value}
                        attrName={[addInsurer.ageBand, "min"]}
                        value_update={updateMasterState}
                        dropdown_data={minimumage_data}
                        warn_status={addInsurer.ageBand.min.warning}
                      />
                    </Grid>

                    <Grid xs={6} md={6} lg={4} xl={3}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Maximum Age"
                        value={addInsurer.ageBand.max.value}
                        attrName={[addInsurer.ageBand, "max"]}
                        value_update={updateMasterState}
                        dropdown_data={maximumage_data}
                        warn_status={addInsurer.ageBand.max.warning}
                      />
                    </Grid>
                  </Grid>
                </Box> */}
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* footer section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="submitBtn"
            onClick={validate_form}
            disabled={disableSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
export default AddNewInsurer;
