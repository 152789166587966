// Importing necessary libraries and components
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import { PolicyWalletObj } from "../../../../Services/DTO/Employee/EmployeeWalletDTO";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import AddRetailPolicy from "./AddRetailPolicy/AddRetailPolicy";
import CarRetailPolicy from "./CarRetailPolicy/CarRetailPolicy";
import HealthRetailPolicy from "./HealthRetailPolicy/HealthRetailPolicy";
import InsurancePolicyDetails from "./InsurancePolicyDetail/InsurancePolicyDetail";
import "./PolicyWallet.scss";
import NewLoader from "../../../../Components/NewLoader/NewLoader"; 

function PolicyWallet() {
  const [retailPolicy, setAddRetailPolicy] = useState(false);
  const [healthRetailPolicy, setHealthRetailPolicy] = useState(false);
  const [carRetailPolicy, setCarRetailPolicy] = useState(false);
  const [policyCard, setPolicyCard] = useState<any>([]);
  const walletStyle = [
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
    { classvalue: "green", boxcolor: "health", colorid: "blue-gradient" },
  ];
  const [showLoader, setShowLoader] = useState(true);

  const getWallet = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let arr: PolicyWalletObj[] = [];
      res.data.data.policyDetails?.forEach((data: any, index: number) => {
        let productlogo;
        switch (data.productType.code) {
          case 0:
            productlogo = "./images/term_insurance.svg";
            break;
          case 1:
            productlogo = "./images/health_insurance_img.svg";
            break;
          case 4:
            productlogo = "./images/super_topup.svg";
            break;
          default:
            productlogo = "";
        }

        const obj: PolicyWalletObj = {
          productlogo,
          policyId: data?.policyNumber,
          insurerlogo: data?.insurerLogo,
          heading1: data?.productType,
          heading2: "Policy No.",
          heading3: "Coverage for",
          spanheading1: "",
          spanheading2: data?.policyNumber || "",
          spanheading3: data?.coverage?.join(", ") || "",
          classvalue: walletStyle[index]?.classvalue || "",
          boxcolor: walletStyle[index]?.boxcolor || "",
          colorid: walletStyle[index]?.colorid || "",
          code: data?.productType?.code || 0,
          type: data?.planType || "",
          member: data?.member,
          fullData: data,
        };
        arr.push(obj);
      });
      setPolicyCard(res.data.data.policyDetails);
    };

    const onError = (err: any) => {
      console.log("Error occurred:", err);
    };

    const url = EMPLOYEE_URL_CONSTANTS.get_employee_wallet;
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };
  let timeout: any;

  useEffect(() => {
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      getWallet();

    };

    timeout = setTimeout(delayedApiCall, 700);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "health") {
      if (value === 0) {
        setCarRetailPolicy(true);
      } else if (value === 1) {
        setHealthRetailPolicy(true);
      }
    }
  };
  return (
    <>
      {showLoader && <NewLoader />}
      <>
        <AddRetailPolicy
          open_status={retailPolicy}
          attrName={setAddRetailPolicy}
          value_update={updateMasterState}
        />
        <HealthRetailPolicy
          open_status={healthRetailPolicy}
          attrName={setHealthRetailPolicy}
          value_update={updateMasterState}
        />
        <CarRetailPolicy
          open_status={carRetailPolicy}
          attrName={setCarRetailPolicy}
          value_update={updateMasterState}
        />
        <Grid xs className="policyWallet_wrapper">
          <Grid container spacing={3}>
            <Grid xs={12} className="main_heading mb-6">
              <h5>
                Policy <span> Wallet</span>
              </h5>
              <p>
                This is your digital space where you can keep a track of all the
                policies.
              </p>
            </Grid>
          </Grid>
          <div className="corporate_section">
            <Grid container spacing={3} marginBottom={"16px"}>
              <Grid md={12} style={{ alignSelf: "center" }}>
                <h6>Corporate Policy</h6>
                <p>It is provided by your employer</p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {policyCard.length > 0 &&
                policyCard?.map((data: any, index: number) => (
                  <Grid md={6} lg={4} xl={3} key={index}>
                    <InsurancePolicyDetails data={data} index={index} getWallet={getWallet} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </Grid>
      </>
    </>
  );
}

export default PolicyWallet;
