import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import { store } from "./Store/Store";
import NotAllowed from "./Pages/Desktop/Common/NotAllowed/NotAllowed";
import MNotAllowed from "./Pages/Desktop/Common/NotAllowed/MNotAllowed";

// Initialize Sentry once
Sentry.init({
  dsn: "https://b4bc70cb1b139a2501e5ad2d690c3f45@o4506864219521024.ingest.us.sentry.io/4506865893507072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "https://ebdev.evervent.in/",
    "https://ebdevbackend.evervent.in",
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const checkIframe = () => {
  if (window.top !== window.self) {
    // The app is in an iframe
    return <NotAllowed />;
  } else {
    return <App />;
  }
};

const ProviderComponent = () => (
  <Provider store={store}>{checkIframe()}</Provider>
);

ReactDOM.render(
  // <React.StrictMode>
  <>
    <ProviderComponent />
    {/* <MNotAllowed /> */}
  </>,

  // </React.StrictMode>,
  document.getElementById("root")
);
