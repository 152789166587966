import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LocationTableView from "../../../../../../../Components/Broker/ProductConfigurator/LocationTableView/LocationTableView";
import PhysicalDetailView from "../../../../../../../Components/Broker/ProductConfigurator/PhysicalFormDetailView/PhysicalDetailView";
import NewLoader from "../../../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../../../Routes/all_routes";
import { editInsurerDTO } from "../../../../../../../Services/DTO/ProductConfigurator/EditInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../../../../Services/ProductConfigurator/AddNewInsurer";
import { RootState } from "../../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../../Store/hooks";
import {
  date_time_format,
  getStaticNetworkList,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../../../Supporting files/HelpingFunction";
import RKTextFieldDisable from "../../../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdownDisable from "../../../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import "../../../../../../../formLayout.scss";

function InsurerDetails() {
  const navigate = useNavigate();
  const { dateFormat, timeZone } = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation);
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [sectionStatus, SetSectionStatus] = useState<string>("personalDetails");
  const [subSection, setSubSection] = useState("basicinfo");
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: any;
  }>();
  const [addInsurer, setAddInsurer] = useState<editInsurerDTO>({
    type: "GENERAL",
    insurerBasicInfo: {
      logo: "",
      name: "",
      email: "",
      networkCount: "",
      website: "",
      contactNumber: "",
      warnName: false,
      warnEmail: false,
      warnNetworkCount: false,
      warnContactNumber: false,
      warnWebsite: false,
    },
    headOfficeAddressDetails: {
      locationName: "",
      type: "",
      street: "",
      area: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      warnLocationName: false,
      warnType: false,
      warnStreet: false,
      warnArea: false,
      warnCity: false,
      warnState: false,
      warnCountry: false,
    },
    ageBand: {
      min: "",
      max: "",
      warnMin: false,
      warnMax: false,
    },
    location: [
      {
        name: "",
        type: "",
        address: "",
        areaname: "",
        city: "",
        state: "",
        zip: null,
        country: "",
        contactId: "",
        warnName: false,
        warnType: false,
        warnAddress: false,
        warnArea: false,
        warnCity: false,
        warnState: false,
        warnZip: false,
        warnCountry: false,
        warnContactId: false,
      },
    ],
    emailTemplate: [
      {
        title: "",
        module: "",
        subject: "",
        respondentEmail: "",
        sendersEmail: "",
        body: "",
        describe: "",
        attachmentEnable: false,
        warnTitle: false,
        warnModule: false,
        warnSubject: false,
        warnRespondentEmail: false,
        warnSendersEmail: false,
      },
    ],
    physicalForm: [
      {
        title: "",
        product: "",
        description: "",
        type: "",
        subtype: "",
        attachment: "",
        warnTitle: false,
        warnProduct: false,
        warnDescription: false,
        warnType: false,
        warnSubtype: false,
        warnAttachment: false,
      },
    ],
    createdAt: "",
    updatedAt: "",
  });
  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning?: boolean;
  }>({ logo: "", warning: false });
  const [hoslist, setHoslist] = useState<any[]>([])
  const [searchBarFilter, setsearchBarFilter] = useState<string>("");
  const filteredLogsData = hoslist?.filter((data) =>
    Object.values(data).some((value) =>
      typeof value === "string" &&
      value.toLowerCase().includes(searchBarFilter.toLowerCase())
    )
  );
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string }>>([]);
  const [stateData, setStateData] = useState<Array<{ label: string; id: string }>>([]);
  const [cityData, setCityData] = useState<Array<{ label: string; id: string }>>([]);
  const [locationupdate, setLocationUpdate] = useState<boolean>(false);
  const [disableAddress, setDisableAddress] = useState<boolean>(false);

  const [locationData, setLocationData] = useState([
    {
      companyName: "Evervent",
      locationType: "Registered Office",
      companyAddress: "321, Industrial Area  , Phase 9, Mohali, Punjab, India - 1411111",
      spoc: "SP01 - Satish (9876543210)",
      activePolicies: "5",
      actionBtn: "images/edit_icon.svg",
    },
    {
      companyName: "Evervent",
      locationType: "Head Office",
      companyAddress:
        "321, Industrial Area  , Phase 9, Mohali, Punjab, India - 1411111",
      spoc: "SP01 - Satish (9876543210)",
      activePolicies: "5",
      actionBtn: "images/edit_icon.svg",
    },
    {
      companyName: "Evervent",
      locationType: "RnD Office",
      companyAddress:
        "321, Industrial Area  , Phase 9, Mohali, Punjab, India - 1411111",
      spoc: "SP01 - Satish (9876543210)",
      activePolicies: "5",
      actionBtn: "images/edit_icon.svg",
    },
    {
      companyName: "Evervent",
      locationType: "Front Office",
      companyAddress:
        "321, Industrial Area  , Phase 9, Mohali, Punjab, India - 1411111",
      spoc: "SP01 - Satish (9876543210)",
      activePolicies: "5",
      actionBtn: "images/edit_icon.svg",
    },
    {
      companyName: "Evervent",
      locationType: "Head Office",
      companyAddress:
        "321, Industrial Area  , Phase 9, Mohali, Punjab, India - 1411111",
      spoc: "SP01 - Satish (9876543210)",
      activePolicies: "5",
      actionBtn: "images/edit_icon.svg",
    },
  ]);

  // for scrolling
  const personalDetails = useRef(null);
  const address = useRef(null);
  const location = useRef(null);
  const networkhospital = useRef(null);
  const basicinfo = useRef(null);
  const physicalform = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [id, setId] = useState("");
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [EditPermission, setEditPermission] = useState(true);

  useEffect(() => {
    get_city_list_via_name(
      addInsurer?.headOfficeAddressDetails?.country,
      addInsurer?.headOfficeAddressDetails?.state,
      (cb: any) => {
        setCityData(cb);
      }
    );
    get_state_list(addInsurer?.headOfficeAddressDetails?.country, (cb: any) => {
      setStateData(cb);
    });
    setHoslist(getStaticNetworkList())
  }, [addInsurer]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    insurerDetail(id ?? "");
    setShowLoader(true);
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    setEditPermission(BROKER?.ProductConfigurator.edit === undefined ? false : BROKER?.ProductConfigurator.edit);
  }, []);

  const insurerDetail = (id: string) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setAddInsurer(res.data.data[0] ? res.data.data[0] : []);
      setLocationData(res.data.data[0].location);
      let data: any = [];
      res.data.data[0].emailTemplate.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      let dataPhysical: any = [];
      res.data.data[0].emailTemplate.forEach((e: any) => {
        dataPhysical.push({ ...e, id: e._id });
      });
      setDisplayLogo({ logo: res.data.data[0].insurerBasicInfo.logo });
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ADD_NEW_INSURER.getInsuranceDetail(onSuccess, onError, id);
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainstate = attrName[0];
    let key = attrName[1];
    if (addInsurer?.insurerBasicInfo?.hasOwnProperty(key)) {
      setAddInsurer({
        ...addInsurer,
        insurerBasicInfo: {
          ...addInsurer.insurerBasicInfo,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
            value.length === 0 ? true : false,
        },
      });
      setIndividualEditKey({
        ...individualEditKey,
        key: "insurerBasicInfo." + key,
        value: value,
      });
    } else if (addInsurer?.headOfficeAddressDetails?.hasOwnProperty(key)) {
      setAddInsurer({
        ...addInsurer,
        headOfficeAddressDetails: {
          ...addInsurer.headOfficeAddressDetails,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
            value === null || value.length === 0 ? true : false,
        },
      });
      if (attrName[1] === "country") {
        setAddInsurer((prev) => ({
          ...prev,
          headOfficeAddressDetails: {
            ...prev.headOfficeAddressDetails,
            state: "",
            city: "",
          },
        }));
      }
      if (attrName[1] === "state") {
        setAddInsurer((prev) => ({
          ...prev,
          headOfficeAddressDetails: {
            ...prev.headOfficeAddressDetails,
            city: "",
          },
        }));
      }
      setIndividualEditKey({
        ...individualEditKey,
        key: "headOfficeAddressDetails." + key,
        value: value,
      });
      // if (key === "country") {
      //   setSelectedCountry(value);
      // } else if (key === "state") {
      //   setSelectedState(value);
      // }
    } else if (addInsurer.ageBand?.hasOwnProperty(key)) {
      setAddInsurer({
        ...addInsurer,
        ageBand: {
          ...addInsurer.ageBand,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
            value.length === 0 ? true : false,
        },
      });
      setIndividualEditKey({
        ...individualEditKey,
        key: "ageBand." + key,
        value: value,
      });
    } else if (attrName === "location_updated") {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      const id = params.get("id");

      setId(id ?? "");
      insurerDetail(id ?? "");
      setLocationUpdate(value);
    } else {
      attrName(value);
    }
  };

  const individual_edit = () => {
    setDisableAddress(false);
    const keyValidation: any = individualEditKey?.key.split(".");
    if (keyValidation === undefined) {
      return;
    }
    if (
      individualEditKey?.value === null ||
      individualEditKey?.value.length !== 0
    ) {
      if (
        keyValidation[1] === "email" &&
        !validateEmail(
          individualEditKey?.value === undefined ? "" : individualEditKey?.value
        )
      ) {
        return toast.error("Enter valid email");
      } else if (
        keyValidation[1] === "website" &&
        !validateUrl(
          individualEditKey?.value === undefined ? "" : individualEditKey?.value
        )
      ) {
        return toast.error("Enter valid url");
      } else if (
        keyValidation[1] === "contactNumber" &&
        !validateMobileNumber(
          individualEditKey?.value === undefined ? "" : individualEditKey?.value
        )
      ) {
        return toast.error("Enter valid mobile number");
      } else {
        const onSuccess = (res: any) => {
          toast.success("Field Update successfully");
        };
        const onError = (err: any) => {
          console.log("valuefalse", err);
        };
        if (
          individualEditKey?.key === "headOfficeAddressDetails.country" ||
          individualEditKey?.key === "headOfficeAddressDetails.state" ||
          individualEditKey?.key === "headOfficeAddressDetails.city"
        ) {
          if (
            addInsurer?.headOfficeAddressDetails?.country === "" ||
            addInsurer?.headOfficeAddressDetails?.state === "" ||
            addInsurer?.headOfficeAddressDetails?.city === "" ||
            addInsurer?.headOfficeAddressDetails?.country?.id === "N.A." ||
            addInsurer?.headOfficeAddressDetails?.state?.id === "N.A." ||
            addInsurer?.headOfficeAddressDetails?.city?.id === "N.A."
          ) {
            setDisableAddress(true);
            return toast.error(
              "Please check Country, state or city may be empty"
            );
          }

          let arr = [
            {
              key: "headOfficeAddressDetails.country",
              value:
                addInsurer?.headOfficeAddressDetails?.country?.label ??
                addInsurer?.headOfficeAddressDetails?.country,
            },
            {
              key: "headOfficeAddressDetails.state",
              value:
                addInsurer?.headOfficeAddressDetails?.state?.label ??
                addInsurer?.headOfficeAddressDetails?.state,
            },
            {
              key: "headOfficeAddressDetails.city",
              value:
                addInsurer?.headOfficeAddressDetails?.city?.label ??
                addInsurer?.headOfficeAddressDetails?.city,
            },
          ];

          arr?.map((item: any) => {
            ADD_NEW_INSURER.updateInsurerIndividualEdit(
              item,
              onSuccess,
              {},
              onError,
              id
            );
          });
        } else {
          ADD_NEW_INSURER.updateInsurerIndividualEdit(
            individualEditKey,
            onSuccess,
            {},
            onError,
            id
          );
        }
      }
    } else {
      toast.error("Enter the Value");
    }
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="layoutWrapper">
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>
              <span className="detailIcon">
                {addInsurer?.insurerBasicInfo?.name.charAt(0)}
              </span>
              <div>
                <h3>{addInsurer?.insurerBasicInfo?.name}</h3>
                <p>
                  Created on:{" "}
                  {date_time_format(addInsurer.createdAt, dateFormat, timeZone)}
                </p>
              </div>
            </Grid>
            <Grid
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >{EditPermission && (<div>
              <Tooltip title="Global Edit">
                <Link
                  className="actionLinks"
                  onClick={() => {
                    navigate(`${ALL_ROUTES.EDIT_INSURER}?id=${id}`);
                  }}
                >
                  <BorderColorIcon />
                </Link>
              </Tooltip>
            </div>)}

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <>
                <Grid container spacing={3}>
                  {viewTabsStatus === "overview" ? (
                    <Grid xs className="leftSection h-100">
                      <Link
                        className={
                          sectionStatus === "personalDetails"
                            ? "active sectionLink personalDetails"
                            : "sectionLink personalDetails"
                        }
                        onClick={() => {
                          scrollToSection(personalDetails);
                          SetSectionStatus("personalDetails");
                        }}
                      >
                        <span> Basic Info</span>
                      </Link>
                      <ul className="accordion_list">
                        <li>
                          <Link
                            className={
                              subSection === "basicinfo"
                                ? `inner_link active`
                                : `inner_link`
                            }
                            onClick={() => {
                              setSubSection("basicinfo");
                              handleClicks(subSection);
                              scrollToSection(basicinfo);
                              SetSectionStatus("personalDetails");
                            }}
                          >
                            Basic info
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              subSection === "address"
                                ? `inner_link active`
                                : `inner_link`
                            }
                            onClick={() => {
                              setSubSection("address");
                              handleClicks(subSection);
                              scrollToSection(address);
                              SetSectionStatus("personalDetails");
                            }}
                          >
                            Address Details
                          </Link>
                        </li>
                      </ul>
                      {/* <Link
                    className={
                      sectionStatus === "ageband"
                        ? "active sectionLink ageband"
                        : "sectionLink ageband"
                    }
                    onClick={() => {
                      handleClicks(ageband);
                      scrollToSection(ageband);
                      SetSectionStatus("ageband");
                    }}
                  >
                    <span>Age Band for Children</span>
                  </Link> */}
                      {/* <Link
                    className={
                      sectionStatus === "emailtemp"
                        ? "active sectionLink emailtemp"
                        : "sectionLink emailtemp"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(emailtemp);
                      SetSectionStatus("emailtemp");
                    }}
                  >
                    <span>Email Templates</span>
                  </Link> */}
                      <Link
                        className={
                          sectionStatus === "physicalform"
                            ? "active sectionLink physicalform"
                            : "sectionLink physicalform"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(physicalform);
                          SetSectionStatus("physicalform");
                        }}
                      >
                        <span>Physical Forms</span>
                      </Link>
                    </Grid>
                  ) : viewTabsStatus === "location" ? (
                    <Grid xs className="leftSection h-100">
                      <Link
                        className={
                          sectionStatus === "location"
                            ? "active sectionLink location"
                            : "sectionLink location"
                        }
                        onClick={() => {
                          scrollToSection(location);
                          SetSectionStatus("location");
                        }}
                      >
                        <span> Office Locations</span>
                      </Link>
                    </Grid>
                  ) : viewTabsStatus === "networkhospital" ? (
                    <Grid xs className="leftSection h-100">
                      <Link
                        className={
                          sectionStatus === "networkhospital"
                            ? "active sectionLink networkhospital"
                            : "sectionLink networkhospital"
                        }
                        onClick={() => {
                          scrollToSection(networkhospital);
                          SetSectionStatus("networkhospital");
                        }}
                      >
                        <span>Network Hospital</span>
                      </Link>
                    </Grid>
                  ) : null}
                  <Grid xs className="rightSection h-100">
                    <Grid
                      container
                      spacing={3}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid lg={7} md={7} xs={12}>
                        <div className="productTabs mb-4">
                          <Button
                            className={
                              viewTabsStatus === "overview"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(setViewTabsStatus, "overview");
                              SetSectionStatus("personalDetails");
                            }}
                          >
                            Overview
                          </Button>
                          <Button
                            className={
                              viewTabsStatus === "location"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(setViewTabsStatus, "location");
                              SetSectionStatus("location");
                            }}
                          >
                            locations
                          </Button>
                          <Button
                            className={
                              viewTabsStatus === "networkhospital"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "networkhospital"
                              );
                              SetSectionStatus("networkhospital");
                            }}
                          >
                            Network Hospitals
                          </Button>
                        </div>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        xs={12}
                        className="text-sm-center"
                        alignSelf="center"
                      >
                        <p className="lastupdate">
                          Last updated on:{" "}
                          {date_time_format(
                            addInsurer.updatedAt,
                            dateFormat,
                            timeZone
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    {viewTabsStatus === "overview" ? (
                      <>
                        <Box
                          onMouseEnter={() => {
                            SetSectionStatus("personalDetails");
                          }}
                          className="detailSection"
                          ref={personalDetails}
                        >
                          <div className="sectionTitle mb-0">
                            <h4>Basic Info</h4>
                            <div className="actionBtns">
                              {/* <Link
                          className="blueBtn edit"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Edit
                        </Link> */}
                            </div>
                          </div>
                          <div onMouseEnter={() => {
                            setSubSection("basicinfo");
                          }} ref={basicinfo}
                          >
                            <Grid container spacing={3}>
                              <Grid xs={12}>
                                <h5 className="subHeading">Basic info</h5>
                              </Grid>
                            </Grid>

                            <Grid
                              className="upload-logo"
                              container
                              spacing={3}
                              alignItems="center"
                            >
                              <Grid xs={12}>
                                <img
                                  src={
                                    displayLogo.logo
                                      ? displayLogo.logo
                                      : "images/preview-logo-img.svg"
                                  }
                                  alt=""
                                  className="upload_company_logo add_logo"
                                />
                                <Link className="upload_img" component="label">
                                  <input
                                    hidden
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    // value={addInsurer?.insurerBasicInfo.name}
                                    onChange={(e: any) => {
                                      if (!EditPermission) return toast.error("Edit permissions not allowed");
                                      let displaydata: any = displayLogo;
                                      displaydata.warning = displayLogo.logo !== "" ? true : false;
                                      setDisplayLogo({ ...displaydata });
                                      const file = e.target.files[0];
                                      uploadImage(file, (
                                        base64Image: any,
                                        base64String: any
                                      ) => {
                                        setAddInsurer((prevState) => ({
                                          ...prevState,
                                          insurerBasicInfo: {
                                            ...prevState.insurerBasicInfo,
                                            logo: base64Image,
                                          },
                                        }));
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                        const onSuccess = (res: any) => {
                                          toast.success("Field Update successfully");
                                        };
                                        const onError = (err: any) => {
                                          console.log("valuefalse", err);
                                        };
                                        ADD_NEW_INSURER.updateInsurerIndividualEdit(
                                          {
                                            key: "insurerBasicInfo.logo",
                                            value: base64Image,
                                          },
                                          onSuccess,
                                          {},
                                          onError,
                                          id
                                        );
                                      }
                                      );
                                      individual_edit();
                                    }}
                                  />
                                  <img
                                    src="images/upload_logo_icon.svg"
                                    alt=""
                                  />
                                  <span>Update logo</span>
                                </Link>
                              </Grid>
                              <Grid xs={6} textAlign="right">
                                {/* <Link  className="networkhosp">
                            Network Hospitals
                          </Link> */}
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid sm={6} md={4} lg={6} xl={4}>
                                <RKTextFieldDisable
                                  validation_type="name"
                                  class_name="inputField"
                                  title="Insurer Name"
                                  value={addInsurer?.insurerBasicInfo?.name}
                                  attrName={[
                                    addInsurer?.insurerBasicInfo,
                                    "name",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.insurerBasicInfo?.warnName
                                  }
                                  params={individual_edit}
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              {/* <Grid sm={4} md={4} lg={3} xl={2}>
                          <RKTextFieldDisable
                            validation_type="numeric"
                            class_name="inputField"
                            title="No. Of Hospital"
                            value={addInsurer?.insurerBasicInfo.networkCount}
                            attrName={[
                              addInsurer?.insurerBasicInfo,
                              "networkCount",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer?.insurerBasicInfo.warnNetworkCount
                            }
                            params={individual_edit}
                          />
                        </Grid> */}
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid sm={4} md={4} lg={3} xl={2}>
                                <RKTextFieldDisable
                                  validation_type="url"
                                  class_name="inputField"
                                  title="Website URL"
                                  value={addInsurer?.insurerBasicInfo?.website}
                                  attrName={[
                                    addInsurer?.insurerBasicInfo,
                                    "website",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.insurerBasicInfo?.warnWebsite
                                  }
                                  params={individual_edit}
                                  error_messg={
                                    !validateUrl(
                                      addInsurer?.insurerBasicInfo?.website
                                    )
                                      ? "Enter valid url"
                                      : addInsurer?.insurerBasicInfo?.website
                                        .length === 0
                                        ? "Enter Url"
                                        : ""
                                  }
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid sm={4} md={4} lg={3} xl={2}>
                                <RKTextFieldDisable
                                  validation_type="email"
                                  class_name="inputField"
                                  title="Email"
                                  value={addInsurer?.insurerBasicInfo?.email}
                                  value_update={updateMasterState}
                                  attrName={[
                                    addInsurer?.insurerBasicInfo,
                                    "email",
                                  ]}
                                  warn_status={
                                    addInsurer?.insurerBasicInfo?.warnEmail
                                  }
                                  params={individual_edit}
                                  error_messg={
                                    !validateEmail(
                                      addInsurer?.insurerBasicInfo?.email
                                    )
                                      ? "Enter valid email"
                                      : addInsurer?.insurerBasicInfo?.website
                                        .length === 0
                                        ? "Enter email"
                                        : ""
                                  }
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid sm={4} md={4} lg={3} xl={2}>
                                <RKTextFieldDisable
                                  validation_type="numeric"
                                  class_name="inputField"
                                  title="Contact Number"
                                  value={
                                    addInsurer?.insurerBasicInfo?.contactNumber
                                  }
                                  value_update={updateMasterState}
                                  attrName={[
                                    addInsurer?.insurerBasicInfo,
                                    "contactNumber",
                                  ]}
                                  warn_status={
                                    addInsurer?.insurerBasicInfo
                                      ?.warnContactNumber
                                  }
                                  charcterlimit={10}
                                  params={individual_edit}
                                  disabled={!EditPermission}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <div
                            onMouseEnter={() => {
                              setSubSection("address");
                            }}
                            ref={address}
                          >
                            <Grid container spacing={3}>
                              <Grid xs={12}>
                                <h5 className="subHeading">
                                  Head Office Address Details
                                </h5>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextFieldDisable
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Location Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.locationName
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "locationName",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer.headOfficeAddressDetails
                                      ?.warnLocationName
                                  }
                                  error_messg="Enter valid Value"
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextFieldDisable
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Type"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.type
                                      ? addInsurer?.headOfficeAddressDetails
                                        ?.type
                                      : ""
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "type",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer.headOfficeAddressDetails
                                      ?.warnLocationName
                                  }
                                  error_messg="Please select Location"
                                  disabled={!EditPermission}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextFieldDisable
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Building/Street Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.street
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "street",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnStreet
                                  }
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextFieldDisable
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Area Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.area
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "area",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnArea
                                  }
                                  disabled={!EditPermission}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdownDisable
                                  disable_status={disableAddress}
                                  set_disable_status={setDisableAddress}
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Country"
                                  value={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.country
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "country",
                                  ]}
                                  value_update={updateMasterState}
                                  data={countryData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnCountry
                                  }
                                  error_messg="Enter value"
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdownDisable
                                  disable_status={disableAddress}
                                  set_disable_status={setDisableAddress}
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="State"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.state
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "state",
                                  ]}
                                  value_update={updateMasterState}
                                  data={stateData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnState
                                  }
                                  error_messg="Enter value"
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdownDisable
                                  disable_status={disableAddress}
                                  set_disable_status={setDisableAddress}
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="City"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.city
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "city",
                                  ]}
                                  value_update={updateMasterState}
                                  data={cityData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnCity
                                  }
                                  error_messg="Enter value"
                                  disabled={!EditPermission}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <RKTextFieldDisable
                                  params={individual_edit}
                                  class_name="inputField"
                                  title="Pin Code"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.zip
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "zip",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  validation_type="numeric"
                                  charcterlimit={6}
                                  disabled={!EditPermission}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Box>
                        {/* <Box
                      onMouseEnter={() => {
                        SetSectionStatus("emailtemp");
                      }}
                      ref={emailtemp}
                    >
                      <EmailTemplateView />
                    </Box> */}
                        <Box
                          onMouseEnter={() => {
                            SetSectionStatus("physicalform");
                          }}
                          ref={physicalform}
                        >
                          <PhysicalDetailView />
                        </Box>
                      </>
                    ) : null}

                    {viewTabsStatus === "location" ? (
                      <LocationTableView
                        id={id}
                        parentType={ADD_NEW_INSURER.updateInsurerIndividualEdit}
                        length={
                          addInsurer.location?.length
                            ? addInsurer.location?.length
                            : 0
                        }
                        deleteurl={ADD_NEW_INSURER.deleteInsurerLocation}
                        value_update={updateMasterState}
                        data={locationData}
                        parentComponentname="insurerdetails"
                      />
                    ) : null}
                    {viewTabsStatus === "networkhospital" ? <>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Grid xs={12} className="px-0 py-0">
                          <div
                            className="tableview"
                            style={{
                              borderRadius: "16px",
                              borderRight: "1px solid #f1f6ff",
                            }}
                          >
                            <div className="tableinner">
                              <ul className="tableHeadercashlesshospital">
                                <li style={{ width: "50%" }}>
                                  Hospital Name / Address
                                  <button className="sorting-btn"></button>
                                </li>
                                <li style={{ width: "50%", textAlign: "right" }}>
                                  City-Pincode / State
                                  <button className="sorting-btn"></button>
                                </li>
                              </ul>
                              <Box sx={{ maxHeight: "650px", overflow: "auto", borderRadius: "16px", }}>
                                <>
                                  {window.location.hostname === "eb.evervent.io"?filteredLogsData?.map((item: any, i) => (
                                    <ul className="tableData">
                                      <li style={{ width: "50%" }}>
                                        {item.hospital_name}
                                        <div className="liItem">{`${item.address_1} ${item.address_2}`}</div>
                                      </li>
                                      <li style={{ width: "50%", textAlign: "right" }}>
                                        {item.city_name}-{item.pin_code}
                                        <div className="liItem">{item.state_name}</div>
                                      </li>
                                    </ul>
                                  )): (<><h1>No Data Found</h1></>)}
                                </>
                              </Box>
                            </div>
                          </div>
                        </Grid>
                      </Box>
                    </> : null}
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box >
      )
      }
    </>
  );
}
export default InsurerDetails;
