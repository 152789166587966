
const finalUrl: string = `${process.env.REACT_APP_BACKEND_URL}`;
//const finalUrl: string = `${"https://ebstgbackend.evervent.in"}`;
const BASE_URL: any = `${finalUrl}/api/`;

export const EMPLOYEE_URL_CONSTANTS = {
  AUTH_CHECK: `${BASE_URL}/auth/employee/check`,
  GET_ENROLMENT_EMPLOYEE_POLICIES: (empId: string, queryParams: string) =>
    `${BASE_URL}employee/enrolement-employee-policy?id=${empId}&${queryParams}`,
  set_password: (userId: string) =>
    `${BASE_URL}employee/employee-set-password?id=${userId}`,
  add_family_member: (empId: string) => `${BASE_URL}employee/enrolement-assign-member-to-policy?employeeId=${empId}`,
  get_policy_features_exclusions: (policyId: string) =>
    `${BASE_URL}employee/policy-features-exclusions?id=${policyId}`,
  get_employee_wallet: `${BASE_URL}employee/wallet`,
  get_employee_profile: `${BASE_URL}employee/profile`,

  // ENDORSEMENT
  add_endoresement: `${BASE_URL}employee/endorsment`,
  get_employee_list_by_client: `${BASE_URL}employee/employee-dropdown-list?clientId=`,
  get_policy_list_by_employee: `${BASE_URL}employee/employee-policy-dropdown-view/`,
  get_policy_list_by_employee_dependent: `${BASE_URL}employee/get-dependent-attached-policies`,
  get_member_detail: `${BASE_URL}employee/member-details?id=`,
  //CLAIM
  add_claim: `${BASE_URL}employee/client/claim`,
  // NOMINE
  add_nomine: `${BASE_URL}employee/assign-nominee-to-policy?employeeId=`,
  get_employee_detail_layout: `${BASE_URL}employee/employee-detail-view?id=`,

  //MEMBER
  get_member_list: (user: string) => `${BASE_URL}${user}/get-family`,
  get_assigned_policies: `${BASE_URL}employee/employee-policy-view?employeeId=`,
  getPreviousInsurer: `${BASE_URL}global/previous-insurer`,
  add_member: `${BASE_URL}employee/add-policy-member?`,
  get_claim_assistant: `${BASE_URL}employee/endorsment-list`,
  get_dashboard_data: `${BASE_URL}employee/dashboard`,
  support: `${BASE_URL}employee/support`,
  submit_doc_endorsment_claim: (Objectid: string, type: string) => `${BASE_URL}employee/attachment?id=${Objectid}&type=${type}`,

  add_profile_member: `${BASE_URL}employee/employee-family-member`,
  //CHANGEPASSWORD
  change_password: `${BASE_URL}employee/change-password`,
  update_pic: `${BASE_URL}employee/update-profile`,
  get_policy_details: `${BASE_URL}employee/policy-detail-view`,
  submitRider: `${BASE_URL}employee/add_rider`,
  add_family_member_from_journey: `${BASE_URL}employee/add-family-member`,
  complete_Policy_Assignment: (user: string) => `${BASE_URL}${user}/complete-policy-assignment`,
  delete_family_member: `${BASE_URL}employee/employee-member-delete`,
};
