import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import Modules from "../CustomisationSettings/Modules/Modules";
import Templates from "../CustomisationSettings/Templates/Templates";
import Appearance from "../GeneralSettings/Appearance/Appearance";
import EmailSettings from "../GeneralSettings/EmailSettings/EmailSettings";
import EditPersonalDetails from "../GeneralSettings/PersonalSettings/EditPersonalDetails/EditPersonalDetails";
import PersonalSettings from "../GeneralSettings/PersonalSettings/PersonalSettings";
import MainSettingMenu from "../MainSettingMenu/MainSettingMenu";
import Branches from "../OrganisationSettings/Branches/Branches";
import Currencies from "../OrganisationSettings/Currencies/Currencies";
import Department from "../OrganisationSettings/Department/Department";
import OrganisationDetails from "../OrganisationSettings/OrganisationDetails/OrganisationDetails";
import Taxation from "../OrganisationSettings/Taxation/Taxation";
import AccessControl from "../UserAndControl/AccessControl/AccessControl";
import Users from "../UserAndControl/Users/Users";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import StorageSettings from "../GeneralSettings/Storage/Storage";
import Retails from "../Developer/Retails/Retails";

function SettingModulesForm() {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("");
  const [section, setSection] = useState("");
  const { BROKER, EMPLOYER } = useAppSelector((state: RootState) => state.permissionSlice);
  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    let section = params.get("section");
    let page = params.get("page");
    setSection(section ?? "");
    setActivePage(page ?? "");
  }, [window.location.search]);
  return (
    <Box className="newSettingWrapper">
      <Grid xs={12}>
        <h3 className="pageTitle mb-7 ">
          <Link
            onClick={() => {
              navigate(ALL_ROUTES.SETTING);
            }}>
            <ChevronLeftIcon />
          </Link>
          Settings
        </h3>
      </Grid>
      <Grid container paddingBottom={"8px"}>
        <Grid xs sx={{ maxWidth: "250px" }}>
          <MainSettingMenu />
        </Grid>
        <Grid xs>
          {activePage === "modules" && BROKER?.Module?.view ? <Modules /> : null}
          {activePage === "templates" ? <Templates /> : null}
          {activePage === "personalSettings" && BROKER?.personalSetting?.view ? <PersonalSettings /> : null}
          {activePage === "editPersonalSettings" && BROKER?.personalSetting?.view && BROKER?.personalSetting?.edit ? <EditPersonalDetails /> : null}
          {activePage === "emailSettings" ? <EmailSettings /> : null}
          {activePage === "storageSettings" ? <StorageSettings /> : null}
          {activePage === "appearance" && BROKER?.Appearance?.view ? <Appearance /> : null}
          {activePage === "currencies" ? <Currencies /> : null}
          {activePage === "users" ? <Users /> : null}
          {activePage === "accessControl" ? <AccessControl /> : null}
          {activePage === "taxation" ? <Taxation /> : null}
          {activePage === "branches" ? <Branches /> : null}
          {activePage === "departments" ? <Department /> : null}
          {activePage === "companyDetails" ? <OrganisationDetails /> : null}
          {activePage === "retail" ? <Retails /> : null}
        </Grid> 
      </Grid>
    </Box>
  );
}

export default SettingModulesForm;
