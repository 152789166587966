import { Alert, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "./../../../../../SlideModalOpen.scss";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";

const SelectFeature = ({
  open_status,
  attrName,
  value_update,
  data_list,
  component_name,
  index_value,
  leng,
  productType,
  setShowLoader,
}: {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data_list?: any;
  component_name?: string;
  index_value?: any;
  leng?: any;
  productType?: string;
  setShowLoader?: Function;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const [addFeature, setAddFeature] = useState<any>([]);
  const [sendaddFeature, setsendAddFeature] = useState<any>([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<Array<any>>(data_list);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader && setShowLoader(false);
    }, 1000);

    // Add the new key to every "features" object
    if (component_name === "FLAT") {
      const modifiedData = filteredData?.map((item: any) => ({
        ...item,
        features: item?.features?.map((feature: any) => ({
          ...feature,
          value: "",
          check: feature.check || false,
          groupid: "",
          id: "",
          // Replace "New Value" with your desired value
        })),
      }));

      setAddFeature(modifiedData);
      setAddFeature(filteredData);
    } else if (component_name === "GRADED") {
    }
  }, [open_status]);

  const updateMasterStateArray = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      const filteredData = data_list?.filter(
        (data: any) =>
          data?.groupName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          data?.groupDescription
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          data?.features?.some(
            (feature: any) =>
              feature?.description
                ?.toLowerCase()
                ?.includes(value?.toLowerCase()) ||
              feature?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
              feature?.fieldType
                ?.toLowerCase()
                ?.includes(value?.toLowerCase()) ||
              feature?.unit?.toLowerCase()?.includes(value?.toLowerCase()) ||
              feature?.customUnitType
                ?.toLowerCase()
                ?.includes(value?.toLowerCase())
          )
      );
      setFilteredData(filteredData);
    }
    if (component_name === "GRADED") {
      const array_data = [...data_list];
      if (attrName[2] === "value") {
        if (attrName[3] === "Currency") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            formatCurrency(+value));
        } else if (attrName[3] === "Free Text") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            value);
        } else if (attrName[3] === "Percentage") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            value);
        } else if (attrName[3] === "dropdown") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[4])
            .features.find((feature: any) => feature.id === attrName[5]).value =
            value);
        } else {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            value);
        }
      } else if (attrName[2] === "checkbox_status") {
        const resultFeature = (array_data
          .find((group: any) => group._id === attrName[3])
          .features.find((feature: any) => feature.id === attrName[4]).check =
          value);
      }
      setAddFeature(array_data);

      setsendAddFeature(array_data);
    } else if (component_name === "FLAT") {
      const array_data = [...data_list];
      if (attrName[2] === "value") {
        if (attrName[3] === "Currency") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            formatCurrency(+value));
        } else if (attrName[3] === "Free Text") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            value);
        } else if (attrName[3] === "Percentage") {
          if (Number(value) <= 100) {
            const resultFeature = (array_data
              .find((group: any) => group._id === attrName[5])
              .features.find(
                (feature: any) => feature.id === attrName[6]
              ).value = value);
          }
        } else if (attrName[3] === "dropdown") {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[4])
            .features.find((feature: any) => feature.id === attrName[5]).value =
            value);
        } else {
          const resultFeature = (array_data
            .find((group: any) => group._id === attrName[5])
            .features.find((feature: any) => feature.id === attrName[6]).value =
            value);
        }
      } else if (attrName[2] === "checkbox_status") {
        const resultFeature = (array_data
          .find((group: any) => group._id === attrName[3])
          .features.find((feature: any) => feature.id === attrName[4]).check =
          value);
      }
      setAddFeature(array_data);

      const modifiedData = array_data?.map((item: any) => ({
        ...item,
        features: item.features.map((feature: any) => ({
          ...feature,
          value: feature.value ? feature.value : "",
        })),
      }));
      setsendAddFeature(modifiedData);
    }
  };

  const onSubmit = () => {
    let dataWarn = false;
    sendaddFeature?.map((data: any) =>
      data.features?.map((el: any) => {
        if (el.check === true && el.value !== "") {
        } else if (el.check === true && el.value === "") {
          dataWarn = true;
        }
      })
    );
    if (dataWarn === false) {
      let arr: any = [];
      data_list?.map((data: any) =>
        data.features?.map((el: any) =>
          el.check === true && el.value
            ? arr.push({
              name: el.name,
              unit: el.unit === "Free Text" ? el.customUnitType : el.unit,
              value: el.value,
              featureId: el.id,
              groupId: data._id,
            })
            : null
        )
      );
      let mainData;
      if (component_name === "FLAT") {
        mainData = arr;
      } else {
        mainData = sendaddFeature;
      }
      value_update(["slidepanelfeatures"], false);
      value_update([component_name, index_value], mainData);
    } else {
      toast.error("Please Check value");
    }
  };

  useEffect(() => {
    updateMasterStateArray("", "");
  }, []);

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="slidingPanel"
    >
      <div className="select_feature">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3">Select Features</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-2">
            <GridCloseIcon
              onClick={() => value_update(["slidepanelfeatures"], false)}
            />
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <SearchBox value_update={updateMasterStateArray} fullWidth={true} />
        </Grid>
        {filteredData?.length > 0 && (
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            {component_name === "GRADED" ? (
              <Grid xs={12}>
                <div className="scrollable_area">
                  {filteredData?.map((data: any, index: any) => (
                    <div
                      style={{
                        border: `1px solid ${border_code[index]}`,
                        borderRadius: 10,
                      }}
                      className="coverage_section mr-3 mb-6 blue"
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{ alignItems: "center" }}
                      >
                        <Grid
                          xs={12}
                          sx={{
                            color: color_code[index],
                            backgroundColor: border_code[index],
                          }}
                          className="coverage_heading blue"
                        >
                          <h5 className="mb-0">{data?.groupName}</h5>
                          {/* <p className={"started_heading " + item.title_class}></p> */}
                        </Grid>
                        {data?.features?.map((value: any, indexx: any) => (
                          <>
                            <Grid xs={8} className="checkbox_feature">
                              <CheckBox
                                title={
                                  <p>
                                    {value?.name}
                                    <span>{value?.description}</span>
                                  </p>
                                }
                                name={index.toString() + indexx.toString()}
                                id={index.toString() + indexx.toString()}
                                value={value.check}
                                attrName={[
                                  index,
                                  indexx,
                                  "checkbox_status",
                                  data._id,
                                  value.id,
                                ]}
                                value_update={updateMasterStateArray}
                                default_check={false}
                              />
                            </Grid>
                            <Grid xs={4}>
                              {value.fieldType === "Text Field" ||
                                value.fieldType === "Input Field" ? (
                                <>
                                  <RKTextField
                                    class_name="inputField"
                                    title="value"
                                    value={value.value}
                                    attrName={[
                                      index,
                                      indexx,
                                      "value",
                                      value.unit,
                                      value.customUnitType,
                                      data._id,
                                      value.id,
                                    ]}
                                    value_update={updateMasterStateArray}
                                    warn_status={false}
                                    validation_type={
                                      value.valueType === "Numeric Only" &&
                                        value.unit === "Free Text"
                                        ? "name"
                                        : value.valueType === "Numeric Only" &&
                                          value.unit !== "Free Text"
                                          ? "numeric"
                                          : value.valueType === "Text Only"
                                            ? "name"
                                            : "name"
                                    }
                                    alphanumeric={
                                      value.valueType === "Alphanumeric" ||
                                        (value.valueType === "Numeric Only" &&
                                          value.unit === "Free Text")
                                        ? true
                                        : false
                                    }
                                    specialcharacter={
                                      value.valueType === "Alphanumeric" ||
                                        (value.valueType === "Numeric Only" &&
                                          value.unit === "Free Text")
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    style={{
                                      display: "block",
                                      fontSize: "12px",
                                      color: "#828282",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {value.unit === "Free Text"
                                      ? value.customUnitType
                                      : value.unit}
                                  </span>
                                </>
                              ) : (
                                // <SearchDropdown
                                //   class_name="inputField"
                                //   title="Select Field"
                                //   value={value.value}
                                //   attrName={[
                                //     index,
                                //     indexx,
                                //     "value",
                                //     "dropdown",
                                //     data._id,
                                //     value.id,
                                //   ]}
                                //   value_update={updateMasterStateArray}
                                //   data={value.dropDownOptions}
                                //   warn_status={false}
                                // />
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Select Field"
                                  value={value.value}
                                  attrName={[
                                    index,
                                    indexx,
                                    "value",
                                    "dropdown",
                                    data._id,
                                    value.id,
                                  ]}
                                  value_update={updateMasterStateArray}
                                  dropdown_data={value.dropDownOptions}
                                  warn_status={false}
                                />
                              )}
                            </Grid>
                            <Grid xs={12} className="mb-0 pt-0 pb-0">
                              {indexx + 1 === data.data?.length ? null : <hr />}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </div>
                  ))}
                </div>
              </Grid>
            ) : component_name === "FLAT" ? (
              <Grid xs={12}>
                <div className="scrollable_area">
                  {filteredData?.map((data: any, index: any) => (
                    <div
                      style={{ border: `1px solid ${border_code[index]}` }}
                      className="coverage_section mr-3 mb-6 blue"
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{ alignItems: "center" }}
                      >
                        <Grid
                          xs={12}
                          sx={{ color: color_code[index] }}
                          className="coverage_heading "
                        >
                          <h5>{data.groupName}</h5>
                          {/* <p className={"started_heading " + item.title_class}></p> */}
                        </Grid>
                        {data.features.map((value: any, indexx: any) => (
                          <>
                            <Grid xs={8} className="checkbox_feature">
                              <CheckBox
                                title={
                                  <p>
                                    {value.name}
                                    <span>{value.description}</span>
                                  </p>
                                }
                                name={index.toString() + indexx.toString()}
                                id={index.toString() + indexx.toString()}
                                value={value.check}
                                attrName={[
                                  index,
                                  indexx,
                                  "checkbox_status",
                                  data._id,
                                  value.id,
                                ]}
                                value_update={updateMasterStateArray}
                                default_check={false}
                              />
                            </Grid>
                            <Grid xs={4}>
                              {value.fieldType === "Text Field" ||
                                value.fieldType === "Input Field" ? (
                                <>
                                  <RKTextField
                                    class_name="inputField"
                                    title={"Value"}
                                    value={
                                      value.check === true &&
                                        component_name === "FLAT"
                                        ? value.value
                                        : ""
                                    }
                                    attrName={[
                                      index,
                                      indexx,
                                      "value",
                                      value.unit,
                                      value.customUnitType,
                                      data._id,
                                      value.id,
                                    ]}
                                    value_update={updateMasterStateArray}
                                    warn_status={false}
                                    validation_type={
                                      value.valueType === "Numeric Only" &&
                                        value.unit === "Free Text"
                                        ? "name"
                                        : value.valueType === "Numeric Only" &&
                                          value.unit !== "Free Text"
                                          ? "numeric"
                                          : value.unit === "Free Text"
                                            ? "name"
                                            : value.valueType === "Text Only"
                                              ? "name"
                                              : "name"
                                    }
                                    alphanumeric={
                                      value.valueType === "Alphanumeric" ||
                                        (value.valueType === "Numeric Only" &&
                                          value.unit === "Free Text")
                                        ? true
                                        : false
                                    }
                                    specialcharacter={
                                      value.valueType === "Alphanumeric" ||
                                        (value.valueType === "Numeric Only" &&
                                          value.unit === "Free Text")
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      value.check === true ? false : true
                                    }
                                  />
                                  <span
                                    style={{
                                      display: "block",
                                      fontSize: "12px",
                                      color: "#828282",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {value.unit === "Free Text"
                                      ? value.customUnitType
                                      : value.unit}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {/* <SearchDropdown
                                    class_name="inputField"
                                    title="Select Field search"
                                    value={value.value}
                                    attrName={[
                                      index,
                                      indexx,
                                      "value",
                                      "dropdown",
                                      data._id,
                                      value.id,
                                    ]}
                                    value_update={updateMasterStateArray}
                                    data={value.dropDownOptions}
                                    warn_status={false}
                                  /> */}
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Select Field"
                                    value={value.value}
                                    attrName={[
                                      index,
                                      indexx,
                                      "value",
                                      "dropdown",
                                      data._id,
                                      value.id,
                                    ]}
                                    value_update={updateMasterStateArray}
                                    dropdown_data={value.dropDownOptions}
                                    warn_status={false}
                                  />
                                </>
                              )}
                            </Grid>
                            <Grid xs={12} className="mb-0 pt-0 pb-0">
                              {indexx + 1 === data.data?.length ? null : <hr />}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </div>
                  ))}
                </div>
              </Grid>
            ) : ("")}

            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={onSubmit}>
                Submit
              </Link>
            </Grid>
          </Grid>
        )}
        <>
          {data_list?.length === 0 && (
            <Alert severity="warning">
              Since there are currently no active features for{" "}
              <strong>{productType}</strong>, kindly add them in the Product
              Configurator module of Group Health Product Section. Thanks!
            </Alert>
          )}
          {filteredData?.length === 0 && "No Records Found"}
          {data_list?.length === 0 && (
            <a
              onClick={() => {
                if (productType === "Group Term") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM);
                } else if (productType === "Group Health") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH);
                } else if (productType === "Group Travel") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL);
                } else if (productType === "Group Personal Accident") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT);
                } else if (productType === "Group Critical Illness") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS);
                } else if (productType === "Group Super TopUp") {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_SUPER_TOPUP);
                } else {
                  navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH);
                }
              }}
            >
              Click here to add features
            </a>
          )}
        </>
      </div>
    </SlidingPanel>
  );
};

const color_code = [
  "#6B62DC",
  "#F5BE4C",
  "#EC3F3F",
  "#029D11",
  "#6B62DC",
  "#F5BE4C",
  "#EC3F3F",
  "#029D11",
  "#6B62DC",
  "#F5BE4C",
  "#EC3F3F",
  "#029D11",
];
const border_code = [
  "#E5E7FE",
  "#FFF2DB",
  "#FFE4E4",
  "#CDFFD2",
  "#E5E7FE",
  "#FFF2DB",
  "#FFE4E4",
  "#CDFFD2",
  "#E5E7FE",
  "#FFF2DB",
  "#FFE4E4",
  "#CDFFD2",
];

const dropdown_data = [
  { key: "50000", value: "Rs. 50 Lakhs" },
  { key: "60000", value: "Rs. 60 Lakhs" },
  { key: "70000", value: "Rs. 70 Lakhs" },
  { key: "80000", value: "Rs. 80 Lakhs" },
  { key: "90000", value: "Rs. 90 Lakhs" },
  { key: "100000", value: "Rs. 1 Crore" },
];

export default SelectFeature;
