import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import {GetAPI, PostAPI, DeleteAPI, PatchAPI, PutAPI} from "../API"



const post_branch_data = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PostAPI.call(`${URL_CONSTANTS.create_branch}`,dto,onSuccess, onError)
}
const post_employer_branch_data = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PostAPI.call(`${URL_CONSTANTS.create_employer_branch}`,dto,onSuccess, onError)
}

const get_branch_list_data = (skip: any,limit: any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    GetAPI.call(`${URL_CONSTANTS.get_branch_list}?skip=${skip}&limit=${limit}`,onSuccess, onError)
}

const branch_detail_view = (onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any)=>{
    GetAPI.call(`${URL_CONSTANTS.branch_detail_view}?id=${id}`,onSuccess, onError)
}

const update_branch = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any)=>{
    PatchAPI.call(`${URL_CONSTANTS.update_branch}?id=${id}`,dto,onSuccess, onError)
}

const delete_branches = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    DeleteAPI.call(`${URL_CONSTANTS.delete_branches}`,dto,onSuccess, onError)
}


const get_status = (
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
) => {
    GetAPI.call(
        URL_CONSTANTS.get_branch_status,
        onSuccess,
        {},
        onError
    );
};
const update_status = (
    dto: any,
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
    id: any
) => {
    PatchAPI.call(
        `${URL_CONSTANTS.update_branch_status}${id}`,
        dto,
        onSuccess,
        {},
        onError
    );
};
const isBeingUsed = (
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
    id:string,
) => {
    GetAPI.call(
        `${URL_CONSTANTS.isStatusBeingUsed}${id}&type=BRANCH`,
        onSuccess,
        {},
        onError
    );
};


export const BRANCHES_SERVICES = {
    post_employer_branch_data,
    post_branch_data,
    get_branch_list_data,
    branch_detail_view,
    update_branch,
    delete_branches,
    get_status,
    update_status,
    isBeingUsed,
}