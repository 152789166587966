import { Box } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Ensure you have the correct Grid import
import "./NotAllowed.scss";

const NotAllowed = () => {
  return (
    <div className="not_allowed">
      <Box className="enrollmentdrive_wrapper">
        <div className="enrollment_bg">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <div className="enrollment_innersection">
                <div className="inner_content">
                  <h5>Not Allowed !!</h5>
                  <p>
                    Embedding this web application in an iframe is not supported.
                  </p>

                  <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <img
                      src="./images/not-allowed-girl.svg"
                      alt=""
                      height={406}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default NotAllowed;
