//used
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  CreateSupportTicketDTO,
  GetTicketDTO,
} from "../../../../Services/DTO/SupportDTO";
import { SUPPORT_SERVICES } from "../../../../Services/Support/SupportServices";
import { useAppSelector } from "../../../../Store/hooks";
import { allowFileExtention, uploadImage } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SupportDropDown/SelectDropdown";
import TextAreaField from "../../../../Supporting files/TextAreaField/TextAreaField";
import "./Support.scss";

function Support() {
  const official_email = useAppSelector(
    (state: any) => state.userDetailsSlice.support_email
  );
  const [supportTicket, setSupportTicket] = useState<CreateSupportTicketDTO>({
    description: "",
    emailId: "",
    reasons: "",
    upload: "",
    subject: "",
  });
  const [warnReasons, setwarnReasons] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [warnDescription, setWarnDescription] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [warnSubject, setWarnSubject] = useState(false);
  const [reasonForSupport, setReasonForSupport] = useState("");
  const [filename, setFilename] = useState<string>("");
  const [getTicket, setGetTicket] = useState<GetTicketDTO>([]);
  const [warnUpload, setwarnUpload] = useState(false);

  const reason_data = [
    { key: "01-Facing Issue", value: "Facing Issue" },
    { key: "01-Complaint", value: "Complaint" },
    { key: "00-Feedback", value: "Feedback" },
  ];

  const faqs = [
    {
      expanded: "panel1",
      title: "How to raise a request for claim intimation?",
      description:
        "Reach out to your insurance company as soon as possible to inform them about the incident or event that may lead to a claim. You can find their contact information on your insurance policy documents or their website.",
    },
    {
      expanded: "panel2",
      title: "How to update password?",
      description: "kdsgjsdkjgkdsjgksd dskgsdkgs gksdjgk",
    },
    {
      expanded: "panel3",
      title: "What is riskmap?",
      description:
        "A risk score map is a visual representation or a graphical representation that indicates the level of risk associated with different areas or regions. It is used to identify and communicate the varying degrees of risk across a specific geographical area or within a particular domain.",
    },
    {
      expanded: "panel4",
      title: "How does group health insurance policy work?",
      description:
        "An employer purchases a group healthcare coverage plan for their respective employees based on the number of workers. The employer entirely bears the cost of group healthcare. In return, the employees can avail medical insurance as per the sum insured.",
    },
  ];

  const updateMasterState = (attrName: any, value: string) => {
    if (attrName === "setSupportTicket") {
      setSupportTicket({ ...supportTicket, reasons: value });
      setwarnReasons(false);
    } else if (attrName == "description") {
      setSupportTicket({ ...supportTicket, description: value });
      setWarnDescription(false);
    } else if (attrName == "subject") {
      setSupportTicket({ ...supportTicket, subject: value });
      setWarnSubject(false);
    } else {
      attrName(value);
    }
  };
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file?.size;
    const maxSizeBytes = 8 * 1024 * 1024;
    if (fileSize && fileSize > maxSizeBytes) {
      e.target.files = null;
      e.target.value = null;
      return toast.error("File size exceeds 8 MB limit");
    }
    if (file) {
      const allowExt = allowFileExtention(file)
      if(!allowExt){
        toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
        return (e.target.value = null);
      }

      uploadImage(file, (base64Image: any, base64String: any) => {
        setFilename(file.name);
        setSupportTicket({
          ...supportTicket,
          upload: base64Image,
        });
      });
    }
    e.target.files = null;
    e.target.value = null;
  };
  const submitSupportTicket = () => {
    if (disableSubmit) return;
    // let hasError = false;
    // if (supportTicket.reasons?.trim() === "") {
    //   setwarnReasons(true);
    //   hasError = true;
    // } else if (supportTicket.description?.trim() === "" || supportTicket.upload === "") {
    //   return toast.error("enter all fields")
    // }
    let hasError = false;
    if (supportTicket.reasons?.trim() === "") {
      setwarnReasons(true);
      hasError = true;
    }
    if (supportTicket.subject?.trim() === "" || null) {
      setWarnSubject(true);
      hasError = true;
    }
    if (supportTicket.description?.trim() === "" || null) {
      setWarnDescription(true);
      hasError = true;
    }
    if (
      // supportTicket.description?.trim() === "" ||
      supportTicket.upload === ""
    ) {
      setwarnUpload(true);
      hasError = true;
    }
    const onSuccess = (res: any) => {
      setDisableSubmit(false);
      toast.success("Submitted support ticket successfully!");
      setFilename("");
      setwarnReasons(false);
      setSupportTicket({
        description: "",
        emailId: "",
        reasons: "",
        upload: "",
        subject: "",
      });
    };

    const onError = (err: any) => {
      toast.error("Error submiting support ticket");
      setDisableSubmit(false);
    };
    if (!hasError) {
      setDisableSubmit(true);
      SUPPORT_SERVICES.create_employee_support_ticket(
        supportTicket,
        onSuccess,
        onError
      );
    }
  };

  return (
    <Grid xs className="supportWrapper">
      <>
        <Grid container spacing={3}>
          <Grid xs={12} className="pageTitle">
            <h2>
              <span>Get</span> Support
            </h2>
            {/* <p>
              What incredible experiences are unfolding within you? Kindly grace
              us with the gift of sharing.
            </p> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <>
              <Grid container spacing={3}>
                <Grid xs={4}>
                  <img src="./images/confused.svg" />
                </Grid>
                <Grid xs={8}>
                  <>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Reason of writing to us"
                          value={supportTicket.reasons}
                          attrName={"setSupportTicket"}
                          value_update={updateMasterState}
                          dropdown_data={reason_data}
                          warn_status={warnReasons}
                          error_messg="Select Reason of writing to us"
                        />
                      </Grid>
                      <Grid xs={12}>
                        <RKTextField
                          attrName="subject"
                          class_name="inputField"
                          title="Subject"
                          value={supportTicket.subject}
                          value_update={updateMasterState}
                          warn_status={warnSubject}
                          error_messg={"Subject is required"}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <p className="describe-text mb-2">Describe here</p>
                        <TextAreaField
                          class_name="inputField"
                          title="Description"
                          value={supportTicket.description}
                          attrName="description"
                          value_update={updateMasterState}
                          warn_status={warnDescription}
                          error_messg={"Description is required"}
                        />
                      </Grid>
                      <Grid xs={12} className="px-0">
                        <>
                          <Grid container spacing={0} className="uploadFile">
                            <Grid xs={10} className="inputField">
                              <RKTextField
                                class_name="inputField"
                                title={"Upload .jpg .png .pdf file"}
                                value={filename}
                                attrName={setUploadFile}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                            <Grid xs={2}>
                              <Button
                                variant="contained"
                                className="browsebtn"
                                component="label"
                              >
                                Browse
                                <input
                                  hidden
                                  accept="application/pdf, image/*"
                                  multiple
                                  type="file"
                                  style={{ zIndex: "99" }}
                                  onChange={handleFileUpload}
                                />
                              </Button>
                            </Grid>
                            <Grid xs={12}>
                              <div className="uploadAttachmentInfo">
                                <span>Maximum size of attachment is 8 MB*</span>
                              </div>
                              <div className="inputField">
                                <span className="error">
                                  {warnUpload && "Upload file"}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      </Grid>
                      <Grid xs={12} className="ctaBtn">
                        <Button
                          variant="contained"
                          className="submitBtn"
                          onClick={submitSupportTicket}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}

export default Support;
