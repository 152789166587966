import Active from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import EmployeeDetailPolicies from "../../../../../Components/Broker/EmployeeDetailPolicies/EmployeeDetailPolicies";
import LogsTable from "../../../../../Components/Broker/LogsTable/LogsTable";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import ClaimList from "../../../../../Components/Common/ClaimList/ClaimList";
import EndorsementList from "../../../../../Components/Common/EndorsementList/EndorsementList";
import Nominee from "../../../../../Components/Common/Nominee/NomineDetail/Nominee";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { employeeDTO } from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { permissionSlice } from "../../../../../Store/Slice_Reducer/Permission/PermissionSlice";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../formLayout.scss";
import EmployeeDetailProfile from "./EmployeeDetailProfile";
import OverViewTab from "./OverViewTab";
interface TStatusCode {
  key: string;
  value: string;
  color: string;
}

function EmployeeDetails() {
  const windowURL = window.location.search;
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [priorityStatus, setPriorityStatus] = useState(32);
  const [employementStatus, setemployementStatus] = useState(37);
  const [priorityStatusPrimaryEmp, setPriorityStatusPrimaryEmp] = useState(32);
  const [employementStatusPrimaryEmp, setemployementStatusPrimaryEmp] = useState(37);
  const [employeeBaseLocationId, setemployeeBaseLocationId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState("");
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [addPolicy, setAddPolicy] = useState(false);
  const [addNominee, setAddNominee] = useState(false);
  const [addEcard, setAddEcard] = useState(false);
  const [fullName, setFullName] = useState("");
  const [relationEmployee, setRelationEmployee] = useState("Brother");
  const [nomineeDob, setNomineeDob] = useState<Date | null>(new Date());
  const [age, setAge] = useState("25");
  const [selectPolicy, setSelectPolicy] = useState("PB22345780");
  const [healthEcard, setAddHealthEcard] = useState("PB223674780");
  const user_type_dateFormat = useAppSelector((state) => state.userDetailsSlice.localInformation.dateFormat);
  const tz = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.timeZone);
  const [selectedMemberType, setselectedMemberType] = useState<any>("");
  const [selectedMemberDOB, setselectedMemberDOB] = useState<any>("");
  const [statusCode, setstatusCode] = useState<Array<TStatusCode>>([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [dateData, setDateData] = useState({
    createdAt: "",
    updatedAt: "",
    employeeId: "",
    _id: "",
  });
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [firstNameChar, setFirstNameChar] = useState("");
  const relationEmployee_data = [
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
  ];
  const selectPolicy_data = [
    { key: "PB22345780", value: "PB22345780" },
    { key: "PB223457802", value: "PB223457802" },
    { key: "PB223457803", value: "PB223457803" },
  ];
  const [selectedClient, setSelectedClient] = useState<{
    id: string;
    label: string;
  }>({ id: "", label: "" });
  const [empSalary, setEmpSalary] = useState<string>("");
  const [empSalaryBracket, setEmpSalaryBracket] = useState<string>("");
  const [empDepartment, setEmpDepartment] = useState<string>("");
  const [empDesignation, setEmpDesignation] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [dob, setDOB] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const [relatedEmpId, setRelatedEmpId] = useState<string>("");
  const [policyMemberRelationId, setPolicyMemberRelationId] = useState<string>("");
  const [relationWithEmployee, setRelationWithEmployee] = useState<string>("");

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "_id") {
      getStatusCode(value);
    } else if (attrName === "Member Type") {
      setselectedMemberType(value);
    } else if (attrName === setPriorityStatus) {
      if (employementStatus === 36 ||
        employementStatus === 35) {
        return toast.warn("Can't change member status when employement status is Resigned/Terminated");
      }
      if (priorityStatus === 34) {
        return toast.warn("Void status is not Reversible");
      }
      dispatch(permissionSlice.actions.updateMemberStatus(value));
      setPriorityStatus(value);
      update_status(value, "MEMBER");
    } else {
      attrName(value);
    }
  };
  let timeout: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setEmployeeId(id ?? "");

    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      get_employee_layout(id ?? "");
      member_detail_data(id ?? "");
      get_status();
    }

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

  }, []);

  useEffect(() => {
    dispatch(permissionSlice.actions.updateMemberStatus(priorityStatus));
  }, [priorityStatus]);

  const member_detail_data = (id: string) => {
    const onSuccess = (res: any) => {
      let resopnse = res.data.data[0];
      setFirstNameChar(resopnse["First Name"]?? "");
      setLastName(resopnse["Last Name"]?? "");
      setRelationWithEmployee(resopnse["Relationship With Employee"]);
    };
    const onError = (err: any) => { };

    let url = `${URL_CONSTANTS.get_member_detail(
      id,
      usertypeinfo.toLocaleLowerCase()
    )}`;
    ENDORSEMENT_SERVICES.member_detail(url, onSuccess, onError);
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, type: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id ? id : "",
      type,
      usertypeinfo.toLowerCase()
    );
  };

  const getStatusCode = (id: string) => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      setstatusCode(
        data.map((item: TStatusCode) => {
          return {
            ...item,
            key: item.key.toUpperCase(),
            value: item.value.toUpperCase(),
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log("Status list", err);
    };

    EMPLOYEE_SERVICES.get_status_data(onSuccess, onError, id);
  };

  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      setemployementStatus(res.data.data[0]?.employement_status.value);
      setPriorityStatusPrimaryEmp(res.data.data[0]?.primaryEmployeeStatus)
      setemployementStatusPrimaryEmp(res.data.data[0]?.primaryEmployeeEmploymentStatus)
      getStatusCode(res.data.data[0]._id);
      setDateData({
        createdAt: res.data.data[0].createdAt,
        updatedAt: res.data.data[0].updatedAt,
        employeeId: res.data.data[0].employeeId,
        _id: res.data.data[0]._id,
      });
      setPriorityStatus(res.data.data[0]?.status?.value || res.data.data[0]?.primaryEmployeeStatus);

      res.data.data[0].layout?.map((dataMainsection: employeeDTO) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection.children.map(
          (dataSubsection) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection.children.map((dataRow) => {
              let newRow = { ...dataRow };
              newRow.children = dataRow.children.map((dataColumn) => {
                let newColumn = { ...dataColumn };
                newColumn.children = dataColumn.children;
                newColumn.children = dataColumn.children.map((dataInput) => {
                  if (dataInput.field_type === "searchLocation") {
                    let temp: any = dataInput;
                    setemployeeBaseLocationId(temp.value_id);
                  }
                  if (dataInput?.field_lable === "Member Type") {
                    setselectedMemberType(dataInput.value);
                  }
                  if (dataInput?.field_lable === "First Name") {
                    setFirstNameChar(dataInput.value);
                  }
                  if (dataInput?.field_lable === "Last Name") {
                    setLastName(dataInput.value);
                  }
                  if (dataInput?.field_lable === "Date Of Birth") {
                    setselectedMemberDOB(dataInput.value);
                    setDOB(dataInput.value);
                  }
                  if (dataInput.field_type === "hideid") {
                    return {
                      ...dataInput,
                      field_lable: "hide",
                      field_type: "hide",
                    };
                  } else {
                    return { ...dataInput };
                  }
                });
                return newColumn;
              });
              return newRow;
            });
            return newSubsection;
          }
        );
        return newMainSection;
      });
    };
    const onError = (err: any) => { };
    EMPLOYEE_SERVICES.employee_detail_view_layout(id, onSuccess, onError);
  };

  //for policy Tab Table Data
  const employee_table_data = [
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Health",
      basic_config: "1E+1S+1C+1C+1C+1C",
      individual_config: "Nill",
      checkbox_status: false,
      check_status: "",
      insurer: "HDFC Ergo",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Term",
      basic_config: "1E+1S+1C+1C+1C",
      individual_config: "1P+1P",
      checkbox_status: false,
      check_status: "",
      insurer: "Star",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Personal Accident",
      basic_config: "1E+1S+1C+1C",
      individual_config: "1PIL+1PIL",
      checkbox_status: false,
      check_status: "",
      insurer: "Bajaj Allianz",
    },
    {
      policy_no: "PVAZ23456788",
      product_type: "Group Critical Illness",
      basic_config: "1E+1S+1C",
      individual_config: "1P+1P+1PIL+1PIL",
      checkbox_status: false,
      check_status: "",
      insurer: "Royal Sundaram",
    },
  ];
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [statusPermission, setStatusPermission] = useState(true);


  useEffect(() => {
    setStatusPermission(BROKER?.Employees.status === undefined ? false : BROKER?.Employees.status);
  }, []);

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(ALL_ROUTES.EMPLOYEE);
            }}
          >
            <ChevronLeftIcon />
          </Link>
          <span className="detailIcon">{firstNameChar && firstNameChar.charAt(0)}</span>
          <div>
            <h3>
              Edit {memberId} - {firstNameChar}
            </h3>
            <p>
              {memberId}
              {" - Created On "}
              {date_time_format(dateData.createdAt, user_type_dateFormat, tz)}
            </p>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div>
            {/* <Tooltip title="Remove">
              <Link
                underline="none"
                className="actionLinks red_icon"
                onClick={() => {
                  setRemoveStatus(true);
                }}
              >
                <DeleteOutlineIcon />
              </Link>
            </Tooltip> */}
          </div>

          {/* <div> */}
          {/* <Tooltip title="Global Edit">
            <Link
              className="actionLinks"
              onClick={() => {
                navigate(`${ALL_ROUTES.EDIT_EMPLOYEE}?id=${id}`);
              }}>
              <BorderColorIcon />
            </Link>
          </Tooltip> */}
          {/* </div> */}
          {/* Status Dropdown */}

          <div id={`_${priorityStatus?.toString()}`}>
            <div className="status_dropdown" id={`_${priorityStatus?.toString()}`} >
              {priorityStatus === 32 ? (
                <Active id={`_${priorityStatus?.toString()}`} />
              ) : priorityStatus === 33 ? (
                <Inactive id={`_${priorityStatus?.toString()}`} />
              ) : priorityStatus === 34 ? (
                <Void id={`_${priorityStatus?.toString()}`} />
              ) : null}
              <SelectDropdown
                class_name="inputFieldd"
                title=""
                value={priorityStatus}
                attrName={setPriorityStatus}
                value_update={updateMasterState}
                dropdown_data={prioritystatusData}
                warn_status={false}
                disabled={!statusPermission}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Box>
            <Grid container spacing={3}>
              <Grid xs className="rightSection h-100">
                <Box>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs maxWidth="260px"></Grid>
                    <Grid
                      xl={7}
                      lg={8}
                      md={8}
                      xs={12}
                    // style={{ padding: "20px" }}
                    >
                      <div className="productTabs ">
                        <Button
                          className={
                            viewTabsStatus === "overview"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "overview");
                          }}
                        >
                          Overview
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "policies"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "policies");
                          }}
                        >
                          Policies
                        </Button>
                        {selectedMemberType === "Employee" && (
                          <>
                            <Button
                              className={
                                viewTabsStatus === "nominees"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(
                                  setViewTabsStatus,
                                  "nominees"
                                );
                              }}
                            >
                              Nominees
                            </Button>
                            <Button
                              className={
                                viewTabsStatus === "profile"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "profile");
                              }}
                            >
                              Profile
                            </Button>
                          </>
                        )}
                        <Button
                          className={
                            viewTabsStatus === "endorsement"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "endorsement");
                          }}
                        >
                          Endorsement
                        </Button>{" "}
                        <Button
                          className={
                            viewTabsStatus === "claims"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "claims");
                          }}
                        >
                          Claims
                        </Button>
                        {/* <Button
                          className={
                            viewTabsStatus === "logs"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "logs");
                          }}
                        >
                          Logs
                        </Button> */}
                      </div>
                    </Grid>
                    <Grid
                      xs
                      style={{ textAlign: "right" }}
                      className="text-sm-center"
                    >
                      <p className="lastupdate">
                        Last updated on: {date_time_format(dateData.updatedAt, user_type_dateFormat, tz)}
                      </p>
                    </Grid>
                  </Grid>
                  {viewTabsStatus === "overview" ? (
                    <OverViewTab
                      value_update={updateMasterState}
                      setSelectedClient={setSelectedClient}
                      setEmpSalary={setEmpSalary}
                      setEmpSalaryBracket={setEmpSalaryBracket}
                      setEmpDepartment={setEmpDepartment}
                      setEmpDesignation={setEmpDesignation}
                      setPropsMemberId={setMemberId}
                      setRelatedEmpId={setRelatedEmpId}
                      setPolicyMemberRelationId={setPolicyMemberRelationId}
                    />
                  ) : null}
                  {viewTabsStatus === "policies" ? (
                    <>
                      <EmployeeDetailPolicies
                        selectedClient={selectedClient}
                        employeeId={employeeId}
                        empSalary={empSalary}
                        empSalaryBracket={empSalaryBracket}
                        empDepartment={empDepartment}
                        empDesignation={empDesignation}
                        empFirstName={firstNameChar}
                        empLastName={lastName}
                        dob={dob}
                        memberId={memberId}
                        selectedMemberType={selectedMemberType}
                        relatedEmpId={relatedEmpId}
                        policyMemberRelationId={policyMemberRelationId}
                        employementStatus={employementStatusPrimaryEmp ?? employementStatus}
                        mainStatus={priorityStatusPrimaryEmp ?? priorityStatus}
                        selectedMemberDOB={selectedMemberDOB}
                        employeeBaseLocationId={employeeBaseLocationId}
                        relationWithEmployee={relationWithEmployee}
                      />
                    </>
                  ) : null}
                  {viewTabsStatus === "nominees" ? (
                    <Nominee
                      id={employeeId === undefined ? "" : employeeId}
                      selectedClient={selectedClient}
                    />
                  ) : null}
                  {viewTabsStatus === "profile" ? (
                    <EmployeeDetailProfile />
                  ) : null}
                  {viewTabsStatus === "logs" ? (
                    <Box>
                      <LogsTable />
                    </Box>
                  ) : null}
                  {viewTabsStatus === "endorsement" ? (
                    <Box>
                      <EndorsementList id={id} />
                    </Box>
                  ) : null}
                  {viewTabsStatus === "claims" ? (
                    <Box>
                      <ClaimList id={id} />
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <PostQueryForm
        attrName={setPostQueryForm}
        open_status={postQueryForm}
        value_update={updateMasterState}
      />
      <BulkUploadForm
        open_status={uploadFile}
        attrName={setUploadFile}
        value_update={updateMasterState}
      />

      <Modal open={deleteStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setDeleteStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are about to Delete this Attatchment.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={removeStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setRemoveStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are want to remove this Contacts.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* policies add policy popup */}

      <SlidingPanel
        type={"right"}
        isOpen={addPolicy}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddPolicy(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Add Policy</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area pr-5">
                <Grid container spacing={3} className="mb-1">
                  <Grid xs={12}>
                    <div className="addPolicy_info_section">
                      <p className="addPolicy_info_p">
                        Client ID : <span>CL00001</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <h5 className="policy_subheading mt-4">Select Policy</h5>
                  </Grid>
                  <Grid xs={12}>
                    <div className="policy_table">
                      <div
                        className="policy_table_inner"
                        style={{ overflow: "hidden" }}
                      >
                        <ul className="heading">
                          <li>Choose Policy</li>
                          <li>Active Policy Number</li>
                          <li>Product Type</li>
                          <li>Insurer</li>
                          <li>Basic configuration</li>
                          <li>Individual configuration</li>
                        </ul>

                        {employee_table_data.map((data, index) => (
                          <ul className="subheading">
                            <li>
                              <div className="Checkbox">
                                <input type="checkbox" name="check" />
                                <label className="employee"></label>
                              </div>
                            </li>
                            <li>{data.policy_no}</li>
                            <li>{data.product_type}</li>
                            <li>{data.insurer}</li>
                            <li>{data.basic_config}</li>
                            <li>{data.individual_config}</li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddPolicy(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      {/* Nominee add policy popup */}
      <SlidingPanel
        type={"right"}
        isOpen={addNominee}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddNominee(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Fill Nominee Details</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Full Name"
                      value={fullName}
                      attrName={setFullName}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Relationship with employee"
                      value={relationEmployee}
                      attrName={setRelationEmployee}
                      value_update={updateMasterState}
                      dropdown_data={relationEmployee_data}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title="Date of Birth"
                      value={nomineeDob}
                      attrName={setNomineeDob}
                      value_update={updateMasterState}
                      error_message="Please enter Date of Birth"
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Age"
                      value={age}
                      attrName={setAge}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddNominee(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      {/* health ecard policy popup */}
      <SlidingPanel
        type={"right"}
        isOpen={addEcard}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => setAddEcard(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-3 text-left">Add Health/E-Card Details</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area ">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Select Policy"
                      value={selectPolicy}
                      attrName={setSelectPolicy}
                      value_update={updateMasterState}
                      dropdown_data={selectPolicy_data}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Enter Health/E-Card Number"
                      value={healthEcard}
                      attrName={setAddHealthEcard}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid xs={10} className="inputField uploadFile">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Upload Document"
                      variant="outlined"
                      className="textarea"
                    />
                  </Grid>
                  <Grid xs={2}>
                    <Button variant="contained" className="browsebtn">
                      Browse
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} className="footer_sec">
              <Link className="save_btn" onClick={() => setAddEcard(false)}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </Box>
  );
}
export default EmployeeDetails;
