import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from '@mui/icons-material/Close';
import "./../CustomModules.scss"
import { useSelector, useDispatch } from "react-redux";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { checkErrors } from "../CustomModuleV1/helpers";
import { ROW, COLUMN } from "../CustomModuleV1/constants";
import shortid from "shortid";

const AddressProperties= ({open_status}) => {
    
    const modal = useSelector((state)=> {return state.modalReducer});
    const layoutData = useSelector(({layoutReducer})=>{return layoutReducer});

    const [tooltiptext, setTooltipText] = React.useState(modal.data.tipText?modal.data.tipText:"");
    const [tooltip, setTooltip] = React.useState(modal.data.toolTip?modal.data.toolTip:false);
    const [required, setRequired] = React.useState(modal.data.required?modal.data.required:false);
    const [checkedItems, setCheckedItems] = useState({
        street: false,
        area: false,
        country: false,
    });
    const [street, setStreet] = React.useState(false);
    const [area, setArea] = React.useState(false);
    const [country, setCountry] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [city, setCity] = React.useState(false);
    const [pin, setPin] = React.useState(false);

    const [title, setTitle] = React.useState("Address");
    const [speacialChar, setSpeacialChar] = React.useState(modal.data.speacialChar?modal.data.speacialChar:false);
    const [alphaNumeric, setAlphaNumeric] = React.useState(modal.data.alphaNumeric?modal.data.alphaNumeric:false);
    const [characterLimit, setCharacterLimit] = React.useState(modal.data.characterLimit?modal.data.characterLimit:'');

    const [isError, setError] = React.useState(false);
    const [isSuccess, setSuccess] = React.useState(false);

    const updateMasterState = (attrName, value) => {
        attrName(value);
    };
    const handleChange = (data)=>{
        const { name, required } = data;
        console.log(data, "name......", );
        // setStreet(!required)
        setCheckedItems((prevState) => ({
                ...prevState,
                [name]: required, // Update the specific checkbox by its name
            }));
    }
    const dispatch = useDispatch();
    const submitHandler = ()=>{
        console.log(checkedItems, "..checkedItems..")
        let objectToUpdate = {...modal.data, field_lable:title, characterLimit, required, tipText:tooltiptext, toolTip:tooltip, speacialChar, alphaNumeric}
        console.log(objectToUpdate, "(OBJECT SYSTEM)");
        let path = modal.path.split("-");

        //from sidebar to layout
        
        let columnObj = {id:shortid.generate(), type: COLUMN, children:[objectToUpdate]}
        
        if(!objectToUpdate.from){
            if(path.length === 5) {
                layoutData.layout[path[0]].children[path[1]].children[path[2]].children[path[3]].children.splice(path[4], 0, objectToUpdate);
            }else if(path.length === 4 ) {
                layoutData.layout[path[0]].children[path[1]].children[path[2]].children.splice(path[3], 0, columnObj);
            }else if(path.length === 3){
               layoutData.layout[path[0]].children = [
                ...layoutData.layout[path[0]].children,
                {
                    "preDefiend": false,
                    "_id": "643e6ae8a08ed337483ecc0d",
                    "type": "subsection",
                    "name": "Address Details",
                    "id": shortid.generate(),
                    "children": [
                        {
                            "preDefiend": false,
                            "_id": "643e6ae8a08ed337483ecc0e",
                            "id": shortid.generate(),
                            "type": "row",
                            "children": [
                                {
                                    "preDefiend": false,
                                    "_id": "643e6ae8a08ed337483ecc0f",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": true,
                                            "alphaNumeric": true,
                                            "field_lable": "Bulding/Street Name",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": null,
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": street,
                                            "order": 9,
                                            "_id": "643e6ae8a08ed337483ecc10",
                                            "id": "DeLS3HSKf",
                                            "type": "Single Line",
                                            "field_type": "singleline",
                                            "characterLimit": 100,
                                            "pickliststep": []
                                        }
                                    ]
                                },
                                {
                                    "preDefiend": false,
                                    "_id": "643e6ae8a08ed337483ecc11",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": true,
                                            "alphaNumeric": true,
                                            "field_lable": "Area Name",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": null,
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": area,
                                            "order": 10,
                                            "_id": "643e6ae8a08ed337483ecc12",
                                            "id": "d3GQPw32b",
                                            "type": "Single Line",
                                            "field_type": "singleline",
                                            "characterLimit": 100,
                                            "pickliststep": []
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "preDefiend": false,
                            "_id": "643e6ae8a08ed337483ecc13",
                            "id": shortid.generate(),
                            "type": "row",
                            "children": [
                                {
                                    "preDefiend": false,
                                    "_id": "643e6ae8a08ed337483ecc14",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": false,
                                            "alphaNumeric": false,
                                            "field_lable": "Country",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": null,
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": country,
                                            "order": 11,
                                            "_id": "643e6ae8a08ed337483ecc15",
                                            "id": "k9KHiDZju",
                                            "type": "country",
                                            "field_type": "country",
                                            "pickliststep": [
                                                {
                                                    "_id": "643e6ae8a08ed337483ecc16",
                                                    "id": "0.3474258234218477dndid",
                                                    "field_name": "",
                                                    "field_type": "",
                                                    "label": "",
                                                    "value": "",
                                                    "action_btns": "Edit",
                                                    "deleteable": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "preDefiend": false,
                                    "_id": "643e6ae8a08ed337483ecc17",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": false,
                                            "alphaNumeric": false,
                                            "field_lable": "State",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": null,
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": state,
                                            "order": 12,
                                            "_id": "643e6ae8a08ed337483ecc18",
                                            "id": "ZIU7vNrjQ",
                                            "type": "state",
                                            "field_type": "state",
                                            "pickliststep": [
                                                {
                                                    "_id": "643e6ae8a08ed337483ecc19",
                                                    "id": "0.8293942254156883dndid",
                                                    "field_name": "",
                                                    "field_type": "",
                                                    "label": "",
                                                    "value": "",
                                                    "action_btns": "Edit",
                                                    "deleteable": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "preDefiend": false,
                                    "_id": "643e6ae8a08ed337483ecc1a",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": false,
                                            "alphaNumeric": false,
                                            "field_lable": "City",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": null,
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": city,
                                            "order": 13,
                                            "_id": "643e6ae8a08ed337483ecc1b",
                                            "id": "2k-vXIz-3",
                                            "type": "city",
                                            "field_type": "city",
                                            "pickliststep": [
                                                {
                                                    "_id": "643e6ae8a08ed337483ecc1c",
                                                    "id": "0.5707534417855689dndid",
                                                    "field_name": "",
                                                    "field_type": "",
                                                    "label": "",
                                                    "value": "",
                                                    "action_btns": "Edit",
                                                    "deleteable": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "preDefiend": false,
                                    "_id": "643f7edab8b8d155d8765190",
                                    "id": shortid.generate(),
                                    "type": "column",
                                    "children": [
                                        {
                                            "preDefiend": false,
                                            "speacialChar": false,
                                            "alphaNumeric": false,
                                            "field_lable": "Pincode",
                                            "isLocked": true,
                                            "lockType": "1",
                                            "value": "",
                                            "toolTip": false,
                                            "tipText": "",
                                            "unique": false,
                                            "decimalPlaces": null,
                                            "maxDigits": "6",
                                            "roundingOption": null,
                                            "defaultEnable": false,
                                            "required": pin,
                                            "order": 13,
                                            "_id": "643f7edab8b8d155d8765191",
                                            "id": "sFvraWxqN",
                                            "type": "Number",
                                            "field_type": "number",
                                            "pickliststep": []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ] //.splice(path[2], 0, {id:shortid.generate(), type: ROW, children:[columnObj]})
            }else if(path.length === 2){
                layoutData.layout[path[0]].children.splice(path[1], 0, {id:shortid.generate(), type:ROW ,name: "New Sub Section", children:[{id:shortid.generate(), type:COLUMN, children:[objectToUpdate]}]})
            }
        }else{
            layoutData.layout[path[0]].children[path[1]].children[path[2]].children[path[3]].children[path[4]] = objectToUpdate //[path[3]]
        }
        
        // let isError = checkErrors(title);
        dispatch(UpdateLayoutPropertiesAction(layoutData.layout,objectToUpdate)); 
        dispatch(ToggelModalAction(modal.isOpen, "", null))

        // if(!isError){
        //     dispatch(UpdateLayoutPropertiesAction(layoutData.layout,objectToUpdate)); 
        //     setError(false);   
        //     setSuccess(true); 
        //     dispatch(ToggelModalAction(modal.isOpen, "", null))
        // }else{
        //     setError(true); 
        //     setSuccess(false);
        // }     
    }

    useEffect(() => {
        if(isError || isSuccess){
            setTimeout(()=>{
                setError(false);
                setSuccess(false);
            }, 2000)
        }
    }, [isError, isSuccess])

    const checkErrorOnBackdrop = ()=>{
        let isError = checkErrors(title);
        if(!isError){
            //dispatch(UpdateLayoutPropertiesAction(layoutData.layout,objectToUpdate)); 
            //dispatch(ToggelModalAction(modal.isOpen, "", null))
          setError(false);  
        }else{
          setError(true);  
        }
    }

    return (
        <SlidingPanel
            type={"right"}
            isOpen={open_status}
            size={75}
            backdropClicked={checkErrorOnBackdrop}>
            <div>
                <Grid container spacing={3} alignItems="center">
                    <Grid xs={11} className="pt-3">
                        <h4 className="mb-0">Field Properties - Address</h4>
                    </Grid>
                    
                    {/* {modal.data.from?(<Grid xs={1} className="pt-3" onClick={()=>{
                            dispatch(ToggelModalAction(modal.isOpen, "", null))
                        }}>
                        <CloseIcon/>
                    </Grid>): ""} */}

                    <Grid xs={1} className="pt-3" onClick={()=>{
                            dispatch(ToggelModalAction(modal.isOpen, "", null))
                        }}>
                        <CloseIcon/>
                    </Grid>

                    
                    <Grid xs={12} className="mb-4">
                        <hr />
                    </Grid>
                </Grid> 
                <div className="scrollable_area pt-3">
                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>Street</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="street"
                                id="street"
                                value={street}
                                attrName={setStreet}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>Area</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="area"
                                id="area"
                                value={area}
                                attrName={setArea}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>Country</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="country"
                                id="country"
                                value={country}
                                attrName={setCountry}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>State</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="state"
                                id="state"
                                value={state}
                                attrName={setState}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>City</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="city"
                                id="city"
                                value={city}
                                attrName={setCity}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid xs>
                            <p style={{fontWeight:600}}>Pincode</p>
                        </Grid>
                        <Grid xs={"auto"}>
                            <CheckBox
                                title="Required"
                                name="pin"
                                id="pin"
                                value={pin}
                                attrName={setPin}
                                value_update={updateMasterState}
                                default_check={true}
                            />
                        </Grid>
                    </Grid>
                </div>


                <Grid xs={12} className="ctaBtn mt-4 text-center">
                    <Button
                        variant="contained"
                        className="submitBtn "
                        onClick={submitHandler}>
                        Done
                    </Button>
                </Grid>
                
                {isSuccess? (<div className="successMessage" >
                    <h4>Field Property Updated!</h4>
                </div>):""}
            </div>
        </SlidingPanel>
    );
};

export default AddressProperties;
