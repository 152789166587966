import Employed from "@mui/icons-material/Badge";
import Terminated from "@mui/icons-material/DirectionsRun";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  employeeDTO,
  locationDTO,
} from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextFieldDisable from "../../../../../Supporting files/CurrencyDisable/CurrencyFieldDisable";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePickerDisable from "../../../../../Supporting files/DatePickerDisable/DatePickerDisable";
import {
  extractNumbersFromString,
  get_city_list,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdownDisable from "../../../../../Supporting files/MultipleSearchDropdownDisable/MultipleSearchDropdownDisable";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SelectDropdownDisable from "../../../../../Supporting files/SelectDropdownDisable/SelectDropdownDisable";
import TextAreaFieldDisable from "../../../../../Supporting files/TextAreaFieldDisable/TextAreaFieldDisable";
import "../../../../../formLayout.scss";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import { TMemberDetail } from "../../../../../Services/Types/Endorsement/TMemberDetail";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../Services/Employee/EmployeePolicyService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../../../Services/EmployerServices/Employee/EmployeeServices";
import { PolicyDetailObj } from "../../../../../Services/DTO/Employee/EmployeePoliciesDTO";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { differenceInCalendarDays } from "date-fns";

const OverViewTab = ({
  value_update,
  setSelectedClient,
  setEmpSalary,
  setEmpSalaryBracket,
  setEmpDepartment,
  setEmpDesignation,
  setPropsMemberId,
  setRelatedEmpId,
  setPolicyMemberRelationId,
}: {
  value_update: any;
  setSelectedClient?: any;
  setEmpSalary: any;
  setEmpSalaryBracket: any;
  setEmpDepartment: any;
  setEmpDesignation: any;
  setPropsMemberId: any;
  setRelatedEmpId: any;
  setPolicyMemberRelationId: any;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const [employeeAssignedPolicies, setEmployeeAssignedPolicies] = useState<
    Array<PolicyDetailObj>
  >([]);
  const [priorityStatus, setPriorityStatus] = useState(35);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [formData, setFormData] = useState<employeeDTO[]>([]);
  const [primaryEmpId, setprimaryEmpId] = useState<string>("");
  const [selectedMember, setselectedMember] = useState<string>("");
  const [memberId, setmemberId] = useState<any>("");
  const [primaryEmployeeId, setPrimaryEmployeeId] = useState<any>("");
  const [firstNameChar, setFirstNameChar] = useState("");
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: any;
  }>({ key: "", value: "" });
  const [locationIdData, setLocationIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientIdData, setClientIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [oldAddress, setOldAddress] = useState();
  const [personalAddress, setPersonalAddress] = useState<locationDTO>({
    street: "",
    area: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    warnStreet: false,
    warnArea: false,
    warnCountry: false,
    warnState: false,
    warnCity: false,
    warnZip: false,
  });
  const [isRealtionExist, setIsRealtionExist] = useState<boolean>(false);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [id, setId] = useState("");
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const [allPolicybetweenenrollDate, setAllPolicybetweenenrollDate] =
    useState<boolean>(false);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [selectedClientName, setSelectedClientName] = useState<string>("");
  const [lastWorkingDay, setlastWorkingDay] = useState<any[]>([]);
  const [currentSalarySlab, setcurrentSalarySlab] = useState<string>("");
  const [relatedEmpIdLocal, setRelatedEmpIdLocal] = useState<string>("");
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [sectionStatus, SetSectionStatus] = useState<any>(
    "Member Type Details"
  );
  const [subsectionStatus, SetsubSectionStatus] =
    useState<any>("Member Type Info");
  const [salaryBracketPath, setsalaryBracketPath] = useState<any[]>([]);
  const [firstNamePath, setfirstNamePath] = useState<any[]>([]);
  const [lastNamePath, setlastNamePath] = useState<any[]>([]);
  const [salaryPath, setsalaryPath] = useState<any[]>([]);
  const [subSection, setSubSection] = useState("businessc");
  const [oldValueMember, setoldValueMember] = useState<any>({});
  const [attachedPolicy, setattachedPolicy] = useState([
    {
      memberId: [],
      ecardId: [],
      nomineeId: [],
      _id: "",
      policyId: "",
      status: 41,
    },
  ]);
  const [departmentDropDownData, setdepartmentDropDownData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [assignable_policies, setAssignable_policies] = useState<
    {
      _id: "";
      policyNumber: "";
      productType: "";
      insurer: "";
      basicConfig: "";
      individualConfig: "";
    }[]
  >([]);
  const [designationDropDowndata, setdesignationDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndata, setsalaryDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndataOriginal, setsalaryDropDowndataOriginal] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const OrganisationDetail = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const { BROKER, EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, setEditPermission] = useState<any>(null);
  const [statusPermission, setStatusPermission] = useState<any>(null);

  const [empDoj, setEmpDoj] = useState<any>();
  const [empLdw, setEmpLdw] = useState<any>();
  const [empDob, setEmpDob] = useState<any>();
  const splitedClinetid = selectedClientName.split("-");
  let timeout: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");

    setShowLoader(true);

    if (usertypeinfo === "BROKER") {
      setEditPermission(
        BROKER?.Employees.edit === undefined ? false : BROKER?.Employees.edit
      );
      setStatusPermission(
        BROKER?.Employees.status === undefined
          ? false
          : BROKER?.Employees.status
      );
    } else if (usertypeinfo === "EMPLOYER") {
      setEditPermission(
        EMPLOYER?.Employees.edit === undefined
          ? false
          : EMPLOYER?.Employees.edit
      );
      setStatusPermission(
        EMPLOYER?.Employees.status === undefined
          ? false
          : EMPLOYER?.Employees.status
      );
    }

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      member_detail_data(id ?? "");
      get_employee_layout(id ?? "");
      get_status_employement();
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      get_client_list();
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const handleClicks = (id: any) => {
    setSubSection(id);
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (clientIdData?.length > 0 && selectedClientName) {
      const selectedClientObj = clientIdData.find(
        (item: any) =>
          item.label.replace(/\s+/g, "").trim().split("-")[0] ===
          selectedClientName.replace(/\s+/g, "").trim().split("-")[0]
      );
      setSelectedClient(selectedClientObj || null);
    } else {
      setSelectedClient(null);
    }
  }, [selectedClientName, clientIdData]);

  const get_client_list = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }

      setClientIdData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.get_client_list(
      usertypeinfo?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  // get form layout dynamically
  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      let memberName: any;
      let response = res.data.data[0].layout;
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "DEPARTMENTS"
      );
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "DESIGNATIONS"
      );
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "SALARY_BRACKETS"
      );
      setSelectedClientId(res.data.data[0].attachedClient);
      setattachedPolicy(res.data.data[0].policy);
      setmemberId(res.data.data[0].preFix + res.data.data[0].employeeId);
      setPropsMemberId(res.data.data[0].preFix + res.data.data[0].employeeId);
      setOldAddress(res.data.data[0].personalAddress);
      setPersonalAddress(res.data.data[0].personalAddress);
      value_update("_id", res.data.data._id);
      setPolicyMemberRelationId(res.data.data[0].memberId);
      if (res.data.data[0].attachedPrimaryEmployee) {
        if (res.data.data[0].attachedPrimaryEmployee.includes("-")) {
          setPrimaryEmployeeId(
            res.data.data[0].attachedPrimaryEmployee.split("-")[0]
          );
        } else {
          setPrimaryEmployeeId(res.data.data[0].attachedPrimaryEmployee);
        }
      }
      let newData = response.map(
        (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection, dataSubsectionIndex: any) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow: any, dataRowIndex: any) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map(
                    (dataColumn: any, dataColumnIndex: any) => {
                      let newColumn = { ...dataColumn };
                      newColumn.children = dataColumn.children;
                      newColumn.children = dataColumn.children.map(
                        (dataInput: any, dataInputIndex: any) => {
                          if (dataInput.field_lable === "Salary") {
                            setEmpSalary(dataInput.value);
                            setsalaryPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput?.field_lable === "Member Type") {
                            memberName = dataInput.value;
                            setselectedMember(dataInput.value);
                            setPriorityStatus(
                              dataInput.value === "Employee"
                                ? res.data.data[0].employement_status.value
                                : 39
                            );

                            value_update("Member Type", dataInput.value);
                          }
                          if (dataInput?.field_lable === "First Name") {
                            setFirstNameChar(dataInput.value);
                            setfirstNamePath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Last Name") {
                            setlastNamePath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Salary Bracket") {
                            setEmpSalaryBracket(dataInput.value);
                            setsalaryBracketPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Department") {
                            setEmpDepartment(dataInput.value);
                          }
                          if (dataInput.field_lable === "Primary Employee") {
                            setprimaryEmpId(dataInput.value);
                            setRelatedEmpId(dataInput.value);
                            setRelatedEmpIdLocal(dataInput.value);
                            if (
                              memberName.toLowerCase() === "dependent" ||
                              memberName.toLowerCase() === "depandent"
                            ) {
                              getDepAssignedPolicies(
                                dataInput.value,
                                res.data.data[0].memberId
                              );
                            } else {
                              getAssignedPolicies(id);
                            }
                          }
                          if (dataInput.field_lable === "Designation") {
                            setEmpDesignation(dataInput.value);
                          }
                          if (
                            dataInput.field_type === "datepicker" &&
                            dataInput.field_lable === "Date Of Joining"
                          ) {
                            setEmpDoj(dataInput.value);
                          }
                          if (
                            dataInput.field_type === "datepicker" &&
                            dataInput.field_lable === "Date Of Birth"
                          ) {
                            setEmpDob(dataInput.value);
                          } else if (
                            dataInput.field_type === "datepicker" &&
                            dataInput.field_lable?.toLowerCase() ===
                              "last day of working"
                          ) {
                            setEmpLdw(dataInput.value);
                          }

                          if (dataInput.field_type === "searchClient") {
                            setSelectedClientName(dataInput.value);

                            //This is breaking for some reason :(
                            // value_update("clientId", dataInput.value);
                          }
                          if (dataInput?.field_lable === "Select Client") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }

                          if (dataInput?.field_lable === "Primary Employee") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }
                          if (dataInput?.field_lable === "Client") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }
                          if (memberName === "Employee") {
                            if (
                              dataInput?.field_lable ===
                              "Relationship With Employee"
                            ) {
                              return {
                                ...dataInput,
                                pickliststep: [
                                  {
                                    field_name: "Self",
                                    label: "Self",
                                    value: "Self",
                                  },
                                ],
                              };
                            }
                          }
                          if (dataInput.field_type === "hideid") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          } else {
                            return { ...dataInput };
                          }
                        }
                      );
                      return newColumn;
                    }
                  );
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );

      const updatedItems = newData;
      if (
        memberName.toLowerCase() === "dependent" ||
        memberName.toLowerCase() === "depandent"
      ) {
        updatedItems[1]?.children.splice(1, 1);
      }
      setFormData(updatedItems);
    };
    const onError = (err: any) => {};
    EMPLOYEE_SERVICES.employee_detail_view_layout(id, onSuccess, onError);
  };

  const update_status = (value: number, id: string, type: string) => {
    const onSuccess = (res: any) => {
      get_employee_layout(id);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id,
      type,
      usertypeinfo.toLowerCase()
    );
  };
  const checkIsRelationExist = (
    attachedPrimaryEmployee: string,
    relation: string
  ) => {
    const onSuccess = (res: any) => {
      setIsRealtionExist(res.data.data.exists);
      if (res.data.data.exists) {
        toast.warning(
          "This relationship already exists for the Primary Employee"
        );
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    if (
      !attachedPrimaryEmployee ||
      !relation ||
      selectedMember !== "Dependent" ||
      relation === "Son" ||
      relation === "Daughter"
    ) {
      return;
    }
    EMPLOYEE_SERVICES.checkIsRelationExist(
      onSuccess,
      onError,
      attachedPrimaryEmployee,
      relation
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setPriorityStatus) {
      const lwd: any = locateFieldIndexFromCustomlayout([
        "Last Day of Working",
        setlastWorkingDay,
      ]);
      let lastworking;
      if (lwd) {
        lastworking =
          formData[lwd[0]]?.children[lwd[1]]?.children[lwd[2]]?.children[lwd[3]]
            ?.children[lwd[4]]?.value;
      }
      let diff: number = differenceInCalendarDays(
        new Date(lastworking),
        new Date()
      );
      if (value === 36 || value === 35) {
        if (!lastworking) {
          return toast.warn(
            "Please Add Last Date of Working before to change status to Resigned/Terminated"
          );
        } else if (diff > 0 && lastworking) {
          return toast.warn(
            "Before Last Date of Working status can't be changed to Resigned/Terminated"
          );
        } else {
          return update_status(value, id, "EMPLOYMENT");
        }
      } else if (priorityStatus === 36 || priorityStatus === 35) {
        if (
          (priorityStatus === 36 && value === 35) ||
          (priorityStatus === 35 && value === 36)
        ) {
          return update_status(value, id, "EMPLOYMENT");
        } else {
          return toast.warning(
            "You can't Change status after Resigned/Terminated"
          );
        }
      } else if (priorityStatus === 38 && value === 37) {
        return update_status(value, id, "EMPLOYMENT");
      } else if (value === 39) {
        return toast.warning("This Staus is not Applicable for Employee");
      } else {
        return update_status(value, id, "EMPLOYMENT");
      }
    }
    setIndividualEditKey({
      key:
        "layout." +
        attrName[0] +
        ".children." +
        attrName[1] +
        ".children." +
        attrName[2] +
        ".children." +
        attrName[3] +
        ".children." +
        attrName[4] +
        ".value",

      value: value,
    });
    let tempkey = {
      key:
        "layout." +
        attrName[0] +
        ".children." +
        attrName[1] +
        ".children." +
        attrName[2] +
        ".children." +
        attrName[3] +
        ".children." +
        attrName[4] +
        ".value",

      value: value,
    };
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "personalAddress") {
      setPersonalAddress({
        ...personalAddress,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
      if (key === "country") {
        get_state_list(value.label, (cb: any) => {
          setStateData(cb);
        });
        setPersonalAddress({
          ...personalAddress,
          country: value.label,
          state: "",
          city: "",
        });
      } else if (key === "state") {
        get_city_list_via_name(
          personalAddress.country,
          value.label,
          (cb: any) => {
            setCityData(cb);
          }
        );
        setPersonalAddress({
          ...personalAddress,
          state: value.label,
          city: value.label === "N.A." ? "N.A." : "",
        });
      } else if (key === "city") {
        setPersonalAddress({
          ...personalAddress,
          city: value.label,
        });
      }
    } else {
      let data: employeeDTO[] = formData;
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Relationship With Employee"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
        checkIsRelationExist(primaryEmpId, value);
      }
      //For required fields
      if (
        data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
          ?.children[attrName[3]]?.children[attrName[4]].field_type ===
        "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_state_list(value, (cb: any) => {
          setStateData(cb);
        });
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_city_list(value, (cb: any) => {
          setCityData(cb);
        });
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Salary"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
        data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
          salaryBracketPath[2]
        ].children[salaryBracketPath[3]].children[salaryBracketPath[4]].value =
          "";
        for (var i = 0; i < salaryDropDowndata?.length; i++) {
          var range: any = salaryDropDowndata[i].label
            .split("-")
            .map((item) => {
              if (!["Below", "Above"].includes(item.trim())) {
                return Number(extractNumbersFromString(item));
              } else {
                return item.trim();
              }
            });
          var min: any = null;
          var max: any = null;

          if (range[0] === "Below") {
            min = -Infinity;
            max = Number(range[1]);
          } else if (range[1] === "Above") {
            min = Number(range[0]);
            max = Infinity;
          } else {
            min = range[0];
            max = range[1];
          }

          // Check if the input number is within the range
          if (Number(value) >= min && Number(value) <= max) {
            var slab = salaryDropDowndata[i].label;

            data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
              salaryBracketPath[2]
            ].children[salaryBracketPath[3]].children[
              salaryBracketPath[4]
            ].value = slab;

            break;
          }
        }
        for (var i = 0; i < salaryDropDowndata?.length; i++) {
          var range: any = salaryDropDowndata[i].label
            .split("-")
            .map((item) => {
              if (!["Below", "Above"].includes(item.trim())) {
                return Number(extractNumbersFromString(item));
              } else {
                return item.trim();
              }
            });
          var min: any = null;
          var max: any = null;

          if (range[0] === "Below") {
            min = -Infinity;
            max = Number(range[1]);
          } else if (range[1] === "Above") {
            min = Number(range[0]);
            max = Infinity;
          } else {
            min = range[0];
            max = range[1];
          }

          // Check if the input number is within the range
          if (Number(value) >= min && Number(value) <= max) {
            var slab = salaryDropDowndata[i].label;

            data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
              salaryBracketPath[2]
            ].children[salaryBracketPath[3]].children[
              salaryBracketPath[4]
            ].value = slab;
            setcurrentSalarySlab(slab);
            break;
          }
        }
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setFormData(data);
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "searchClient"
      ) {
        const onSuccess = (res: any) => {
          setLocationIdData(res.data.data);
        };
        const onError = (err: any) => {
          console.log("err", err);
        };
        EMPLOYEE_SERVICES.get_location_list(
          onSuccess,
          onError,
          value ? value.id : ""
        );
      }
    }
    setIndividualEditKey(tempkey);
  };

  const updateAddressField = () => {
    const onSuccess = (res: any) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    let count = 0;
    if (employeeAssignedPolicies?.length > 0) {
      employeeAssignedPolicies.map((data: any, index: number) => {
        const policyEndDate = new Date(data.endDate);
        const policyStartDate = new Date(data.startDate);
        const currentDate = new Date();

        const isTodayBetweenPolicyDates =
          currentDate >= policyStartDate && currentDate <= policyEndDate;

        if (
          isTodayBetweenPolicyDates &&
          employeeAssignedPolicies?.length > 0 &&
          data.status !== 42
        ) {
          count = 1;
          let warnToast = index === 0 ? true : false;
          if (oldAddress === personalAddress) {
            return;
          } else {
            endorsementAddress(data.policyId, warnToast, data.policyNumber);
          }
        } else if (count === 0 && !allPolicybetweenenrollDate) {
          count = 1;
          let data = { ...personalAddress };
          delete data.warnStreet;
          delete data.warnArea;
          delete data.warnCountry;
          delete data.warnState;
          delete data.warnCity;
          delete data.warnZip;

          ENDORSEMENT_SERVICES.updateAddress(
            URL_CONSTANTS.updateAddress(usertypeinfo.toLowerCase(), id),
            { personalAddress: data },
            onSuccess,
            onError
          );
        }
      });
    } else {
      let data = { ...personalAddress };
      delete data.warnStreet;
      delete data.warnArea;
      delete data.warnCountry;
      delete data.warnState;
      delete data.warnCity;
      delete data.warnZip;

      ENDORSEMENT_SERVICES.updateAddress(
        URL_CONSTANTS.updateAddress(usertypeinfo.toLowerCase(), id),
        { personalAddress: data },
        onSuccess,
        onError
      );
    }
  };

  const endorsementAddress = (
    policyId: string,
    warnToast: boolean,
    policyNumber: string
  ) => {
    let newValue: any = {
      dob: "",
      firstName: "",
      lastName: "",
      contactNumber: 0,
      gender: "",
      email: "",
      street: personalAddress.street,
      area: personalAddress.area,
      country: personalAddress.country,
      state: personalAddress.state,
      city: personalAddress.city.label ?? personalAddress.city,
      zip: Number(personalAddress.zip),
    };
    let params = {
      memberId: id,
      member: `${memberId}-${firstNameChar}`,
      policyId: policyId,
      policy: policyNumber,
      clientId: selectedClientId,
      client: oldValueMember?.Client,
      type: "NON COMMERCIAL",
      endorsementFor: "Personal Address",
      old: [
        oldValueMember.personalAddress.street,
        oldValueMember.personalAddress.area,
        oldValueMember.personalAddress.country,
        oldValueMember.personalAddress.state,
        oldValueMember.personalAddress.city,
        oldValueMember.personalAddress.zip,
      ]
        .filter(Boolean)
        .join(" , "),
      new: newValue,
      update_key: "Personal Address",
    };
    const onSuccess = (res: any) => {
      if (warnToast) {
        toast.success("Your Endorsement has been Created");
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    ENDORSEMENT_SERVICES.create_endorsement(
      URL_CONSTANTS.add_endorsement(usertypeinfo.toLocaleLowerCase()),
      params,
      onSuccess,
      onError
    );
  };

  // api service call as per the validate the dat of the form
  const addEndorsement = (
    label: any,
    value: string,
    policyId: string,
    update_key: string,
    warnToast: boolean,
    policyNumber: string
  ) => {
    let newlabel: string =
      label === "First Name"
        ? "firstName"
        : label === "Last Name"
        ? "lastName"
        : label === "Contact Number"
        ? "contactNumber"
        : label === "Gender"
        ? "gender"
        : label === "Date Of Birth"
        ? "dob"
        : label === "Email"
        ? "email"
        : label === "Department"
        ? "department"
        : label === "Designation"
        ? "designation"
        : label === "Salary Bracket"
        ? "salary_grade"
        : label === "Salary"
        ? "salary"
        : label;
    let newValue: any = {
      dob: "",
      firstName: "",
      lastName: "",
      contactNumber: 0,
      gender: "",
      email: "",
      street: "",
      area: "",
      country: "",
      state: "",
      city: "",
      zip: 0,
    };
    newValue[newlabel] = label === "Contact Number" ? Number(value) : value;
    if (label === "First Name") {
      newValue["lastName"] = oldValueMember["Last Name"];
    }
    if (label === "Last Name") {
      newValue["firstName"] = oldValueMember["First Name"];
    }
    if (label === "Salary") {
      newValue["salary"] = value + "/" + currentSalarySlab;
    }

    const onSuccess = (res: any) => {
      if (warnToast) {
        toast.success("Your Endorsement has been Created");
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    let params = {
      memberId: id,
      member: `${memberId}-${firstNameChar}`,
      policyId: policyId,
      policy: policyNumber,
      clientId: selectedClientId,
      client: oldValueMember?.Client,
      type: label === "Date Of Birth" ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor:
        label === "Last Name" || label === "First Name"
          ? "Name"
          : label === "Date Of Birth"
          ? "Date of Birth"
          : label === "Salary Bracket"
          ? "Salary Grade"
          : label === "Salary Bracket"
          ? "Salary Grade"
          : label,
      old:
        label === "Last Name" || label === "First Name"
          ? `${oldValueMember["First Name"]} ${oldValueMember["Last Name"]}`
          : oldValueMember[label],
      new: newValue,
      update_key:
        label === "Salary"
          ? update_key +
            "/" +
            "layout." +
            salaryBracketPath[0] +
            ".children." +
            salaryBracketPath[1] +
            ".children." +
            salaryBracketPath[2] +
            ".children." +
            salaryBracketPath[3] +
            ".children." +
            salaryBracketPath[4] +
            ".value"
          : label === "Last Name" || label === "First Name"
          ? "layout." +
            firstNamePath[0] +
            ".children." +
            firstNamePath[1] +
            ".children." +
            firstNamePath[2] +
            ".children." +
            firstNamePath[3] +
            ".children." +
            firstNamePath[4] +
            ".value" +
            "/" +
            "layout." +
            lastNamePath[0] +
            ".children." +
            lastNamePath[1] +
            ".children." +
            lastNamePath[2] +
            ".children." +
            lastNamePath[3] +
            ".children." +
            lastNamePath[4] +
            ".value"
          : update_key,
    };
    if (value && value.toString().trim() === oldValueMember[label]?.trim()) {
      if (warnToast) {
        toast.warning("Please make any changes");
      }
      return;
    } else {
      ENDORSEMENT_SERVICES.create_endorsement(
        URL_CONSTANTS.add_endorsement(usertypeinfo.toLocaleLowerCase()),
        params,
        onSuccess,
        onError
      );
    }
  };

  const member_detail_data = (id: string) => {
    const onSuccess = (res: any) => {
      let resopnse: TMemberDetail = res.data.data[0];
      setoldValueMember(resopnse);
    };
    const onError = (err: any) => {};

    let url = `${URL_CONSTANTS.get_member_detail(
      id,
      usertypeinfo.toLocaleLowerCase()
    )}`;
    ENDORSEMENT_SERVICES.member_detail(url, onSuccess, onError);
  };

  const getDepAssignedPolicies = (
    relatedEmpIdLocal: string,
    policyMemberRelationId: string
  ) => {
    const onSuccess = (res: any) => {
      setEmployeeAssignedPolicies(res.data.data.policyDetails);
      res.data.data.policyDetails.map((data: any) => {
        const policyEndDate = new Date(data.endDate);
        const policyStartDate = new Date(data.startDate);
        const currentDate = new Date();

        const isTodayBetweenPolicyDates =
          currentDate >= policyStartDate && currentDate <= policyEndDate;

        if (isTodayBetweenPolicyDates && res.data.data?.length > 0) {
          setAllPolicybetweenenrollDate(true);
        }
      });
    };

    const onError = (err: any) => {
      console.log("---err----", err);
    };

    EMPLOYEE_SERVICES.get_dependent_assigned_policies(
      onSuccess,
      onError,
      relatedEmpIdLocal,
      policyMemberRelationId
    );
  };

  const getAssignedPolicies = (employeeId: any) => {
    const onSuccess = (res: any) => {
      const membersToExclude: string[] = [];

      const newPolicies = res.data.data.policyDetails.map(
        (item: any, index: number) => {
          const newMembers = item.members.filter((item: any) => item);

          if (index !== 0) {
            return { ...item, deleteable: true, members: newMembers };
          } else {
            return { ...item, deleteable: false, members: newMembers };
          }
        }
      );
      setEmployeeAssignedPolicies(newPolicies);

      newPolicies.map((data: any, index: number) => {
        const policyEndDate = new Date(data.endDate);
        const policyStartDate = new Date(data.startDate);
        const currentDate = new Date();

        const isTodayBetweenPolicyDates =
          currentDate >= policyStartDate && currentDate <= policyEndDate;

        if (isTodayBetweenPolicyDates && res.data.data?.length > 0) {
          setAllPolicybetweenenrollDate(true);
        }
      });
    };

    const onError = (err: any) => {
      console.log(err);
    };

    {
      usertypeinfo === "EMPLOYER"
        ? EMPLOYER_EMPLOYEE_SERVICES.get_assigned_policies(
            onSuccess,
            onError,
            employeeId
          )
        : EMPLOYEE_POLICY_SERVICES.get_assigned_policies(
            onSuccess,
            onError,
            employeeId
          );
    }
  };

  const handleFieldUpdate = (
    formData: any,
    attrName: any,
    individualEditKey: any,
    id: any,
    usertypeinfo: any
  ) => {
    const field =
      formData[attrName[0]].children[attrName[1]].children[attrName[2]]
        .children[attrName[3]].children[attrName[4]];

    const onSuccess = (res: any) => {
      toast.success(res.data.message);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    if (field.field_type === "phone") {
      if (!validateMobileNumber(field.value)) {
        return toast.error("Please Enter Valid Phone Number");
      }
    } else if (field.field_type === "email") {
      if (!validateEmail(field.value)) {
        return toast.error("Please Enter Valid Email");
      }
    }

    EMPLOYEE_SERVICES.update_detail_view_individual_edit_employee(
      individualEditKey,
      onSuccess,
      onError,
      id,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const individual_edit = (attrName?: any) => {
    if (isRealtionExist) {
      return toast.error(
        "This relationship already exists for the Primary Employee"
      );
    }
    if (individualEditKey.value === "") {
      return;
    } else if (attrName?.length > 2) {
      let label =
        formData[attrName[0]].children[attrName[1]].children[attrName[2]]
          .children[attrName[3]].children[attrName[4]].field_lable;
      let newValue =
        formData[attrName[0]].children[attrName[1]].children[attrName[2]]
          .children[attrName[3]].children[attrName[4]].value;
      let count = 0;
      if (
        employeeAssignedPolicies?.length > 0 &&
        label !== "Last Day of Working" &&
        label !== "Office Contact Number" &&
        label !== "Date Of Joining"
      ) {
        let num = 0;
        employeeAssignedPolicies.forEach((data) => {
          const policyEndDate = new Date(data.endDate);
          const policyStartDate = new Date(data.startDate);
          const currentDate = new Date();
          const isTodayBetweenPolicyDates =
            currentDate >= policyStartDate && currentDate <= policyEndDate;
          if (isTodayBetweenPolicyDates && data.status !== 42) {
            let warnToast = num === 0 ? true : false;
            num = num + 1;
            addEndorsement(
              label,
              newValue,
              data.policyId,
              individualEditKey.key,
              warnToast,
              data.policyNumber
            );
          } else if (count === 0 && !allPolicybetweenenrollDate) {
            let datakey = individualEditKey;
            if (label === "Last Day of Working") {
              datakey = {
                key: individualEditKey.key + "/" + "Last Day of Working",
                value: individualEditKey.value,
              };
            } else if (label === "Salary") {
              datakey = {
                key:
                  individualEditKey.key +
                  "/" +
                  `layout.${salaryBracketPath[0]}.children.${salaryBracketPath[1]}.children.${salaryBracketPath[2]}.children.${salaryBracketPath[3]}.children.${salaryBracketPath[4]}.value`,
                value: individualEditKey.value + "/" + currentSalarySlab,
              };
            }
            count = 1;
            handleFieldUpdate(formData, attrName, datakey, id, usertypeinfo);
          }
        });
      } else {
        let datakey = individualEditKey;
        if (label === "Last Day of Working") {
          datakey = {
            key: individualEditKey.key + "/" + "Last Day of Working",
            value: individualEditKey.value,
          };
        } else if (label === "Salary") {
          datakey = {
            key:
              individualEditKey.key +
              "/" +
              `layout.${salaryBracketPath[0]}.children.${salaryBracketPath[1]}.children.${salaryBracketPath[2]}.children.${salaryBracketPath[3]}.children.${salaryBracketPath[4]}.value`,
            value: individualEditKey.value + "/" + currentSalarySlab,
          };
        }
        handleFieldUpdate(formData, attrName, datakey, id, usertypeinfo);
      }
    }
  };

  const get_status_employement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const get_dep_designation_salary = (clientid: any, type: any) => {
    const onSuccess = (res: any) => {
      if (type === "DEPARTMENTS") {
        setdepartmentDropDownData(res.data.data[0].data);
      } else if (type === "DESIGNATIONS") {
        setdesignationDropDowndata(res.data.data[0].data);
      } else if (type === "SALARY_BRACKETS") {
        setsalaryDropDowndataOriginal(res.data.data[0].data);
        let dropDownData: any = res.data.data[0].data.map(
          (item: any, index: any) => {
            return {
              id: item.label
                .split("-")
                .map((e: any) => formatCurrency(Number(e)))
                .join(" - "),
              label: item.label
                .split("-")
                .map((e: any) => formatCurrency(Number(e)))
                .join(" - "),
            };
          }
        );

        // dropDownData.unshift({
        //   id: `Below - ${dropDownData[0].label.split("-")[0]}`,
        //   label: `Below - ${dropDownData[0].label.split("-")[0]}`,
        // });

        dropDownData.push({
          id: `${
            dropDownData[dropDownData?.length - 1].label.split("-")[1]
          } - Above`,
          label: `${
            dropDownData[dropDownData?.length - 1].label.split("-")[1]
          } - Above`,
        });
        if (
          res.data.data[0].data?.length === 1 &&
          !res.data.data[0].data[0].label
        ) {
          setsalaryDropDowndata([]);
        } else {
          setsalaryDropDowndata(dropDownData);
        }
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      usertypeinfo.toLowerCase(),
      clientid,
      type
    );
  };
  const locateFieldIndexFromCustomlayout = (attrName: [string, any]) => {
    let returnVal;

    formData?.map((dataMainsection: any, dataMainsectionIndex: any) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection?.children?.map(
        (dataSubsection: any, dataSubsectionIndex: any) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection?.children?.map(
            (dataRow: any, dataRowIndex: any) => {
              let newRow = { ...dataRow };
              newRow.children = dataRow?.children?.map(
                (dataColumn: any, dataColumnIndex: any) => {
                  let newColumn = { ...dataColumn };
                  newColumn.children = dataColumn.children;
                  newColumn.children = dataColumn?.children?.map(
                    (dataInput: any, dataInputIndex: any) => {
                      if (dataInput?.field_lable === attrName[0]) {
                        if (attrName[1]) {
                          attrName[1]([
                            dataMainsectionIndex,
                            dataSubsectionIndex,
                            dataRowIndex,
                            dataColumnIndex,
                            dataInputIndex,
                          ]);
                        }
                        returnVal = [
                          dataMainsectionIndex,
                          dataSubsectionIndex,
                          dataRowIndex,
                          dataColumnIndex,
                          dataInputIndex,
                        ];
                      }
                    }
                  );
                  return newColumn;
                }
              );
              return newRow;
            }
          );
          return newSubsection;
        }
      );
      return newMainSection;
    });

    return returnVal;
  };

  return (
    <>
      {showLoader && <NewLoader />}

      <>
        <Grid container spacing={3}>
          <Grid
            xs={2}
            style={{
              maxWidth: "260px",
              position: "relative",
              top: "-75px",
              paddingLeft: "0",
            }}
          >
            <div
              className="leftSection h-100"
              style={{ position: "fixed", paddingLeft: "8px" }}
            >
              <>
                {formData.map((dataMainSection: employeeDTO, index: number) => (
                  <>
                    <Link
                      className={
                        sectionStatus === dataMainSection.name
                          ? `active sectionLink`
                          : "sectionLink personalDetailsSection"
                      }
                      onClick={() => {
                        handleClicks(dataMainSection.name);
                        SetSectionStatus(
                          dataMainSection.name === undefined
                            ? ""
                            : dataMainSection.name
                        );
                      }}
                    >
                      <span>{dataMainSection.name}</span>
                    </Link>
                    {dataMainSection.children.map((dataSubsection, index) => (
                      <ul key={index} className="accordion_list">
                        <li>
                          <Link
                            className={
                              subsectionStatus === dataSubsection.name
                                ? `inner_link active`
                                : `inner_link`
                            }
                            onClick={() => {
                              handleClicks(dataSubsection.name);
                              SetsubSectionStatus(dataSubsection.name);
                              SetSectionStatus(
                                dataMainSection.name === undefined
                                  ? ""
                                  : dataMainSection.name
                              );
                            }}
                          >
                            {dataSubsection.name}
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </>
                ))}
                <Link
                  className={
                    sectionStatus === "OrganisationDetail"
                      ? "active sectionLink OrganisationDetail"
                      : "sectionLink OrganisationDetail"
                  }
                  onClick={() => {
                    scrollToSection(OrganisationDetail);
                    SetSectionStatus("OrganisationDetail");
                  }}
                >
                  <span>Address Details</span>
                </Link>
              </>
            </div>
          </Grid>
          <Grid xs paddingLeft={0}>
            <>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <div className="client_info_section">
                    <p className="client_info_p empID md_width">
                      Client ID: <span>{splitedClinetid[0]}</span>
                    </p>

                    <p className="client_info_p totalEmployees md_width">
                      Member ID: <span>{memberId}</span>
                    </p>
                    {selectedMember.toLowerCase() === "dependent" ||
                    selectedMember.toLowerCase() === "depandent" ? (
                      <p className="client_info_p user md_width">
                        Primary Employee Member ID:{" "}
                        <span>{primaryEmployeeId}</span>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid xs={6}>
                  <div
                    className="client_info_section"
                    style={{ justifyContent: "end" }}
                  >
                    <p
                      className="client_info_p user"
                      style={{ width: "unset" }}
                    >
                      Employement Status:{" "}
                      <span>
                        <div
                          className="ml-2"
                          id={`_${priorityStatus}`}
                          style={{ borderRadius: "20px" }}
                        >
                          <div
                            className="status_dropdown"
                            id={`_${priorityStatus?.toString()}`}
                          >
                            {selectedMember !== "Employee" ? (
                              <NA id={`_${priorityStatus?.toString()}`} />
                            ) : priorityStatus === 35 ? (
                              <Resigned id={`_${priorityStatus?.toString()}`} />
                            ) : priorityStatus === 36 ? (
                              <Terminated
                                id={`_${priorityStatus?.toString()}`}
                              />
                            ) : priorityStatus === 37 ? (
                              <Employed id={`_${priorityStatus?.toString()}`} />
                            ) : priorityStatus === 38 ? (
                              <Probation
                                id={`_${priorityStatus?.toString()}`}
                              />
                            ) : priorityStatus === 39 ? (
                              <NA id={`_${priorityStatus?.toString()}`} />
                            ) : null}
                            {selectedMember === "Employee" ? (
                              <SelectDropdown
                                class_name="inputFieldd"
                                title=""
                                value={priorityStatus}
                                attrName={setPriorityStatus}
                                value_update={updateMasterState}
                                dropdown_data={prioritystatusData}
                                warn_status={false}
                                disabled={!statusPermission}
                              />
                            ) : (
                              <span>NA</span>
                            )}
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </Grid>
                <Grid xs={12}>
                  {formData.map(
                    (
                      dataMainSection: employeeDTO,
                      indexMainSection: number
                    ) => (
                      // Main section
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus(dataMainSection.name);
                        }}
                        key={indexMainSection}
                        className="detailSection"
                      >
                        <div id={dataMainSection.name}>
                          <div className="sectionTitle">
                            <h4>{dataMainSection.name}</h4>
                          </div>
                          {dataMainSection.children.map(
                            (dataSubsection, indexSubsection) => (
                              // Sub Section
                              <Grid
                                onMouseEnter={() => {
                                  SetsubSectionStatus(dataSubsection.name);
                                }}
                                key={indexSubsection}
                                container
                              >
                                <Grid xs={12}>
                                  <h5
                                    id={dataSubsection.name}
                                    key={dataSubsection.type}
                                    className="subHeading"
                                  >
                                    {dataSubsection.name}
                                  </h5>
                                  {dataSubsection.children.map(
                                    (dataRow, indexRow) => (
                                      // Row
                                      <Grid
                                        key={indexRow}
                                        container
                                        spacing={3}
                                      >
                                        {dataRow.children.map(
                                          (dataColumn, indexColumn) => (
                                            // Columns created
                                            <Grid key={indexColumn} xs>
                                              {dataColumn.children.map(
                                                (dataInput, indexInput) => (
                                                  // input Component will be here
                                                  <form key={indexInput}>
                                                    {dataInput.field_lable ===
                                                    "Member Type" ? (
                                                      <RKTextField
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput?.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 16
                                                            : dataInput.characterLimit
                                                        }
                                                        disabled
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "salary" ? (
                                                      <SelectDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          salaryDropDowndata
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                            ? true
                                                            : formData[
                                                                salaryPath[0]
                                                              ]?.children[
                                                                salaryPath[1]
                                                              ]?.children[
                                                                salaryPath[2]
                                                              ]?.children[
                                                                salaryPath[3]
                                                              ]?.children[
                                                                salaryPath[4]
                                                              ].value
                                                            ? true
                                                            : false
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        params={individual_edit}
                                                      />
                                                    ) : dataInput.field_lable ===
                                                      "Salary" ? (
                                                      <RKTextFieldDisable
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={
                                                          dataInput.value
                                                            ? formatCurrency(
                                                                dataInput.value
                                                              )
                                                            : ""
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        params={individual_edit}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 16
                                                            : dataInput.characterLimit
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "department" ? (
                                                      <SelectDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          departmentDropDownData
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        params={individual_edit}
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "designation" ? (
                                                      <SelectDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          designationDropDowndata
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        params={individual_edit}
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                        "singleselect" &&
                                                      (dataInput.field_lable ===
                                                        "Relationship With Employee" ||
                                                        dataInput.field_lable ===
                                                          "Title") ? (
                                                      <SelectDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        // disabled={dataInput.field_lable === "Title"
                                                        //   || dataInput.field_lable === "Relationship With Employee"
                                                        // }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        params={individual_edit}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "singleselect" ? (
                                                      <SelectDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                            ? true
                                                            : dataInput.field_lable ===
                                                                "Title" ||
                                                              dataInput.field_lable ===
                                                                "Relationship With Employee"
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        params={individual_edit}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "searchClient" ? (
                                                      <SearchDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_type_id_value
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={clientIdData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg={
                                                          "Select Client"
                                                        }
                                                        params={individual_edit}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "searchLocation" ? (
                                                      <SearchDropdownDisable
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        fieldDisable={true}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={locationIdData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg={
                                                          "Select Client"
                                                        }
                                                        params={individual_edit}
                                                      />
                                                    ) : dataInput.field_lable ===
                                                      "Member Type" ? (
                                                      <RKTextField
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 16
                                                            : dataInput.characterLimit
                                                        }
                                                        disabled
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "phone" ? (
                                                      <RKTextFieldDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        params={individual_edit}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 16
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "email" ? (
                                                      <RKTextFieldDisable
                                                        // disabled={true}
                                                        validation_type="email"
                                                        params={individual_edit}
                                                        type="email"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateEmail(
                                                                dataInput.value
                                                              ) === false
                                                            ? `Enter a valid email Id`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "datepicker" ? (
                                                      <DatePickerDisable
                                                        params={individual_edit}
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={
                                                          dataInput.value
                                                            ? new Date(
                                                                dataInput.value
                                                              )
                                                            : null
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        error_message="Enter a Valid Date"
                                                        warn_status={
                                                          dataInput.error ===
                                                          undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        max_date={
                                                          dataInput.field_lable ===
                                                            "Date Of Birth" ||
                                                          dataInput.field_lable ===
                                                            "Date Of Joining"
                                                            ? new Date()
                                                            : null
                                                        }
                                                        min_date={
                                                          dataInput.field_lable?.toLowerCase() ===
                                                            "date of joining" &&
                                                          empDob
                                                            ? new Date(empDob)
                                                            : dataInput.field_lable?.toLowerCase() ===
                                                                "last day of working" &&
                                                              empDoj
                                                            ? new Date(empDoj)
                                                            : dataInput.field_lable?.toLowerCase() ===
                                                              "last day of working"
                                                            ? new Date()
                                                            : null
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "searchselect" ? (
                                                      <SearchDropdownDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                          undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg={
                                                          "Select the value"
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "checkbox" ? (
                                                      <CheckBox
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        name={
                                                          dataInput.field_lable
                                                        }
                                                        id={dataInput._id}
                                                        value={Boolean(
                                                          dataInput.value
                                                        )}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        default_check={true}
                                                        disable={
                                                          editPermission ===
                                                          false
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "number" ? (
                                                      <RKTextFieldDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={
                                                          dataInput.field_lable ===
                                                          "Salary"
                                                            ? formatCurrency(
                                                                dataInput.value
                                                              )
                                                            : dataInput.value
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ==
                                                      "currency" ? (
                                                      <CurrencyTextFieldDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} Limit`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiselect" ? (
                                                      <MultipleSearchDropdownDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        dropdown_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                          undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          "Select the value"
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiline" ? (
                                                      <TextAreaFieldDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        specialcharacter={
                                                          !dataInput.speacialChar
                                                            ? false
                                                            : dataInput.speacialChar
                                                        }
                                                        alphanumeric={
                                                          !dataInput.alphaNumeric
                                                            ? false
                                                            : dataInput.alphaNumeric
                                                        }
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                          undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} charcter`
                                                            : ""
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 30
                                                            : dataInput.characterLimit
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "url" ? (
                                                      <RKTextFieldDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        params={individual_edit}
                                                        validation_type="url"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateUrl(
                                                                dataInput.value
                                                              ) === false
                                                            ? `Enter a valid Url`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "country" ? (
                                                      <SearchDropdownDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        disable_status={
                                                          disableAddress
                                                        }
                                                        set_disable_status={
                                                          setDisableAddress
                                                        }
                                                        params={individual_edit}
                                                        class_name="inputField"
                                                        title="Country"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={countryData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg="Please select country"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "state" ? (
                                                      <SearchDropdownDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        disable_status={
                                                          disableAddress
                                                        }
                                                        set_disable_status={
                                                          setDisableAddress
                                                        }
                                                        class_name="inputField"
                                                        title="State"
                                                        params={individual_edit}
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={stateData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg="Please select state"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "city" ? (
                                                      <SearchDropdownDisable
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                        }
                                                        disable_status={
                                                          disableAddress
                                                        }
                                                        set_disable_status={
                                                          setDisableAddress
                                                        }
                                                        params={individual_edit}
                                                        class_name="inputField"
                                                        title="City"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={cityData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg="Please select city"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "hide" ? null : (
                                                      <RKTextFieldDisable
                                                        params={individual_edit}
                                                        specialcharacter={
                                                          !dataInput.speacialChar
                                                            ? false
                                                            : dataInput.speacialChar
                                                        }
                                                        alphanumeric={
                                                          !dataInput.alphaNumeric
                                                            ? false
                                                            : dataInput.alphaNumeric
                                                        }
                                                        validation_type="name"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        disabled={
                                                          editPermission ===
                                                          false
                                                            ? true
                                                            : dataInput.field_lable ===
                                                              "Organization Employee ID"
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            ?.length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                ?.length >
                                                                dataInput.characterLimit
                                                            ? `Maximum ${dataInput.characterLimit} charcter`
                                                            : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 30
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    )}
                                                  </form>
                                                )
                                              )}
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            )
                          )}
                        </div>
                      </Box>
                    )
                  )}

                  <Box
                    onMouseEnter={() => {
                      SetSectionStatus("OrganisationDetail");
                    }}
                    className="detailSection"
                    ref={OrganisationDetail}
                  >
                    <div className="sectionTitle">
                      <h4>Address Details</h4>
                    </div>
                    <div>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          <h5 className="subHeading">
                            Personal Address Details
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Building/Street Name"
                            value={personalAddress.street}
                            attrName={["personalAddress", "street"]}
                            value_update={updateMasterState}
                            warn_status={personalAddress.warnStreet}
                            required={true}
                            disabled={editPermission === false}
                          />
                        </Grid>
                        <Grid sm={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Area Name"
                            value={personalAddress.area}
                            attrName={["personalAddress", "area"]}
                            value_update={updateMasterState}
                            warn_status={personalAddress.warnArea}
                            required={false}
                            disabled={editPermission === false}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Country"
                            value={personalAddress.country}
                            attrName={["personalAddress", "country"]}
                            value_update={updateMasterState}
                            data={countryData}
                            warn_status={personalAddress.warnCountry}
                            required={true}
                            error_message="Select Country"
                            disabled={editPermission === false}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="State"
                            value={personalAddress.state}
                            attrName={["personalAddress", "state"]}
                            value_update={updateMasterState}
                            data={stateData}
                            warn_status={personalAddress.warnState}
                            error_message="Select state"
                            required={true}
                            disabled={editPermission === false}
                          />
                        </Grid>

                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <SearchDropdown
                            class_name="inputField"
                            title="City"
                            value={personalAddress.city}
                            attrName={["personalAddress", "city"]}
                            value_update={updateMasterState}
                            data={cityData}
                            warn_status={personalAddress.warnCity}
                            error_message="Select City"
                            required={true}
                            disabled={editPermission === false}
                          />
                        </Grid>

                        <Grid sm={6} md={4} lg={3} xl={3}>
                          <RKTextField
                            class_name="inputField"
                            title={"Pincode"}
                            value={personalAddress.zip}
                            attrName={["personalAddress", "zip"]}
                            value_update={updateMasterState}
                            warn_status={personalAddress.warnZip}
                            required={true}
                            charcterlimit={6}
                            validation_type={"numeric"}
                            error_messg="Enter valid Pincode"
                            disabled={editPermission === false}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Box className="ctaBtn mt-4" textAlign={"center"}>
                      <Button
                        variant="contained"
                        className="mediumBtn"
                        onClick={() => {
                          updateAddressField();
                        }}
                        disabled={editPermission === false}
                      >
                        Update Address
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </>
    </>
  );
};
export default OverViewTab;
