import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import VerifiedIcon from "@mui/icons-material/Verified";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import SlidingPanel from "react-sliding-side-panel";
import DetailPolicyHistory from "../../../../../Components/Broker/DetailPolicyHistory/DetailPolicyHistory";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import RemovePopup from "../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { AddQuoteNoteDTO } from "../../../../../Services/DTO/QuotesDTO";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import "../../../../../Statuses.scss";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import QuoteInsurer from "../Insurer/QuoteInsurer";
import FeatureAndExclusions from "./FeaturesAndExclusions";
import PolicyDetailOverViewTab from "./OverviewTab";
import QuoteConfig from "./QuoteConfig/QuoteConfig";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import Riders from "./Riders";
import { toast } from "react-toastify";

const contact_organization_data = [
  { key: "Evervent", value: "Evervent" },
  { key: "Amazon", value: "Amazon" },
  { key: "Infosys", value: "Infosys" },
  { key: "Google", value: "Google" },
  { key: "Microsoft", value: "Microsoft" },
];

const spoc_contact_data = [
  { key: "EV0065VikasSaini", value: "EV0065 Vikas Saini" },
  { key: "EV0016KartikeKakkar", value: "EV0016 Kartike Kakkar" },
];
const contact_department_data = [
  { key: "Engineering", value: "Engineering" },
  { key: "IT", value: "IT" },
  { key: "Human Resource", value: "Human Resource" },
  { key: "Business Development", value: "Business Development" },
  { key: "Designing", value: "Designing" },
  { key: "Management", value: "Management" },
];
const current_employer_data = [
  { key: "Evervent", value: "Evervent" },
  { key: "Amazon", value: "Amazon" },
  { key: "Infosys", value: "Infosys" },
  { key: "Google", value: "Google" },
  { key: "Microsoft", value: "Microsoft" },
];
const job_title_data = [
  {
    key: "Associate Frontend Developer",
    value: "Associate Frontend Developer",
  },
  { key: "Hr", value: "Hr" },
  { Key: "QA Engineer", value: "QA Engineer" },
];

function QuotesDetail() {
  const navigate = useNavigate();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [viewTabsStatus, setViewTabsStatus] = React.useState("overview");
  const [postQueryForm, setPostQueryForm] = React.useState(false);
  const [intialCdBalance, setintialCdBalance] = React.useState("");
  const [priorityStatus, setPriorityStatus] = React.useState(1);
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [spocContact, setSpocContact] = React.useState("");
  const [openContactForm, setOpenContactForm] = React.useState(false);
  const [contactName, setContactName] = React.useState("");
  const [contactOrganization, setContactOrganization] = React.useState("");
  const [contactDepartment, setContactDepartment] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactMobile, setContactMobile] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [currentEmployer, setCurrentEmployer] = React.useState("");
  const [policyNotes, setpolicyNotes] = React.useState("");
  const [uploadFile, setUploadFile] = React.useState(false);
  const [removeCategory, setRemoveCategory] = React.useState(false);
  const [overviewData, setOverviewData] = useState<any>({});
  const [notesData, setNotesData] = useState<Array<{ name: string; date: Date; description: string }>>([]);
  const [sectionStatus, SetSectionStatus] = React.useState<string>("notesTab");
  const [quoteId, setQuoteId] = useState<string | null>("");
  const notesTab = useRef(null);
  const userDetailsState = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [showLoader, setShowLoader] = useState(true);
  const Quotes_permission = useAppSelector((state) => state.permissionSlice.BROKER?.Quotes);
  const [editQuotesPermission, setEditQuotesPermission] = useState<any>(null);
  const [statusQuotesPermission, setStatusQuotesPermission] = useState<any>(null);
  const [check, setCheck] = useState(false);
  const [quoteStatus,setQuoteStatus] = useState();
  console.log("quoteStatdus",quoteStatus)
  
  useEffect(() => {
    console.count("baigan")
    //get_status();
    setShowLoader(true);
    setEditQuotesPermission(
      Quotes_permission?.edit === undefined ? false : Quotes_permission?.edit
    );
    setStatusQuotesPermission(
      Quotes_permission?.status === undefined ? false : Quotes_permission?.status
    );
  }, []);

  let timeout: any;
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    const delayedApiCall = () => {
      
      const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setQuoteId(id);
    if (viewTabsStatus === "notes") {
      get_policy_notes_tab(id);
    }

    if (viewTabsStatus === "overview") {
      get_policy_overview_tab(id);
    }
    }
    timeout = setTimeout(delayedApiCall, 900);
 
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
 
   
  }, [viewTabsStatus]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
      setpolicyNotes(value);
      post_notes(value);
    } else if (attrName === setPriorityStatus) {
      setPriorityStatus(value);
      update_status(value);
    } else {
      attrName(value);
    }
  };

  const post_notes = (policyNotes: any) => {
    let data: AddQuoteNoteDTO = {
      notes: {
        name: userDetailsState?.name
          ? userDetailsState?.name + "-" + userDetailsState.username
          : userDetailsState.username,
        description: policyNotes,
      },
    };

    const onSuccess = (res: any) => {
      get_policy_notes_tab(quoteId);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    QUOTES_SERVICES.add_quote_note(
      onSuccess,
      onError,
      data,
      quoteId ? quoteId : ""
    );
  };

  const get_policy_notes_tab = (id: any) => {
    const onSuccess = (res: any) => {
      setNotesData(res.data.data[0].notes);
     
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.get_quote_tab_data(onSuccess, onError, id, "NOTES");
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const get_policy_overview_tab = (id: any) => {
    get_status();

    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
     setQuoteStatus(res?.data?.data[0]?.status);
      setPriorityStatus(res.data.data[0].status);
      setOverviewData(res.data.data[0]);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.get_quote_tab_data(onSuccess, onError, id, "OVERVIEW");
  };

  const sendDataToChild = (data: string) => {
    setintialCdBalance(data);
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="layoutWrapper">
          <BulkUploadForm
            open_status={uploadFile}
            attrName={setUploadFile}
            value_update={updateMasterState}
          />
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(ALL_ROUTES.QUOTES);
                }}
              >
                <ChevronLeftIcon />
              </Link>
              <span className="detailIcon">
                {overviewData?.organisationDetails?.organisationName.charAt(0)}
              </span>
              <div>
                <h3>{overviewData?.organisationDetails?.organisationName}</h3>
                <p>
                  Quote Id:{" "}
                  {overviewData?.preFix + String(overviewData?.quoteId)}
                </p>
              </div>
            </Grid>
            <Grid
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {/* <div>
            <Tooltip title="Remove">
              <Link
                underline="none"
                className="actionLinks red_icon"
                onClick={() => {
                  setRemoveStatus(true);
                }}
              >
                <DeleteOutlineIcon />
              </Link>
            </Tooltip>
          </div> */}
              {editQuotesPermission && (quoteStatus==1 ||  quoteStatus==2|| quoteStatus==3)&&  (
                <div>
                  <Tooltip title="Global Edit">
                    <Link
                      className="actionLinks"
                      onClick={() => {
                        navigate(`${ALL_ROUTES.EDIT_QUOTES}?id=${id}`);
                      }}
                    >
                      <BorderColorIcon />
                    </Link>
                  </Tooltip>
                </div>
              )}

              {/* Status Dropdown */}
              <div id={`_${priorityStatus?.toString()}`}>
                <div className="status_dropdown" id={`_${priorityStatus?.toString()}`}                >
                  {priorityStatus === 1 ? (
                    <BookmarkAddIcon id={`_${priorityStatus?.toString()}`} />
                  ) : priorityStatus === 2 ? (
                    <MarkEmailReadIcon id={`_${priorityStatus?.toString()}`} />
                  ) : priorityStatus === 3 ? (
                    <MarkEmailReadIcon id={`_${priorityStatus?.toString()}`} />
                  ) : priorityStatus === 4 ? (
                    <VerifiedIcon id={`_${priorityStatus?.toString()}`} />
                  ) : priorityStatus === 5 ? (
                    <CancelIcon id={`_${priorityStatus?.toString()}`} />
                  ) : priorityStatus === 6 ? (
                    <PublishedWithChangesIcon
                      id={`_${priorityStatus?.toString()}`}
                    />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={priorityStatus}
                    attrName={setPriorityStatus}
                    value_update={updateMasterState}
                    dropdown_data={prioritystatusData}
                    warn_status={false}
                    disabled={!statusQuotesPermission}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <>
                <Grid container spacing={3}>
                  <Grid xs className="rightSection h-100">
                    <>
                      <Grid container spacing={3} alignItems="center">
                        <Grid xs maxWidth="260px"></Grid>
                        <Grid xl={7} lg={8} md={8} xs={12}>
                          <div className="productTabs ">
                            <Button
                              className={
                                viewTabsStatus === "overview"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(
                                  setViewTabsStatus,
                                  "overview"
                                );
                              }}
                            >
                              Overview
                            </Button>
                            {/* <Button
                      className={
                        viewTabsStatus === "History"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "History");
                      }}>
                      Policy History
                    </Button> */}

                            <Button
                              className={
                                viewTabsStatus === "Configuration"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(
                                  setViewTabsStatus,
                                  "Configuration"
                                );
                              }}
                            >
                              Configuration
                            </Button>
                            <Button
                              className={
                                viewTabsStatus === "Features"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(
                                  setViewTabsStatus,
                                  "Features"
                                );
                              }}
                            >
                              Features & Exclusions
                            </Button>
                            {overviewData.productType?.name === "Group Term" && (
                              <Button
                                className={
                                  viewTabsStatus === "Riders"
                                    ? "defaultTab active"
                                    : "defaultTab"
                                }
                                onClick={() => {
                                  updateMasterState(
                                    setViewTabsStatus,
                                    "Riders"
                                  );
                                }}
                              >
                                Riders
                              </Button>
                            )}
                            <Button
                              className={
                                viewTabsStatus === "Insurers"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(
                                  setViewTabsStatus,
                                  "Insurers"
                                );
                              }}
                            >
                              Insurers
                            </Button>

                            <Button
                              className={
                                viewTabsStatus === "notes"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "notes");
                              }}
                            >
                              Notes
                            </Button>
                          </div>
                        </Grid>
                        <Grid
                          xl
                          lg={12}
                          md={12}
                          xs={12}
                          sx={{ textAlign: "right" }}
                          className="text-xs-center ctaBtn"
                        >
                          <Button
                            onClick={() => {
                              update_status(3);
                              setPriorityStatus(3);
                            }}
                            variant="contained"
                            className="borderBtn smBtn"
                          >
                            Send To client
                          </Button>

                          <Button
                            onClick={() => {
                              update_status(2);
                              setPriorityStatus(2);
                            }}
                            variant="contained"
                            className="borderBtn ml-4 smBtn"
                          >
                            Send To Insurer
                          </Button>
                        </Grid>
                      </Grid>
                      {viewTabsStatus === "overview" ? (
                        <PolicyDetailOverViewTab
                          overviewData={overviewData}
                          cdBalanceState={sendDataToChild}
                          refetchData={get_policy_overview_tab}
                        />
                      ) : null}

                      {viewTabsStatus === "Configuration" ? (
                        <>
                          <QuoteConfig />
                        </>
                      ) : null}

                      {viewTabsStatus === "History" ? (
                        <>
                          <DetailPolicyHistory />
                        </>
                      ) : null}

                      {viewTabsStatus === "Features" ? (
                        <FeatureAndExclusions />
                      ) : null}
                      {viewTabsStatus === "Riders" ? <Riders /> : null}
                      {viewTabsStatus === "Insurers" && (
                        <QuoteInsurer overviewData={overviewData} />
                      )}

                      {viewTabsStatus === "notes" ? (
                        <Grid container spacing={3}>
                          <Grid
                            xs={2}
                            style={{
                              maxWidth: "260px",
                              position: "relative",
                              top: "-75px",
                              paddingLeft: "0",
                            }}
                          >
                            <div
                              className="leftSection h-100"
                              style={{ position: "fixed", paddingLeft: "8px" }}
                            >
                              <Link
                                className={
                                  sectionStatus === "notesTab"
                                    ? "active sectionLink notesTab"
                                    : "sectionLink notesTab"
                                }
                                onClick={() => {
                                  scrollToSection(notesTab);
                                  SetSectionStatus("notesTab");
                                }}
                              >
                                <span>Notes</span>
                              </Link>
                            </div>
                          </Grid>
                          <Grid xs>
                            <Box className="detailSection" ref={notesTab}>
                              <div className="sectionTitle">
                                <h4>Notes</h4>

                                <div className="actionBtns">
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if(!editQuotesPermission) return toast.error("Edit permissions not allowed");
                                      setPostQueryForm(true);
                                    }}
                                  >
                                    Add Notes
                                  </Link>
                                </div>
                              </div>

                              <NotesSection data={notesData} />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
          <SlidingPanel
            type={"right"}
            isOpen={openContactForm}
            size={75}
          // backdropClicked={() => {
          //   setOpenContactForm(false);
          // }}
          >
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={10}>
                  <h4 className="mb-0 mt-4 ml-0">SPOC Details</h4>
                </Grid>
                <Grid xs={2} textAlign="end" className="mt-3">
                  <GridCloseIcon
                    onClick={() => {
                      setOpenContactForm(false);
                    }}
                  />
                </Grid>
                <Grid xs={12} className="pt-0">
                  <hr />
                </Grid>
              </Grid>
              <div className="scrollable_area">
                <Grid container spacing={3} className="mt-3">
                  <Grid xs={7}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Select Contact"
                      value={spocContact}
                      attrName={setSpocContact}
                      value_update={updateMasterState}
                      dropdown_data={spoc_contact_data}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={5} className="mt-2" style={{ textAlign: "right" }}>
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        navigate("/AddNewContact");
                      }}
                    >
                      Create New Contact
                    </Link>
                  </Grid>
                  {spocContact === "EV0065VikasSaini" ||
                    spocContact === "EV0016KartikeKakkar" ? (
                    <>
                      <Grid xs={6}>
                        <RKTextFieldDisable
                          class_name="inputField"
                          title="Name"
                          value={contactName}
                          attrName={setContactName}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Organization"
                          value={contactOrganization}
                          attrName={setContactOrganization}
                          value_update={updateMasterState}
                          dropdown_data={contact_organization_data}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Department"
                          value={contactDepartment}
                          attrName={setContactDepartment}
                          value_update={updateMasterState}
                          dropdown_data={contact_department_data}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Current Job Title"
                          value={jobTitle}
                          attrName={setJobTitle}
                          value_update={updateMasterState}
                          dropdown_data={job_title_data}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Current Employer"
                          value={currentEmployer}
                          attrName={setCurrentEmployer}
                          value_update={updateMasterState}
                          dropdown_data={current_employer_data}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextFieldDisable
                          class_name="inputField"
                          title="Email"
                          value={contactEmail}
                          attrName={setContactEmail}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextFieldDisable
                          class_name="inputField"
                          title="Mobile"
                          value={contactMobile}
                          attrName={setContactMobile}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextFieldDisable
                          class_name="inputField"
                          title="Contact number"
                          value={contactNumber}
                          attrName={setContactNumber}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </div>
              <Grid container spacing={3}>
                <Grid xs={12} className="ctaBtn mt-4">
                  <Button
                    variant="contained"
                    className="submitBtn fixedbtn"
                    onClick={() => { }}
                  >
                    Add SPOC
                  </Button>
                </Grid>
              </Grid>
            </div>
          </SlidingPanel>

          <RemovePopup
            open_status={removeCategory}
            attrName={setRemoveCategory}
            value_update={updateMasterState}
          />

          <PostQueryForm
            attrName={setPostQueryForm}
            open_status={postQueryForm}
            value_update={updateMasterState}
          />
        </Box>
      )}
    </>
  );
}

export default QuotesDetail;
