export const ProductConfigCategory = [
    {
        title: "",
        subSections: [
            {
                title: "Category",
                fieldData: [
                    {
                        title: "Title",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },
                    {
                        title: "Description",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },

                ],
            },
        ],
    },

]
export const ProductConfigExclusion = [
    {
        title: "",
        subSections: [
            {
                title: "Exclusion",
                fieldData: [
                    {
                        title: "Title",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },
                    {
                        title: "Description",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },

                ],
            },
        ],
    },

]
export const ProductConfigFeature = [
    {
        title: "",
        subSections: [
            {
                title: "Feature",
                fieldData: [
                    {
                        title: "Title",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },
                    {
                        title: "Description",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },
                    {
                        title: "Field Type",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },
                    {
                        title: "Value Type",
                        required: false,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },                  {
                        title: "Unit Type",
                        required: false,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },                  {
                        title: "Dropdown Values",
                        required: false,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },                  {
                        title: "Custom Unit Type",
                        required: false,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },                  {
                        title: "Category",
                        required: true,
                        field: {
                            value: "",
                            warning: false
                        },
                        dropdown_data:[]
                    },







                ],
            },
        ],
    },

]
