import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { PROFILE_SERVICE } from "../../../../../../Services/Employee/ProfileService";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data: any;
  get_property_detail?: Function;
  // selectedProperty?: Number;
  // setSelectedProperty?: Function;
}

const EditProperty: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
  get_property_detail,
  // selectedProperty,
  // setSelectedProperty,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);

  const { userType, id } = useAppSelector((state) => state.userDetailsSlice);
  const [selectedCountry, setSelectedCountry] = useState({ label: "", id: "" });
  const [selectedState, setSelectedState] = useState({ label: "", id: "" });
  const [countryData, setCountryData] = React.useState<Array<{ label: string; id: string }>>([]);
  const [stateData, setStateData] = React.useState<Array<{ label: string; id: string }>>([]);
  const [cityData, setCityData] = React.useState<Array<{ label: string; id: string }>>([]);
  const [property_detail, setProperty_detail] = useState<any>({
    id: "",
    propertyType: "",
    propertyAge: { label: "", id: "" },
    buildingType: "",
    streetName: "",
    areaName: "",
    country: { label: "", id: "" },
    state: { label: "", id: "" },
    city: { label: "", id: "" },
    zipCode: "",
    warnPropertyType: false,
    warnPropertyAge: false,
    warnBuildingType: false,
    warnStreetName: false,
    warnCountry: false,
    warnState: false,
    warnCity: false,
    warnZipCode: false,
  });
  const propertyType_data = [
    { key: "Owned", value: "Owned" },
    { key: "Rented", value: "Rented" },
  ];
  const propertyAge_data = [
    { label: "0-5 years", value: "0-5 years" },
    { label: "5-10 years", value: "5-10 years" },
    { label: "10-15 years", value: "10-15 years" },
    { label: "15-20 years", value: "15-20 years" },
    { label: "20-25 years", value: "20-25 years" },
    { label: "25-30 years", value: "25-30 years" },
    { label: "30-35 years", value: "30-35 years" },
    { label: "35 years & more", value: "35 years & more" },
  ];

  const buildingType_data = [
    { key: "Independent", value: "Independent" },
    { key: "MultiStory", value: "MultiStory" },
  ];
  useEffect(() => {
    if(open_status){
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
    }
  }, [open_status]);

  useEffect(() => {
    if(open_status){
      if(selectedCountry&& selectedState){

    get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
      setCityData(cb);
    });
  }
  if(selectedCountry){
    get_state_list(selectedCountry, (cb: any) => {
      setStateData(cb);
    });
  }

  }
  }, [open_status,selectedState, selectedCountry]);

  useEffect(() => {
    if (data?._id) {
      setProperty_detail({
        id: data?._id,
        propertyType: data?.propertyType,
        propertyAge: { label: data?.propertyAge, id: data?.propertyAge },
        buildingType: data.buildingType,
        streetName: data.streetName,
        areaName: data.areaName,
        country: { label: data.countryName, id: data.countryName },
        state: { label: data.stateName, id: data.stateName },
        city: { label: data.city, id: data.city },
        zipCode: data.zipCode,
      });
      setSelectedState({ label: data.stateName, id: data.stateName });
      setSelectedCountry({ label: data.stateName, id: data.stateName });
    }
  }, [open_status, data]);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    if (mainState === "property_detail") {
      setProperty_detail({
        ...property_detail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
      if (key === "country") {
        setSelectedCountry(value === null ? "" : value.label);
        setProperty_detail({
          ...property_detail,
          [key]: value, [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
          state: { label: "", id: "" },
          city: { label: "", id: "" },
        });
      } else if (key === "state") {
        setSelectedState(value === null ? "" : value.label);
        setProperty_detail({
          ...property_detail,
          [key]: value, [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
          city: { label: "", id: "" },
        });
      }
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
    if (disableSave) return;
    let data: any = property_detail;
    data.warnPropertyType = property_detail.propertyType.length === 0 ? true : false;
    data.warnBuildingType = property_detail.buildingType.length === 0 ? true : false;
    data.warnAreaName = property_detail.areaName.length === 0 ? true : false;
    data.warnStreetName = property_detail.streetName.length === 0 ? true : false;
    data.warnCountry = property_detail.country.label === "" ? true : false;
    data.warnState = property_detail.state.label === "" ? true : false;
    data.warnCity = property_detail.city.label === "" ? true : false;
    data.warnZipCode = property_detail.zipCode.length === 0 ? true : false;
    setProperty_detail({ ...data });
    if (
      data.warnPropertyType === false &&
      data.warnBuildingType === false &&
      data.warnAreaName === false &&
      data.warnStreetName === false &&
      data.warnCountry === false &&
      data.warnState === false &&
      data.warnCity === false &&
      data.warnZipCode === false
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const Id = params.get("id");
      let param = {
        employeId: Id ? Id : id,
        propertyType: property_detail.propertyType,
        propertyAge: property_detail.propertyAge ? property_detail.propertyAge.label : "",
        buildingType: property_detail.buildingType,
        streetName: property_detail.streetName,
        areaName: property_detail.areaName,
        city: property_detail.city ? property_detail.city.label : "",
        stateName: selectedState.label ? selectedState.label : property_detail.state.label,
        zipCode: property_detail.zipCode,
        countryName: selectedCountry.label ? selectedCountry.label : property_detail.country.label,
      };
      const onSuccess = (res: any) => {
        value_update(attrName, false);
        toast.success(res.data.message);
        setDisableSave(false)
        setProperty_detail({
          propertyType: "",
          propertyAge: "",
          buildingType: "",
          streetName: "",
          areaName: "",
          country: "",
          state: "",
          city: "",
          zipCode: "",
          warnPropertyType: false,
          warnPropertyAge: false,
          warnBuildingType: false,
          warnStreetName: false,
          warnCountry: false,
          warnState: false,
          warnCity: false,
          warnZipCode: false,
        });
        if (get_property_detail) {
          get_property_detail();
        }
        // if (setSelectedProperty) {
        //   setSelectedProperty(selectedProperty);
        // }
      };
      const onError = (err: any) => { setDisableSave(false); };
     if(disableSave === false) {
      setDisableSave(true);
      PROFILE_SERVICE.update_property_detail(
        param,
        onSuccess,
        onError,
        property_detail.id,
        userType.toLowerCase()
      );
     }
    }
  };

  return (
    <Box>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h3
                className="mb-0 mt-3 text-left"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                Edit Property
              </h3>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setProperty_detail({
                    propertyType: "",
                    propertyAge: "",
                    buildingType: "",
                    streetName: "",
                    areaName: "",
                    country: "",
                    state: "",
                    city: "",
                    zipCode: "",
                    warnPropertyType: false,
                    warnPropertyAge: false,
                    warnBuildingType: false,
                    warnStreetName: false,
                    warnCountry: false,
                    warnState: false,
                    warnCity: false,
                    warnZipCode: false,
                  });
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <SelectDropdown
                    required
                    class_name="inputField"
                    title="Property Type"
                    value={property_detail.propertyType}
                    attrName={["property_detail", "propertyType"]}
                    value_update={updateMasterState}
                    dropdown_data={propertyType_data}
                    warn_status={property_detail.warnPropertyType}
                    error_messg="Select Property Type"
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    required
                    class_name="inputField"
                    title="Property Age"
                    value={property_detail.propertyAge}
                    attrName={["property_detail", "propertyAge"]}
                    value_update={updateMasterState}
                    data={propertyAge_data}
                    warn_status={property_detail.warnPropertyAge}
                    error_message="Select Property Age"
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    required
                    class_name="inputField"
                    title="Building Type"
                    value={property_detail.buildingType}
                    attrName={["property_detail", "buildingType"]}
                    value_update={updateMasterState}
                    dropdown_data={buildingType_data}
                    warn_status={property_detail.warnBuildingType}
                    error_messg="Select Building Type"
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    required
                    class_name="inputField"
                    title={"Building/Street Name"}
                    value={property_detail.streetName}
                    attrName={["property_detail", "streetName"]}
                    value_update={updateMasterState}
                    warn_status={property_detail.warnStreetName}
                    error_messg="Enter Building/Street Name"
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    required
                    class_name="inputField"
                    title={"Area Name"}
                    value={property_detail.areaName}
                    attrName={["property_detail", "areaName"]}
                    value_update={updateMasterState}
                    warn_status={property_detail.warnAreaName}
                    error_messg="Enter Area Name"
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    required
                    class_name="inputField"
                    title="Country"
                    value={property_detail.country}
                    attrName={["property_detail", "country"]}
                    value_update={updateMasterState}
                    data={countryData}
                    warn_status={property_detail.warnCountry}
                    error_message="Select Country"
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    required
                    class_name="inputField"
                    title="State"
                    value={property_detail.state}
                    attrName={["property_detail", "state"]}
                    value_update={updateMasterState}
                    data={stateData}
                    warn_status={property_detail.warnState}
                    error_message="Select State"
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    required
                    class_name="inputField"
                    title="City"
                    value={property_detail.city}
                    attrName={["property_detail", "city"]}
                    value_update={updateMasterState}
                    data={cityData}
                    warn_status={property_detail.warnCity}
                    error_message="Select city"
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    required
                    class_name="inputField"
                    title={"Pin Code"}
                    value={property_detail.zipCode}
                    attrName={["property_detail", "zipCode"]}
                    value_update={updateMasterState}
                    warn_status={property_detail.warnZipCode}
                    charcterlimit={6}
                    validation_type={"numeric"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={form_submit}
                    disabled={disableSave}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </Box>
  );
};

export default EditProperty;
