import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../../API";
import { AddCurrencyEntryDTO } from "../../DTO/Settings/Organisation/CurrenciesDTO";

const create_currency_entry = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any
) => {
  PostAPI.call(
    `${URL_CONSTANTS.add_currency_entry}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_currency_entries = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  currencyCode: string,
  skip:any,
  limit:any,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.list_currency_entries(currencyCode,skip,limit)}`,
    onSuccess,
    {},
    onError
  );
};

const delete_currency_entry = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  currencyId: string
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_currency_entry(currencyId),
    {},
    onSuccess,
    {},
    onError
  );
};

const update_currency_entry = (
  onSuccess: any,
  onError: any,
  dto: AddCurrencyEntryDTO,
  currencyId: string
) => {
  PatchAPI.call(
    URL_CONSTANTS.update_currency_entry(currencyId),
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_currency_options = (onSuccess: any, onError: any) => {
  GetAPI.call(URL_CONSTANTS.currency_list, onSuccess, {}, onError);
};

export const CURRENCIES_SERVICES = {
  create_currency_entry,
  get_currency_entries,
  delete_currency_entry,
  update_currency_entry,
  get_currency_options,
};
