import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import SaveIcon from "@mui/icons-material/Save";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import shortid from "shortid";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import {
  GetLayoutAction,
  GetUnusedFields,
  SetOnSwapLayoutAction,
  SetUnusedFields,
  clear,
  saveSection,
} from "../../../../../Store/actions/layoutAction";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import GetModal from "./GetModal";
import GetRemoveModal from "./GetRemoveModal";
import MainSection from "./MainSection";
import SideBarItem from "./SideBarItem";
import UnusedFieldsSideBarItems from "./UnusedFieldsSideBarItems";
import { COLUMN, COMPONENT, SIDEBAR_ITEM, SIDEBAR_ITEMS } from "./constants";
import {
  handleMoveSidebarComponentIntoParent,
  handleMoveToDifferentParent,
  handleMoveWithinParent,
} from "./helpers";
import StageStatus from "../Tabs/StageStatus";
import Summary from "../Tabs/Summary";
import { useAppSelector } from "../../../../../Store/hooks";
import { toast } from "react-toastify";

const Container = () => {
  const layoutData = useSelector(({ layoutReducer }) => {    return layoutReducer;  });
  const location = useLocation();
  const dispatch = useDispatch();
  const initialLayout = layoutData.layout; //initialData.layout;
  //const initialLayout = initialData.layout;
  const initialComponents = layoutData.components; //initialData.components;
  //const initialComponents = initialData.components;
  const modal = useSelector(({ modalReducer }) => {    return modalReducer;  });
  const [layout, setLayout] = useState(initialLayout);
  const [currentTab, setCurrentTab] = useState("Layout");
  const [unusedFieldsData, setUnusedFieldsData] = useState(layoutData.unusedFields);
  const [components, setComponents] = useState(initialComponents);
  const [initialSet, setIntialSet] = useState(false);
  const navigate = useNavigate();
  const [isUnusedDelete, setisUnusedDelete] = useState(true);
  const [modulepicklist, setmodulepicklist] = useState(new URLSearchParams(location.search).get("layout").toLowerCase());
  const modulepicklist_data = [
    { key: "contact", value: "Contact" },
    { key: "client", value: "Client" },
    { key: "member", value: "Member" },
  ];

  const [isSwapped, setisSwapped] = useState(false);
  const [isDropDownChanged, setIsDropDownChanged] = useState(false);
  const updateMasterState = (attrName, value) => {
    setIsDropDownChanged(true);
    attrName(value);
    navigate(`${ALL_ROUTES.CUSTOM_MODULES}?layout=${value}`);
    // navigate(`${ALL_ROUTES.CUSTOM_MODULES}`)
    // window.location.search = `layout=${value}`;
  };

  const [unusedFields, setUnusedFields] = useState(false);
  const openUnusedFields = () => {
    setUnusedFields((expanded) => !expanded);
  };
  const [isUnusedFeatched, setUnusedFeatched] = useState(false);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  // const [addPermission, setaddPermission] = useState(false);
  // const [removePermission, setremovePermission] = useState(false);
  const [editPermission, seteditPermission] = useState(false);


  const handleDrop = useCallback(
    (dropZone, item) => {

      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: shortid.generate(),
          ...item.component,
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
        };
        setComponents({
          ...components,
          [newComponent.id]: newComponent,
        });

        if (item.isArchived) {
          let filterData = layoutData.unusedFields.filter(
            (e) => e.id !== item.id
          );

          setUnusedFieldsData(filterData);
          dispatch(SetUnusedFields(filterData));
          // dispatch(GetUnusedFields(layoutData.unusedFields));
          setLayout(
            handleMoveSidebarComponentIntoParent(
              layout,
              splitDropZonePath,
              newComponent
            )
          );
          return setisSwapped(true);
        }

        dispatch(ToggelModalAction(modal.isOpen, newComponent, dropZone.path));
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
          );
          setisSwapped(true);
          return;
        }

        //2.b. OR move different parent
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        );

        return;
      }

      //3. Move + Create
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          components[newItem.id]
        )
      );

      setisSwapped(true);
    },
    [layout, components]
  );

  useEffect(() => {
    setIsDropDownChanged(true);
    // setaddPermission(BROKER?.Module?.add === undefined ? false : BROKER?.Module?.add);
    // setremovePermission(BROKER?.Module?.remove === undefined ? false : BROKER?.Module?.remove);
    seteditPermission(BROKER?.Module?.edit === undefined ? false : BROKER?.Module?.edit);
  }, []);

  useEffect(() => {

    if (isSwapped) {
      setisSwapped(false);
      //check for auto delete if column and row is empty
      const filteredData = layout.map((section) => ({
        ...section,
        children: section.children
          .map((subsection) => ({
            ...subsection,
            children: subsection.children.map((row) => ({
              ...row,
              children: row.children.filter(
                (column) => column.children.length !== 0
              ),
            })),
          }))
          .map((subsection) => ({
            ...subsection,
            children: subsection.children.filter(
              (row) => row.children.length !== 0
            ),
          })),
      }));

      dispatch(SetOnSwapLayoutAction(filteredData));
      setLayout(filteredData);
    }

    if (isDropDownChanged) {
      if (modulepicklist === "contact") {
        // navigate(`${ALL_ROUTES.CUSTOM_MODULES}?layout=Contact`);
        dispatch(GetLayoutAction(modulepicklist));
      } else if (modulepicklist === "client") {
        // navigate(`${ALL_ROUTES.CUSTOM_MODULES}?layout=Client`);
        dispatch(GetLayoutAction(modulepicklist));
      } else if (modulepicklist === "member") {
        dispatch(GetLayoutAction(modulepicklist));
        // navigate(`${ALL_ROUTES.CUSTOM_MODULES}?layout=Employee`);
      }
      //dispatch(GetLayoutAction("contact"));

      setIsDropDownChanged(false);
      setIntialSet(false);
    }

    if (layoutData.isUpdated) {
      if (layoutData.updatedComponent !== undefined) {
        components[layoutData.updatedComponent.id] =
          layoutData.updatedComponent;
      }

      setLayout(layoutData.layout);
      setUnusedFieldsData(layoutData.unusedFields);
      dispatch(clear());
    }

    if (layoutData.isAdded) {
      setLayout(layoutData.layout);
    }

    if (layoutData.isDeleted) {
      setLayout(layoutData.layout);
      setUnusedFieldsData(layoutData.unusedFields);
    }

    if (!layoutData.isGet && !layoutData.isUpdated && !layoutData.isDeleted) {
      dispatch(GetUnusedFields(layoutData.layout.components, modulepicklist));
      dispatch(GetLayoutAction(modulepicklist));
    }

    if (layoutData.isUnUsedFeached && !isUnusedFeatched) {
      setUnusedFieldsData(layoutData.unusedFields);
      setUnusedFeatched(true);
    }

    if (layoutData.layout.length !== 0 && !initialSet) {
      dispatch(GetUnusedFields(layoutData.components, modulepicklist));
      setLayout(layoutData.layout);
      setComponents(layoutData.components);
      setUnusedFieldsData(layoutData.unusedFields);
      setIntialSet(true);
    }
    setUnusedFieldsData(layoutData.unusedFields);
  }, [
    layout,
    components,
    layoutData.isUpdated,
    layoutData.isDeleted,
    layoutData.isAdded,
    layoutData.layout.length,
    modal.isOpen,
    isSwapped,
    layoutData.unusedFields,
    layoutData.components,
    isUnusedDelete,
    layoutData.isUnUsedFeached, 
    modulepicklist,
  ]);

  const renderMainSection = (section, currentPath, childrenCount) => {
    //console.log(currentPath)
    return (
      <MainSection
        key={section.id}
        data={section}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        childrenCount={childrenCount}
      />
    );
  };

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // dont use index for key when mapping over items
  return (
    <Box className="customModuleWrapper">
      <GetModal />
      <GetRemoveModal />
      <Grid container spacing={3}>
        <Grid xs={12} className="custom_layout_header">
          <h3 className="pageTitle">
            <Link
              onClick={() => {
                navigate(`${ALL_ROUTES.SETTING_MODULE_FORM}?page=modules&section=custom`);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            Modules
            <SelectDropdown
              class_name="inputField ml-6"
              title="Select Module"
              value={modulepicklist}
              attrName={setmodulepicklist}
              value_update={updateMasterState}
              dropdown_data={modulepicklist_data}
              warn_status={false}
            />
          </h3>
          {currentTab === "Layout" ? (
            <Button
              className="privew_button"
              onClick={() => {
                if(!editPermission) return toast.error("Update permission not allowed");
                dispatch(
                  saveSection(
                    layoutData.layout,
                    components,
                    layoutData.unusedFields,
                    modulepicklist
                  )
                );
              }}
            >
              <SaveIcon /> Save Layout
            </Button>
          ) : null}
        </Grid>

        <Grid xs={12} className="module_tab">
          <div className="productTabs">
            <Button
              className={
                currentTab === "Layout" ? "defaultTab active" : "defaultTab"
              }
              onClick={() => {
                setCurrentTab("Layout");
              }}
            >
              Layout
            </Button>
              {/* <Button
                className={
                  currentTab === "Status" ? "defaultTab active" : "defaultTab"
                }
                onClick={() => {
                  setCurrentTab("Status");
                }}
              >
                Stages & Status
              </Button> */}
            <Button
              className={
                currentTab === "Summary" ? "defaultTab active" : "defaultTab"
              }
              onClick={() => {
                setCurrentTab("Summary");
              }}
            >
              Summary
            </Button>
          </div>
          {currentTab === "Layout" ? <Grid xs={6} className="text-right right_tab">
            <Link
              underline="none"
              className="blueBtn addfile"
              onClick={() => {
                if (!editPermission) { return toast.error("Permissions Not Allowed") }
                dispatch(
                  ToggelModalAction(modal.isOpen, { field_type: "addSection" })
                );
              }}
            >
              Add New Section
            </Link>
          </Grid> : <></>}

        </Grid>
        {currentTab === "Layout" ? (
          <Grid xs className="fieldsSection">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h5>
                  <DashboardCustomizeIcon /> New Fields
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {Object.values(SIDEBAR_ITEMS).map((sideBarItem, index) => (
                    <SideBarItem key={sideBarItem.id} data={sideBarItem} />
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h5 className="unusedTitle mb-1">
                  <BookmarkBorderIcon /> Unused Fields
                  <span className="count">{unusedFieldsData.length}</span>
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="unusedFields">
                  {Object.values(unusedFieldsData).map((sideBarItem, index) => (
                    <UnusedFieldsSideBarItems
                      key={sideBarItem.id}
                      data={sideBarItem}
                    />
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
            <Box className="innerPart notes mt-5 p-3">
              <h4>Notes:</h4>
              <div style={{"display":"flex", alignItems: "center", justifyContent: "center"}}>
                  <h6>General</h6>
              </div>
              <ol>
                <li>You can create max. 3 columns in a row.</li>
                <li>You can't revert removed fields from "Unused Fields".</li>
                <li>Always click on "Save Layout" after each modification.</li>
                <li>You can rename any main & sub section.</li>
              </ol>

              <div style={{"display":"flex", alignItems: "center", justifyContent: "center", marginTop: "20px"}}>
                  <h6>Address Field</h6>
              </div>
              <ol>
                <li>The address field consists of 6 individual fields.</li>
                <li>Positions of these fields cannot be changed.</li>
                <li>Other fields may be dropped above or below the address fields.</li>
                <li>The section name for the address fields can be altered as needed.</li>
                <li>Each address field can be individually set as "Required" to make it mandatory.</li>
              </ol>
            </Box>
          </Grid>
        ) : null}
        {currentTab === "Layout" ? (
          <Grid xs className="layoutSection">
            <Box className="innerPart">
              {/* <Grid container spacing={0} className="sectionTitle">
          <Grid xs={6}>
            <h5>Main Section</h5>
          </Grid>
          <Grid xs={6}>
            <div className="actionBtns text-right">
              <Link className="delete_btn">
                <DeleteForeverIcon />
                Remove
              </Link>
            </div>
          </Grid> 
        </Grid> */}

              {layout.map((section, index) => {
                const currentPath = `${index}`;

                return (
                  <React.Fragment key={section.id}>
                    {/* <Grid container spacing={0} className="sectionTitle">
                <Grid xs={6}>
                  <h5>{section.name}</h5>
                </Grid>
                {index!==0? <Grid xs={6}>
                  <div className="actionBtns text-right">
                    <Link className="delete_btn" onClick={removeMainSection}>
                      <DeleteForeverIcon />
                      Remove
                    </Link>
                  </div>
                </Grid>:""} 
              </Grid> */}
                    {/* <DropZone
                data={{
                  path: currentPath,
                  childrenCount: layout.length,
                }}
                onDrop={handleDrop}
                path={currentPath}
              /> */}
                    {/* {renderRow(row, currentPath, layout.length)} */}
                    {renderMainSection(section, currentPath, layout.length)}
                  </React.Fragment>
                );
              })}
              {/* <DropZone
          data={{
            path: `${layout.length}`,
            childrenCount: layout.length,
          }}
          onDrop={handleDrop}
          isLast
        /> */}
            </Box>
          </Grid>
        ) : currentTab === "Status" ? (
          <StageStatus stageType={modulepicklist} currentTab={currentTab} />
        ) : currentTab === "Summary" ? (
          <Summary stageType={modulepicklist} currentTab={currentTab} />
        ) : null}
      </Grid>
    </Box>
  );
};
export default Container;
