import React from 'react';
import Turnstile from 'react-turnstile';

interface TurnstileProps {
  onTokenChange: (token: string | null) => void;
}

const TurnstileComponent: React.FC<TurnstileProps> = ({ onTokenChange }) => {
  return (
    <Turnstile
      sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY || ""}
      onVerify={(token) => {
        onTokenChange(token);
      }}
    />
  );
};

export default TurnstileComponent;
