import Employed from "@mui/icons-material/Badge";
import Terminated from "@mui/icons-material/DirectionsRun";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import {
  employeeDTO,
  locationDTO,
} from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { PolicyDetailObj } from "../../../../../Services/DTO/Employee/EmployeePoliciesDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { PROFILE_SERVICE } from "../../../../../Services/Employee/ProfileService";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { TMemberDetail } from "../../../../../Services/Types/Endorsement/TMemberDetail";
import { useAppSelector } from "../../../../../Store/hooks";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextFieldDisable from "../../../../../Supporting files/CurrencyDisable/CurrencyFieldDisable";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePickerDisable from "../../../../../Supporting files/DatePickerDisable/DatePickerDisable";
import {
  extractNumbersFromString,
  get_city_list,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
  validatePincode,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdownDisable from "../../../../../Supporting files/MultipleSearchDropdownDisable/MultipleSearchDropdownDisable";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdownDisable from "../../../../../Supporting files/SelectDropdownDisable/SelectDropdownDisable";
import TextAreaFieldDisable from "../../../../../Supporting files/TextAreaFieldDisable/TextAreaFieldDisable";
import "../../../../../formLayout.scss";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
const OverViewTab = ({
  value_update,
  setSelectedClient,
  setEmpSalary,
  setEmpSalaryBracket,
  setEmpDepartment,
  setEmpDesignation,
  setPropsMemberId,
  setRelatedEmpId,
  setPolicyMemberRelationId,
  memName,
}: {
  value_update: any;
  setSelectedClient?: any;
  setEmpSalary: any;
  setEmpSalaryBracket: any;
  setEmpDepartment: any;
  setEmpDesignation: any;
  setPropsMemberId: any;
  setRelatedEmpId: any;
  setPolicyMemberRelationId: any;
  memName: string;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const [employeeAssignedPolicies, setEmployeeAssignedPolicies] = useState<
    Array<PolicyDetailObj>
  >([]);
  const [priorityStatus, setPriorityStatus] = useState(35);
  const [prioritystatusData, setprioritystatusData] = useState<any>([]);
  const [formData, setFormData] = useState<employeeDTO[]>([]);
  const [primaryEmpId, setprimaryEmpId] = useState<string>("");
  const [selectedMember, setselectedMember] = useState<string>("");
  const [memberId, setmemberId] = useState<any>("");
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: any;
  }>({ key: "", value: "" });
  const [locationIdData, setLocationIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientIdData, setClientIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [personalAddress, setPersonalAddress] = useState<locationDTO>({
    street: "",
    area: "",
    country: "",
    state: "",
    city: "",
    zip: null,
    warnStreet: false,
    warnArea: false,
    warnCountry: false,
    warnState: false,
    warnCity: false,
    warnZip: false,
  });
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [id, setId] = useState("");
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const [allPolicybetweenenrollDate, setAllPolicybetweenenrollDate] =
    useState<boolean>(false);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [selectedClientName, setSelectedClientName] = useState<string>("");
  const [currentSalarySlab, setcurrentSalarySlab] = useState<string>("");
  const [relatedEmpIdLocal, setRelatedEmpIdLocal] = useState<string>("");
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [sectionStatus, SetSectionStatus] = useState<any>(
    "Member Type Details"
  );
  const [subsectionStatus, SetsubSectionStatus] =
    useState<any>("Member Type Info");
  const [salaryBracketPath, setsalaryBracketPath] = useState<any[]>([]);
  const [salaryPath, setsalaryPath] = useState<any[]>([]);
  const [subSection, setSubSection] = useState("businessc");
  const [oldValueMember, setoldValueMember] = useState<any>({});
  const [firstNamePath, setfirstNamePath] = useState<any[]>([]);
  const [lastNamePath, setlastNamePath] = useState<any[]>([]);
  const [disableSave, setDisableSave] = useState(false);
  const [attachedPolicy, setattachedPolicy] = useState([
    {
      memberId: [],
      ecardId: [],
      nomineeId: [],
      _id: "",
      policyId: "",
      status: 41,
    },
  ]);
  const [departmentDropDownData, setdepartmentDropDownData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [assignable_policies, setAssignable_policies] = useState<
    {
      _id: "";
      policyNumber: "";
      productType: "";
      insurer: "";
      basicConfig: "";
      individualConfig: "";
    }[]
  >([]);
  const [designationDropDowndata, setdesignationDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndata, setsalaryDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndataOriginal, setsalaryDropDowndataOriginal] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [showLoader, setShowLoader] = useState(true);
  const OrganisationDetail = useRef(null);

  // for scrolling
  const handleClicks = (id: any) => {
    setSubSection(id);
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  let timeoutdd: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");

    if (timeoutdd) {
      clearTimeout(timeoutdd);
    }
    const delayedApiCall = () => {
      member_detail_data(id ?? "");
      get_employee_layout(id ?? "");
      get_status_employement();
      setShowLoader(true);
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      get_client_list();
    };
    timeoutdd = setTimeout(delayedApiCall, 900);
    return () => {
      if (timeoutdd) {
        clearTimeout(timeoutdd);
      }
    };
  }, []);
  useEffect(() => {
    const selectedClientObj = clientIdData.filter(
      (item: any) =>
        item.label.replace(/\s+/g, "") ===
        selectedClientName.replace(/\s+/g, "")
    );

    setSelectedClient(selectedClientObj[0]);
  }, [selectedClientName, clientIdData.length]);

  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setClientIdData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("dataErr", err);
    };
    // EMPLOYEE_SERVICES.get_client_list(onSuccess, onError);
  };
  // get form layout dynamically
  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      let memberName: any;
      let response = res.data.data[0].layout;
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "DEPARTMENTS"
      );
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "DESIGNATIONS"
      );
      get_dep_designation_salary(
        res.data.data[0].attachedClient,
        "SALARY_BRACKETS"
      );
      setSelectedClientId(res.data.data[0].attachedClient);
      setattachedPolicy(res.data.data[0].policy);
      setmemberId(res.data.data[0].preFix + res.data.data[0].employeeId);
      setPropsMemberId(res.data.data[0].preFix + res.data.data[0].employeeId);
      // setFirstNameChar(res.data.data[0].preFix)
      setPersonalAddress(res.data.data[0].personalAddress);
      value_update("_id", res.data.data._id);
      setPolicyMemberRelationId(res.data.data[0].memberId);

      let newData = response.map(
        (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection, dataSubsectionIndex: any) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow: any, dataRowIndex: any) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map(
                    (dataColumn: any, dataColumnIndex: any) => {
                      let newColumn = { ...dataColumn };
                      newColumn.children = dataColumn.children;
                      newColumn.children = dataColumn.children.map(
                        (dataInput: any, dataInputIndex: any) => {
                          if (dataInput.field_lable === "Salary") {
                            setEmpSalary(dataInput.value);
                            setsalaryPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput?.field_lable === "Member Type") {
                            memberName = dataInput.value;
                            setselectedMember(dataInput.value);
                            setPriorityStatus(
                              dataInput.value === "Employee"
                                ? res.data.data[0].employement_status.value
                                : 39
                            );

                            value_update("Member Type", dataInput.value);
                          }
                          if (dataInput?.field_lable === "First Name") {
                            setfirstNamePath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Last Name") {
                            setlastNamePath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Salary Bracket") {
                            setEmpSalaryBracket(dataInput.value);
                            setsalaryBracketPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_lable === "Department") {
                            setEmpDepartment(dataInput.value);
                          }
                          if (dataInput.field_lable === "Primary Employee") {
                            setprimaryEmpId(dataInput.value);
                            setRelatedEmpId(dataInput.value);
                            setRelatedEmpIdLocal(dataInput.value);
                            if (
                              memberName === "Dependent" ||
                              memberName === "Depandent"
                            ) {
                              getDepAssignedPolicies(
                                dataInput.value,
                                res.data.data[0].memberId
                              );
                            } else {
                              getAssignedPolicies(id);
                            }
                          }
                          if (dataInput.field_lable === "Designation") {
                            setEmpDesignation(dataInput.value);
                          }

                          if (dataInput.field_type === "searchClient") {
                            setSelectedClientName(dataInput.value);

                            //This is breaking for some reason :(
                            // value_update("clientId", dataInput.value);
                          }
                          if (dataInput?.field_lable === "Select Client") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }
                          if (
                            dataInput?.field_lable === "Department" ||
                            dataInput.field_type === "designation"
                          ) {
                            return {
                              ...dataInput,
                              disable: true,
                            };
                          }
                          if (dataInput?.field_lable === "Primary Employee") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }
                          if (dataInput?.field_lable === "Client") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          }
                          if (memberName === "Employee") {
                            if (
                              dataInput?.field_lable ===
                              "Relationship With Employee"
                            ) {
                              return {
                                ...dataInput,
                                pickliststep: [
                                  {
                                    field_name: "Self",
                                    label: "Self",
                                    value: "Self",
                                  },
                                ],
                              };
                            }
                          }
                          if (dataInput.field_type === "hideid") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          } else {
                            return { ...dataInput };
                          }
                        }
                      );
                      return newColumn;
                    }
                  );
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );

      const updatedItems = newData;

      updatedItems.splice(0, 1);

      setFormData(updatedItems);
    };
    const onError = (err: any) => {};
    EMPLOYEE_SERVICES.employee_detail_layout(id, onSuccess, onError);
  };

  const update_status = (value: number, id: string, type: string) => {
    const onSuccess = (res: any) => {
      if(id){
        get_employee_layout(id);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id,
      type,
      usertypeinfo.toLowerCase()
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setPriorityStatus) {
      if (value === 37 || value === 39)
        return toast.warning("This status is Automated");
      update_status(value, id, "EMPLOYMENT");
    }
    setIndividualEditKey({
      key:
        "layout." +
        (attrName[0] === 0 ? 1 : attrName[0]) +
        ".children." +
        attrName[1] +
        ".children." +
        attrName[2] +
        ".children." +
        attrName[3] +
        ".children." +
        attrName[4] +
        ".value",

      value: value,
    });

    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "personalAddress") {
      setPersonalAddress({
        ...personalAddress,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
      if (key === "country") {
        get_state_list(value.label, (cb: any) => {
          setStateData(cb);
        });
        setPersonalAddress({
          ...personalAddress,
          country: value.label,
          state: "",
          city: "",
        });
      } else if (key === "state") {
        get_city_list_via_name(
          personalAddress.country,
          value.label,
          (cb: any) => {
            setCityData(cb);
          }
        );
        setPersonalAddress({
          ...personalAddress,
          state: value.label,
          city: value.label === "N.A." ? "N.A." : "",
        });
      } else if (key === "city") {
        setPersonalAddress({
          ...personalAddress,
          city: value.label,
        });
      }
    } else {
      let data: employeeDTO[] = formData;

      //For required fields
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_state_list(value, (cb: any) => {
          setStateData(cb);
        });
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_city_list(value, (cb: any) => {
          setCityData(cb);
        });
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Salary"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
        data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
          salaryBracketPath[2]
        ].children[salaryBracketPath[3]].children[salaryBracketPath[4]].value =
          "";

        for (var i = 0; i < salaryDropDowndata.length; i++) {
          var range: any = salaryDropDowndata[i].label
            .split("-")
            .map((item) => {
              if (!["Below", "Above"].includes(item.trim())) {
                return Number(extractNumbersFromString(item));
              } else {
                return item.trim();
              }
            });
          var min: any = null;
          var max: any = null;

          if (range[0] === "Below") {
            min = -Infinity;
            max = Number(range[1]);
          } else if (range[1] === "Above") {
            min = Number(range[0]);
            max = Infinity;
          } else {
            min = range[0];
            max = range[1];
          }

          // Check if the input number is within the range
          if (Number(value) >= min && Number(value) <= max) {
            var slab = salaryDropDowndata[i].label;

            data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
              salaryBracketPath[2]
            ].children[salaryBracketPath[3]].children[
              salaryBracketPath[4]
            ].value = slab;
            setcurrentSalarySlab(slab);
            break;
          }
        }
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setFormData(data);
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "searchClient"
      ) {
        const onSuccess = (res: any) => {
          setLocationIdData(res.data.data);
        };
        const onError = (err: any) => {
          console.log("dataErr", err);
        };
        EMPLOYEE_SERVICES.get_location_list(
          onSuccess,
          onError,
          value ? value.id : ""
        );
      }
    }
  };

  const updateAddressField = () => {
    if (disableSave) return;
    let data = { ...personalAddress };
    const { area, street, country, state, city, zip, warnZip } = data;
    // data.warnArea = data.area ? false : true;
    data.warnStreet = data.street ? false : true;
    data.warnCity = data.city ? false : true;
    data.warnCountry = data.country ? false : true;
    data.warnZip = data.zip ? !validatePincode(data.zip.toString()) : true;
    data.warnState = data.state ? false : true;
    setPersonalAddress({ ...data });
    if (!street || !country || !state || !city || !zip || data.warnZip)
      return;
    const onSuccess = (res: any) => {
      setDisableSave(false);
      toast.success("Address has been updated");
    };
    const onError = (err: any) => {
      setDisableSave(false);

      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    let count = 0;
    if (employeeAssignedPolicies.length > 0) {
      employeeAssignedPolicies.map((data: any, index: number) => {
        const enrollmentEndDate = new Date(
          data.policySettings.enrolmentEndDate
        );
        const enrolmentStartDate = new Date(
          data.policySettings.enrolmentStartDate
        );
        const currentDate = new Date();
        const isTodayBetweenEnrollmentDates =
          currentDate >= enrolmentStartDate && currentDate <= enrollmentEndDate;

        if (
          !isTodayBetweenEnrollmentDates &&
          employeeAssignedPolicies.length > 0
        ) {
          count = 1;
          let warnToast = index === 0 ? true : false;
          endorsementAddress(data.policyId, warnToast, data.policyNumber);
        } else if (count === 0 && !allPolicybetweenenrollDate) {
          count = 1;
          if (disableSave === false) {
            setDisableSave(true);
            ENDORSEMENT_SERVICES.updateAddress(
              URL_CONSTANTS.updateAddress(usertypeinfo.toLocaleLowerCase(), id),
              { personalAddress: personalAddress },
              onSuccess,
              onError
            );
          }
        }
      });
    } else {
      if (disableSave === false) {
        setDisableSave(true);
        ENDORSEMENT_SERVICES.updateAddress(
          URL_CONSTANTS.updateAddress(usertypeinfo.toLocaleLowerCase(), id),
          { personalAddress: personalAddress },
          onSuccess,
          onError
        );
      }
    }
  };

  const endorsementAddress = (
    policyId: string,
    warnToast: boolean,
    policyNumber: string
  ) => {
    let newValue: any = {
      dob: "",
      firstName: "",
      lastName: "",
      contactNumber: 0,
      gender: "",
      email: "",
      street: personalAddress.street,
      area: personalAddress.area,
      country: personalAddress.country,
      state: personalAddress.state,
      city: personalAddress.city.label
        ? personalAddress.city.label
        : personalAddress.city,
      zip: Number(personalAddress.zip),
    };
    let params = {
      memberId: id,
      member: memName,
      policyId: policyId,
      policy: policyNumber,
      clientId: selectedClientId,
      client: selectedClientName,
      type: "NON COMMERCIAL",
      endorsementFor: "Personal Address",
      old: [
        oldValueMember.personalAddress.street,
        oldValueMember.personalAddress.area,
        oldValueMember.personalAddress.country,
        oldValueMember.personalAddress.state,
        oldValueMember.personalAddress.city,
        oldValueMember.personalAddress.zip,
      ]
        .filter(Boolean)
        .join(" , "),
      new: newValue,
      update_key: "Personal Address",
    };
    const onSuccess = (res: any) => {
      if (warnToast) {
        toast.success("Your Endorsement has been Created");
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    ENDORSEMENT_SERVICES.create_endorsement(
      URL_CONSTANTS.add_endorsement(usertypeinfo.toLocaleLowerCase()),
      params,
      onSuccess,
      onError
    );
  };

  // api service call as per the validate the dat of the form
  const addEndorsement = (
    label: any,
    value: string,
    policyId: string,
    update_key: string,
    warnToast: boolean,
    policyNumber: string
  ) => {
    let newlabel: string =
      label === "First Name"
        ? "firstName"
        : label === "Last Name"
        ? "lastName"
        : label === "Contact Number"
        ? "contactNumber"
        : label === "Gender"
        ? "gender"
        : label === "Date Of Birth"
        ? "dob"
        : label === "Email"
        ? "email"
        : label === "Department"
        ? "department"
        : label === "Designation"
        ? "designation"
        : label === "Salary Bracket"
        ? "salary_grade"
        : label === "Salary"
        ? "salary"
        : label;
    let newValue: any = {
      dob: "",
      firstName: "",
      lastName: "",
      contactNumber: 0,
      gender: "",
      email: "",
      street: "",
      area: "",
      country: "",
      state: "",
      city: "",
      zip: 0,
    };
    newValue[newlabel] = label === "Contact Number" ? Number(value) : value;
    if (label === "First Name") {
      newValue["lastName"] = oldValueMember["Last Name"];
    }
    if (label === "Last Name") {
      newValue["firstName"] = oldValueMember["First Name"];
    }
    if (label === "Salary") {
      newValue["salary"] = value + "/" + currentSalarySlab;
    }

    const onSuccess = (res: any) => {
      if (warnToast) {
        toast.success("Your Endorsement has been Created");
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data.errors[0].param +
          "" +
          "" +
          err?.response?.data.errors[0].message
      );
    };
    let params = {
      memberId: id,
      member: memName,
      policyId: policyId,
      policy: policyNumber,
      clientId: selectedClientId,
      client: selectedClientName,
      type: label === "Date Of Birth" ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor:
        label === "Last Name" || label === "First Name"
          ? "Name"
          : label === "Date Of Birth"
          ? "Date of Birth"
          : label === "Salary Bracket"
          ? "Salary Grade"
          : label === "Salary Bracket"
          ? "Salary Grade"
          : label,
      old:
        label === "Last Name" || label === "First Name"
          ? `${oldValueMember["First Name"]} ${oldValueMember["Last Name"]}`
          : oldValueMember[label],
      new: newValue,
      update_key:
        label === "Salary"
          ? update_key +
            "/" +
            "layout." +
            salaryBracketPath[0] +
            ".children." +
            salaryBracketPath[1] +
            ".children." +
            salaryBracketPath[2] +
            ".children." +
            salaryBracketPath[3] +
            ".children." +
            salaryBracketPath[4] +
            ".value"
          : label === "Last Name" || label === "First Name"
          ? "layout." +
            firstNamePath[0] +
            ".children." +
            firstNamePath[1] +
            ".children." +
            firstNamePath[2] +
            ".children." +
            firstNamePath[3] +
            ".children." +
            firstNamePath[4] +
            ".value" +
            "/" +
            "layout." +
            lastNamePath[0] +
            ".children." +
            lastNamePath[1] +
            ".children." +
            lastNamePath[2] +
            ".children." +
            lastNamePath[3] +
            ".children." +
            lastNamePath[4] +
            ".value"
          : update_key,
    };
    if (value && value.toString().trim() === oldValueMember[label]?.trim()) {
      if (warnToast) {
        toast.warning("Please make any changes");
      }
      return;
    } else {
      ENDORSEMENT_SERVICES.create_endorsement(
        URL_CONSTANTS.add_endorsement(usertypeinfo.toLocaleLowerCase()),
        params,
        onSuccess,
        onError
      );
    }
  };

  const member_detail_data = (id: string) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let resopnse: TMemberDetail = res.data.data[0];
      setoldValueMember(resopnse);
    };
    const onError = (err: any) => {};

    let url = `${URL_CONSTANTS.get_member_detail(
      id,
      usertypeinfo.toLocaleLowerCase()
    )}`;
    ENDORSEMENT_SERVICES.member_detail(url, onSuccess, onError);
  };

  const getDepAssignedPolicies = (
    relatedEmpIdLocal: string,
    policyMemberRelationId: string
  ) => {
    const onSuccess = (res: any) => {
      setEmployeeAssignedPolicies(res.data.data.policyDetails);
      res.data.data.policyDetails.map((data: any) => {
        const enrollmentEndDate = new Date(
          data.policySettings.enrolmentEndDate
        );
        const enrolmentStartDate = new Date(
          data.policySettings.enrolmentStartDate
        );
        const currentDate = new Date();
        const isTodayBetweenEnrollmentDates =
          currentDate >= enrolmentStartDate && currentDate <= enrollmentEndDate;

        if (!isTodayBetweenEnrollmentDates && res.data.data.length > 0) {
          setAllPolicybetweenenrollDate(true);
        }
      });
    };

    const onError = (err: any) => {
      console.log("---err----", err);
    };

    EMPLOYEE_SERVICES.get_dependent_assigned_policies(
      onSuccess,
      onError,
      relatedEmpIdLocal,
      policyMemberRelationId
    );
  };

  const getAssignedPolicies = (employeeId: any) => {
    const onSuccess = (res: any) => {
      const membersToExclude: string[] = [];

      const newPolicies = res.data.data.policyDetails.map(
        (item: any, index: number) => {
          const newMembers = item.members.filter((item: any) => item);

          if (index !== 0) {
            return { ...item, deleteable: true, members: newMembers };
          } else {
            return { ...item, deleteable: false, members: newMembers };
          }
        }
      );
      setEmployeeAssignedPolicies(newPolicies);
      newPolicies.map((data: any, index: number) => {
        const enrollmentEndDate = new Date(
          data.policySettings.enrolmentEndDate
        );
        const enrolmentStartDate = new Date(
          data.policySettings.enrolmentStartDate
        );
        const currentDate = new Date();
        const isTodayBetweenEnrollmentDates =
          currentDate >= enrolmentStartDate && currentDate <= enrollmentEndDate;
        if (!isTodayBetweenEnrollmentDates && newPolicies.length > 0) {
          setAllPolicybetweenenrollDate(true);
        }
      });
    };

    const onError = (err: any) => {
      console.log(err);
    };

    {
      PROFILE_SERVICE.get_assigned_policies(onSuccess, onError, employeeId);
    }
  };

  const individual_edit = (attrName?: any) => {
    if (individualEditKey.value === "") {
      return;
    } else if (attrName.length > 2) {
      let label =
        formData[attrName[0]].children[attrName[1]].children[attrName[2]]
          .children[attrName[3]].children[attrName[4]].field_lable;
      let newValue =
        formData[attrName[0]].children[attrName[1]].children[attrName[2]]
          .children[attrName[3]].children[attrName[4]].value;
      let count = 0;
      if (employeeAssignedPolicies.length > 0) {
        employeeAssignedPolicies.map((data, index) => {
          const enrollmentEndDate = new Date(
            data.policySettings.enrolmentEndDate
          );
          const enrolmentStartDate = new Date(
            data.policySettings.enrolmentStartDate
          );
          const policyEndDate = new Date(data.endDate);
          const policyStartDate = new Date(data.startDate);
          const currentDate = new Date();
          const isTodayBetweenPolicyDates =
            currentDate >= policyStartDate && currentDate <= policyEndDate;
          if (
            isTodayBetweenPolicyDates &&
            employeeAssignedPolicies.length > 0
          ) {
            let warnToast = index === 0 ? true : false;
            addEndorsement(
              label,
              newValue,
              data.policyId,
              individualEditKey.key,
              warnToast,
              data.policyNumber
            );
          } else if (count === 0 && !allPolicybetweenenrollDate) {
            updateIndividual(attrName, count);
          }
        });
      } else {
        updateIndividual(attrName, count);
      }
    }
  };

  const updateIndividual = (attrName: any, count: any) => {
    count = 1;
    if (
      formData[attrName[0]].children[attrName[1]].children[attrName[2]]
        .children[attrName[3]].children[attrName[4]].field_type === "phone"
    ) {
      if (
        !validateMobileNumber(
          formData[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].value
        )
      ) {
        return toast.error("Please Enter Valid Phone Number");
      } else {
        const onSuccess = (res: any) => {
          toast.success(res.data.message);
        };
        const onError = (err: any) => {
          console.log("errr", err);
        };
        EMPLOYEE_SERVICES.update_detail_view_individual_edit_employee(
          individualEditKey,
          onSuccess,
          onError,
          id,
          usertypeinfo.toLocaleLowerCase()
        );
      }
    }
    if (
      formData[attrName[0]].children[attrName[1]].children[attrName[2]]
        .children[attrName[3]].children[attrName[4]].field_type === "email"
    ) {
      if (
        !validateEmail(
          formData[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].value
        )
      ) {
        return toast.error("Please Enter Valid Email");
      } else {
        const onSuccess = (res: any) => {
          toast.success(res.data.message);
        };
        const onError = (err: any) => {
          console.log("errr", err);
        };
        EMPLOYEE_SERVICES.update_detail_view_individual_edit_employee(
          individualEditKey,
          onSuccess,
          onError,
          id,
          usertypeinfo.toLocaleLowerCase()
        );
      }
    } else {
      const onSuccess = (res: any) => {
        toast.success(res.data.message);
      };
      const onError = (err: any) => {
        console.log("errr", err);
      };
      EMPLOYEE_SERVICES.update_detail_view_individual_edit_employee(
        individualEditKey,
        onSuccess,
        onError,
        id,
        usertypeinfo.toLocaleLowerCase()
      );
    }
  };
  const splitedClinetid = selectedClientName.split("-");

  const get_status_employement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const get_dep_designation_salary = (clientid: any, type: any) => {
    const onSuccess = (res: any) => {
      if (type === "DEPARTMENTS") {
        setdepartmentDropDownData(res.data.data[0].data);
      } else if (type === "DESIGNATIONS") {
        setdesignationDropDowndata(res.data.data[0].data);
      } else if (type === "SALARY_BRACKETS") {
        setsalaryDropDowndataOriginal(res.data.data[0].data);
        let dropDownData: any = res.data.data[0].data.map(
          (item: any, index: any) => {
            return {
              id: item.label
                .split("-")
                .map((e: any) => formatCurrency(Number(e)))
                .join("-"),
              label: item.label
                .split("-")
                .map((e: any) => formatCurrency(Number(e)))
                .join("-"),
            };
          }
        );

        // dropDownData.unshift({
        //   id: `Below - ${dropDownData[0].label.split("-")[0]}`,
        //   label: `Below - ${dropDownData[0].label.split("-")[0]}`,
        // });

        dropDownData.push({
          id: `${
            dropDownData[dropDownData.length - 1].label.split("-")[1]
          } - Above`,
          label: `${
            dropDownData[dropDownData.length - 1].label.split("-")[1]
          } - Above`,
        });

        setsalaryDropDowndata(dropDownData);
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    // EMPLOYEE_SERVICES.get_dep_designation_salary(
    //   onSuccess,
    //   onError,
    //   usertypeinfo.toLowerCase(),
    //   clientid,
    //   type
    // );
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid xs paddingLeft={0}>
              <>
                <Grid container spacing={3}>
                  <Grid xs={6}></Grid>
                  <Grid xs={6}>
                    <div
                      className="client_info_section"
                      style={{ justifyContent: "end" }}
                    >
                      <p
                        className="client_info_p user"
                        style={{ width: "unset", marginRight: 0 }}
                      >
                        Employement Status{" "}
                        <span style={{ marginLeft: "10px" }}>
                          <div
                            id={`_${priorityStatus}`}
                            style={{ borderRadius: "20px", marginLeft: "8px" }}
                          >
                            <div
                              className="status_dropdown"
                              id={`_${priorityStatus?.toString()}`}
                            >
                              {selectedMember !== "Employee" ? (
                                <NA id={`_${priorityStatus?.toString()}`} />
                              ) : priorityStatus === 35 ? (
                                <Resigned
                                  id={`_${priorityStatus?.toString()}`}
                                />
                              ) : priorityStatus === 36 ? (
                                <Terminated
                                  id={`_${priorityStatus?.toString()}`}
                                />
                              ) : priorityStatus === 37 ? (
                                <Employed
                                  id={`_${priorityStatus?.toString()}`}
                                />
                              ) : priorityStatus === 38 ? (
                                <Probation
                                  id={`_${priorityStatus?.toString()}`}
                                />
                              ) : priorityStatus === 39 ? (
                                <NA id={`_${priorityStatus?.toString()}`} />
                              ) : null}
                              <span>
                                {
                                  prioritystatusData.find(
                                    (data: any) => data.label === priorityStatus
                                  )?.value
                                }
                              </span>
                            </div>
                          </div>
                        </span>
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    {formData?.map(
                      (
                        dataMainSection: employeeDTO,
                        indexMainSection: number
                      ) => (
                        // Main section
                        <Box
                          onMouseEnter={() => {
                            SetSectionStatus(dataMainSection.name);
                          }}
                          key={indexMainSection}
                          className="detailSection"
                        >
                          <div id={dataMainSection.name}>
                            <div className="sectionTitle">
                              <h4>{dataMainSection.name}</h4>
                            </div>
                            {dataMainSection.children.map(
                              (dataSubsection, indexSubsection) => (
                                // Sub Section
                                <Grid
                                  onMouseEnter={() => {
                                    SetsubSectionStatus(dataSubsection.name);
                                  }}
                                  key={indexSubsection}
                                  container
                                >
                                  <Grid xs={12}>
                                    <h5
                                      id={dataSubsection.name}
                                      key={dataSubsection.type}
                                      className="subHeading"
                                    >
                                      {dataSubsection.name}
                                    </h5>
                                    {dataSubsection.children.map(
                                      (dataRow, indexRow) => (
                                        // Row
                                        <Grid
                                          key={indexRow}
                                          container
                                          spacing={3}
                                        >
                                          {dataRow.children.map(
                                            (dataColumn, indexColumn) => (
                                              // Columns created
                                              <Grid key={indexColumn} xs>
                                                {dataColumn.children.map(
                                                  (dataInput, indexInput) => (
                                                    // input Component will be here
                                                    <form key={indexInput}>
                                                      {dataInput.field_lable ===
                                                      "Member Type" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                          disabled
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "salary" ? (
                                                        <SelectDropdownDisable
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          dropdown_data={
                                                            salaryDropDowndata
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          // disabled={
                                                          //   formData[salaryPath[0]]
                                                          //     ?.children[
                                                          //     salaryPath[1]
                                                          //   ]?.children[
                                                          //     salaryPath[2]
                                                          //   ]?.children[
                                                          //     salaryPath[3]
                                                          //   ]?.children[
                                                          //     salaryPath[4]
                                                          //   ].value
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                          disabled={true}
                                                        />
                                                      ) : dataInput.field_lable ===
                                                        "Salary" ? (
                                                        <RKTextFieldDisable
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={formatCurrency(
                                                            dataInput.value
                                                          )}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          params={
                                                            individual_edit
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          disabled={true}
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "department" ? (
                                                        <SelectDropdownDisable
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          dropdown_data={
                                                            departmentDropDownData
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                          disabled={true}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "designation" ? (
                                                        <SelectDropdownDisable
                                                          class_name="inputField"
                                                          disabled={true}
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          dropdown_data={
                                                            designationDropDowndata
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "singleselect" ? (
                                                        <SelectDropdownDisable
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          disabled={
                                                            dataInput.field_lable ===
                                                              "Title" ||
                                                            dataInput.field_lable ===
                                                              "Relationship With Employee"
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          dropdown_data={
                                                            dataInput.pickliststep
                                                              ? dataInput.pickliststep
                                                              : []
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchClient" ? (
                                                        <SearchDropdownDisable
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_type_id_value
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={clientIdData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select Client"
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchLocation" ? (
                                                        <SearchDropdownDisable
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          fieldDisable={true}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={locationIdData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select Client"
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                        />
                                                      ) : dataInput.field_lable ===
                                                        "Member Type" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                          disabled
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "phone" ? (
                                                        <RKTextFieldDisable
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          params={
                                                            individual_edit
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                            "Office Contact Number"
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "email" ? (
                                                        <RKTextFieldDisable
                                                          validation_type="email"
                                                          params={
                                                            individual_edit
                                                          }
                                                          type="email"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : validateEmail(
                                                                  dataInput.value
                                                                ) === false
                                                              ? `Enter a valid email Id`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                            "Official Email"
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                          "datepicker" &&
                                                        dataInput.field_lable?.toLowerCase() ===
                                                          "last day of working" ? (
                                                        <DatePicker
                                                          // params={individual_edit}
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value ||
                                                            null
                                                          }
                                                          disabled={true}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          error_message="Enter a Valid Date"
                                                          warn_status={
                                                            dataInput.error ===
                                                            undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "datepicker" ? (
                                                        <DatePickerDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value ||
                                                            null
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                              "Date Of Joining" ||
                                                            dataInput.field_lable ===
                                                              "Last Day Of Working"
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          error_message="Enter a Valid Date"
                                                          warn_status={
                                                            dataInput.error ===
                                                            undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchselect" ? (
                                                        <SearchDropdownDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          data={
                                                            dataInput.pickliststep
                                                              ? dataInput.pickliststep
                                                              : []
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                            undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select the value"
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "checkbox" ? (
                                                        <CheckBox
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          name={
                                                            dataInput.field_lable
                                                          }
                                                          id={dataInput._id}
                                                          value={Boolean(
                                                            dataInput.value
                                                          )}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          default_check={true}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "number" ? (
                                                        <RKTextFieldDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.field_lable ===
                                                            "Salary"
                                                              ? formatCurrency(
                                                                  dataInput.value
                                                                )
                                                              : dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ==
                                                        "currency" ? (
                                                        <CurrencyTextFieldDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} Limit`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiselect" ? (
                                                        <MultipleSearchDropdownDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          dropdown_data={
                                                            dataInput.pickliststep
                                                              ? dataInput.pickliststep
                                                              : []
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                            undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            "Select the value"
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiline" ? (
                                                        <TextAreaFieldDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                            undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} charcter`
                                                              : ""
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "url" ? (
                                                        <RKTextFieldDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          validation_type="url"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : validateUrl(
                                                                  dataInput.value
                                                                ) === false
                                                              ? `Enter a valid Url`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "country" ? (
                                                        <SearchDropdownDisable
                                                          disable_status={
                                                            disableAddress
                                                          }
                                                          set_disable_status={
                                                            setDisableAddress
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                          class_name="inputField"
                                                          title="Country"
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={countryData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select country"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "state" ? (
                                                        <SearchDropdownDisable
                                                          disable_status={
                                                            disableAddress
                                                          }
                                                          set_disable_status={
                                                            setDisableAddress
                                                          }
                                                          class_name="inputField"
                                                          title="State"
                                                          params={
                                                            individual_edit
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={stateData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select state"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "city" ? (
                                                        <SearchDropdownDisable
                                                          disable_status={
                                                            disableAddress
                                                          }
                                                          set_disable_status={
                                                            setDisableAddress
                                                          }
                                                          params={
                                                            individual_edit
                                                          }
                                                          class_name="inputField"
                                                          title="City"
                                                          value={
                                                            dataInput.value
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={cityData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select city"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "hide" ? null : (
                                                        <RKTextFieldDisable
                                                          params={
                                                            individual_edit
                                                          }
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          validation_type="name"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                            "Organization Employee ID"
                                                          }
                                                          value={
                                                            dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                  dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} charcter`
                                                              : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      )}
                                                    </form>
                                                  )
                                                )}
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </div>
                        </Box>
                      )
                    )}

                    <Box
                      onMouseEnter={() => {
                        SetSectionStatus("OrganisationDetail");
                      }}
                      className="detailSection"
                      ref={OrganisationDetail}
                    >
                      <div className="sectionTitle">
                        <h4>Address Details</h4>
                      </div>
                      <div>
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <h5 className="subHeading">
                              Personal Address Details
                            </h5>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid sm={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Building/Street Name"
                              value={personalAddress.street}
                              attrName={["personalAddress", "street"]}
                              value_update={updateMasterState}
                              warn_status={personalAddress.warnStreet}
                              required={true}
                            />
                          </Grid>

                          <Grid sm={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Area Name"
                              value={personalAddress.area}
                              attrName={["personalAddress", "area"]}
                              value_update={updateMasterState}
                              warn_status={personalAddress.warnArea}
                              required={false}
                            />
                          </Grid>

                          <Grid sm={6} md={4} lg={3} xl={3}>
                            <SearchDropdown
                              class_name="inputField"
                              title="Country"
                              value={personalAddress.country}
                              attrName={["personalAddress", "country"]}
                              value_update={updateMasterState}
                              data={countryData}
                              warn_status={personalAddress.warnCountry}
                              required={true}
                              error_message="Select Country"
                            />
                          </Grid>

                          <Grid sm={6} md={4} lg={3} xl={3}>
                            <SearchDropdown
                              class_name="inputField"
                              title="State"
                              value={personalAddress.state}
                              attrName={["personalAddress", "state"]}
                              value_update={updateMasterState}
                              data={stateData}
                              warn_status={personalAddress.warnState}
                              error_message="Select state"
                              required={true}
                            />
                          </Grid>

                          <Grid sm={6} md={4} lg={3} xl={3}>
                            <SearchDropdown
                              class_name="inputField"
                              title="City"
                              value={personalAddress.city}
                              attrName={["personalAddress", "city"]}
                              value_update={updateMasterState}
                              data={cityData}
                              warn_status={personalAddress.warnCity}
                              error_message="Select City"
                              required={true}
                            />
                          </Grid>

                          <Grid sm={6} md={4} lg={3} xl={3}>
                            <RKTextField
                              class_name="inputField"
                              title={"Pincode"}
                              value={personalAddress.zip}
                              attrName={["personalAddress", "zip"]}
                              value_update={updateMasterState}
                              warn_status={personalAddress.warnZip}
                              required={true}
                              charcterlimit={6}
                              validation_type={"numeric"}
                              error_messg="Enter valid Pincode"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Box className="ctaBtn mt-4" textAlign={"center"}>
                        <Button
                          variant="contained"
                          className="mediumBtn"
                          disabled={disableSave}
                          onClick={() => {
                            updateAddressField();
                          }}
                        >
                          Update Address
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default OverViewTab;
