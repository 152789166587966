import { Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './EndorsementMassUpdate.scss';
import UGTable from '../../../../Supporting files/UGTable/UGTable';
import { ALL_ROUTES } from '../../../../Routes/all_routes';
import { CLIENT_SERVICES } from '../../../../Services/Clients/ClientService';
import { useAppSelector } from '../../../../Store/hooks';

const EndorsementMassUpdate = () => {
    const userType = useAppSelector(
        (state: any) => state.userDetailsSlice.userType
      );
  const location = useLocation();
  const data = location.state?.data; // Access 'data' from the state
  const updatedErrorData = data?.errorData.map((item: any) => {
    const [cl_id, cl_name] = item.client.split("-"); // Split client value into cl_id and cl_name
    return {
      ...item,
      id: item._id,   // Adds a new key `id` with the value of `_id`
      cl_id,          // Sets cl_id from the first part of client
      cl_name,        // Sets cl_name from the second part of client
    };
  });
  const navigate = useNavigate();
  const columns = [
    {
      field: "endorsementId",
      headerName: "Endorsement ID",
      minWidth: 250,
      disableColumnMenu: true,
      sortable: false,
      // check_status: true,
    },
    {
      field: "reason",
      headerName: "Reason",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "cl_id",
      headerName: "Client ID",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "endorsementFor",
      headerName: "Endorsement Value",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
    //   {
    //     field: "client",
    //     headerName: "Client Name",
    //     minWidth: 250,
    //     check_status: true,
    //     disableColumnMenu: true, 
    //     sortable: false,  
    //   },
    {
      field: "cl_name",
      headerName: "Client Name",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "member",
      headerName: "Member ID",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "policy",
      headerName: "Policy Number",
      minWidth: 250,
      check_status: true,
      disableColumnMenu: true,
      sortable: false,
    },
  ]

  const handleClick = ()=>{
    const onSuccess = async (res: any) => {
        const data = await res;
        const blob = new Blob([data.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "endorsement.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      };
   const onError = (err:any)=>{alert("something went wrong...");console.log("error",err)} 
   
   let dto = {
    headers: [
        {
            "field": "endorsementId",
            "headerName": "Endorsement ID",
            "minWidth": 250
        },
        {
            "field": "reason",
            "headerName": "Reason",
            "minWidth": 250
        },
        {
            "field": "cl_id",
            "headerName": "Client ID",
            "minWidth": 250
        },
        {
            "field": "endorsementFor",
            "headerName": "Endorsement Value",
            "minWidth": 250
        },
        {
            "field": "cl_name",
            "headerName": "Client Name",
            "minWidth": 250
        },
        {
            "field": "member",
            "headerName": "Member ID",
            "minWidth": 250
        },
        {
            "field": "policy",
            "headerName": "Policy Number",
            "minWidth": 250
        }
    ],
    data: data.errorData,
  };
   CLIENT_SERVICES.csvDownload(
        onSuccess,
        onError,
        dto,
        userType.toLowerCase(),
        "endorsement"
      );
  }

  return (
    <>


      <Grid container className='main-container'>
        <Grid xs={12} className='header'>
          <h3 > Mass Update of Endorsement</h3>
        </Grid>
        <Grid xs={12}>
          <Box width={"100%"}>
            <Grid container padding={5}>
              <Grid xs={12} className='record-container'>
                <div className='leftSide'>
                  <div className='record-section'>
                    <label className='label'>Total records : </label>
                    <label className='value'>{data?.errorData.length + data?.successData.length}</label>
                  </div>
                  <div className='record-section'>
                    <label className='label'>Successful : </label>
                    <label className='value'>{data?.successData.length}</label>
                  </div>
                  <div className='record-section'>
                    <label className='label'>Error : </label>
                    <label className='value'>{data?.errorData.length}</label>
                  </div>
                </div>


                <div className='right-side'>
                  <button className='download-btn'>
                    <img src='/images/download_icon_solid.svg' alt='download icon' onClick={handleClick}/>

                  </button>
                  <span>Export</span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} style={{ height: "500px", overflow: "scroll" }} display={"flex"} justifyContent={"center"}>
          <UGTable
            header_data={columns}
            data={updatedErrorData}
            value_update={() => { }}
            attrName={"UGTable"}
            hideThreeDot={true}
            paginationMode={""}
            checkboxSelection={false}
            totalRecords={0}
          //    BE_filter_code={0}
          // refetch_data={get_claim}
          // totalRecords={totalRecords}
          // setTableLimit={setTableLimit}
          // setTableSkip={setTableSkip}
          // paginationMode="client"
          // setPageNumberData={setPageNumber}
          // disableCheckboxKey={"tpaType"}
          // disableCheckboxValue={"INTEGRATED"}

          />
        </Grid>
        <Grid xs={12} display={"flex"} justifyContent={"center"} className='mt-7'>
          <button className='done-btn' onClick={() => navigate(ALL_ROUTES.ENDORSEMENT)}>Done</button>
        </Grid>
      </Grid>

    </>
  );
}

export default EndorsementMassUpdate;