import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import {GetAPI, PostAPI, DeleteAPI, PatchAPI, PutAPI} from "../API"



const post_department_data = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PostAPI.call(`${URL_CONSTANTS.create_department}`,dto,onSuccess,{}, onError)
}

const get_department_list_data = (limit:any,skip:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    GetAPI.call(`${URL_CONSTANTS.get_department_list}?skip=${skip}&limit=${limit}`,onSuccess,{}, onError)
}

const department_detail_view = (onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any)=>{
    GetAPI.call(`${URL_CONSTANTS.department_detail_view}?id=${id}`,onSuccess,{}, onError)
}

const update_department = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any)=>{
    PatchAPI.call(`${URL_CONSTANTS.update_department}?id=${id}`,dto,onSuccess,{}, onError)
}

const delete_departments = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    DeleteAPI.call(`${URL_CONSTANTS.delete_departments}`,dto,onSuccess,{}, onError)
}


const get_status = (
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
) => {
    GetAPI.call(
        URL_CONSTANTS.get_department_status,
        onSuccess,
        {},
        onError
    );
};

const update_status = (
    dto: any,
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
    id: any
) => {
    PatchAPI.call(
        `${URL_CONSTANTS.update_department_status}${id}`,
        dto,
        onSuccess,
        {},
        onError
    );
};

const isBeingUsed = (
    onSuccess: (data: any) => void,
    onError: (err: any) => void,
    id:string,
) => {
    GetAPI.call(
        `${URL_CONSTANTS.isStatusBeingUsed}${id}&type=DEPARTMENT`,
        onSuccess,
        {},
        onError
    );
};


export const DEPARTMENT_SERVICES = {
    post_department_data,
    get_department_list_data,
    department_detail_view,
    update_department,
    delete_departments,
    get_status,
    update_status,
    isBeingUsed
}