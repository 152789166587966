import Rejected from "@mui/icons-material/Cancel";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import PartialApproval from "@mui/icons-material/Rule";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYER_URL_CONSTANTS } from "../../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import InitiateAClaim from "../../../../Components/Broker/Claim/InitiateAClaimSp/InitiateAClaim";
import ViewDocuments from "../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";
import { Claim_SERVICES } from "../../../../Services/Claim/ClaimServices";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { useAppSelector } from "../../../../Store/hooks";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
import { date_time_format } from "../../../../Supporting files/HelpingFunction";

interface Props {
  intiateClaim?: boolean;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}

const Claimassistant: React.FC<Props> = ({
  intiateClaim,
  shortcutDisable,
  setShortcutDisable,
}) => {
  const userType = useAppSelector(    (state: any) => state.userDetailsSlice.userType  );
  const { dateFormat, timeZone } = useAppSelector(    (state) => state.userDetailsSlice.localInformation  );
   const navigate = useNavigate();
  const [addFilter, setAddFilter] = useState(false);
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [relation, setRelation] = useState("");
  const [selectDocument, setSelectDocument] = useState("");
  const [InitiateACliam, setInitiateACliam] = useState(intiateClaim);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [doc_data, setDoc_data] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [claimAssistData, setClaimAssistData] = useState<any>([]);
  let claimAssistDataCopy: Array<any> = [];
  const [showLoader, setShowLoader] = useState(true);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    downloadCsv(claimAssistData);
    handleClose();
  };

  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit > claimAssistData.length) {
      csvData = claimAssistData;
    } else {
      csvData = claimAssistData.slice(
        pageNumber ? pageNumber * tableLimit : 0,
        (pageNumber + 1) * tableLimit
      );
    }
    console.log("employerCsvDta",csvData)
    downloadCsv(csvData);
    handleClose();
  };
  let timeoutIdOne: any;
  useEffect(() => {
 
  if (timeoutIdOne) {
      clearTimeout(timeoutIdOne);
    }
 
    const delayedApiCall = () => {
      get_status();
    }
 
    timeoutIdOne = setTimeout(delayedApiCall, 900);
    setShowLoader(true);
    return () => {
      if (timeoutIdOne) {
        clearTimeout(timeoutIdOne);
      }
    };
    
  }, []);

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_claim();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "claimId",
          headerName: "Claim ID",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <>
                <Link
                  onClick={() =>
                    navigate(
                      `${EMPLOYER_ALL_ROUTES.CLAIM_DETAIL}?id=${row._id}`
                    )
                  }
                >
                  {row.claimId}
                </Link>
              </>
            );
          },
        },
        {
          field: "clientId",
          headerName: "Client ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "memberId",
          headerName: "Member ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "memberName",
          headerName: "Member Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "insurer",
          headerName: "Insurer Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "tpaName",
          headerName: "TPA Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "designation",
          headerName: "Designation",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "department",
          headerName: "Department",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "status",
          headerName: "Claim Status",
          minWidth: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 47 ? (
                    <Requested id={`_${row.status.toString()}`} />
                  ) : row.status === 48 ? (
                    <SentToTPA id={`_${row.status.toString()}`} />
                  ) : row.status === 49 ? (
                    <Required id={`_${row.status.toString()}`} />
                  ) : row.status === 50 ? (
                    <Approved id={`_${row.status.toString()}`} />
                  ) : row.status === 51 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 52 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 53 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 65 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 66 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 67 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 68 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 69 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 70 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={userType === "EMPLOYER"}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "TpaClaimStatus",
          headerName: "TPA Claim Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return row.tpa_status ? <>
            <div id={`_${row.status}`}>
            <div className="status_dropdown" id={`_${row.status}`}>
              {row.status === 47 ? (
                <Requested id={`_${row.status.toString()}`} />
              ) : row.status === 48 ? (
                <SentToTPA id={`_${row.status.toString()}`} />
              ) : row.status === 49 ? (
                <Required id={`_${row.status.toString()}`} />
              ) : row.status === 50 ? (
                <Approved id={`_${row.status.toString()}`} />
              ) : row.status === 51 ? (
                <Rejected id={`_${row.status.toString()}`} />
              ) : row.status === 52 ? (
                <Expired id={`_${row.status.toString()}`} />
              ) : row.status === 53 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 65 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 66 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 67 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 68 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 69 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : row.status === 70 ? (
                <PartialApproval id={`_${row.status.toString()}`} />
              ) : null}
              <SelectDropdown
                class_name="inputFieldd"
                title=""
                value={row.tpa_status}
                attrName={["updatestatus", row, index]}
                value_update={updateMasterState}
                dropdown_data={[{ label: row?.tpa_status, id: row?.tpa_status, }]}
                warn_status={false}
                disabled={true}
              />
            </div>
          </div> 
          </> : "--";
          },
        },
        {
          field: "requestType",
          headerName: "Request Type",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            
            return (
              <>
                <p>{row.requestType.toLowerCase() === "intimation" ? "Reimbursement" :row.requestType }</p>
              </>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Created at",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.createdAt,dateFormat, timeZone)}</p>
            );
          },
        },
        {
          field: "updatedAt",
          headerName: "Updated at",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.updatedAt,dateFormat, timeZone)}</p>
            );
          },
        },
        {
          field: "insured_person",
          headerName: "Insured Person",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            let insuredPerson: any[] = [];
            let insureData = "";
            if (Array.isArray(row.insuredPerson)) {
              insuredPerson =
                row.insuredPerson.length > 0 &&
                row.insuredPerson.map((e: any) => {
                  return delete e._id, e.value;
                });
              insureData = insuredPerson.toString();
            } else {
              insureData = row.insuredPerson;
            }
            return (
              <>
                <p>{insureData}</p>
              </>
            );
          },
        },
        {
          field: "document",
          headerName: "Document",
          minWidth: 100,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                sx={{ textDecoration: "none" }}
                onClick={() => {
                  get_doc_list(row._id);
                }}
              >
                <span
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    marginRight: "10px",
                  }}
                >
                  {row.docCount}
                </span>
                <img
                  src="images/visibility-icon.svg"
                  alt=""
                  style={{
                    zIndex: 99,
                    position: "relative",
                    cursor: "pointer",
                  }}
                />
              </Link>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    Claim_SERVICES.get_status(onSuccess, onError);
  };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyPress);
  //   InitiateACliam && get_claim();
  // }, [InitiateACliam]);

  useEffect(() => {
    let timeoutId: any;
 
    if (timeoutId) {
        clearTimeout(timeoutId);
      }
   
      const delayedApiCall = () => {
        get_claim();
      }
   
      timeoutId = setTimeout(delayedApiCall, 1500);
   
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
  },[])
  const get_doc_list = (id: string) => {
    const onSuccess = (res: any) => {
      setViewDocuments(true);
      setDoc_data(res.data.data.upload);
    };
    const onError = (err: any) => {
      // console.log("err", err);
      toast.error(err.response.data.errors[0].message);
    };
    let api_url =
      userType === "BROKER"
        ? `${URL_CONSTANTS.get_claim_document}${id}`
        : `${EMPLOYER_URL_CONSTANTS.get_claim_document}${id}`;

    Claim_SERVICES.get_claim_document_list(api_url, onSuccess, onError);
  };

  const get_claim = (limit?: number, skip?: number,) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let data: any = [];
      res.data.data.claimData.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      setClaimAssistData(data);
      setTotalRecords(res.data.data.countData);
      claimAssistDataCopy = data;
    };
    const onError = (err: any) => {
      setShowLoader(false);
    };
    let api_url = URL_CONSTANTS.get_claim_list(
      userType?.toLowerCase(),
      limit ?? 25,
      skip ?? 0,
      searchBarFilter
    );
    Claim_SERVICES.get_claim_list(api_url, onSuccess, onError);
  };

  const handleChange = (event: SelectChangeEvent, attrName: string) => {};

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    if (event.target.tagName !== "INPUT") {
      if (event.shiftKey && event.keyCode === 76) {
        setInitiateACliam(true);
      }
    }
  }, []);

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      searchBarFilter && get_claim();
    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === setViewDocuments) {
      return setViewDocuments(value);
    } else if (attrName === setInitiateACliam) {
      return setInitiateACliam(value);
    } else if (attrName[0] === "updatestatus") {
      if (value === 20 || value === 21 || value === 22 || value === 23)
        return toast.warning("This status is Automated");
      update_status(value, attrName[1]._id);
    } else {
      // attrName(value);
    }
  };

  const downloadCsv = (CsvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "claims.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };

    const updatedArr = CsvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          "Claim ID": item?.claimId,
          "Client ID": item?.clientId,
          "Client Name": item?.clientName,
          "Member ID": item?.memberId,
          "Member Name": item?.memberName,
          "Insurer Name": item?.insurer,
          "TPA Name": item?.tpaName,
          Designation: item?.designation,
          Department: item?.department,
          "Policy Number": item?.policyNumber,
          "Policy Type": item?.policyType,
          "Claim Status": statusObj?.value,
          "TPA Claim Status": "--",
          "Request Type": item?.requestType,
          "Insured Person": item?.insuredPerson,
        };
      }
      return item;
    });

    columns.forEach((item: any, index: number) => {
      if (item.headerName === "Document") columns.splice(index, 1);
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType.toLowerCase(),
      "claims"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}

      <Grid container spacing={3} className="claimAssistant">
        <InitiateAClaim
          attrName={setInitiateACliam}
          open_status={InitiateACliam === undefined ? false : InitiateACliam}
          value_update={updateMasterState}
          client_id_status={true}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
          callApi={get_claim}
        />
        <ViewDocuments
          attrName={setViewDocuments}
          open_status={viewDocuments}
          value_update={updateMasterState}
          data={doc_data}
        />
        <Modal
          open={addFilter}
          onClose={() => {
            setAddFilter(false);
          }}
          className="modalWrapper"
        >
          <Box className="modalInner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link
                  className="close-button"
                  onClick={() => setAddFilter(false)}
                ></Link>
                <h5 className="popup-heading">Add Filters</h5>
              </Grid>
            </Grid>
            <Grid container spacing={3} className="mb-3">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Employee Id
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDocument}
                    label="Employee Id"
                    onChange={(e) => handleChange(e, "selectDocument")}
                  >
                    <MenuItem value={"aadhar"}>Aadhar Card</MenuItem>
                    <MenuItem value={"pan"}>PAN</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={relation}
                    label="Designation"
                    onChange={(e) => handleChange(e, "relation")}
                  >
                    <MenuItem value={"i"}>ICICI</MenuItem>
                    <MenuItem value={"h"}>HDFC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} className="mb-3">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={relation}
                    label="Department"
                    onChange={(e) => handleChange(e, "relation")}
                  >
                    <MenuItem value={"i"}>ICICI</MenuItem>
                    <MenuItem value={"h"}>HDFC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Request Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={relation}
                    label="Request Type"
                    onChange={(e) => handleChange(e, "relation")}
                  >
                    <MenuItem value={"i"}>ICICI</MenuItem>
                    <MenuItem value={"h"}>HDFC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                className="ctaBtn"
                style={{ textAlign: "center" }}
              >
                <Button variant="contained" className="submitBtn">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Claim Assistant</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid item xs={7} className="text-right">
          {claimAssistData?.length > 0 && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          <Link
            underline="none"
            className="greenBtn docs"
            onClick={() => {
              setInitiateACliam(true);
              if (!shortcutDisable && setShortcutDisable) {
                setShortcutDisable(true);
              }
            }}
          >
            Intiate a claim
          </Link>
        </Grid>
        <Grid item xs={12}>
          <UGTable
            header_data={columns}
            data={claimAssistData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.CLAIMS}
            refetch_data={get_claim}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            // paginationMode="client"
            setPageNumberData={setPageNumber}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Claimassistant;
