import { Box, Button, Grid, Link, Modal, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
// import AddNewPolicy from "../../../../Components/Broker/Policies/AddNewPolicy/AddNewPolicy";
import Renewed from "@mui/icons-material/Autorenew";
import Inforced from "@mui/icons-material/Beenhere";
import Draft from "@mui/icons-material/BookmarkAdd";
import Rejected from "@mui/icons-material/Cancel";
import Cancelled from "@mui/icons-material/CancelPresentation";
import Active from "@mui/icons-material/CheckCircle";
import Void from "@mui/icons-material/DoDisturbOff";
import Expired from "@mui/icons-material/GppBad";
import Inactive from "@mui/icons-material/HighlightOff";
import New from "@mui/icons-material/NewReleases";
import Renewal from "@mui/icons-material/Sync";
import Grace from "@mui/icons-material/TrendingUp";
import { toast } from "react-toastify";
import BulkUploadForm from "../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { POLICIES_SERVICES } from "../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../Services/Types/TUserData";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import {
  date_time_format,
  formatSumInsured,
} from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import moment from "moment";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import Published from "@mui/icons-material/PublishedWithChanges";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";

const Policies = () => {
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const { formatCurrency } = useFormatCurrency();
  const [addCdBalance, setAddCdBalance] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(false);
  const [lastChangedStatusData, setlastChangedStatusData] = useState<{
    value: number;
    id: string;
  }>({ value: 0, id: "" });
  const [statusWarningPopoup, setstatusWarningPopoup] = React.useState(false);
  const [addNewEmployeeStep, setAddNewEmployeeStep] = React.useState(1);
  const [cdAmount, setCdAmount] = React.useState("");
  const [policyData, setPolicyData] = useState([]);
  const [editStatus, setEditStatus] = React.useState(false);
  const [editStatusStep, setEditStatusStep] = React.useState(1);
  const [array_delete, setArray_Delete] = React.useState<Array<{ id: number }>>(
    []
  );
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [columns, setColumns] = useState<any>([]);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const policy_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Policies
  );
  const [addPolicyPermission, setaddPolicyPermission] = useState<any>(null);
  const [editPolicyPermission, setEditPolicyPermission] = useState<any>(null);
  const [csvPolicyPermission, setCsvPolicyPermission] = useState<any>(null);
  const [statusPermission, setStatusPermission] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    get_policy(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    get_policy(tableLimit ?? 25, 0, "csv");

    handleClose();
  };
  let timeout: any;

  useEffect(() => {
    setaddPolicyPermission(
      policy_permission?.add === undefined ? false : policy_permission?.add
    );
    setEditPolicyPermission(
      policy_permission?.edit === undefined ? false : policy_permission?.edit
    );
    setCsvPolicyPermission(
      policy_permission?.csv === undefined ? false : policy_permission?.csv
    );
    setStatusPermission(
      policy_permission?.status === undefined
        ? false
        : policy_permission?.status
    );

    setShowLoader(true);
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      get_policy();
      get_status( policy_permission?.status === undefined
        ? false
        : policy_permission?.status); 
    };
    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);


  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      if(searchBarFilter ||startEndDate.endDate){
        get_policy();
      }
      
    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter,startEndDate]);

  // useEffect(() => {
  //   get_policy();
  // }, [startEndDate]);
  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditStatus(true);
    } else if (array_delete.length > 1) {
      setEditStatus(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_POLICY}?id=${array_delete[0]}`);
    }
  };
  const get_policy = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const data = res.data.data.data.map((e: any) => ({
        ...e,
        id: e._id,
      }));
      setPolicyData(data);
      setTotalRecords(res.data.data.countData);
      if (csv) {
        downloadCsv(data);
      }
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    POLICIES_SERVICES.get_policy_list_view(
      onSuccess,
      onError,
      limit ? limit : 25,
      skip ? skip : 0,
      startEndDate.startDate
        ? moment(startEndDate.startDate).format("YYYY-MM-DD")
        : "",
      startEndDate.endDate
        ? moment(startEndDate.endDate).format("YYYY-MM-DD")
        : "",
      searchBarFilter ? searchBarFilter : ""
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else if (attrName[0] === "updatestatus") {
      if (!statusPermission) {
        toast.warn("You are not allowed to change the status");
      }
      let priorityStatus = attrName[1].status;
      if (
        (priorityStatus === 20 && (value === 24 || value === 62)) ||
        (priorityStatus === 21 && (value === 25 || value === 26))
      ) {
        setlastChangedStatusData({ id: attrName[1]._id, value: value });
        setstatusWarningPopoup(true);
      }
      if (value === 20 || value === 21 || value === 22 || value === 23) {
        return toast.warning("This status is Automated");
      }
      if (priorityStatus !== 21 && (value === 25 || value === 26)) {
        return toast.warning(
          "Status should be Inforced before to switch Cancelled/Rejected"
        );
      }
      if (priorityStatus === 21 && (value === 24 || value === 62)) {
        return toast.warning("Status can't switch Inforced to Void/Published");
      }
      if (
        priorityStatus === 24 ||
        priorityStatus === 25 ||
        priorityStatus === 26
      ) {
        return toast.warning(
          "You can't switch status after Void/Cancelled/Rejected"
        );
      }
    } else {
      setArray_Delete(value);
    }
  };

  const customComparator = (v1:any, v2:any) => {
    const regex = /(\D+)(\d+)/;
    const [, a1, n1] = v1.match(regex);
    const [, a2, n2] = v2.match(regex);

    if (a1 === a2) {
        return parseInt(n1, 10) - parseInt(n2, 10);
    }
    return a1.localeCompare(a2);
};

  const get_status = (newStatus:boolean) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "policyId",
          headerName: "Policy ID",
          width: 170,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  color: "#6b62dc",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.POLICY_DETAILS}?id=${row._id}`);
                }}
              >
                {row["policyId"]}
              </Link>
            );
          },
        },
        {
          field: "clientName",
          headerName: "Client Name",
          width: 220,
          check_status: true,
        },
        {
          field: "clientId",
          headerName: "Client ID",
          width: 150,
          sortComparator: customComparator,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          width: 200,
          check_status: true,
        },
        {
          field: "insurerDetails",
          headerName: "Insurer",
          width: 180,
          check_status: true,
        },
        {
          field: "planType",
          headerName: "Plan Type",
          width: 100,
          check_status: true,
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          width: 180,
          check_status: true,
        },
        {
          field: "policyStartDate",
          headerName: "Start Date",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.policyStartDate,
                  userState.localInformation.dateFormat,
                  userState.localInformation.timeZone
                )}
              </span>
            );
          },
        },
        {
          field: "policyEndDate",
          headerName: "End Date",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.policyEndDate,
                  userState.localInformation.dateFormat,
                  userState.localInformation.timeZone
                )}
              </span>
            );
          },
        },
        {
          field: "status",
          headerName: "Policy Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 20 ? (
                    <Draft id={`_${row.status.toString()}`} />
                  ) : row.status === 21 ? (
                    <Inforced id={`_${row.status.toString()}`} />
                  ) : row.status === 22 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 23 ? (
                    <Renewed id={`_${row.status.toString()}`} />
                  ) : row.status === 24 ? (
                    <Void id={`_${row.status.toString()}`} />
                  ) : row.status === 25 ? (
                    <Cancelled id={`_${row.status.toString()}`} />
                  ) : row.status === 26 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 62 ? (
                    <Published id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!newStatus}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "Enrollmentstatus",
          headerName: "Enrollment Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.enrolment_status.value}`}>
                <div
                  className="status_dropdown"
                  id={`_${row.enrolment_status.value}`}
                >
                  {row.enrolment_status.value === 27 ? (
                    <Active id={`_${row.enrolment_status.value.toString()}`} />
                  ) : row.enrolment_status.value === 28 ? (
                    <Inactive
                      id={`_${row.enrolment_status.value.toString()}`}
                    />
                  ) : row.enrolment_status.value === 29 ? (
                    <Grace id={`_${row.enrolment_status.value.toString()}`} />
                  ) : null}
                  <span>{row.enrolment_status.label}</span>
                </div>
              </div>
            );
          },
        },
        {
          field: "B.Type",
          headerName: "B.Type",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.btype_status.value}`}>
                <div
                  className="status_dropdown"
                  id={`_${row.btype_status.value}`}
                >
                  {row.btype_status.value === 30 ? (
                    <New id={`_${row.btype_status.value.toString()}`} />
                  ) : row.btype_status.value === 31 ? (
                    <Renewal id={`_${row.btype_status.value.toString()}`} />
                  ) : null}
                  <span>{row.btype_status.label}</span>
                </div>
              </div>
            );
          },
        },
        {
          field: "liveCounts",
          headerName: "No. of Dependants",
          width: 150,
          check_status: true,
        },
        {
          field: "employeeCount",
          headerName: "No. of Employees",
          width: 150,
          check_status: true,
        },
        {
          field: "sumInsured",
          headerName: "Sum Insured/Assured",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return typeof row.sumInsured === "object"
              ? `${formatCurrency(row.sumInsured[0])}/...`
              : // row.sumInsured
                //   .map((item: any) => {
                //     return formatCurrency(item);
                //   })
                //   .join("/")
                formatCurrency(row.sumInsured);
          },
        },
        {
          field: "cdBalance",
          headerName: "CD Balance",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <span>{formatCurrency(row.cdBalance)}</span>;
          },
        },
        {
          field: "premium",
          headerName: "Premium",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <span>{formatCurrency(row.premium)}</span>;
          },
        },
        {
          field: "locations",
          headerName: "Locations",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.locations.map((obj: any) => obj.name).join(", ");
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, dateFormat, timeZone)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, dateFormat, timeZone)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    POLICIES_SERVICES.get_status(onSuccess, onError);
  };
  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_policy();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const downloadCsv = (DataCsv: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "policies.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const updatedArr = DataCsv?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      const enrol_status_obj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          ...item,
          "Policy ID": item?.policyId,
          "Policy Status": statusObj?.value,
          "Client Name": item?.clientName,
          "Plan Type": item?.planType,
          "Client ID": item?.clientId,
          "Policy Type": item?.policyType,
          Insurer: item?.insurerDetails,
          "Policy Number": item?.policyNumber,
          "Enrollment Status": item?.enrolment_status?.label,
          "B.Type": item?.btype_status?.label,
          "No. of Dependants": item?.liveCounts,
          "No. of Employees": item?.employeeCount,
          "Start Date": date_time_format(
            item?.policyStartDate,
            dateFormat,
            timeZone
          ),
          "End Date": date_time_format(
            item?.policyEndDate,
            dateFormat,
            timeZone
          ),
          "Created At": date_time_format(item?.createdAt, dateFormat, timeZone),
          "Sum Insured/Assured": formatSumInsured(
            item?.sumInsured,
            formatCurrency
          ),
          "CD Balance":
            item?.cdBalance !== "--" && item?.cdBalance !== undefined
              ? formatCurrency(item?.cdBalance)?.replace(/₹/g, "Rs")
              : item?.cdBalance,
          Premium:
            item?.premium !== "--" && item?.premium !== undefined
              ? formatCurrency(item?.premium)?.replace(/₹/g, "Rs")
              : item?.premium,
          "Updated At": date_time_format(item?.updatedAt, dateFormat, timeZone),
          Locations: item?.locations
            ?.map((label: any) => label?.name)
            ?.join(", "),
          "Enrolment Status": item?.enrolment_status?.label,
        };
      }
      return item;
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });
    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userState.userType.toLowerCase(),
      "policies"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={2}>
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />

        {/* add cd balance popup */}
        <Modal open={addCdBalance} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={2}>
              <Grid item xs={12} className="mb-4">
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddCdBalance(false);
                  }}
                />
                <h5 className="popup-heading">Add CD Balance</h5>
              </Grid>
            </Grid>
            {addNewEmployeeStep === 1 ? (
              <div>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  className="mb-4"
                >
                  <Grid item xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Enter CD Amount"}
                      value={cdAmount}
                      attrName={setCdAmount}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center" }}
                    className="ctaBtn"
                  >
                    <Button
                      variant="contained"
                      className="submitBtn"
                      onClick={() => setAddCdBalance(false)}
                    >
                      Add Amount
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            {addNewEmployeeStep === 2 ? (
              <Grid item xs={12}>
                <Link className="md-link" underline="none">
                  Great ! New Employee added.
                </Link>
              </Grid>
            ) : null}
          </Box>
        </Modal>

        <Modal open={editStatus} className="modalWrapper">
          <>
            {editStatusStep === 1 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setEditStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_edit_icon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <h5 className="popup-heading">
                        You can't select multiple ids
                      </h5>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link mb-10" underline="none">
                      {array_delete?.length === 0
                        ? "Please Select the Client for edit"
                        : `You have selected ${array_delete?.length} ids`}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        </Modal>
        <Modal open={statusWarningPopoup} className="modalWrapper">
          <>
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setstatusWarningPopoup(false)}
                  />
                  <div className="img-prt">
                    <img
                      src="./images/warninglogo.svg"
                      alt=""
                      className="img"
                    />
                  </div>
                  {lastChangedStatusData.value === 24 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Void", all the future
                      activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Draft !
                    </h5>
                  ) : lastChangedStatusData.value === 62 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Published", limited
                      policy editing will be permitted & the Policy Status
                      <br />
                      cannot be reversed back to Draft . Upon the beginning of
                      the Policy Enrolment Period, Policy Status will
                      automatically change to "Published".
                    </h5>
                  ) : lastChangedStatusData.value === 26 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Rejected", all the
                      future activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Enforced !
                    </h5>
                  ) : lastChangedStatusData.value === 25 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Cancelled", all the
                      future activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Enforced !
                    </h5>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid xs={12}>
                  <Link className="md-link mb-10" underline="none">
                    Are you sure you want to Proceed ?
                  </Link>
                </Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      update_status(
                        lastChangedStatusData.value,
                        lastChangedStatusData.id
                      );
                      setstatusWarningPopoup(false);
                    }}
                  >
                    Yes, Change
                  </Button>
                  <Button
                    variant="contained"
                    className="cancelBtn"
                    onClick={() => setstatusWarningPopoup(false)}
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        </Modal>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Policies</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid item xs={6} className="text-right">
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {csvPolicyPermission && policyData?.length > 0 && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          {editPolicyPermission && (
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {addPolicyPermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_POLICY);
              }}
            >
              Add Policy
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <UGTable
            header_data={columns}
            data={policyData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.POLICIES}
            totalRecords={totalRecords}
            refetch_data={get_policy}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Policies;
