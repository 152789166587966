import { Modal } from "@material-ui/core";
import Renewed from "@mui/icons-material/Autorenew";
import Inforced from "@mui/icons-material/Beenhere";
import Draft from "@mui/icons-material/BookmarkAdd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Rejected from "@mui/icons-material/Cancel";
import Cancelled from "@mui/icons-material/CancelPresentation";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Expired from "@mui/icons-material/GppBad";
import Published from "@mui/icons-material/PublishedWithChanges";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import CDLedger from "../../../../../Components/Broker/CDLedger/CDLedger";
import DetailPolicyHistory from "../../../../../Components/Broker/DetailPolicyHistory/DetailPolicyHistory";
import LogsTable from "../../../../../Components/Broker/LogsTable/LogsTable";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import FeatureAndExclusions from "./FeaturesAndExclusions";
import PolicyDetailOverViewTab from "./OverviewTab";
import PolicyConfig from "./PolicyConfig/PolicyConfig";
import Riders from "./Riders";
import Members from "./Members";

const contact_organization_data = [
  { key: "Evervent", value: "Evervent" },
  { key: "Amazon", value: "Amazon" },
  { key: "Infosys", value: "Infosys" },
  { key: "Google", value: "Google" },
  { key: "Microsoft", value: "Microsoft" },
];

const spoc_contact_data = [
  { key: "EV0065VikasSaini", value: "EV0065 Vikas Saini" },
  { key: "EV0016KartikeKakkar", value: "EV0016 Kartike Kakkar" },
];
const contact_department_data = [
  { key: "Engineering", value: "Engineering" },
  { key: "IT", value: "IT" },
  { key: "Human Resource", value: "Human Resource" },
  { key: "Business Development", value: "Business Development" },
  { key: "Designing", value: "Designing" },
  { key: "Management", value: "Management" },
];
const current_employer_data = [
  { key: "Evervent", value: "Evervent" },
  { key: "Amazon", value: "Amazon" },
  { key: "Infosys", value: "Infosys" },
  { key: "Google", value: "Google" },
  { key: "Microsoft", value: "Microsoft" },
];
const job_title_data = [
  { key: "Associate Frontend Developer", value: "Associate Frontend Developer", },
  { key: "Hr", value: "Hr" },
  { Key: "QA Engineer", value: "QA Engineer" },
];

function PolicyDetail() {
  const navigate = useNavigate();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const { dateFormat, timeZone } = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation);
  const [viewTabsStatus, setViewTabsStatus] = useState<
    | "overview"
    | "Configuration"
    | "History"
    | "Features"
    | "CDLedger"
    | "Logs"
    | "notes"
    | "Members"
    | "Riders"
  >("overview");
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [lastChangedStatusData, setlastChangedStatusData] = useState<{
    value: number;
    id: string | null;
  }>({ value: 0, id: "" });
  const [statusWarningPopoup, setstatusWarningPopoup] = useState(false);
  const [priorityStatus, setPriorityStatus] = useState(1);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [intialCdBalance, setintialCdBalance] = useState("");
  const [spocContact, setSpocContact] = useState("");
  const [openContactForm, setOpenContactForm] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactOrganization, setContactOrganization] = useState("");
  const [contactDepartment, setContactDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [currentEmployer, setCurrentEmployer] = useState("");
  const [uploadFile, setUploadFile] = useState(false);
  const [overviewData, setOverviewData] = useState<any>({});
  console.log("overviewData",overviewData);
  let [isHealthCard,setIsHealthCard] = useState<boolean>(false);
  console.log("isHealthCARD",isHealthCard)
  const [sectionStatus, SetSectionStatus] = useState<string>("notesTab");
  const [notesData, setNotesData] = useState<Array<{ name: string; date: Date; description: string }>>([]);
  const notesTab = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const policy_permission = useAppSelector((state) => state.permissionSlice.BROKER?.Policies);
  const [editPolicyPermission, setEditPolicyPermission] = useState<any>(null);
  const [statusPermission, setStatusPermission] = useState<any>(null);
  let timeoutId: any;

  useEffect(() => {
    setEditPolicyPermission(policy_permission?.edit === undefined ? false : policy_permission?.edit);
    setStatusPermission(policy_permission?.status === undefined ? false : policy_permission?.status);
    setShowLoader(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_status();
    }

    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };


  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: any) => {
    const onSuccess = (res: any) => {
      get_policy_overview_tab(id);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  let timeout: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");

    if (viewTabsStatus === "notes") {
      get_policy_notes_tab();
    }


    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      if (viewTabsStatus === "overview") {
        get_policy_overview_tab(id);
      }

    }

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

  }, [viewTabsStatus]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
      post_notes(value);
      get_policy_notes_tab();
    } else if (attrName === setPriorityStatus) {
      if (
        (priorityStatus === 20 && (value === 24 || value === 62)) ||
        (priorityStatus === 21 && (value === 25 || value === 26))
      ) {
        setlastChangedStatusData({ id: id, value: value });
        setstatusWarningPopoup(true);
        // update_status(value, id);
      }
      if (value === 20 || value === 21 || value === 22 || value === 23) {
        return toast.warning("This status is Automated");
      }
      if (priorityStatus !== 21 && (value === 25 || value === 26)) {
        return toast.warning(
          "Status should be Inforced before to switch Cancelled/Rejected"
        );
      }
      if (priorityStatus === 21 && (value === 24 || value === 62)) {
        return toast.warning("Status can't switch Inforced to Void/Published");
      }
      if (
        priorityStatus === 24 ||
        priorityStatus === 25 ||
        priorityStatus === 26
      ) {
        return toast.warning(
          "You can't switch status after Void/Cancelled/Rejected"
        );
      }
    } else {
      attrName(value);
    }
  };

  const post_notes = (policyNotes: any) => {
    let data = {
      notes: {
        description: policyNotes,
      },
    };
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("Notes", err);
    };
    POLICIES_SERVICES.add_policy_notes(data, id, onSuccess, onError);
  };

  const get_policy_notes_tab = () => {
    const onSuccess = (res: any) => {
      setNotesData(res.data.data[0].notes);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_policy_detail_view(
      `${URL_CONSTANTS.get_policy_detail_view}${id}&tab=NOTES`,
      onSuccess,
      onError
    );
  };

  const get_policy_overview_tab = (id: any) => {
    const onSuccess = (res: any) => {
      setOverviewData(res.data.data[0]);
      setPriorityStatus(res.data.data[0].status);
      setIsHealthCard(res.data.data[0].policy?.healthCard);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_policy_detail_view(
      `${URL_CONSTANTS.get_policy_detail_view}${id}&tab=OVERVIEW`,
      onSuccess,
      onError
    );
  };

  const sendDataToChild = (data: string) => {
    setintialCdBalance(data);
  };

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(ALL_ROUTES.POLICIES);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">
              {overviewData?.organisationDetails?.organisationName.charAt(0)}
            </span>
            <div>
              <h3>{overviewData?.organisationDetails?.organisationName}</h3>
              <p>Policy No. {overviewData?.policyCase?.policyNumber}</p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* <div>
            <Tooltip title="Remove">
              <Link
                underline="none"
                className="actionLinks red_icon"
                onClick={() => {
                  setRemoveStatus(true);
                }}
              >
                <DeleteOutlineIcon />
              </Link>
            </Tooltip>
          </div> */}
            {editPolicyPermission && (
              <div>
                <Tooltip title="Global Edit">
                  <Link
                    className="actionLinks"
                    onClick={() => {
                      navigate(`${ALL_ROUTES.EDIT_POLICY}?id=${id}`);
                    }}
                  >
                    <BorderColorIcon />
                  </Link>
                </Tooltip>
              </div>
            )}

            {/* Status Dropdown */}
            <div id={`_${priorityStatus.toString()}`}>
              <div className="status_dropdown" id={`_${priorityStatus.toString()}`}              >
                {priorityStatus === 20 ? (
                  <Draft id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 21 ? (
                  <Inforced id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 22 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 23 ? (
                  <Renewed id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 24 ? (
                  <Void id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 25 ? (
                  <Cancelled id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 26 ? (
                  <Rejected id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 62 ? (
                  <Published id={`_${priorityStatus.toString()}`} />
                ) : null}
                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled={!statusPermission}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <>
              <Grid container spacing={3}>
                <Grid xs className="rightSection h-100">
                  <>
                    <Grid container spacing={3} alignItems="center" paddingLeft={"260px"}>
                      {/* <Grid xs maxWidth="260px"></Grid> */}
                      {/* {viewTabsStatus === "overview" ||
                        viewTabsStatus === "Configuration" ||
                        viewTabsStatus === "Features" ||
                        viewTabsStatus === "CDLedger" ||
                        viewTabsStatus === "Logs" ||
                        viewTabsStatus === "Members" ||
                        viewTabsStatus === "Riders" ||
                        viewTabsStatus === "notes" ? (
                        <Grid xl lg md xs sx={{ Width: "260px" }}></Grid>
                      ) : null} */}
                      <Grid xs={12} lg
                      // style={{
                      //   paddingLeft:
                      //     viewTabsStatus === "overview" ||
                      //       viewTabsStatus === "Configuration" ||
                      //       viewTabsStatus === "Features" ||
                      //       viewTabsStatus === "CDLedger" ||
                      //       viewTabsStatus === "Logs" ||
                      //       viewTabsStatus === "notes" ||
                      //       viewTabsStatus === "Riders" ||
                      //       viewTabsStatus === "Members"
                      //       ? "119px"
                      //       : "0px",
                      // }}
                      >
                        <div className="productTabs ">
                          <Button
                            className={
                              viewTabsStatus === "overview"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "overview"
                              );
                            }}
                          >
                            Overview
                          </Button>
                          {/* <Button
                              className={
                                viewTabsStatus === "History"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "History");
                              }}>
                              Policy History
                            </Button> */}
                          <Button
                            className={
                              viewTabsStatus === "Configuration"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "Configuration"
                              );
                            }}
                          >
                            Configuration
                          </Button>
                          <Button
                            className={
                              viewTabsStatus === "Features"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "Features"
                              );
                            }}
                          >
                            Features & Exclusions
                          </Button>

                          <Button
                            className={
                              viewTabsStatus === "Members"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "Members"
                              );
                            }}
                          >
                            Members
                          </Button>

                          {overviewData?.productType?.name === "Group Term" &&
                            <Button
                              className={
                                viewTabsStatus === "Riders"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "Riders");
                              }}
                            >
                              Riders
                            </Button>}
                          {/* <Button
                              className={
                                viewTabsStatus === "Voluntaryplans"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "Voluntaryplans");
                              }}>
                              Voluntary Plans
                            </Button> */}
                          <Button
                            className={
                              viewTabsStatus === "CDLedger"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(
                                setViewTabsStatus,
                                "CDLedger"
                              );
                            }}
                          >
                            CD Ledger
                          </Button>
                          {/* <Button
                              className={
                                viewTabsStatus === "Logs"
                                  ? "defaultTab active"
                                  : "defaultTab"
                              }
                              onClick={() => {
                                updateMasterState(setViewTabsStatus, "Logs");
                              }}
                            >
                              Logs
                            </Button> */}
                          <Button
                            className={
                              viewTabsStatus === "notes"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(setViewTabsStatus, "notes");
                            }}
                          >
                            Notes
                          </Button>
                        </div>
                      </Grid>
                      <Grid xs={12} lg={"auto"} sx={{ textAlign: "right" }} className="text-sm-center" >
                        <p className="lastupdate">
                          Last Update:{" "}
                          {date_time_format(
                            overviewData?.updatedAt,
                            dateFormat,
                            timeZone
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    {viewTabsStatus === "overview" ? (
                      <PolicyDetailOverViewTab
                        cdBalanceState={sendDataToChild}
                        checklist={overviewData?.tpa?.documentChecklist}
                      />
                    ) : null}
                    {viewTabsStatus === "Configuration" ? (
                      <>
                        <PolicyConfig />
                      </>
                    ) : null}
                    {viewTabsStatus === "History" ? (
                      <>
                        <DetailPolicyHistory />
                      </>
                    ) : null}
                    {viewTabsStatus === "Features" ? (
                      <FeatureAndExclusions type={overviewData.planType.name} />
                    ) : null}
                    {viewTabsStatus === "Members" ? (
                      <>
                        <Members tpa={overviewData?.tpa} clientInfo={overviewData.organisationDetails} policyNumber={overviewData.policyCase.policyNumber} isHealthCard={isHealthCard}/>
                      </>
                    ) : null}
                    {viewTabsStatus === "CDLedger" ? (
                      <>
                        <CDLedger intialCdBalance={intialCdBalance} />
                      </>
                    ) : null}
                    {viewTabsStatus === "Logs" ? (
                      <>
                        <LogsTable />
                      </>
                    ) : null}
                    {viewTabsStatus === "Riders" ? (
                      <>
                        <Riders />
                      </>
                    ) : null}
                    {viewTabsStatus === "notes" ? (
                      <Grid container spacing={3}>
                        <Grid
                          xs
                          style={{
                            maxWidth: "260px",
                            position: "relative",
                            top: "-74px",
                            paddingLeft: "0",
                          }}
                        >
                          <div
                            className="leftSection h-100"
                            style={{ position: "fixed", paddingLeft: "8px" }}
                          >
                            <Link
                              className={
                                sectionStatus === "notesTab"
                                  ? "active sectionLink notesTab"
                                  : "sectionLink notesTab"
                              }
                              onClick={() => {
                                scrollToSection(notesTab);
                                SetSectionStatus("notesTab");
                              }}
                            >
                              <span>Notes</span>
                            </Link>
                          </div>
                        </Grid>
                        <Grid xs>
                          <Box className="detailSection" ref={notesTab}>
                            <div className="sectionTitle">
                              <h4>Notes</h4>
                              <div className="actionBtns">
                                <Link
                                  className="blueBtn addfile"
                                  sx={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!editPolicyPermission) return toast.error("Edit permission not allowed");
                                    setPostQueryForm(true);
                                  }}
                                >
                                  Add Notes
                                </Link>
                              </div>
                            </div>

                            <NotesSection data={notesData} />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
        <SlidingPanel
          type={"right"}
          isOpen={openContactForm}
          size={75}
        // backdropClicked={() => {
        //   setOpenContactForm(false);
        // }}
        >
          <div>
            <Grid container spacing={3} alignItems="center">
              <Grid xs={10}>
                <h4 className="mb-0 mt-4 ml-0">SPOC Details</h4>
              </Grid>
              <Grid xs={2} textAlign="end" className="mt-3">
                <GridCloseIcon
                  onClick={() => {
                    setOpenContactForm(false);
                  }}
                />
              </Grid>
              <Grid xs={12} className="pt-0">
                <hr />
              </Grid>
            </Grid>
            <div className="scrollable_area">
              <Grid container spacing={3} className="mt-3">
                <Grid xs={7}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Select Contact"
                    value={spocContact}
                    attrName={setSpocContact}
                    value_update={updateMasterState}
                    dropdown_data={spoc_contact_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={5} className="mt-2" style={{ textAlign: "right" }}>
                  <Link
                    className="greenBtn addfile"
                    underline="none"
                    onClick={() => {
                      navigate("/AddNewContact");
                    }}
                  >
                    Create New Contact
                  </Link>
                </Grid>
                {spocContact === "EV0065VikasSaini" ||
                  spocContact === "EV0016KartikeKakkar" ? (
                  <>
                    <Grid xs={6}>
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Name"
                        value={contactName}
                        attrName={setContactName}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Organization"
                        value={contactOrganization}
                        attrName={setContactOrganization}
                        value_update={updateMasterState}
                        dropdown_data={contact_organization_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Department"
                        value={contactDepartment}
                        attrName={setContactDepartment}
                        value_update={updateMasterState}
                        dropdown_data={contact_department_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Current Job Title"
                        value={jobTitle}
                        attrName={setJobTitle}
                        value_update={updateMasterState}
                        dropdown_data={job_title_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Current Employer"
                        value={currentEmployer}
                        attrName={setCurrentEmployer}
                        value_update={updateMasterState}
                        dropdown_data={current_employer_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      setContactEmail
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Email"
                        value={contactEmail}
                        attrName={setContactEmail}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Mobile"
                        value={contactMobile}
                        attrName={setContactMobile}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Contact number"
                        value={contactNumber}
                        attrName={setContactNumber}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </div>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn mt-4">
                <Button
                  variant="contained"
                  className="submitBtn fixedbtn"
                  onClick={() => { }}
                >
                  Add SPOC
                </Button>
              </Grid>
            </Grid>
          </div>
        </SlidingPanel>
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />
        <Modal open={statusWarningPopoup} className="modalWrapper">
          <>
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setstatusWarningPopoup(false)}
                  />
                  <div className="img-prt">
                    <img
                      src="./images/warninglogo.svg"
                      alt=""
                      className="img"
                    />
                  </div>
                  {lastChangedStatusData.value === 24 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Void", all the
                      future activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Draft !
                    </h5>
                  ) : lastChangedStatusData.value === 62 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Published", limited
                      policy editing will be permitted & the Policy Status
                      <br />
                      cannot be reversed back to Draft . Upon the beginning of
                      the Policy Enrolment Period, Policy Status will
                      automatically change to "Published".
                    </h5>
                  ) : lastChangedStatusData.value === 26 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Rejected", all the
                      future activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Enforced !
                    </h5>
                  ) : lastChangedStatusData.value === 25 ? (
                    <h5 className="popup-heading">
                      After changing the Policy Status to "Cancelled", all the
                      future activities of
                      <br />
                      this Policy will be frozen & the Policy Status cannot be
                      reversed back to Enforced !
                    </h5>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid xs={12}>
                  <Link className="md-link mb-10" underline="none">
                    Are you sure you want to Proceed ?
                  </Link>
                </Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      update_status(
                        lastChangedStatusData.value,
                        lastChangedStatusData.id
                      );
                      setstatusWarningPopoup(false);
                    }}
                  >
                    Yes, Change
                  </Button>
                  <Button
                    variant="contained"
                    className="cancelBtn"
                    onClick={() => setstatusWarningPopoup(false)}
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        </Modal>
      </Box>
    </>
  );
}

export default PolicyDetail;
