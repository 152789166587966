import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { POLICIES_SERVICES } from "../../../Services/Policies/PoliciesServices";
import SearchBox from "../../../Supporting files/SearchBarAnimation/SearchBox";
import "../../Broker/LogsTable/LogsTable.scss";
import NewLoader from "../../NewLoader/NewLoader";
import "./../../../formLayout.scss";
import { TPA_TYPE } from "../../../Services/Enum/tpaType";
import { useLocation } from "react-router-dom";
import { getStaticNetworkList, isIntegratedTpa } from "../../../Supporting files/HelpingFunction";
interface Props {
  page?: string;
  tpaName?: string;
  policyNumber?: string;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const NetworkHospital: React.FC<Props> = ({ page, tpaName, policyNumber }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [searchBarFilter, setsearchBarFilter] = useState<string>("");
  const [sectionStatus, SetSectionStatus] = useState<string>("logsTab");
  const [hoslist, setHoslist] = useState<any[]>([])
  const query = useQuery();
  const tpaParam = query.get('tpaName');
  const policyParam = query.get('policyNumber');
  const tpa = tpaName ? tpaName : tpaParam
  const policy = policyNumber ? policyNumber : policyParam

  useEffect(() => {
    // if (isIntegratedTpa(tpa)) {
      getNetworkHospitalsList();
      setShowLoader(true);
    // } 
    //else {
    //setHoslist(getStaticNetworkList());
    //}
  }, [tpa, policy]);

  const filteredLogsData = hoslist?.filter((data) =>
    Object.values(data).some((value) =>
      typeof value === "string" &&
      value.toLowerCase().includes(searchBarFilter.toLowerCase())
    )
  );

  //update state
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    }
  };

  const NetworkHospitalList = () => {
    const onSuccess = (res: any) => {
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.networkHospitalList(
      onSuccess,
      onError,
      URL_CONSTANTS.networkHospitalList(TPA_TYPE.PARAMOUNT)
    );
  };



  const getNetworkHospitalsList = () => {
    const onSuccess = (res: any) => {
      let response = res.data.data;
      setHoslist(response);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);

    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    POLICIES_SERVICES.networkHospitalList(
      onSuccess,
      onError,
      URL_CONSTANTS.getNetworkHospitalsList(tpa ? tpa : "", policy ? policy : ""),
    );
  };


  return (
    <>
      {showLoader && (<NewLoader />)}

      <Box className={`${page !== "contentOnly" ? "layoutWrapper" : ""}`}>
        <Grid container spacing={3} className=""></Grid>
        <Grid container spacing={3}>
          {page !== "contentOnly" && (
            <Grid
              xs
              style={{
                maxWidth: "260px",
                position: "relative",
              }}
            >
              <div
                className="leftSection h-100"
                style={{ position: "fixed", paddingLeft: "8px" }}
              >
                <Link
                  className={
                    sectionStatus === "logsTab"
                      ? "active sectionLink logsTab"
                      : "sectionLink logsTab"
                  }
                >
                  <span>Network Hospital</span>
                </Link>
              </div>
            </Grid>
          )}
          <Grid xs paddingLeft={"0"}>
            <Box
              textAlign={"center"}
              marginBottom={"12px"}
              display={"flex"}
              gap={"12px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <h5 style={{ marginBottom: 0 }}>Cashless Network Hospital List</h5>
              <SearchBox value_update={updateMasterState} custom={true} />
            </Box>

            <Box display={"flex"} justifyContent={"center"}>
              <Grid xs={12} className="px-0 py-0">
                <div
                  className="tableview"
                  style={{
                    borderRadius: "16px",
                    borderRight: "1px solid #f1f6ff",
                  }}
                >
                  <div className="tableinner">
                    <ul className="tableHeadercashlesshospital">
                      <li style={{ width: "50%" }}>
                        Hospital Name / Address
                        <button className="sorting-btn"></button>
                      </li>
                      <li style={{ width: "50%", textAlign: "right" }}>
                        City-Pincode / State
                        <button className="sorting-btn"></button>
                      </li>
                    </ul>
                    <Box sx={{ maxHeight: "800px", overflow: "auto", borderRadius: "16px", }}>
                      {/* {isIntegratedTpa(tpa) ? (
                        <>
                          {filteredLogsData?.map((item: any, i) => (
                            <ul className="tableData">
                              <li style={{ width: "50%" }}>
                                {item.hospital_name}
                                <div className="liItem">{`${item.address_1} ${item.address_2}`}</div>
                              </li>
                              <li style={{ width: "50%", textAlign: "right" }}>
                                {item.city_name}-{item.pin_code}
                                <div className="liItem">{item.state_name}</div>
                              </li>
                            </ul>
                          ))}
                        </>
                      ) : <h5 style={{ textAlign: 'center', padding: '65px' }}>No records Available</h5>} */}
                      <>
                        {filteredLogsData?.map((item: any, i) => (
                          <ul className="tableData">
                            <li style={{ width: "50%" }}>
                              {item.hospital_name}
                              <div className="liItem">{`${item.address_1} ${item.address_2}`}</div>
                            </li>
                            <li style={{ width: "50%", textAlign: "right" }}>
                              {item.city_name}-{item.pin_code}
                              <div className="liItem">{item.state_name}</div>
                            </li>
                          </ul>
                        ))}
                      </>
                    </Box>
                  </div>
                </div>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default NetworkHospital;
