import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, TextField, Tooltip } from "@mui/material";
import { useState } from "react";

const RKTextField = ({
  title = "",
  value = "",
  type = "",
  attrName = "",
  value_update = () => { },
  warn_status = false,
  info_status = false,
  class_name = "inputField",
  error_messg = `Enter ${title}`,
  info_message = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  password_status = false,
  disabled,
  charcterlimit,
  required,
  validation_type,
  specialcharacter,
  alphanumeric,
  inputProps,
  runOnEnter,
  onKeyDown,
  onBlur,
  autoComplete = "on"
}: {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  validation_type?: string;
  ref?: any;
  password_status?: boolean;
  type?: string;
  required?: boolean;
  charcterlimit?: number;
  alphanumeric?: boolean;
  specialcharacter?: boolean;
  disabled?: boolean;
  inputProps?: any;
  runOnEnter?: boolean;
  onKeyDown?: any;
  onBlur?: any;
  autoComplete?: string;
}) => {
  const [passwordType, setPasswordType] = useState(type);
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onChangeText = (updatedValue: string) => {
    if (validation_type === "name") {
      if (specialcharacter === true && alphanumeric === true) {
        if (charcterlimit && updatedValue.toString().length > charcterlimit) {
          return;
        }
        value_update(attrName, updatedValue);
      } else if (alphanumeric === true) {
        let valid_value = updatedValue.replace(/[^a-zA-Z0-9]/g, "");
        valid_value = valid_value.toString();

        if (charcterlimit && valid_value.toString().length > charcterlimit) {
          return;
        }
        value_update(attrName, valid_value);
      } else if (specialcharacter === true) {
        if (charcterlimit && updatedValue.toString().length > charcterlimit) {
          return;
        }
        value_update(attrName, updatedValue);
      } else {
        let valid_value = updatedValue.replace(/[^a-zA-Z ]/g, "");
        valid_value = valid_value.toString();

        if (charcterlimit && valid_value.toString().length > charcterlimit) {
          return;
        }
        value_update(attrName, valid_value);
      }
    } else if (validation_type === "numeric") {
      let valid_value = updatedValue.replace(/\D/g, "").toString();

      if (charcterlimit && valid_value.length > charcterlimit) {
        value_update(attrName, valid_value);
        return;
      }

      value_update(attrName, valid_value);
    } else if (validation_type === "numericfloatvalue") {
      let valid_value = updatedValue.replace(/[^0-9\.]/g, "").toString();

      if (charcterlimit && valid_value.length > charcterlimit) {
        value_update(attrName, valid_value);
        return;
      }

      value_update(attrName, valid_value);
    } else if (validation_type === "email") {
      let valid_value = updatedValue?.toLowerCase()?.replace(/\s/g, "")?.trim();
      if (charcterlimit && valid_value.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, valid_value);
    } else if (validation_type === "url") {
      let valid_value = updatedValue;
      if (charcterlimit && valid_value.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, valid_value);
    } else {
      value_update(attrName, updatedValue);
    }
  };

  return (
    <div className={class_name}>
      <TextField
        fullWidth
        label={title}
        variant="outlined"
        type={passwordType}
        autoComplete={autoComplete}
        value={value}
        InputProps={{ inputProps: { maxLength: charcterlimit }, ...inputProps }}
        onChange={(e) => {
          if (e.target.value?.slice(e.target.value?.length - 2, e.target.value?.length) === "  ") {
            onChangeText(e.target.value?.trim());
          } else {
            onChangeText(e.target.value);
          }
        }}
        required={required}
        disabled={disabled}
        onKeyDown={onKeyDown ?? (() => { })}
        onBlur={onBlur ?? (() => { })}
        InputLabelProps={{
          shrink: value ? true : undefined,
        }}
        onKeyDownCapture={(e) => {
          if (e.keyCode === 32 && validation_type === "email") {
            e.preventDefault();
            return;
          }

          if (e.key === "<" || e.key === ">") {
            e.preventDefault();
          }

        }}
        onKeyPress={(e) => {
          if (!runOnEnter) {
            e.key === "Enter" && e.preventDefault();
          }
        }}
      />
      {password_status === true ? (
        <Link
          onClick={togglePassword}
          className="passwordView"
          style={{
            position: "absolute",
            right: "8px", // Position the icon with space from the right edge
            top: "52%",
            transform: "translateY(-50%)",
            cursor: "pointer",

            background: "inherit",
          }}
        >
          {passwordType === "password" ? (
            <VisibilityOffIcon />
          ) : (
            <VisibilityIcon />
          )}
        </Link>
      ) : null}

      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span
          className="error"
          style={{ top: title === "Upper Range" ? "40px" : "" }}
        >
          {error_messg}
        </span>
      ) : null}
    </div>
  );
};

export default RKTextField;
