import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import axios from "axios";
import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import Progress from "../../../../Components/TopBarLoader/TopBarLoader";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../Services/Employee/EmployeeEnrolmentService";
import { extractNumbersFromString } from "../../../../Supporting files/HelpingFunction";
import "../EnrollmentDrive/EnrollmentDrive.scss";
import ChoosePolicy from "./ChoosePolicy/ChoosePolicy";
import ConfirmationPage from "./ConfirmationPage/ConfirmationPage";
import EnrollmentConfirmation from "./EnrollmentConfirmation/EnrollmentConfirmation";
import ResetPassword from "./ResetPassword/ResetPassword";
import ThankYou from "./ThankYou/ThankYou";
import useIsMobile from "../../../../Supporting files/MobileProvider";

function EnrollmentDrive() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [startFirstScreen, setStartFirstScreen] = useState(true);
  const [startSecondScreen, setStartSecondScreen] = useState(false);
  const [startThirdScreen, setStartThirdScreen] = useState(false);
  const [thirdPage, setThirdPage] = useState(true);
  const [startFourthScreen, setStartFourthScreen] = useState(false);
  const [startFifthScreen, setStartFifthScreen] = useState(false);
  const [startSixthScreen, setStartSixthScreen] = useState(false);
  const [startSeventhScreen, setStartSeventhScreen] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [userObj, setUserObj] = useState<{
    Client: string;
    "Contact Number": string;
    "Date Of Birth": string | Date;
    "Date Of Joining": string | Date | null;
    Department: string;
    Designation: string;
    Email: string;
    "First Name": string;
    Gender: string;
    "Last Name": string;
    Location: string;
    "Member Type": string;
    "Office Contact Number": string;
    "Official Email": string;
    "Organization Employee ID": string;
    "Relationship With Employee": string;
    Salary: string;
    "Salary Bracket": string;
    Title: string;
    clientId: string | null;
    createdAt: string | Date | null;
    createdBy: string;
    hide: string;
    id: string;
    logo: string | null;
    updatedAt: string | Date | null;
    userId: string;
    userType: string;
  }>({
    "Contact Number": "",
    "Date Of Birth": "",
    "Date Of Joining": "",
    "First Name": "",
    "Last Name": "",
    "Member Type": "",
    "Office Contact Number": "",
    "Official Email": "",
    "Organization Employee ID": "",
    "Relationship With Employee": "",
    "Salary Bracket": "",
    Client: "",
    clientId: "",
    createdAt: "",
    createdBy: "",
    Department: "",
    Designation: "",
    Email: "",
    Gender: "",
    hide: "",
    id: "",
    Location: "",
    logo: "",
    Salary: "",
    Title: "",
    updatedAt: "",
    userId: "",
    userType: "",
  });
  const [empPolicies, setEmpPolicies] = useState<any[]>([]);
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  console.log("selectedPolicies", selectedPolicies);
  const [userLocalInfo, setUserLocalInfo] = useState<{
    country: string;
    countryCurrencyCode: string;
    currency: string;
    dateFormat: string;
    reportBasis: string;
    timeZone: string;
  }>();
  const [finalPaymentInfo, setFinalPaymentInfo] = useState();
  const [totalPayable, setTotalPayable] = useState<number>(1000);
  const [newlyAddedMembers, setNewlyAddedMembers] = useState<Array<{}>>([]);
  console.log("newlyAddedMembers", newlyAddedMembers);
  const [stopNavigation, setStopNavigation] = useState<boolean>(false);
  const [showPwButton, setShowPwButton] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [policiesAmount, setPoliciesAmount] = useState<any[]>([]);
  const [amountRiders, setamountRiders] = useState<number>(totalPayable);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmpPolicies(policiesAmount);
  }, [policiesAmount]);

  useEffect(() => {
    userAuthCheck();
  }, []);

  useEffect(() => {
    calculatePaymentInfo();
  }, [selectedPolicies.length]);

  useEffect(() => {
    if (isMobile) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [
    startFirstScreen,
    startSecondScreen,
    startThirdScreen,
    startFourthScreen,
    startFifthScreen,
    startSixthScreen,
    startSeventhScreen,
  ]);
  const userAuthCheck = () => {
    const token = new URLSearchParams(window.location.search).get("token");

    if (token) {
      sessionStorage.setItem("usertoken", token);
    }

    // if (!token) {
    //   navigate(EMPLOYEE_ALL_ROUTES.LOGIN);
    //   return;
    // }

    axios
      .get(EMPLOYEE_URL_CONSTANTS.AUTH_CHECK, {
        headers: {
          auth: token,
        },
      })
      .then((res: any) => {
        if (res.data.data) {
          setUserObj(res.data.data);
          setUserLocalInfo(res.data.data.localInformation);
          setApiLoading(false);
        }
      })
      .catch((err: any) => {
        navigate(EMPLOYEE_ALL_ROUTES.LOGIN);
      });
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleClick = (policyId: string) => {
    setSelectedPolicies((prevSelected) => {
      if (prevSelected.includes(policyId)) {
        // Unselect if already selected
        return prevSelected.filter((id) => id !== policyId);
      } else {
        // Select if not already selected
        return [...prevSelected, policyId];
      }
    });
  };
  const get_employee_policies = () => {
    setApiLoading(true);
    const onSuccess = (res: any) => {
      setEmpPolicies(res.data.data);
      setSelectedPolicies(
        res.data.data.map((item: any) => item.policyData._id)
      );
      setStartFirstScreen(false);
      setStartSecondScreen(true);
      setApiLoading(false);
    };

    const onError = (err: any) => {
      console.log("err", err);
      if (err?.response?.data?.status === "failed") {
        toast.error(err?.response?.data?.errors[0]?.message);
      }
    };
    const queryString = new URLSearchParams();
    queryString.append("salary_bracket", userObj["Salary Bracket"]);
    queryString.append("department", userObj.Department);
    queryString.append("designation", userObj.Designation);

    EMPLOYEE_ENROLMENT_SERVICES.get_emp_enrolment_policies(
      onSuccess,
      onError,
      userObj.id,
      queryString.toString()
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setStartThirdScreen") {
      setStartThirdScreen(false);
      setThirdPage(true);
      setStartSecondScreen(true);
      setStartFirstScreen(false);
    } else if (attrName === "setStartFourthScreen") {
      setStartFourthScreen(true);
      setStartThirdScreen(false);
    } else if (attrName === "setStartFifthScreen") {
      setStartFifthScreen(true);
      setStartFourthScreen(false);
    } else if (attrName === "setStartSixthScreen") {
      setStartSixthScreen(true);
      setStartFifthScreen(false);
    } else if (attrName === "setStartSeventhScreen") {
      setStartSeventhScreen(true);
      setStartSixthScreen(false);
    } else if (attrName === "setStartFourthScreenfalse") {
      setStartFourthScreen(false);
      setStartThirdScreen(true);
    } else if (attrName === "setStartFifthScreenfalse") {
      setStartFifthScreen(false);
      setStartFourthScreen(true);
    } else if (attrName === "setStartSixthScreenfalse") {
      setStartSixthScreen(false);
      setStartFifthScreen(true);
    } else if (attrName === "setStartSeventhScreenfalse") {
      setStartSeventhScreen(false);
      setStartSixthScreen(true);
    }
  };

  const calculatePaymentInfo = () => {
    const empAge = differenceInYears(
      new Date(),
      new Date(userObj["Date Of Birth"].toString())
    );

    const policyObjs: any = empPolicies?.filter((item: any) =>
      selectedPolicies?.includes(item.policyData._id)
    );

    let finalPaymentInfo: any = [];

    for (let i = 0; i < policyObjs.length; i++) {
      if (
        policyObjs[i].policyData.planType.name === "FLAT" ||
        policyObjs[i].policyData.planType.name === "GRADED"
      ) {
        let ageBandCoverage = [];

        for (
          let j = 0;
          j < policyObjs[i].policyData.livesInfoFlat.length;
          j++
        ) {
          if (
            empAge >
              Number(
                extractNumbersFromString(
                  policyObjs[i].policyData.livesInfoFlat[j].min
                )
              ) &&
            empAge <
              Number(
                extractNumbersFromString(
                  policyObjs[i].policyData.livesInfoFlat[j].max
                )
              )
          ) {
            ageBandCoverage =
              policyObjs[i].policyData.livesInfoFlat[j].coverageTypeData;
          }
        }

        if (!ageBandCoverage) {
          setStopNavigation(true);
          toast.error("Please add Lives info on policy");
          return;
        }

        let coveredInfo: any = [];

        ageBandCoverage.map((item: any) => {
          if (
            ["EMPLOYEE", "SPOUSE", "KIDS", "PARENTS", "PARENTSINLAW"]?.includes(
              item.name
            )
          ) {
            coveredInfo.push({ name: item.name });
          }

          if (item.name === "amount") {
            if (coveredInfo?.length) {
              coveredInfo = coveredInfo.map((e: any) => {
                return {
                  ...e,
                  amount: item.value,
                };
              });
            } else {
              coveredInfo.push({
                amount: item.value,
              });
            }
          }
        });

        if (policyObjs[i].policyData.payType.type === "SPLIT") {
          coveredInfo = coveredInfo.map((e: any) => {
            let percentage = 0;
            policyObjs[i].policyData.payType.data.map((e1: any) => {
              if (e1.name === e.name) {
                percentage = e1.value;
              }
            });

            return {
              ...e,
              percentage,
            };
          });
        } else {
          coveredInfo = coveredInfo.map((e: any) => {
            return {
              ...e,
              percentage: 0,
            };
          });
        }

        let finalAmount: number = 0;

        coveredInfo.map((item: any) => {
          if (item.amount && item.percentage) {
            finalAmount += item.amount * (item.percentage / 100);
          }
        });

        finalPaymentInfo = [
          ...finalPaymentInfo,
          {
            policyName: policyObjs[i].policyData.productType.name,
            policyId: policyObjs[i].policyData._id,
            finalAmount,
          },
        ];
      }
    }

    let totalPayable = 0;

    for (let i = 0; i < finalPaymentInfo.length; i++) {
      totalPayable += finalPaymentInfo[i].finalAmount;
    }

    setTotalPayable(totalPayable);
    return finalPaymentInfo;
  };

  useEffect(() => {
    if (startThirdScreen) {
      let data = empPolicies?.filter((el: any) =>
        selectedPolicies?.includes(el.policyData._id)
      );
      setEmpPolicies(data);
      setPoliciesAmount(data);
    }
  }, [startThirdScreen]);

  const handleCheckboxChange = (
    policyIndex: number,
    memberIndex: number,
    isChecked: boolean,
    newlyAdded: boolean
  ) => {
    if (newlyAdded) {
      const updatedPolicies = [...empPolicies];
      updatedPolicies[policyIndex].memberData[memberIndex].checked = isChecked;
      setEmpPolicies(updatedPolicies);
      setPoliciesAmount(updatedPolicies);
    }
  };

  useEffect(() => {
    if (startSecondScreen) {
      get_employee_policies();
    }
  }, [startSecondScreen]);

  return (
    <>
      {loading && <NewLoader />}
      {isMobile ? (
        <Box className="enrollmentdrive_wrapper m_enrollmentdrive">
          <Grid container padding={0}>
            <Grid xs={12}>
              <div className="enrollment_innersection ">
                {/* first screen */}
                {startFirstScreen && (
                  <div className="inner_content">
                    <img
                      src="./images/enrollment_shield.svg"
                      alt=""
                      height={64}
                      className=""
                      loading="eager"
                    />
                    <h5 className="m_inner_section-heading m-0">
                      Hey{" "}
                      {userObj["First Name"] && (
                        <span>
                          {userObj["First Name"] + " " + userObj["Last Name"]}
                        </span>
                      )}
                      {/* <br /> Welcome to the <span>Enrolment Drive !</span> */}
                    </h5>
                    <h5 className="welcome_text m-0">
                      Welcome to the Enrolment Drive !
                    </h5>
                    <p className="m_inner_section_desc">
                      Enroll yourself and your dependants for a healthier & a
                      safe future.
                    </p>
                    <div className="mb-8 mt-3">
                      <img
                        src="mobile/enrollment-start-img.svg"
                        alt=""
                        loading="eager"
                      />
                    </div>
                    <div className=" MenrollmentStartBtn">
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={() => {
                          get_employee_policies();
                        }}
                        fullWidth
                      >
                        Start Enrollment
                      </Button>
                    </div>
                  </div>
                )}

                {/* second screen */}
                {startSecondScreen && (
                  <div className="choose_policy px-3">
                    <Grid container padding={0}>
                      <Grid xs={12} className="mt-2">
                        <div className="MenrollmentBackBtn">
                          <ArrowBackIosOutlinedIcon
                            fontSize="small"
                            onClick={() => {
                              setStartFirstScreen(true);
                              setStartSecondScreen(false);
                              setStartThirdScreen(false);
                              setThirdPage(false);
                            }}
                          />
                          <h5 className="choose-policy-text p-0 m-0 ">
                            Choose Policy
                          </h5>
                          {/* <span>Back to Choose Policy</span> */}
                        </div>
                      </Grid>
                      {/* <Grid xs={12} className="mb-0 ">
                        <h5 className="choose-policy-text p-0 pt-4 my-6 ">
                          Choose Policys
                        </h5>
                      </Grid> */}
                      <Grid xs={12} className=" pt-6 my-15">
                        <div className="mpolicy_main_container mb-10">
                          {empPolicies?.map((item) => {
                            const isChecked = selectedPolicies.includes(
                              item.policyData._id
                            );

                            return (
                              <div
                                key={item.policyData._id}
                                className={`mpolicy_container ${
                                  isChecked ? "checked" : ""
                                }`}
                                onClick={() => handleClick(item.policyData._id)}
                              >
                                <div className="policy-top w-full">
                                  <div
                                    className={`checkbox-circle ${
                                      isChecked ? "checked" : ""
                                    }`}
                                  >
                                    {isChecked && (
                                      <img
                                        src="mobile/tick_icon.svg"
                                        width="15px"
                                        height="15px"
                                        alt="tick"
                                        loading="eager"
                                      />
                                    )}
                                  </div>
                                  <div className="w-100 flex justify-center">
                                    <img
                                      loading="eager"
                                      src={`${
                                        item.policyData.productType.name ===
                                        "Group Health"
                                          ? "mobile/group-health-insurance.svg"
                                          : item.policyData.productType.name ===
                                            "Group Super TopUp"
                                          ? "mobile/group-super-top-up.svg"
                                          : item.policyData.productType.name ===
                                            "Group Term"
                                          ? "mobile/term_icon.svg"
                                          : item.policyData.productType.name ===
                                            "Group Travel"
                                          ? "mobile/travel_productLogo.png"
                                          : item.policyData.productType.name ===
                                            "Group Personal Accident"
                                          ? "mobile/personal-accident.svg"
                                          : item.policyData.productType.name ===
                                            "Group Critical Illness"
                                          ? "mobile/group-critical.svg"
                                          : null
                                      }`}
                                      className="mr-0"
                                      alt="policy"
                                    />
                                  </div>
                                </div>
                                {/* <hr className={isChecked ? "active" : ""} /> */}

                                <div className="policy-bottom">
                                  {/* <div
                                    className="m-0  "
                                  
                                  >
                                    <img
                                      src={`${
                                        item.policyData.productType.name ===
                                        "Group Health"
                                          ? "mobile/group-health-insurance.svg"
                                          : item.policyData.productType.name ===
                                            "Group Super TopUp"
                                          ? "mobile/group-super-top-up.svg"
                                          : item.policyData.productType.name ===
                                            "Group Term"
                                          ? "mobile/term_icon.svg"
                                          : item.policyData.productType.name ===
                                            "Group Travel"
                                          ? "mobile/travel_productLogo.png"
                                          : item.policyData.productType.name ===
                                            "Group Personal Accident"
                                          ? "mobile/personal-accident.svg"
                                          : item.policyData.productType.name ===
                                            "Group Critical Illness"
                                          ? "mobile/ci_productLogo.png"
                                          : null
                                      }`}
                                      width={106}
                                      height={79}
                                     
                                      alt="policy"
                                    />
                                  </div> */}
                                  <div>
                                    <h3>{item.policyData.productType.name}</h3>
                                    <span className="mt-1 mb-1">
                                      (
                                      {
                                        item.insurerDetails.insurerBasicInfo
                                          .name
                                      }
                                      )
                                    </span>
                                    <span>
                                      {item.policyData.policyCase.policyNumber}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                      <div className=" MenrollmentStartBtn  text-center ">
                        {!stopNavigation && (
                          <Button
                            disabled={selectedPolicies.length <= 0}
                            variant="contained"
                            className="submitBtn"
                            onClick={() => {
                              setStartFirstScreen(false);
                              setStartSecondScreen(false);
                              setStartThirdScreen(true);
                              setThirdPage(false);
                              window.scrollTo({
                                top: 0,
                                behavior: "auto",
                              });
                            }}
                          >
                            Continue
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </div>
                )}
                {/*start third screen*/}
                {startThirdScreen && (
                  <ChoosePolicy
                    policiesAmount={policiesAmount}
                    setPoliciesAmount={setPoliciesAmount}
                    amountRiders={amountRiders}
                    setamountRiders={setamountRiders}
                    handleCheckboxChange={handleCheckboxChange}
                    value_update={updateMasterState}
                    policies={empPolicies}
                    userLocalInfo={userLocalInfo}
                    calculatePaymentInfo={calculatePaymentInfo}
                    totalPayable={totalPayable}
                    refetchPolicies={get_employee_policies}
                    newlyAddedMembers={newlyAddedMembers}
                    setNewlyAddedMembers={setNewlyAddedMembers}
                    employeeId={userObj.id}
                    employeeDob={userObj["Date Of Birth"]}
                    userObj={userObj}
                  />
                )}
                {startFourthScreen && (
                  <ConfirmationPage
                    policiesAmount={policiesAmount}
                    setPoliciesAmount={setPoliciesAmount}
                    amountRiders={amountRiders}
                    setamountRiders={setamountRiders}
                    policies={empPolicies}
                    userObj={userObj}
                    value_update={updateMasterState}
                    calculatePaymentInfo={calculatePaymentInfo}
                    totalPayable={totalPayable}
                    userLocalInfo={userLocalInfo}
                    newlyAddedMembers={newlyAddedMembers}
                    user={userObj}
                    selectedPolicies={selectedPolicies}
                    setShowPwButton={setShowPwButton}
                    setShowLoader={setShowLoader}
                    empPolicies={empPolicies}
                  />
                )}

                {startFifthScreen && (
                  <ThankYou
                    value_update={updateMasterState}
                    showPwButton={showPwButton}
                  />
                )}
                {startSixthScreen && (
                  <EnrollmentConfirmation
                    userName={
                      userObj["First Name"] + " " + userObj["Last Name"]
                    }
                    email={userObj.Email}
                    userId={userObj.id}
                    mob={userObj["Contact Number"]}
                    value_update={updateMasterState}
                    apiLoading={apiLoading}
                    setApiLoading={setApiLoading}
                  />
                )}
                {startSeventhScreen && (
                  <ResetPassword value_update={updateMasterState} />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
          <Progress isAnimating={apiLoading} />

          {showLoader && <NewLoader />}

          <Box className="enrollmentdrive_wrapper">
            {thirdPage && (
              <div className="enrollment_bg">
                <Grid container spacing={3} className="row">
                  <Grid xs={12}>
                    {!startFirstScreen && (
                      <div className="backBtn">
                        <ArrowBackIcon
                          onClick={() => {
                            setStartFirstScreen(true);
                            setStartSecondScreen(false);
                          }}
                        />
                        <span>Back to Welcome</span>
                      </div>
                    )}
                    <div className="enrollment_innersection">
                      {/* first screen */}
                      {startFirstScreen && (
                        <div className="inner_content">
                          <img
                            src="./images/enrollment_shield.svg"
                            alt=""
                            height={100}
                            loading="eager"
                          />
                          <h5>
                            Hey{" "}
                            {userObj["First Name"] && (
                              <span>
                                {userObj["First Name"] +
                                  " " +
                                  userObj["Last Name"]}
                                ,
                              </span>
                            )}
                            <br /> Welcome to the <span>Enrolment Drive !</span>
                          </h5>
                          <p>
                            Enroll yourself and your dependants for a healthier
                            & a safe future.
                          </p>
                          <div className="ctaBtn">
                            <Button
                              variant="contained"
                              className="submitBtn"
                              onClick={() => {
                                get_employee_policies();
                              }}
                            >
                              Start Enrollment
                            </Button>
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <img
                              src="./images/enrollmetdrive_image.svg"
                              alt=""
                              height={406}
                              style={{ maxWidth: "100%" }}
                              loading="eager"
                            />
                          </div>
                        </div>
                      )}

                      {/* second screen */}
                      {startSecondScreen && (
                        <div className="choose_policy">
                          <h5>Choose Policy</h5>
                          <hr />
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <ul className="coverageAmt">
                                {empPolicies?.map((item: any) => {
                                  return (
                                    <li>
                                      <div className="Checkbox">
                                        <input
                                          type="checkbox"
                                          name="check"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setSelectedPolicies([
                                                ...selectedPolicies,
                                                item.policyData._id,
                                              ]);
                                            } else {
                                              setSelectedPolicies(
                                                selectedPolicies.filter(
                                                  (el) =>
                                                    el !== item.policyData._id
                                                )
                                              );
                                            }
                                          }}
                                          checked={selectedPolicies?.includes(
                                            item.policyData._id
                                          )}
                                        />
                                        <label>
                                          <img
                                            loading="eager"
                                            src={`${
                                              item.policyData.productType
                                                .name === "Group Health"
                                                ? "./images/hi_productLogo.png"
                                                : item.policyData.productType
                                                    .name ===
                                                  "Group Super TopUp"
                                                ? "./images/stu_productLogo.png"
                                                : item.policyData.productType
                                                    .name === "Group Term"
                                                ? "./images/ti_productLogo.png"
                                                : item.policyData.productType
                                                    .name === "Group Travel"
                                                ? "./images/travel_productLogo.png"
                                                : item.policyData.productType
                                                    .name ===
                                                  "Group Personal Accident"
                                                ? "./images/pa_productLogo.png"
                                                : item.policyData.productType
                                                    .name ===
                                                  "Group Critical Illness"
                                                ? "./images/ci_productLogo.png"
                                                : null
                                            }`}
                                            alt="Product Logo"
                                            height={"40px"}
                                          />
                                          <hr />
                                          <p>
                                            {item.policyData.productType.name}
                                            <span>
                                              ({" "}
                                              {
                                                item.insurerDetails
                                                  .insurerBasicInfo.name
                                              }{" "}
                                              )
                                            </span>
                                            <span>
                                              {
                                                item.policyData.policyCase
                                                  .policyNumber
                                              }
                                            </span>
                                          </p>
                                        </label>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Grid>
                            <Grid xs={12} className="ctaBtn text-center mt-4">
                              {!stopNavigation && (
                                <Button
                                  disabled={selectedPolicies.length <= 0}
                                  variant="contained"
                                  className="submitBtn"
                                  onClick={() => {
                                    setStartFirstScreen(false);
                                    setStartSecondScreen(false);
                                    setStartThirdScreen(true);
                                    setThirdPage(false);
                                  }}
                                >
                                  Continue
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {startThirdScreen && (
              <ChoosePolicy
                policiesAmount={policiesAmount}
                setPoliciesAmount={setPoliciesAmount}
                amountRiders={amountRiders}
                setamountRiders={setamountRiders}
                handleCheckboxChange={handleCheckboxChange}
                value_update={updateMasterState}
                policies={empPolicies}
                userLocalInfo={userLocalInfo}
                calculatePaymentInfo={calculatePaymentInfo}
                totalPayable={totalPayable}
                refetchPolicies={get_employee_policies}
                newlyAddedMembers={newlyAddedMembers}
                setNewlyAddedMembers={setNewlyAddedMembers}
                employeeId={userObj.id}
                employeeDob={userObj["Date Of Birth"]}
                userObj={userObj}
              />
            )}

            {startFourthScreen && (
              <ConfirmationPage
                policiesAmount={policiesAmount}
                setPoliciesAmount={setPoliciesAmount}
                amountRiders={amountRiders}
                setamountRiders={setamountRiders}
                policies={empPolicies}
                userObj={userObj}
                value_update={updateMasterState}
                calculatePaymentInfo={calculatePaymentInfo}
                totalPayable={totalPayable}
                userLocalInfo={userLocalInfo}
                newlyAddedMembers={newlyAddedMembers}
                user={userObj}
                selectedPolicies={selectedPolicies}
                setShowPwButton={setShowPwButton}
                setShowLoader={setShowLoader}
                empPolicies={empPolicies}
              />
            )}

            {startFifthScreen && (
              <ThankYou
                value_update={updateMasterState}
                showPwButton={showPwButton}
              />
            )}

            {startSixthScreen && (
              <EnrollmentConfirmation
                userName={userObj["First Name"] + " " + userObj["Last Name"]}
                email={userObj.Email}
                userId={userObj.id}
                mob={userObj["Contact Number"]}
                value_update={updateMasterState}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
              />
            )}

            {startSeventhScreen && (
              <ResetPassword value_update={updateMasterState} />
            )}
          </Box>
        </div>
      )}
    </>
  );
}

export default EnrollmentDrive;
