import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, IconButton, Link, Menu, MenuItem, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { APPREANCE_SERVICES } from "../../../../Services/Settings/OrganisationSettings/Appearance";
import { TUserData } from "../../../../Services/Types/TUserData";
import Session from "../../../../Session/Session";
import { userDetailsSlice } from "../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { Post } from "../../../../Supporting files/CallAPI";
import "./Header.scss";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";

function Header() {
  const { userType, username, email, portals, "Organization Email": organizationEmail, parentsId, } = useAppSelector((state) => state.userDetailsSlice);
  const [userSignOutStatus, setUserSignOutStatus] = useState<null | HTMLElement>(null);
  const openSignOut = Boolean(userSignOutStatus);
  const [portalChange, setPortalChange] = useState<null | HTMLElement>(null);
  const openpcMenu = Boolean(portalChange);
  const [notificationMenuStatus, setNotificationMenuStatus] = useState<null | HTMLElement>(null);
  const openNotification = Boolean(notificationMenuStatus);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [logo, setLogo] = useState<string>(userState?.logo);
  const [selectedValue, setSelectedValue] = useState(userState.appearance.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { origin } = window.location;

  useEffect(() => {
    if (
      Session.getTokken().toString().length === 0 &&
      Session.getUserType() != "broker"
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      let page = params.get("page");
    }
    if (userState.userType === "EMPLOYER") {
      setLogo(userState?.logo || "clientstaticlogo.jpg")
    }
  }, []);

  const save_appearance_data = (data: number) => {
    let dto = {
      appearance: {
        theme: data,
      },
    };
    const onSuccess = (res: any) => { };

    const onError = (err: any) => {
      console.log(err);
    };
    {
      userState.userType === "EMPLOYER"
        ? APPREANCE_SERVICES.save_appearance_data_employer(
          onSuccess,
          onError,
          dto
        )
        : APPREANCE_SERVICES.save_appearance_data(
          onSuccess,
          onError,
          dto,
          userType.toLowerCase()
        );
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    attrName: string
  ) => {
    if (attrName === "notificationMenuStatus") {
      setNotificationMenuStatus(event.currentTarget);
    } else if (attrName === "userSignOutStatus") {
      setUserSignOutStatus(event.currentTarget);
    } else if (attrName === "portalChange") {
      setPortalChange(event.currentTarget);
    }
  };

  const handleClose = (attrName: string) => {
    if (attrName === "notificationMenuStatus") {
      setNotificationMenuStatus(null);
    } else if (attrName === "userSignOutStatus") {
      setUserSignOutStatus(null);
    } else if (attrName === "portalChange") {
      setPortalChange(null);
    }
  };

  const handleChange = (data: number) => {
    setSelectedValue(data);
    save_appearance_data(data);
    dispatch(userDetailsSlice.actions.updateAppearance(data));
  };

  const logout = () => {
    let params = {};
    Post(
      userType === "EMPLOYER"
        ? URL_CONSTANTS.user_logout_employer
        : URL_CONSTANTS.delete_user,
      params
    ).then((data: any) => {
      let error = data[0];
      let response = data[1];
      if (error !== "failed") {
        navigate("/");
        sessionStorage.clear();
        window.location.reload();
      }
    });
  };

  const handleMenuItemClick = (value: any) => {
    changeAccountType(value);
    setPortalChange(null);
    //setSelectedValue(value); // Update the selected value state
  };

  const changeAccountType = (type: string) => {
    let params = {
      username: username || organizationEmail || email,
      switch_to: type.toUpperCase(),
    };

    Post(URL_CONSTANTS.changeAccountType, params)?.then((data: any) => {
      let status = data[0];
      let response = data[1];
      if (response && status === "success") {

        sessionStorage.setItem("usertoken", response.userToken);
        dispatch(
          userDetailsSlice.actions.updateUserDetails([
            {
              key: "userType",
              value: response.userType,
            },
            {
              key: "portals",
              value: response.portals,
            },
          ])
        );
        if (response.userType === "EMPLOYEE") {
          setTimeout(() => {
            window.location.href = `${origin}/${EMPLOYEE_ALL_ROUTES.DASHBOARD}`;
          }, 1000);
        } else {
          navigate(ALL_ROUTES.DASHBOARD)
        }
      }
      if (data[0] && data[0].status === "failed") {
        // let param = data[0].errors[0].param;
        // let error = data[0].errors[0].message;
        // setParam(param);
        // setErrorLogin(error);
      }
    });
  };

  const userTypeData = (type: string) => {
    if (type === "broker_portal") {
      return "Broker";
    }
    if (type === "client_portal") {
      return "Employer";
    }
    if (type === "employee_portal") {
      return "Employee";
    }
  };
  let keysWithTrueValues = Object.values(portals).filter(value => value);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid className="logoCol" xs onClick={() => navigate(ALL_ROUTES.LOGIN)}>
        <Box>
          <img src={logo} alt="Logo" height={"60px"}
            onError={(e: any) => {
              if (userState.userType === "EMPLOYER") {
                e.currentTarget.src = "clientstaticlogo.jpg";
              } else if (userState.userType === "BROKER") {
                e.currentTarget.src = window.location.hostname === "eb.policysquare.in" || window.location.hostname === "eb.evervent.io"
                  ? "/insurevent.png" : "/images/eb_logo.svg";
              }
            }}
            width={'100%'}
          />
        </Box>
      </Grid>
      <Grid className="topNavbar pl-0" xs>
        {/* <Grid xs={6}>
          <Button
            className="searchBox"
            onClick={() => {
              // setSearchopen(true);
            }}>
            Search here
          </Button>
        </Grid> */}
        <Grid container justifyContent="flex-end" alignItems="center" xs={12}>
          {/* <Tooltip title="Task Management">
            <Link
              className="CustomModuleLink mr-5"
              onClick={() => {
                navigate(ALL_ROUTES.TASK_MANGEMENT);
              }}
            >
              <AssignmentTurnedInOutlinedIcon />
            </Link>
          </Tooltip> */}
          {keysWithTrueValues.length > 1 && <React.Fragment>
            <Box className="portalChangeTab">
              <IconButton
                onClick={(e) => handleClick(e, "portalChange")}
                aria-controls={portalChange ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={portalChange ? "true" : undefined}
              >
                Switch To <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
            <Menu
              className="signout_option"
              anchorEl={portalChange}
              id="account-menu"
              open={openpcMenu}
              onClose={() => handleClose("portalChange")}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              {Object.entries(portals).map(([portalKey, portalValue]) =>
                portalValue ? (
                  <MenuItem
                    className={`${userTypeData(portalKey)?.toLowerCase() ===
                      userType.toLowerCase()
                      ? "selected"
                      : ""
                      }`}
                    key={portalKey}
                    onClick={() => {
                      if (userTypeData(portalKey)?.toLowerCase() === userType.toLowerCase()) return;
                      else {
                        handleMenuItemClick(userTypeData(portalKey));
                      }
                    }}
                  >
                    {userTypeData(portalKey)}
                  </MenuItem>
                ) : null
              )}
            </Menu>
          </React.Fragment>}


          <Tooltip title="Settings">
            <Link
              className="CustomModuleLink mr-5"
              onClick={() => {
                navigate(ALL_ROUTES.SETTING);
              }}
            >
              <TuneIcon />
            </Link>
          </Tooltip>

          {/* <Tooltip title="Appearence">
            <Link
              onClick={() => {
                handleChange(selectedValue === 0 ? 1 : 0);
              }}
              className="themeToggle mr-5"
            >
              {selectedValue === 0 ? <LightModeIcon /> : <NightsStayIcon />}
            </Link>
          </Tooltip> */}

          {/* Notiifcation */}
          {/* {<React.Fragment>
            <Tooltip title="Notifications">
              <Box className="notificationMenu">
                <IconButton
                  className="bellIcon"
                  onClick={(e) => {
                    handleClick(e, "notificationMenuStatus");
                  }}
                  aria-controls={
                    openNotification ? "account-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openNotification ? "true" : undefined}
                >
                  <span className="number">0</span>
                </IconButton>
              </Box>
            </Tooltip>

            <Menu
              className="notificationList"
              anchorEl={notificationMenuStatus}
              id="account-menu"
              open={openNotification}
              onClose={() => handleClose("notificationMenuStatus")}
              onClick={() => handleClose("notificationMenuStatus")}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <Box className="menuWrapper">
                <h5>Notifications</h5>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
                <MenuItem className="listItem">
                  <h6>Health Policy is process</h6>
                  <p>Your health policy has been processed.</p>
                </MenuItem>
              </Box>
              <Box className="footer">
                <Link
                  onClick={() => {
                    navigate(ALL_ROUTES.NOTIFICATION);
                  }}
                  underline="none"
                  className="viewall"
                >
                  View all
                </Link>
              </Box>
            </Menu>
          </React.Fragment>} */}
          <React.Fragment>
            <Box className="signoutMenu">
              <IconButton
                onClick={(e) => handleClick(e, "userSignOutStatus")}
                aria-controls={userSignOutStatus ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={userSignOutStatus ? "true" : undefined}
              ></IconButton>
            </Box>
            <Menu
              className="signout_option"
              anchorEl={userSignOutStatus}
              id="account-menu"
              open={openSignOut}
              onClose={() => handleClose("userSignOutStatus")}
              onClick={() => handleClose("userSignOutStatus")}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                    paddingright: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              {!parentsId ? (
                ""
              ) : (
                <MenuItem
                  onClick={() => {
                    // updateMasterState("activePage", "setting");
                    navigate(
                      `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`
                    );
                  }}
                >
                  Change Password
                </MenuItem>
              )}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
