import Renewed from "@mui/icons-material/Autorenew";
import Inforced from "@mui/icons-material/Beenhere";
import Draft from "@mui/icons-material/BookmarkAdd";
import Rejected from "@mui/icons-material/Cancel";
import Cancelled from "@mui/icons-material/CancelPresentation";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Expired from "@mui/icons-material/GppBad";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../APIVar/EmployerUrlConstant";
import CDLedger from "../../../../../Components/Broker/CDLedger/CDLedger";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { EMPLOYER_POLICIES_SERVICES } from "../../../../../Services/EmployerServices/Policies/PoliciesServices";
import FeatureAndExclusions from "./FeaturesAndExclusions";
import PolicyDetailOverViewTab from "./OverviewTab";
import PolicyConfig from "./PolicyConfig/PolicyConfig";
import Riders from "./Riders";
import Members from "./Members";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import Published from "@mui/icons-material/PublishedWithChanges";

function PolicyDetail() {
  const navigate = useNavigate();
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [priorityStatus, setPriorityStatus] = useState({
    value: 21,
    label: "",
  });
  const [overviewData, setOverviewData] = useState<any>({});
  const [uploadFile, setUploadFile] = useState(false);
  const [prioritystatusData, setprioritystatusData] = useState([{
    "label": 20,
    "value": "Draft"
}]);
  const [sectionStatus, SetSectionStatus] = useState<string>("notesTab");
  const [intialCdBalance, setintialCdBalance] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    
    let timeoutId: any;
 
  if (timeoutId) {
      clearTimeout(timeoutId);
    }
 
    const delayedApiCall = () => {
     get_policy_overview_tab(id ?? "");
    }
 
    timeoutId = setTimeout(delayedApiCall, 900);
 
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const get_policy_overview_tab = (id: any) => {
    const onSuccess = (res: any) => {
      setOverviewData(res.data.data[0]);
      setPriorityStatus({
        value: res.data.data[0].status,
        label: "",
      });
      get_status();
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYER_POLICIES_SERVICES.get_policy_detail_view(
      `${EMPLOYER_URL_CONSTANTS.get_policy_detail_view}${id}&tab=OVERVIEW`,
      onSuccess,
      onError
    );
  };
  const sendDataToChild = (data: string) => {
    setintialCdBalance(data);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
    } else {
      attrName(value);
    }
  };
  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_status(onSuccess, onError);
  };
  return (
    <>
      {showLoader && <NewLoader />}

      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(ALL_ROUTES.POLICIES);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">
              {overviewData?.insurerDetails &&
                overviewData?.insurerDetails?.name.charAt(0)} 
            </span>
            <div>
              <h3>
                {overviewData?.insurerDetails &&
                  overviewData?.insurerDetails?.name}
              </h3>
              <p>Policy No. {overviewData?.policyCase?.policyNumber}</p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* <div>
            <Tooltip title="Remove">
              <Link
                underline="none"
                className="actionLinks red_icon"
                onClick={() => {
                  setRemoveStatus(true);
                }}
              >
                <DeleteOutlineIcon />
              </Link>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Global Edit">
              <Link className="actionLinks" onClick={() => {}}>
                <BorderColorIcon />
              </Link>
            </Tooltip>
          </div> */}
            {/* Status Dropdown */}
            <div id={`_${priorityStatus?.value?.toString()}`}>
              <div
                className="status_dropdown"
                id={`_${priorityStatus?.value?.toString()}`}
              >
                {priorityStatus.value === 20 ? (
                  <Draft id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 21 ? (
                  <Inforced id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 22 ? (
                  <Expired id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 23 ? (
                  <Renewed id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 24 ? (
                  <Void id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 25 ? (
                  <Cancelled id={`_${priorityStatus?.value?.toString()}`} />
                ) : priorityStatus.value === 26 ? (
                  <Rejected id={`_${priorityStatus?.value?.toString()}`} />
                ): priorityStatus.value === 62 ? (
                  <Published id={`_${priorityStatus?.value?.toString()}`} />
                ) : null}
                <span>
  {prioritystatusData?.find((data) => data.label === priorityStatus?.value)?.value}
</span>

              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs className="rightSection h-100">
            <>
              <Grid container spacing={3} alignItems="center">
                <Grid xs maxWidth="260px"></Grid>
                <Grid xl={7} lg={8} md={8} xs={12} style={{ padding: "20px" }}>
                  <div className="productTabs ">
                    <Button
                      className={
                        viewTabsStatus === "overview"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "overview");
                      }}
                    >
                      Overview
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "Configuration"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "Configuration");
                      }}
                    >
                      Configuration
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "Features"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "Features");
                      }}
                    >
                      Features & Exclusions
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "Members"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "Members");
                      }}
                    >
                      Members
                    </Button>
                    {overviewData?.productType?.name === "Group Term" && (
                      <Button
                        className={
                          viewTabsStatus === "Riders"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          updateMasterState(setViewTabsStatus, "Riders");
                        }}
                      >
                        Riders
                      </Button>
                    )}
                    <Button
                      className={
                        viewTabsStatus === "CD Ledger"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "CD Ledger");
                      }}
                    >
                      CD Ledger
                    </Button>
                  </div>
                </Grid>
                {/* <Grid
                  xs
                  style={{ textAlign: "right" }}
                  className="text-sm-center"
                >
                  <p className="lastupdate">
                    Last Update: 11:00AM, 10th Nov, 2022
                  </p>
                </Grid> */}
              </Grid>
              {viewTabsStatus === "overview" ? (
                <PolicyDetailOverViewTab
                  cdBalanceState={sendDataToChild}
                  scrollRef={sectionStatus}
                />
              ) : null}
              {viewTabsStatus === "Configuration" ? <PolicyConfig /> : null}
              {viewTabsStatus === "CD Ledger" ? (
                <CDLedger intialCdBalance={intialCdBalance} />
              ) : null}
              {viewTabsStatus === "Features" ? (
                <FeatureAndExclusions type={overviewData.planType.name} />
              ) : null}
              {viewTabsStatus === "Members" ? (
                <>
                  <Members
                    clientInfo={overviewData.organisationDetails}
                    policyNumber={overviewData.policyCase.policyNumber}
                  />
                </>
              ) : null}
              {viewTabsStatus === "Riders" ? (
                <>
                  <Riders />
                </>
              ) : null}
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PolicyDetail;
