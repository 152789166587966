import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { useAppSelector } from "../../../../../Store/hooks";
import "./MainSettingMenu.scss";

const MainSettingMenu = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("");
  const [section, setSection] = useState("");
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [companyDetailViewPermission, setCompanyDetailViewPermission] = useState(false);
  const [branchViewPermission, setBranchViewPermission] = useState(false);
  const [departmentViewPermission, setDepartmentViewPermission] = useState(false);
  const [modulesViewPermission, setModulesViewPermission] = useState(false);
  const [taxViewPermission, setTaxViewPermission] = useState(false);
  const [currencyViewPermission, setCurrencyViewPermission] = useState(false);
  const [usersViewPermission, setUsersViewPermission] = useState(false);
  const [accessControlsViewPermission, setAccessControlsViewPermission] = useState(false);

  const handleChange = (panel: string, subPanel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setActivePage(subPanel)
    navigate(
      `${ALL_ROUTES.SETTING_MODULE_FORM}?page=${subPanel}&section=${panel}`,
      { replace: true }
    );
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    let section = params.get("section");
    let page = params.get("page");
    console.log("section", section,page);
    setSection(section ?? "");
    setActivePage(page ?? "");
  }, [window.location.search]);

  useEffect(() => {
    setAccessControlsViewPermission(
      BROKER?.AccessControl?.view === undefined ? false : BROKER?.AccessControl?.view
    );
    setUsersViewPermission(
      BROKER?.Users?.view === undefined ? false : BROKER?.Users?.view
    );
    setCompanyDetailViewPermission(
      BROKER?.CompanyDetails?.view === undefined ? false : BROKER?.CompanyDetails?.view);
    setBranchViewPermission(
      BROKER?.Branches?.view === undefined ? false : BROKER?.Branches?.view
    );
    setDepartmentViewPermission(
      BROKER?.Department?.view === undefined ? false : BROKER?.Department?.view
    );
    setModulesViewPermission(
      BROKER?.Module?.view === undefined ? false : BROKER?.Module?.view
    );
    setTaxViewPermission(
      BROKER?.Taxation?.view === undefined ? false : BROKER?.Taxation?.view
    );
    setCurrencyViewPermission(
      BROKER?.Currencies?.view === undefined ? false : BROKER?.Currencies?.view
    );
  }, []);

  return (
    <Box className="mainSettingWrapper" sx={{ paddingTop: "90px" }}>
      <Grid container spacing={3}>
        {/* <Grid xs={12}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate("/NewSettings");
              }}>
              <ChevronLeftIcon />
            </Link>
            Modules
          </h3>
        </Grid> */}

        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="accordian-section">
              {/* General */}
              <Accordion
                className="accordian"
                expanded={section === "general"}
                // onChange={handleChange("general", "personalSettings")}>
                onChange={handleChange(
                  "general",
                  userDetails.roleName === "SUPER"
                    ? "appearance"
                    : "personalSettings"
                )}
              >
                <AccordionSummary
                  aria-controls="1-content"
                  id="1-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "general"
                        ? "heading general active"
                        : "heading general "
                    }
                  >
                    General
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {!userDetails.parentsId ? (
                        ""
                      ) : (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "personalSettings" ||
                              activePage === "editPersonalSettings"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`,
                                { replace: true }
                              );
                            }}
                          >
                            Personal Settings
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "emailSettings"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=emailSettings&section=general`,
                              { replace: true }
                            );
                          }}
                        >
                          Email Settings
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "storageSettings"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=storageSettings&section=general`,
                              { replace: true }
                            );
                          }}>
                          Storage Settings
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "appearance"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=appearance&section=general`,
                              { replace: true }
                            );
                          }}
                        >
                          Appearance
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Organisation */}
              <Accordion
                className="accordian"
                expanded={section === "org"}
                onChange={handleChange("org", "companyDetails")}
              >
                <AccordionSummary
                  aria-controls="2-content"
                  id="2-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "org"
                        ? "heading orgnaisation active"
                        : "heading orgnaisation "
                    }
                  >
                    Organisation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {companyDetailViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "companyDetails"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=companyDetails&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Company Details
                          </Link>
                        </li>
                      )}
                      {branchViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "branches"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=branches&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Branches
                          </Link>
                        </li>
                      )}
                      {departmentViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "departments"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=departments&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Departments
                          </Link>
                        </li>
                      )}

                      {taxViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "taxation"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=taxation&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Taxation
                          </Link>
                        </li>
                      )}
                      {currencyViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "currencies"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=currencies&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Currencies
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="accordian"
                expanded={section === "dataAdmin"}
                onChange={handleChange("dataAdmin", "storageSettings")}
              >
                <AccordionSummary
                  aria-controls="3-content"
                  id="3-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "dataAdmin"
                        ? "heading dataadmin active"
                        : "heading dataadmin "
                    }
                  >
                    Data Admin
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "storageSettings"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=storageSettings&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Storage
                        </Link>
                      </li>

                      {/* <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "export"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=export&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Export
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "activity"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=activity&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Activity Log
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "audit"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=audit&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Audit Log
                        </Link>
                      </li> */}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* users & control */}
              <Accordion
                className="accordian"
                expanded={section === "userControl"}
                onChange={handleChange("userControl", "users")}
              >
                <AccordionSummary
                  aria-controls="4-content"
                  id="4-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "userControl"
                        ? "heading userimg active"
                        : "heading userimg "
                    }
                  >
                    Users & Controls
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {usersViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "users"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=users&section=userControl`,
                                { replace: true }
                              );
                            }}
                          >
                            Users
                          </Link>
                        </li>
                      )}

                      {accessControlsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "accessControl"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`,
                                { replace: true }
                              );
                            }}
                          >
                            Access Control
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Customisation */}
              <Accordion
                className="accordian"
                expanded={section === "custom"}
                onChange={handleChange("custom", "modules")}
              >
                <AccordionSummary
                  aria-controls="5-content"
                  id="5-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "custom"
                        ? "heading customisation active"
                        : "heading customisation "
                    }
                  >
                    Customisation
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {modulesViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "modules"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=modules&section=custom`,
                                { replace: true }
                              );
                            }}
                          >
                            Modules
                          </Link>
                        </li>
                      )}

                      {/* <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "templates"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=templates&section=custom`,
                              { replace: true }
                            );
                          }}>
                          Templates
                        </Link>
                      </li> */}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                className="accordian"
                expanded={section === "portalSetup"}
                onChange={handleChange("portalSetup","employee")}>
                <AccordionSummary
                  aria-controls="6-content"
                  id="6-header"
                  className="accordion-header">
                  <Typography
                    className={
                      section === "portalSetup"
                        ? "heading portalspace active"
                        : "heading portalspace "
                    }>
                    Portal Setup
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "employee"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=employee&section=portalSetup`,
                              { replace: true }
                            );
                          }}>
                          Employee Portal
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "employer"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=employer&section=portalSetup`,
                              { replace: true }
                            );
                          }}>
                          Employer Portal
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}

              {/* <Accordion
                className="accordian"
                expanded={section === "developer"}
                // onChange={handleChange("developer","integrations")}>
                onChange={handleChange("developer", "retail")}
              >
                <AccordionSummary
                  aria-controls="7-content"
                  id="7-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "developer"
                        ? "heading devspace active"
                        : "heading devspace "
                    }
                  >
                    Developer Space
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      // <li>
                      //   <Link
                      //     underline="none"
                      //     className={
                      //       activePage === "integrations"
                      //         ? "tab-button active"
                      //         : "tab-button"
                      //     }
                      //     onClick={() => {
                      //       navigate(
                      //         `${ALL_ROUTES.SETTING_MODULE_FORM}?page=integrations&section=developer`,
                      //         { replace: true }
                      //       );
                      //     }}>
                      //     Integrations
                      //   </Link>
                      // </li>
                      // <li>
                      //   <Link
                      //     underline="none"
                      //     className={
                      //       activePage === "webhooks"
                      //         ? "tab-button active"
                      //         : "tab-button"
                      //     }
                      //     onClick={() => {
                      //       navigate(
                      //         `${ALL_ROUTES.SETTING_MODULE_FORM}?page=webhooks&section=developer`,
                      //         { replace: true }
                      //       );
                      //     }}>
                      //     API & Webhooks
                      //   </Link>
                      // </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "retail"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=retail&section=developer`,
                              { replace: true }
                            );
                          }}
                        >
                          Retail Insurance
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainSettingMenu;
