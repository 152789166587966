import Rejected from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import PartialApproval from "@mui/icons-material/Rule";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import DocumentChecklistView from "../../../../../Components/Broker/ProductConfigurator/DocumentChecklist/DocumentChecklist";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { Claim_SERVICES } from "../../../../../Services/Claim/ClaimServices";
import { TClaimDeatil } from "../../../../../Services/Types/Claim/TClaimDetail";
import { TSearchDropdown } from "../../../../../Services/Types/Dropdown";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  capitalize,
  date_time_format,
  extractAlphabetsFromString,
  extractNumbersFromString,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../formLayout.scss";
import "../Claimassistant.scss";
import ConfirmationPopup from "../../../../../Components/Broker/ConfirmationPopUp/ConfirmationPopup";
import SlidingPanel from "react-sliding-side-panel";
import { GridCloseIcon } from "@mui/x-data-grid";
import React from "react";

function ClaimAssistantDetail() {
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userDetailsSlice.userType);
  const { formatCurrency } = useFormatCurrency();
  const [priorityStatus, setPriorityStatus] = useState(47);
  const [openadditionalslider, setopenadditionalslider] = useState(false);
  const userDetail = useAppSelector((state: any) => state.userDetailsSlice);
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [detailInfo, setDetailInfo] = useState<TClaimDeatil>({
    paid: 0,
    outstanding: 0,
    ailment: "",
    deficiency_ir_req: "",
    tpa_status: "",
    reference_no: "",
    status: "",
    transactionId: "",
    branchName: "",
    remarks: "",
    firStatus: false,
    claimAmt: 0,
    insuredPerson: "",
    reason: "",
    firDocument: {
      doc:"",
      docName:""
    },
    upload: [],
    requestType: "",
    claimId: "",
    clientId: "",
    clientName: "",
    memberId: "",
    memberName: "",
    policyNumber: "",
    policyType: "",
    docCount: 0,
    createdAt: "",
    updatedAt: "",
    policyId: "",
    hospitalName: "",
    country: { label: "", id: "" },
    state: { label: "", id: "" },
    city: { label: "", id: "" },
    department: "",
    admisionDate: "",
    dischargeDate: "",
    reasonForHospitalization: "",
    notes: [],
    insurer: "",
    tpaName: "",
    balance_si: "",
    tpaData: { documentChecklist: [], integrated: false },
  });
  const [notesData, setNotesData] = useState<Array<{ name: string; date: Date; description: string }>>([]);
  const [attachment, setAttachment] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<string>("personalDetails");
  const [subSection, setSubSection] = useState("memberdetails");
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [id, setId] = useState("");
  console.log("formatCurrency", formatCurrency);
  const [individualKey, setIndividualKey] = useState<{
    key: string;
    value: string;
  }>({
    key: "",
    value: "",
  });
  const [selectedClaimValue, setselectedClaimValue] = useState<any>({});
  const [statusWarningPopoup, setstatusWarningPopoup] = useState<boolean>(false);
  const [country_data, setCountry_data] = useState<TSearchDropdown[]>([]);
  const [state_data, setState_data] = useState<TSearchDropdown[]>([]);
  const [city_data, setCity_data] = useState<TSearchDropdown[]>([]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const personalDetails = useRef(null);
  const hospitalDetails = useRef(null);
  const claimInfo = useRef(null);
  const expenses = useRef(null);
  const tpaDatesInfo = useRef(null);
  const tpaIdInfo = useRef(null);
  const tpaStatusInfo = useRef(null);
  const tpaRemarkInfo = useRef(null);
  const notes = useRef(null);
  const docChecklist = useRef(null);
  const attatchmentsdetails = useRef(null);
  const memberdetails = useRef(null);
  const basicdetails = useRef(null);
  const claimdetails = useRef(null);
  const { dateFormat, timeZone } = useAppSelector((state: any) => state.userDetailsSlice.localInformation);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, setEditPermission] = useState<any>(null);
  const [isEditMode, setisEditMode] = useState<boolean>(false);
  const [selectedKey, setselectedKey] = useState<string>("expenses");
  const [statusPermission, setStatusPermission] = useState<any>(null);
  const [info, setInfo] = useState<any>({
    expenses: { amount: "" },
    important_dates: { claim_register_date: "" },
    important_ids: { insurance_claim_no: "" },
    important_status: { status: "" },
    remarks: { deduction_reasons: "" },
  });
  const [addInfo, setaddInfo] = useState<any>({
    expenses: {},
    important_dates: {},
    important_ids: {},
    important_status: {},
    remarks: {},
  });
  const [keyValuePairs, setKeyValuePairs] = useState([{ key: "", value: "" }]);

  function objectToKeyValueArray(obj: Record<string, any>) {
    return Object.entries(obj).map(([key, value]) => ({
      key: key,
      value: value
    }));
  }
  // const handleInputChange = (
  //   index: number,
  //   field: "key" | "value",
  //   newValue: string
  // ) => {
  //   console.log("selectedKey",selectedKey)
  //   // alert("hello")
  //   console.log('field',field,newValue)
  //   const updatedPairs = [...keyValuePairs];
  //   updatedPairs[index][field] = newValue;
  //   setKeyValuePairs(updatedPairs);
  // };
  const handleInputChange = (
    index: number,
    field: "key" | "value",
    newValue: string
  ) => {
    console.log("selectedKey", selectedKey);
    console.log('field', field, newValue);
  
    const updatedPairs = [...keyValuePairs];
  
    // Check if the selected key is "expenses" and field is "value"
    if (selectedKey === "expenses" && field === "value") {
  
      newValue = formatCurrency(newValue);
    }
  
    updatedPairs[index][field] = newValue;
    setKeyValuePairs(updatedPairs);
  };
  const addNewKeyValuePair = () => {
    setKeyValuePairs([...keyValuePairs, { key: "", value: "" }]);
  };

  const saveKeyValuePairs = () => {
    let newData = { ...info };
    let flag = false
    if (isEditMode) {
      delete newData[selectedKey]
    }
    if (keyValuePairs?.length > 0) {
      keyValuePairs.forEach(({ key, value }) => {
        if (!key || !value) {
          flag = true
          return;
        }
        if (key) {
          newData[selectedKey] = { ...newData[selectedKey], [key]: value };
        }
      });
    } else {
      newData = { ...newData, [selectedKey]: {} };
    }
    if (flag) {
      return toast.warning("Some of Items or Values are missing")
    }
    setaddInfo(newData);
    add_items(newData);
    setopenadditionalslider(false)
    setKeyValuePairs([{ key: "", value: "" }])
    setisEditMode(false)
  };

  const deleteKeyValue = (index: number) => {
    const data = [...keyValuePairs];
    data.splice(index, 1);
    setKeyValuePairs(data);
  };

  useEffect(() => {
    setEditPermission(
      BROKER?.Claims.edit === undefined ? false : BROKER.Claims.edit
    );
    setStatusPermission(
      BROKER?.Claims.status === undefined ? false : BROKER.Claims.status
    );
  }, []);
  let timeout: any;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    const delayedApiCall = () => {
      get_status();
      get_country_list((cb: any) => {
        setCountry_data(cb);
      });
    }
    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };


  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.get_status(onSuccess, onError);
  };

  const add_items = (newData: any) => {
    let dto = { tpa_claim_detail_info: newData };
    const onSuccess = (res: any) => {
      setInfo(res.data.data.tpa_claim_detail_info)
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.add_items(dto, onSuccess, onError, id);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  let timeoutTwo: any;
  useEffect(() => {

    if (timeoutTwo) {
      clearTimeout(timeoutTwo)
    }
    const delayedApiCall = () => {
      if (detailInfo?.country?.label) {
        get_state_list(detailInfo?.country?.label, (cb: any) => {
          setState_data(cb);
        });
      }
      if (detailInfo?.country?.label && detailInfo?.state?.label) {
        get_city_list_via_name(
          detailInfo?.country?.label,
          detailInfo?.state?.label,
          (cb: any) => {
            setCity_data(cb);
          }
        );
      }

    }

    timeoutTwo = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutTwo) {
        clearTimeout(timeoutTwo);
      }
    };
  }, [detailInfo?.country?.label, detailInfo?.state?.label]);
  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const delayedApiCall = () => {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const id = params.get("id");
      setId(id ?? "");
      if (postQueryForm === false) {
        detail_view_api(id ?? "");
      }
    }
    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [postQueryForm]);

  // function with argument of id for get the detail view data
  const detail_view_api = (id: string) => {
    const onSuccess = (res: any) => {
      setPriorityStatus(res.data.data[0].status);
      setDetailInfo(res.data.data[0]);
      setaddInfo(res.data.data[0].tpa_claim_detail_info);
      setInfo(res.data.data[0].tpa_claim_detail_info);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.get_claim_detail_data(
      id,
      onSuccess,
      onError,
      userDetail?.userType.toLowerCase()
    );
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
      setPostQueryForm(false);
      setNotesData((notesData) => [
        ...notesData,
        { name: userDetail?.username, date: new Date(), description: value },
      ]);
      const onSuccess = (res: any) => { };
      const onError = (err: any) => {
        console.log("err", err);
      };
      Claim_SERVICES.claim_add_notes(
        userType.toLocaleLowerCase(),
        id,
        {
          notes: {
            name: userDetail?.username,
            date: new Date(),
            description: value,
          },
        },
        onSuccess,
        onError
      );
    } else if (attrName === setPriorityStatus) {
      if (value === 51 || value === 67) {
        setstatusWarningPopoup(true);
        setselectedClaimValue(value);
        return;
      }
      update_status(value, id);
      setPriorityStatus(value);
    } else if (attrName === "attachment_deleted") {
      detail_view_api(id ?? "");
    } else if (attrName === "attachment_new_data") {
      claim_attachment(value);
    } else if (attrName[0] === "detailData") {
      if (["balance_si", "claimAmt"]?.includes(attrName[1])) {
        setIndividualKey({
          key: attrName[1],
          value:
            value && value.label
              ? value.label
              : extractNumbersFromString(value),
        });
      } else {
        setIndividualKey({
          key: attrName[1],
          value: value && value.label ? value.label : value,
        });
      }

      setDetailInfo((prev) => ({
        ...prev,
        [attrName[1]]: value,
      }));
      if (attrName[1] === "country") {
        setDetailInfo((prev) => ({
          ...prev,
          country: { label: value.label, id: value.id, warning: false },
          state: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
          city: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
        }));
      } else if (attrName[1] === "state") {
        setDetailInfo((prev) => ({
          ...prev,
          state: { label: value.label, id: value.id, warning: false },
          city: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
        }));
      }
    } else if (attrName === setUploadFile) {
      setUploadFile(value);
    }
  };

  const individualEdit = () => {
    if (individualKey.value === "") {
      toast.error("Enter the value");
      return;
    } else if (
      detailInfo.country?.label === "" ||
      detailInfo.state?.label === "" ||
      detailInfo.city?.label === ""
    ) {
      toast.error("Country State City not be empty");
    } else {
      const onSuccess = (res: any) => {
        if (res.status === 200) {
          toast.success(res.data.message);
        }
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      Claim_SERVICES.individual_edit(id, individualKey, onSuccess, onError);
    }
  };

  const claim_attachment = (value: any) => {
    let dto: any = {
      claimId: id,
      path: "0",
      attachments: {
        doc: value.doc,
        doc_name: value.doc_name,
        description: value.description,
      },
    };
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setUploadFile(false);
        toast.success("File uploaded sucessfully");
        detail_view_api(id ?? "");
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      if (err?.response?.data?.status === "failed") {
        toast.error(
          err.response.data.errors[0]?.param +
          " " +
          err.response.data.errors[0]?.message
        );
      }
    };
    Claim_SERVICES.claim_attachment(
      userType.toLowerCase(),
      dto,
      onSuccess,
      onError
    );
  };

  return (
    <>
      <ConfirmationPopup
        statusWarningPopoup={statusWarningPopoup}
        setstatusWarningPopoup={setstatusWarningPopoup}
        update_status={() => {
          update_status(selectedClaimValue, id);
          setPriorityStatus(selectedClaimValue);
        }}
        title={`Rejecting/Cancelling the claim ${detailInfo?.claimId} will halt the further processing of ${detailInfo?.requestType} for ${detailInfo?.memberId}, ${detailInfo?.memberName} & ${detailInfo?.policyNumber}`}
      />

      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <button
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </button>
            <span className="detailIcon">
              {detailInfo?.clientName?.substring(0, 1)}
            </span>
            <div>
              <h3>
                {detailInfo?.requestType.toLowerCase() === "intimation"
                  ? "Reimbursement"
                  : detailInfo?.requestType}
              </h3>
              <p>
                {detailInfo?.claimId} - Created on:{" "}
                {date_time_format(detailInfo?.createdAt, dateFormat, timeZone)}
              </p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* Status Dropdown */}
            <div id={`_${priorityStatus}`}>
              <div className="status_dropdown" id={`_${priorityStatus}`}>
                {priorityStatus === 47 ? (
                  <Requested id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 48 ? (
                  <SentToTPA id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 49 ? (
                  <Required id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 50 ? (
                  <Approved id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 51 ? (
                  <Rejected id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 52 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 53 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 65 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 66 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 67 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 68 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 69 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 70 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : null}
                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled={
                    !statusPermission || detailInfo.tpaName === "Medi Assist"
                  }
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                {viewTabsStatus === "overview" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "personalDetails"
                          ? "active sectionLink personalDetails"
                          : "sectionLink personalDetails"
                      }
                      onClick={() => {
                        scrollToSection(personalDetails);
                        SetSectionStatus("personalDetails");
                      }}
                    >
                      <span> Member Info</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "memberdetails"
                              ? "inner_link active memberdetails"
                              : "inner_link memberdetails"
                          }
                          onClick={() => {
                            setSubSection("memberdetails");
                            handleClicks(subSection);
                            scrollToSection(memberdetails);
                          }}
                        >
                          Member & Policy Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "hospitalDetails"
                          ? "active sectionLink hospitalDetails"
                          : "sectionLink hospitalDetails"
                      }
                      onClick={() => {
                        scrollToSection(hospitalDetails);
                        SetSectionStatus("hospitalDetails");
                      }}
                    >
                      <span> Hospital Treatment</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "basicdetails"
                              ? "inner_link active basicdetails"
                              : "inner_link basicdetails"
                          }
                          onClick={() => {
                            SetSectionStatus("hospitalDetails");
                            setSubSection("basicdetails");
                            handleClicks(subSection);
                            scrollToSection(basicdetails);
                          }}
                        >
                          Basic Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "claimInfo"
                          ? "active sectionLink claimInfo"
                          : "sectionLink claimInfo"
                      }
                      onClick={() => {
                        scrollToSection(claimInfo);
                        SetSectionStatus("claimInfo");
                      }}
                    >
                      <span> Claim Info</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "claimdetails"
                              ? "inner_link active claimdetails"
                              : "inner_link claimdetails"
                          }
                          onClick={() => {
                            SetSectionStatus("claimInfo");
                            setSubSection("claimdetails");
                            handleClicks(subSection);
                            scrollToSection(claimdetails);
                          }}
                        >
                          Claim Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "docChecklist"
                          ? "active sectionLink docChecklist"
                          : "sectionLink docChecklist"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(docChecklist);
                        SetSectionStatus("docChecklist");
                      }}
                    >
                      <span>Document Checklist</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "attatchmentsdetails"
                          ? "active sectionLink attatchmentsdetails"
                          : "sectionLink attatchmentsdetails"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(attatchmentsdetails);
                        SetSectionStatus("attatchmentsdetails");
                      }}
                    >
                      <span>Attatchments</span>
                    </Link>
                  </>
                )}
                {viewTabsStatus === "additional" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "expenses"
                          ? "active sectionLink expenses"
                          : "sectionLink expenses"
                      }
                      onClick={() => {
                        scrollToSection(expenses);
                        SetSectionStatus("expenses");
                      }}
                    >
                      <span>Expenses</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaDatesInfo"
                          ? "active sectionLink tpaDatesInfo"
                          : "sectionLink tpaDatesInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaDatesInfo);
                        SetSectionStatus("tpaDatesInfo");
                      }}
                    >
                      <span>TPA Dates Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaIdInfo"
                          ? "active sectionLink tpaIdInfo"
                          : "sectionLink tpaIdInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaIdInfo);
                        SetSectionStatus("tpaIdInfo");
                      }}
                    >
                      <span>TPA ID Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaStatusInfo"
                          ? "active sectionLink tpaStatusInfo"
                          : "sectionLink tpaStatusInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaStatusInfo);
                        SetSectionStatus("tpaStatusInfo");
                      }}
                    >
                      <span>TPA Status Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaRemarkInfo"
                          ? "active sectionLink tpaRemarkInfo"
                          : "sectionLink tpaRemarkInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaRemarkInfo);
                        SetSectionStatus("tpaRemarkInfo");
                      }}
                    >
                      <span>TPA Status Information</span>
                    </Link>
                  </>
                )}
                {viewTabsStatus === "notes" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "notes"
                          ? "active sectionLink notes"
                          : "sectionLink notes"
                      }
                      onClick={() => {
                        scrollToSection(notes);
                        SetSectionStatus("notes");
                      }}
                    >
                      <span>Notes</span>
                    </Link>
                  </>
                )}
              </Grid>
              <Grid xs className="rightSection h-100">
                <Box>
                  <Grid container columnSpacing={3} alignItems="center">
                    <Grid
                      lg={7}
                      md={7}
                      xs={12}
                      margin={"12px 0"}
                      className="pl-0"
                    >
                      <div className="productTabs ">
                        <Button
                          className={
                            viewTabsStatus === "overview"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("overview");
                            SetSectionStatus("personalDetails");
                            setSubSection("memberdetails");
                          }}
                        >
                          Overview
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "additional"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("additional");
                            setSubSection("additional");
                            SetSectionStatus("expenses");
                          }}
                        >
                          Additional Details
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "notes"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("notes");
                            setSubSection("notes");
                            SetSectionStatus("notes");
                          }}
                        >
                          Notes
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      margin={"12px 0"}
                      lg={5}
                      md={5}
                      xs={12}
                      style={{ textAlign: "right" }}
                      className="text-sm-center"
                    >
                      <p className="lastupdate">
                        Last Update:{" "}
                        {date_time_format(
                          detailInfo?.updatedAt,
                          dateFormat,
                          timeZone
                        )}
                      </p>
                    </Grid>
                  </Grid>
                  {viewTabsStatus === "overview" && (
                    <>
                      <Box className="detailSection" ref={personalDetails}>
                        <div className="sectionTitle">
                          <h4>Member Info</h4>
                        </div>
                        <div ref={memberdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">
                                Member & Policy Details
                              </h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={4} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Member ID"
                                value={`${detailInfo?.memberId} - ${detailInfo?.memberName}`}
                                attrName={"setMemberId"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>

                            <Grid xs={6} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Client ID"
                                value={`${detailInfo?.clientId} - ${detailInfo.clientName}`}
                                attrName={"setclientId"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            {/* <Grid xs={6} md={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Policy ID"
                              value={detailInfo?.policyId}
                              attrName={setpolicyId}
                              value_update={updateMasterState}
                              warn_status={false}
                              disabled={true}
                            />
                          </Grid> */}
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Policy Number"
                                value={detailInfo?.policyNumber}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Insurer Name"
                                value={detailInfo?.insurer}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="TPA Name"
                                value={detailInfo?.tpaName}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Box>

                      <Box className="detailSection" ref={hospitalDetails}>
                        <div className="sectionTitle">
                          <h4>Hospital/Treatment/Claim Info</h4>
                        </div>
                        <div ref={basicdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">Basic Details</h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Hospital"
                                value={detailInfo?.hospitalName}
                                attrName={"setHospital"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>

                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                title="Country"
                                value={
                                  detailInfo?.country === undefined
                                    ? ""
                                    : detailInfo.country
                                }
                                attrName={["detailData", "country"]}
                                value_update={updateMasterState}
                                data={country_data}
                                warn_status={false}
                                params={individualEdit}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                                disabled={editPermission === false}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                title="State"
                                value={
                                  detailInfo?.state === undefined
                                    ? ""
                                    : detailInfo.state
                                }
                                attrName={["detailData", "state"]}
                                value_update={updateMasterState}
                                data={state_data}
                                warn_status={false}
                                params={individualEdit}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                                disabled={editPermission === false}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                params={individualEdit}
                                title="City"
                                value={
                                  detailInfo?.city === undefined
                                    ? ""
                                    : detailInfo.city
                                }
                                attrName={["detailData", "city"]}
                                value_update={updateMasterState}
                                data={city_data}
                                warn_status={false}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                                disabled={editPermission === false}
                              />
                            </Grid>
                            {/* <Grid sm={6} md={5} lg={3} xl={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Department"
                              value={detailInfo?.department}
                              attrName={"setDepartment"}
                              value_update={updateMasterState}
                              warn_status={false}
                              disabled={true}
                            />
                          </Grid> */}
                            <Grid xs={6} md={6} lg={3}>
                              <DatePicker
                                class_name="inputField"
                                title="Admission Date"
                                value={
                                  detailInfo?.admisionDate === undefined
                                    ? null
                                    : detailInfo.admisionDate
                                }
                                attrName={"setAdmissionDate"}
                                value_update={updateMasterState}
                                error_message="Please enter admission date"
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <DatePicker
                                class_name="inputField"
                                title="Discharge Date"
                                value={
                                  detailInfo?.dischargeDate === undefined
                                    ? null
                                    : detailInfo.dischargeDate
                                }
                                attrName={"setDischargeDate"}
                                value_update={updateMasterState}
                                error_message="Please enter admission date"
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Illness Reason for Hospitalisation/Claim"
                                value={
                                  detailInfo?.reasonForHospitalization ||
                                  detailInfo?.reason
                                }
                                attrName={"setIllness"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="Checkbox">
                                <input
                                  type="checkbox"
                                  name="check"
                                  checked={detailInfo?.firStatus}
                                />
                                <label className="employee"></label>
                              </div>
                              <h5 className="mb-0">
                                If it is a legal case, kindly submit the FIR
                                Report
                              </h5>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      
                      <Box className="detailSection" ref={claimInfo}>
                        <div className="sectionTitle">
                          <h4>Claim Info</h4>
                        </div>
                        <div ref={claimdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={6}>
                              <h5 className="subHeading">Claim Details</h5>
                            </Grid>
                            {detailInfo?.tpaData.integrated && (
                              <Grid
                                xs={6}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                {/* Tpa Status Dropdown */}
                                TPA Claim Status
                                <div
                                  id={`_${priorityStatus}`}
                                  style={{ marginLeft: 10 }}
                                >
                                  <div
                                    className="status_dropdown disabled_sd"
                                    id={`_${priorityStatus}`}
                                  >
                                    {priorityStatus === 47 ? (
                                      <Requested
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 48 ? (
                                      <SentToTPA
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 49 ? (
                                      <Required
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 50 ? (
                                      <Approved
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 51 ? (
                                      <Rejected
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 52 ? (
                                      <Expired
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 53 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 65 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 66 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 67 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 68 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 69 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : priorityStatus === 70 ? (
                                      <PartialApproval
                                        id={`_${priorityStatus.toString()}`}
                                      />
                                    ) : null}
                                    <SelectDropdown
                                      class_name="inputFieldd"
                                      title=""
                                      value={detailInfo?.tpa_status}
                                      attrName={setPriorityStatus}
                                      value_update={updateMasterState}
                                      dropdown_data={[
                                        {
                                          label: detailInfo?.tpa_status,
                                          id: detailInfo?.tpa_status,
                                        },
                                      ]}
                                      warn_status={false}
                                      disabled={editPermission === false}
                                    />
                                  </div>
                                </div>
                              </Grid>
                            )}
                          </Grid>

                          {/* <Grid xs={6} md={6} lg={3}>
                          <RKTextField
                            class_name="inputField"
                            validation_type="name"
                            title="TPA Claim Status"
                            value={detailInfo?.tpa_status}
                            attrName={["detailData", "tpa_status"]}
                            value_update={updateMasterState}
                            warn_status={false}
                            //params={individualEdit}
                            disabled={editPermission === false}
                          />
                        </Grid> */}
                          <Grid container spacing={3}>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                title="Claim Refrence Number"
                                value={detailInfo?.reference_no}
                                attrName={["detailData", "reference_no"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                                info_status
                                params={individualEdit}
                                info_message="Generated through TPA"
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                title="Balance SI"
                                value={formatCurrency(
                                  extractNumbersFromString(
                                    detailInfo?.balance_si ?? "0"
                                  )
                                )}
                                attrName={["detailData", "balance_si"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                params={individualEdit}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              // disabled={true || editPermission === false}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                title="Requested Claim Amount"
                                value={formatCurrency(
                                  extractNumbersFromString(
                                    String(detailInfo?.claimAmt || "0")
                                  )
                                )}
                                attrName={["detailData", "claimAmt"]}
                                value_update={updateMasterState}
                                params={individualEdit}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                validation_type="numeric"
                                class_name="inputField"
                                title="Paid Claim Amount"
                                value={formatCurrency(
                                  Number(detailInfo?.paid?.toString())
                                )}
                                attrName={["detailData", "paid"]}
                                value_update={updateMasterState}
                                params={individualEdit}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                validation_type="numeric"
                                class_name="inputField"
                                title="Outstanding Claim Amount"
                                value={formatCurrency(
                                  Number(detailInfo?.outstanding?.toString())
                                )}
                                attrName={["detailData", "outstanding"]}
                                params={individualEdit}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                title="Transaction ID"
                                value={detailInfo?.transactionId}
                                params={individualEdit}
                                attrName={["detailData", "transactionId"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                validation_type="name"
                                title="Bank Info"
                                value={detailInfo.branchName}
                                params={individualEdit}
                                attrName={["detailData", "branchName"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                validation_type="name"
                                title="Remarks"
                                value={detailInfo?.remarks}
                                params={individualEdit}
                                attrName={["detailData", "remarks"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={12} lg={6}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                validation_type="name"
                                title="Ailment"
                                value={detailInfo?.ailment}
                                attrName={["detailData", "ailment"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                params={individualEdit}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={12} lg={6}>
                              <RKTextFieldDisable
                                class_name="inputField"
                                validation_type="name"
                                title="Deficiency IR Requirment"
                                value={detailInfo?.deficiency_ir_req}
                                attrName={["detailData", "deficiency_ir_req"]}
                                value_update={updateMasterState}
                                warn_status={false}
                                params={individualEdit}
                                disabled={
                                  editPermission === false ||
                                  (detailInfo?.tpaData &&
                                    detailInfo?.tpaData.integrated === true)
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Box>

                      <Box ref={docChecklist}>
                        {/* If the role is viewOnly, always pass the checklist prop */}
                        <DocumentChecklistView
                          type="tpa"
                          role="viewOnly"
                          checklist={detailInfo?.tpaData?.documentChecklist}
                        />
                      </Box>

                      <Box className="detailSection" ref={attatchmentsdetails}>
                        <div className="sectionTitle">
                          <h4>Bills & Attachments </h4>

                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{ textDecoration: "none", cursor: "pointer" }}
                              onClick={() => {
                                if (editPermission === false) {
                                  return toast.error("Permissions Not Allowed");
                                }
                                setUploadFile(true);
                              }}
                            >
                              Add Document
                            </Link>
                          </div>
                        </div>
                        <Attachment
                          open_status={attachment}
                          attrName={setAttachment}
                          value_update={updateMasterState}
                          dataArray={detailInfo?.upload}
                          additionalDoc={detailInfo?.firDocument}
                          firStatus={detailInfo?.firStatus}
                          deleteurl={Claim_SERVICES.delete_attachment_claim}
                          disabled={editPermission === false}
                        />
                      </Box>
                    </>
                  )}
                  {viewTabsStatus === "additional" && (
                    <>
                      <Box className="detailSection" ref={expenses}>
                        <div className="sectionTitle">
                          <h4>Expenses</h4>
                          {!detailInfo?.tpaData.integrated && (
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setselectedKey("expenses");
                                  setopenadditionalslider(true);
                                }}
                              >
                                Add Expenses
                              </Link>
                              {info?.expenses &&
                                Object.keys(info?.expenses).length > 0 && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedKey("expenses");
                                      setKeyValuePairs(
                                        objectToKeyValueArray(info.expenses)
                                      );
                                      setopenadditionalslider(true);
                                      setisEditMode(true);
                                      setaddInfo({
                                        expenses: {},
                                        important_dates: {},
                                        important_ids: {},
                                        important_status: {},
                                        remarks: {},
                                      });
                                    }}
                                  >
                                    Edit Expenses
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table ">
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.expenses &&
                                    Object.entries(info?.expenses)?.map(
                                      ([keys, values]: [any, any]) => {
                                        let data = keys
                                          ?.split("_")
                                          ?.map(
                                            (word: any) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          );
                                        let final = data.join(" ");
                                        return (
                                          <ul className="subheading">
                                            <li>{final}</li>
                                            <li className="title">
                                             
                                              {/* {formatCurrency(values)} */}
                                              {
                                                 formatCurrency(extractNumbersFromString(values))
                                              }
                                            </li>
                                          </ul>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaDatesInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Dates Information</h4>
                          {!detailInfo?.tpaData.integrated && (
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{ textDecoration: "none", cursor: "pointer" }}
                                onClick={() => {
                                  setselectedKey("important_dates");
                                  setopenadditionalslider(true);
                                }}
                              >
                                Add Dates
                              </Link>
                              {info?.important_dates &&
                                Object.keys(info?.important_dates).length > 0 && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedKey("important_dates");
                                      setKeyValuePairs(
                                        objectToKeyValueArray(
                                          info.important_dates
                                        )
                                      );
                                      setopenadditionalslider(true);
                                      setisEditMode(true);
                                      setaddInfo({
                                        expenses: {},
                                        important_dates: {},
                                        important_ids: {},
                                        important_status: {},
                                        remarks: {},
                                      });
                                    }}
                                  >
                                    Edit Dates
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table">
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_dates &&
                                    Object.entries(info?.important_dates)?.map(
                                      ([keys, values]: [any, any]) => {
                                        let data = keys
                                          ?.split("_")
                                          ?.map(
                                            (word: any) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          );
                                        let final = data.join(" ");
                                        return (
                                          <ul className="subheading">
                                            <li>{final}</li>
                                            <li className="title">
                                              {values != ""
                                                ? date_time_format(
                                                  moment(
                                                    values,
                                                    "DD/MM/YYYY HH:mm:ss"
                                                  ).toDate(),
                                                  dateFormat,
                                                  timeZone
                                                )
                                                : "--"}
                                            </li>
                                          </ul>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA ID Information</h4>
                          {!detailInfo?.tpaData.integrated && (
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{ textDecoration: "none", cursor: "pointer" }}
                                onClick={() => {
                                  setselectedKey("important_ids");
                                  setopenadditionalslider(true);
                                }}
                              >
                                Add ID
                              </Link>
                              {info?.important_ids &&
                                Object.keys(info?.important_ids).length > 0 && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedKey("important_ids");
                                      setKeyValuePairs(
                                        objectToKeyValueArray(info.important_ids)
                                      );
                                      setopenadditionalslider(true);
                                      setisEditMode(true);
                                      setaddInfo({
                                        expenses: {},
                                        important_dates: {},
                                        important_ids: {},
                                        important_status: {},
                                        remarks: {},
                                      });
                                    }}
                                  >
                                    Edit ID
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table">
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_ids &&
                                    Object.entries(info?.important_ids)?.map(
                                      ([keys, values]: [any, any]) => {
                                        let data = keys
                                          ?.split("_")
                                          ?.map(
                                            (word: any) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          );
                                        let final = data.join(" ");
                                        return (
                                          <ul className="subheading">
                                            <li>{final}</li>
                                            <li className="title">{values}</li>
                                          </ul>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Status Information</h4>
                          {!detailInfo?.tpaData.integrated && (
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{ textDecoration: "none", cursor: "pointer" }}
                                onClick={() => {
                                  setselectedKey("important_status");
                                  setopenadditionalslider(true);
                                }}
                              >
                                Add Status
                              </Link>
                              {info?.important_status &&
                                Object.keys(info?.important_status).length >
                                0 && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedKey("important_status");
                                      setKeyValuePairs(
                                        objectToKeyValueArray(
                                          info.important_status
                                        )
                                      );
                                      setopenadditionalslider(true);
                                      setisEditMode(true);
                                      setaddInfo({
                                        expenses: {},
                                        important_dates: {},
                                        important_ids: {},
                                        important_status: {},
                                        remarks: {},
                                      });
                                    }}
                                  >
                                    Edit Status
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table">
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_status &&
                                    Object.entries(info?.important_status)?.map(
                                      ([keys, values]: [any, any]) => {
                                        let data = keys
                                          ?.split("_")
                                          ?.map(
                                            (word: any) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          );
                                        let final = data.join(" ");
                                        return (
                                          <ul className="subheading">
                                            <li>{final}</li>
                                            <li className="title">{values}</li>
                                          </ul>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Remarks</h4>
                          {!detailInfo?.tpaData.integrated && (
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{ textDecoration: "none", cursor: "pointer" }}
                                onClick={() => {
                                  setselectedKey("remarks");
                                  setopenadditionalslider(true);
                                }}
                              >
                                Add Remarks
                              </Link>
                              {info?.remarks &&
                                Object.keys(info?.remarks).length > 0 && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setselectedKey("remarks");
                                      setKeyValuePairs(
                                        objectToKeyValueArray(info.remarks)
                                      );
                                      setopenadditionalslider(true);
                                      setisEditMode(true);
                                      setaddInfo({
                                        expenses: {},
                                        important_dates: {},
                                        important_ids: {},
                                        important_status: {},
                                        remarks: {},
                                      });
                                    }}
                                  >
                                    Edit Remarks
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table">
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.remarks &&
                                    Object.entries(info?.remarks)?.map(
                                      ([keys, values]: [any, any]) => {
                                        let data = keys
                                          ?.split("_")
                                          ?.map(
                                            (word: any) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          );
                                        let final = data.join(" ");
                                        return (
                                          <ul className="subheading">
                                            <li>{final}</li>
                                            <li className="title">{values}</li>
                                          </ul>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </>
                  )}
                  {viewTabsStatus === "notes" && (
                    <>
                      <Box className="detailSection" ref={notes}>
                        <div className="sectionTitle">
                          <h4>Notes</h4>
                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{ textDecoration: "none", cursor: "pointer" }}
                              onClick={() => {
                                if (editPermission === false) {
                                  return toast.error("Permissions Not Allowed");
                                }
                                setPostQueryForm(true);
                              }}
                            >
                              Add Notes
                            </Link>
                          </div>
                        </div>

                        <NotesSection data={detailInfo?.notes} />
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />

        <SlidingPanel type={"right"} isOpen={openadditionalslider} size={75}>
          <div>
            <Grid container spacing={3} alignItems="center">
              <Grid xs={10}>
                <h4 className="mb-0 mt-3">
                  {" "}
                  {isEditMode ? "Edit" : "Add"}{" "}
                  {capitalize(selectedKey.replaceAll("important_", ""))}
                </h4>
              </Grid>
              <Grid xs={2} textAlign="right" className="mt-4">
                <GridCloseIcon
                  onClick={() => {
                    setopenadditionalslider(false);
                    setKeyValuePairs([{ key: "", value: "" }]);
                    setisEditMode(false);
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
              <Grid xs={12} textAlign={"end"} className="ctaBtn">
                <Link
                  underline="none"
                  className="blueBtn addfile"
                  onClick={addNewKeyValuePair}
                >
                  Add More
                </Link>
              </Grid>
              <Grid xs={12}>
                <Box className="scrollable_area">
                  <Grid container padding={0} columnSpacing={3} rowSpacing={3}>
                    {keyValuePairs?.map((pair, index) => (
                      <React.Fragment key={index}>
                        <Grid xs={5.5}>
                          <RKTextField
                            class_name="inputField"
                            title="Item"
                            value={pair.key}
                            attrName="setKey"
                            value_update={(attrName: any, newValue: string) =>
                              handleInputChange(index, "key", newValue)
                            }
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={5.5}>
                          {[
                            "expenses",
                            "important_ids",
                            "important_status",
                            "remarks",
                          ].includes(selectedKey) ? (
                            <RKTextField
                              class_name="inputField"
                              title="Value"
                              value={pair.value}
                              attrName="setValue"
                              value_update={(attrName: any, newValue: string) =>
                                handleInputChange(index, "value", newValue)
                              }
                              warn_status={false}
                              disabled={!pair.key}
                              validation_type={
                                selectedKey === "expenses" ? "numeric" : ""
                              }
                            />
                          ) : (
                            <DatePicker
                              class_name="inputField"
                              title="Choose Date"
                              value={moment(
                                pair.value,
                                "DD/MM/YYYY HH:mm:ss"
                              ).toDate()}
                              attrName={"setValue"}
                              value_update={(
                                attrName: any,
                                newValue: string
                              ) => {
                                let dateValue =
                                  moment(newValue).format("DD/MM/YYYY");
                                handleInputChange(
                                  index,
                                  "value",
                                  dateValue.toString()
                                );
                              }}
                              error_message=""
                              warn_status={false}
                              required
                              disabled={!pair.key}
                            />
                          )}
                        </Grid>
                        {(isEditMode || index > 0) && (
                          <Grid xs={1}>
                            <Link
                              underline="none"
                              className="redBtn delete ml-0"
                              onClick={() => {
                                deleteKeyValue(index);
                              }}
                            ></Link>
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} className="footer_sec w-100">
                <Button
                  variant="contained"
                  className="save_btn"
                  onClick={saveKeyValuePairs}
                >
                  {isEditMode ? "Update" : "Submit"}
                </Button>
              </Grid>
            </Grid>

            <div className="scrollable_area mt-6">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                className="mb-3"
              >
                <Grid xs={12} className="textAreaSection"></Grid>
              </Grid>
            </div>
          </div>
        </SlidingPanel>

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={removeStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setRemoveStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are want to remove this Claim.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
export default ClaimAssistantDetail;
