import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PoliciesWarningpopup from "../../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { DEPARTMENT_SERVICES } from "../../../../../../Services/customModule/departmentServices";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  date_time_format,
  generateModuleId,
} from "../../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import "../../OrganisationSettings/Currencies/Currencies.scss";
import DepartmentInformation from "./DepartmentInformation/DepartmentInformation";
import EditDepartmentInformation from "./EditDepartmentInformation/EditDepartmentInformaion";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

const Department = () => {
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [departmentInformation, setDepartmentInformation] = useState(false);
  const [editdepartmentInformation, setEditDepartmentInformation] =
    useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [isDepartmentAssigned, setIsDepartmentAssigned] = useState(false);
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [editStatus, setEditStatus] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showloader, setshowloader] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setaddPermission] = useState(false);
  const [editPermission, seteditPermission] = useState(false);
  const [removePermission, setremovePermission] = useState(false);

  useEffect(() => {
    get_status();
    setaddPermission(
      BROKER?.Department?.add === undefined ? false : BROKER?.Department?.add
    );
    setremovePermission(
      BROKER?.Department?.remove === undefined
        ? false
        : BROKER?.Department?.remove
    );
    seteditPermission(
      BROKER?.Department?.edit === undefined ? false : BROKER?.Department?.edit
    );
  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setColumns([
        {
          field: "departmentId",
          headerName: "Department ID",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  color: "#6b62dc",
                  textDecoration: "none",
                }}
              >
                {generateModuleId("DP", row.id?.toString())}
              </p>
            );
          },
        },
        {
          field: "departmentName",
          headerName: "Department Name",
          width: 300,
          check_status: true,
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 9 ? (
                    <CheckCircleIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 10 ? (
                    <HighlightOffIcon id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!editPermission}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Created on",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          headerName: "Updated on",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    DEPARTMENT_SERVICES.get_status(onSuccess, onError);
  };

  useEffect(() => {
    let departmentAssigned = false;
    array_delete?.forEach((item: any, index: number) => {
      const onSuccess = (res: any) => {
        if (!departmentAssigned) {
          departmentAssigned = res.data.data.is_exist;
          setIsDepartmentAssigned(departmentAssigned);
        }
      };
      const onError = (err: any) => {
        console.log("false", err);
      };
      if (!departmentAssigned) {
        DEPARTMENT_SERVICES.isBeingUsed(onSuccess, onError, item);
      }
    });
  }, [array_delete]);

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_department_list_data();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    DEPARTMENT_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditStatus(true);
    } else if (array_delete.length > 1) {
      setEditStatus(true);
    } else {
      get_indivisual_department_data();
      setEditDepartmentInformation(true);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "createDepartment") {
      setDepartmentInformation(false);
    }
    if (attrName === "selected_data") {
      setArray_Delete(value);
    }
    if (attrName === "EditcloseButton") {
      setEditDepartmentInformation(false);
    }
    if (attrName[0] === "updatestatus") {
      // update_status(value, attrName[1]._id)
      isBeingUsed(attrName[1]._id, value);
    }
  };

  //get department list data
  const get_department_list_data = (limit?: number, skip?: number) => {
    const onSuccess = (res: any) => {
      setDepartmentList(res.data.data.data);
      setTotalRecords(res.data.data.countData);
      setshowloader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setshowloader(false);
    };
    setshowloader(true);
    DEPARTMENT_SERVICES.get_department_list_data(
      limit || 25,
      skip || 0,
      onSuccess,
      onError
    );
  };

  //indivisual Branches data
  const get_indivisual_department_data = () => {
    const onSuccess = (res: any) => {
      setDepartmentName(res.data.data.departmentName);
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    DEPARTMENT_SERVICES.department_detail_view(
      onSuccess,
      onError,
      array_delete[0]
    );
  };

  const deleteditem = () => {
    setDeleteStatusStep(1);
    setDeleteStatus(false);
  };

  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Department is being used,Can't Inactive it. ");
      } else {
        update_status(value, id);
      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    DEPARTMENT_SERVICES.isBeingUsed(onSuccess, onError, id);
  };

  //delete Branches lis
  const delete_department_list_data = () => {
    let body: any = { ids: array_delete };
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    DEPARTMENT_SERVICES.delete_departments(body, onSuccess, onError);
  };

  useEffect(() => {
    get_department_list_data();
  }, [deleteStatus, editdepartmentInformation, departmentInformation]);

  return (
    <>
      {showloader && <NewLoader />}
      <Grid container spacing={2} className="currunciesWrapper">
        <DepartmentInformation
          open_status={departmentInformation}
          attrName={setDepartmentInformation}
          value_update={updateMasterState}
        />
        <EditDepartmentInformation
          open_status={editdepartmentInformation}
          attrName={setEditDepartmentInformation}
          value_update={updateMasterState}
          departmentName={departmentName}
          departmentId={array_delete[0]}
        />
        <Grid xs={4}>
          <h3 className="mb-0">Department</h3>
        </Grid>

        <Grid xs={8} className="text-right">
          <div className="buttons">
            {addPermission && (
              <Link
                underline="none"
                className="greenBtn addfile"
                onClick={() => {
                  setDepartmentInformation(true);
                }}
              >
                Add
              </Link>
            )}
            {editPermission && (
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={() => {
                  edit_navigate();
                }}
              >
                Edit
              </Link>
            )}
            {removePermission && (
              <Link
                underline="none"
                className={
                  isDepartmentAssigned ? `redBtnlight delete` : `redBtn delete`
                }
                onClick={() => {
                  isDepartmentAssigned
                    ? setwarningpopup(true)
                    : setDeleteStatus(true);
                }}
              >
                Remove
              </Link>
            )}
          </div>
        </Grid>
        <Grid xs={12}>
          <div style={{ height: 400 }}>
            <UGTable
              header_data={columns}
              data={departmentList}
              value_update={updateMasterState}
              attrName={"UGTable"}
              BE_filter_code={E_BE_FILTER_CODE.DEPARTMENT}
              refetch_data={get_department_list_data}
              setTableLimit={setTableLimit}
              setTableSkip={setTableSkip}
              totalRecords={totalRecords}
              // paginationMode="client"
              class_Name={"th-setting"}
            />
          </div>
        </Grid>
        <PoliciesWarningpopup
          popupStatus={warningpopup}
          setPopupStatus={setwarningpopup}
          title={`One of the Department is assigned to the user, so you will not be able to delete that Department`}
        />
        {/* Remove pop up */}
        <Modal open={deleteStatus} className="modalWrapper">
          <>
            {deleteStatusStep === 1 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12} sx={{ width: "100%" }}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_deleteicon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <h5 className="popup-heading">
                        Are you sure you want
                        <br /> to delete this list?
                      </h5>
                    )}
                  </Grid>
                  <Grid xs={12} sx={{ width: "100%" }}>
                    <Link className="md-link mb-10" underline="none">
                      {array_delete?.length === 0
                        ? "Please Select the department"
                        : `You are selected ${array_delete?.length} to delete`}
                    </Link>
                  </Grid>
                  {array_delete?.length === 0 ? (
                    ""
                  ) : (
                    <Grid
                      xs={12}
                      sx={{ width: "100%" }}
                      className="ctaBtn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={delete_department_list_data}
                      >
                        Yes, Delete
                      </Button>
                      <Button
                        onClick={() => setDeleteStatus(false)}
                        variant="contained"
                        className="cancelBtn"
                      >
                        cancel
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : deleteStatusStep === 2 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12} sx={{ width: "100%" }}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletePopup_tickicon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    <h5 className="popup-heading">Deleted Successfully!</h5>
                  </Grid>
                  <Grid xs={12} sx={{ width: "100%" }}>
                    <Link className="md-link mb-10" underline="none">
                      Your Department has been Deleted
                    </Link>
                  </Grid>
                  <Grid
                    xs={12}
                    className="ctaBtn"
                    sx={{ width: "100%" }}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      //onClick={deleteditem}
                      variant="contained"
                      className="submitBtn"
                      onClick={deleteditem}
                    >
                      Okay
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        </Modal>

        {/* Remove pop up */}
        <Modal open={editStatus} className="modalWrapper">
          <>
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12} sx={{ width: "100%" }}>
                  <Link
                    className="close-button"
                    onClick={() => setEditStatus(false)}
                  />
                  <Link className="md-link mb-10" underline="none">
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_edit_icon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0
                      ? "Please Select the Department"
                      : `You can't select ${array_delete?.length} Departments to edit`}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        </Modal>
      </Grid>
    </>
  );
};

export default Department;
