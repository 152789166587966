import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, TextField } from "@mui/material";
import SlidingPanel from "react-sliding-side-panel";
import { TextareaAutosize } from "@material-ui/core";
import { allowFileExtention, uploadImage } from "../../../Supporting files/HelpingFunction";
import { GridCloseIcon } from "@mui/x-data-grid";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";
import { toast } from "react-toastify";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  length: any;
  id: any;
}

const UploadAttachmentClientDetails: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  length,
  id,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
  });

  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    attrName(value);
  };

  const attachment_submission = () => {
    if (disableSave) return;
    const data = { ...displayData };
    data.warning = !displayData.doc?.trim();
    data.warnDescription = !displayData.description?.trim();
    let attach: any = { ...data };
    const date = new Date();

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();
    let currentDate: any = `${day}-${month}-${year}`;
    let modifyAttach: any = {
      doc: attach.doc,
      description: attach.description,
      date: currentDate,
      time: new Date().toLocaleTimeString(),
      doc_name: attach.doc_name,
    };

    setDisplayData(data);

    const onSuccess = (res: any) => {
      value_update(attrName, false);
      setDisplayData({
        doc: "",
        warning: false,
        description: "",
        warnDescription: false,
        doc_name: "",
      })
      setDisableSave(false);
    };
    const onError = (err: any) => {
      setDisableSave(false);
      console.log(".....err", err);
    };
    if (data.warning === false && data.warnDescription === false) {
      setDisableSave(true);
      CLIENT_SERVICES.client_indivisualEdit(
        { key: "attachments." + length, value: modifyAttach },
        onSuccess,
        {},
        onError,
        id
      );
    }
  };
  const cross_Icon = () => {
    value_update(attrName, false);
    setDisplayData({
      doc: "",
      warning: false,
      description: "",
      warnDescription: false,
      doc_name: '',
    });
  };

  return (
    <Box className="flter_none">
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="sliding-panel" 

      >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3 text-left">Upload your Documents</h4>
          </Grid>
          <Grid xs={2} sx={{ textAlign: "end" }} className="mt-3">
            <GridCloseIcon onClick={cross_Icon} />
          </Grid>
          <Grid xs={12} className="mb-4 pt-0">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <div>
            {/* <Grid container spacing={3}>
                  <h5 className="popup-heading mb-4">
                    Upload Employees in Bulk
                  </h5>
                </Grid>
              </Grid> */}
            <Grid container spacing={0} justifyContent="center">
              <Grid xs={10} className="inputField uploadFile">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="textarea"
                  value={displayData.doc_name}
                  disabled
                />
              </Grid>
              <Grid xs={2}>
                <Button
                  variant="contained"
                  className="browsebtn"
                  component="label">
                  Browse
                  <input
                    hidden
                    multiple
                    type="file"
                    style={{ zIndex: "99" }}
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      const fileSize = file?.size;
                      const maxSizeBytes = 8 * 1024 * 1024;
                      if (fileSize && fileSize > maxSizeBytes) {
                        toast.warn("File size exceeds 8 MB limit")
                        return e.target.value = null;
                      }
                      if (file) {
                         const allowExt = allowFileExtention(file)
                        if(!allowExt){
                          toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                          return (e.target.value = null);
                        }
                        uploadImage(
                          file,
                          (base64Image: any, base64String: any) => {
                            setDisplayData((prevDisplayData: any) => ({
                              ...prevDisplayData,
                              warning: false,
                              doc: base64Image,
                              doc_name: e.target.files[0]?.name,
                              description: prevDisplayData.data,
                              warnDescription: false,
                            }));
                          }
                        );
                      }

                    }}
                  />
                </Button>
                {displayData.warning ? (
                  <span className="textAreaerror" style={{ color: '#eb5757', fontSize: '11px', display: "flex", justifyContent: "end", }}>
                    Upload File
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid xs={12} >
                <div className="mt-1" style={{
                  color: '#219653',
                  fontSize: '0.75rem',
                  fontWeight: 500
                }}>Maximum size of Attachment is 8 MB*</div>
              </Grid>
            </Grid>
            <Grid xs={12} className="textAreaSection">
              <TextareaAutosize
                value={displayData.description}
                onChange={(e) => {
                  const value = e.target.value;
                  const isEmpty = value?.trim()?.length === 0;

                  setDisplayData((prevDisplayData: any) => ({
                    ...prevDisplayData,
                    description: value,
                    warnDescription: isEmpty,
                  }));
                }}
                className="text_area"
                aria-label="empty textarea"
                placeholder="Description"
              />
              {displayData.warnDescription &&
                <span
                  className="textAreaerror"
                  style={{
                    color: '#eb5757', fontSize: '11px',
                    display: "flex",
                    justifyContent: "end",
                  }}>
                  Enter Description
                </span>
              }
            </Grid>
            <Grid container spacing={3}>
              <Grid
                xs={12}
                className="ctaBtn"
                style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className="submitBtn fixedbtn" disabled={disableSave}
                  onClick={attachment_submission}>
                  Upload
                </Button>
              </Grid>
            </Grid>
          </div>


        </div>
      </div>
    </SlidingPanel>
    </Box>
  );
};

export default UploadAttachmentClientDetails;
