import { FormControlLabel, Link, Radio, RadioGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../APIVar/EmployeeUrlConstant";
import { EMPLOYEE_POLICY_SERVICES } from "../../../Services/Employee/EmployeePolicyService";
import DatePicker from "../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import { useAppSelector } from "../../../Store/hooks";
import { extractNumbersFromString } from "../../../Supporting files/HelpingFunction";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  apiUrl: string;
  policyId: string;
  memberId: string;
  policyBeingWorkedOn: any;
  callApi?: Function;
  policyMember?: any;
}

const AddMember: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  apiUrl,
  policyId,
  memberId,
  policyBeingWorkedOn,
  callApi,
  policyMember,
}) => {
  const { userType, client_id, clientId, Client } = useAppSelector(    (state: any) => state.userDetailsSlice  );
  const baseLocation: any = useAppSelector((state) => state.userDetailsSlice);
  const [toggle, setToggle] = useState(true);
  const [formValue, setFormValue] = useState({
    fullName: { value: "", warning: false },
    relationEmployee: { value: "", warning: false },
    date: { value: null, warning: false },
    familyMember: { value: "", warning: false },
  });
  const [relationEmployees_data, SetrelationEmployees_data] = useState<any[]>(
    []
  );
  const allRelationValues = [
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Father-In-Law", value: "Father-In-Law" },
    { key: "Mother-In-Law", value: "Mother-In-Law" },
  ];
  const [selectFamily_data, setSelectFamily_data] = useState([]);
  const [allFmlyMembers, setAllFmlyMembers] = useState([]);
  const alreadyAddedNames = new Set(
    policyMember?.map((member: any) => member.fullName)
  );
  useEffect(() => {
    const allowedRelations = allRelationValues.filter((listData) => {
      if (listData.key === "Son" || listData.key === "Daughter") {
        return true;
      } else {
        return (
          Array.isArray(allFmlyMembers) &&
          !allFmlyMembers.some(
            (memberData: any) =>
              memberData.relationWithEmployee === listData.key ||
              memberData.relationShip === listData.key ||
              memberData.relation === listData.key
          )
        );
      }
    });
    SetrelationEmployees_data(allowedRelations);
  }, [allFmlyMembers]);

  useEffect(() => {
    if (open_status) {
      get_family_list();
    }
  }, [open_status]);

  const get_family_list = () => {
    const onSucces = (res: any) => {
      setAllFmlyMembers(res.data.data[0].family);
      let data = res.data.data[0].family
        .filter((element: any) => !alreadyAddedNames.has(element.fullName)) // Filter out names already in the added members
        .map((element: any) => ({
          key: element._id,
          value: element.fullName,
          relationWithEmployee: element.relationWithEmployee,
          dob: element.dob,
        }));
      setSelectFamily_data(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_POLICY_SERVICES.get_member_list(
      onSucces,
      onError,
      userType.toLowerCase()
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "formValue") {
      setFormValue((prev) => ({
        ...prev,
        [attrName[1]]: { value: value, warning: false },
      }));
      if (attrName[1] === "familyMember") {
        selectFamily_data.forEach((el: any) => {
          if (el.key === value) {
            setFormValue((prev) => ({
              ...prev,
              relationEmployee: {
                value: el.relationWithEmployee,
                warning: false,
              },
              date: {
                value: el.dob,
                warning: false,
              },
            }));
          }
        });
      }
    } else {
      attrName(value);
    }
  };

  const CreateEndorsementForNomineeAndMember = (
    endorsementFor: string,
    depId?: string,
    multiAssignedPolicy?: boolean,
    policyId?: string,
    policyNumber?: string,
    memberIndentification?: string
  ) => {
    let data = {
      memberId: depId,
      member: memberIndentification || memberId,
      policyId: multiAssignedPolicy ? policyId : policyBeingWorkedOn.policyId,
      policy: multiAssignedPolicy
        ? policyNumber
        : policyBeingWorkedOn.policyNumber,
      clientId: client_id || clientId,
      client: Client,
      type: endorsementFor.includes("Member") ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor: endorsementFor,
      old: "",
      new: { dob: "" },
      attachment: {},
    };
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.CreateEndorsementForNominee(
      onSuccess,
      onError,
      data,
      userType.toLocaleLowerCase()
    );
  };
  const checkAllowedParentOrParentsInLaw = (addUser: string) => {
    let allowed = true;
    const { setOfParents } = policyBeingWorkedOn?.planType;
    if (setOfParents === "both") return true;
    if (addUser === "Mother-In-Law" || addUser === "Father-In-Law") {
      let isParentPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return (
            data.relationWithEmployee === "Mother" ||
            data.relationWithEmployee === "Father"
          );
        }
      );
      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    } else if (addUser === "Mother" || addUser === "Father") {
      let isParentPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return (
            data.relationWithEmployee === "Mother-In-Law" ||
            data.relationWithEmployee === "Father-In-Law"
          );
        }
      );
      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    }

    return allowed;
  };

  const checkMaxAgeForChildren = (
    maxChildAge: string,
    selectedMemberDOB: any,
    selectedRelation: string
  ) => {
    if (selectedRelation === "Son" || selectedRelation === "Daughter") {
      const dobDate = new Date(selectedMemberDOB ? selectedMemberDOB : "");
      const ageInYears = getYearsDiff(dobDate);
      let maxage: number = +extractNumbersFromString(maxChildAge);
      if (ageInYears > maxage) {
        toast.warning(`Permissible maximum age for children is ${maxChildAge}`);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };


  const formSubmit = () => {
    let parm = {};
    let apiUrl;
    if (toggle === true) {
      let data = { ...formValue };
      data = {
        ...data,
        familyMember: {
          ...data.familyMember,
          warning: data.familyMember.value === "",
        },
      };
      setFormValue({ ...data });
      if (!data.familyMember.warning) {
        parm = {
          memberId: formValue.familyMember.value,
          policyId: policyId,
        };
        apiUrl = `${EMPLOYEE_URL_CONSTANTS.add_member}employeeId=${memberId}&code=0`;
      }
    } else {
      let data = { ...formValue };
      data = {
        ...data,
        fullName: { ...data.fullName, warning: data.fullName.value === "" },
        date: { ...data.date, warning: data.date.value === null },
        relationEmployee: {
          ...data.relationEmployee,
          warning: data.relationEmployee.value === "",
        },
      };
      setFormValue({ ...data });
      if (
        !data.fullName.warning &&
        !data.date.warning &&
        !data.relationEmployee.warning
      ) {
        parm = {
          members: [
            {
              fullName: data.fullName.value,
              relationWithEmployee: data.relationEmployee.value,
              dob: data.date.value,
            },
          ],
          policyId: policyId,
          attachedClient: baseLocation.clientId,
        };
        apiUrl = `${EMPLOYEE_URL_CONSTANTS.add_member}code=1&employeeId=${memberId}`;
      }
    }
    const onSucces = (res: any) => {
      value_update(attrName, false);
      toast.success(res.data.message);
      setFormValue({
        fullName: { value: "", warning: false },
        relationEmployee: { value: "", warning: false },
        date: { value: null, warning: false },
        familyMember: { value: "", warning: false },
      });
      setToggle(true);
      if (callApi) {
        callApi();
      }
      const policyEndDate = new Date(policyBeingWorkedOn?.endDate);
      const policyStartDate = new Date(policyBeingWorkedOn?.startDate);
      const currentDate = new Date();
      const isTodayBetweenPolicyDates =
        currentDate >= policyStartDate && currentDate <= policyEndDate;
      if (isTodayBetweenPolicyDates) {
        CreateEndorsementForNomineeAndMember(
          "Member Addition",
          res.data.data._id,
          false,
          "",
          "",
          `${res.data.data.preFix}${res.data.data.employeeId}`
        );
      }
    };
    const onError = (err: any) => {
      toast.error(
        err?.response?.data?.errors[0]?.param +
          err?.response?.data?.errors[0]?.message
      );
    };
    let allowedToSubmitMember = false;
    allowedToSubmitMember =
      checkAllowedAgeBand(formValue) &&
      checkAllowedRelation(formValue.relationEmployee.value) &&
      checkAllowedStatus() &&
      checkAllowedParentOrParentsInLaw(formValue.relationEmployee.value) &&
      checkMaxAgeForChildren(
        policyBeingWorkedOn.maxChildAge,
        formValue.date.value,formValue.relationEmployee.value
      );

    if (apiUrl && allowedToSubmitMember) {
      EMPLOYEE_POLICY_SERVICES.add_member_to_assign_policy(
        onSucces,
        onError,
        parm,
        apiUrl
      );
    }
  };

  const handleClicktoogle = () => {
    setToggle(true);
    setFormValue({
      fullName: { value: "", warning: false },
      relationEmployee: { value: "", warning: false },
      date: { value: null, warning: false },
      familyMember: { value: "", warning: false },
    });
  };
  const handleClicked = () => {
    setToggle(false);
    setFormValue({
      fullName: { value: "", warning: false },
      relationEmployee: { value: "", warning: false },
      date: { value: null, warning: false },
      familyMember: { value: "", warning: false },
    });
  };

  const checkAllowedRelation = (addUser: string) => {
    let validationFlag = false;
    const allowedMember = policyBeingWorkedOn.planType.basicConfig;
    const isSpouseAllowed = allowedMember.includes("1S");

    // Check if spouse is allowed and if the user is Husband or Wife
    if (isSpouseAllowed && (addUser === "Husband" || addUser === "Wife")) {
      let isSpousePresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return (
            data.relationWithEmployee === "Husband" ||
            data.relationWithEmployee === "Wife"
          );
        }
      );

      if (isSpousePresentInMember) {
        toast.warning("Spouse is already present in Policy");
      } else {
        return true;
      }
    } else if (
      !isSpouseAllowed &&
      (addUser === "Husband" || addUser === "Wife")
    ) {
      toast.warning("Spouse is Not allowed in this policy");
    }

    //Here check the child is present or not

    const isChildAllowed = allowedMember.includes("1C");

    if (isChildAllowed && (addUser === "Son" || addUser === "Daughter")) {
      // Count the occurrences of '1C'
      let totalAllowedChildInPolicy = allowedMember
        .split("+")
        .filter((item: any) => item === "1C").length;
      let childCountInMember = 0;

      policyBeingWorkedOn.members.forEach((employee: any) => {
        if (
          employee.relationWithEmployee === "Son" ||
          employee.relationWithEmployee === "Daughter"
        ) {
          childCountInMember++;
        }
      });
      if (totalAllowedChildInPolicy > childCountInMember) {
        return true;
      } else {
        toast.warning("Child Count is exceeded in this Policy");
      }
    } else if (
      !isChildAllowed &&
      (addUser === "Son" || addUser === "Daughter")
    ) {
      toast.warning("Child is Not allowed in this policy");
    }

    // Check For Parent

    const allowedParent = policyBeingWorkedOn.planType.parentConfig;
    const isParentAllowed = allowedParent.includes("1P");

    // Check if Mother is allowed and if the user is Mother
    if (isParentAllowed && addUser === "Mother") {
      let isMotherPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return data.relationWithEmployee === "Mother";
        }
      );

      if (isMotherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentAllowed && addUser === "Mother") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father is allowed and if the user is Father
    if (isParentAllowed && addUser === "Father") {
      let isFatherPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return data.relationWithEmployee === "Father";
        }
      );

      if (isFatherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentAllowed && addUser === "Father") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check for parent in law

    const isParentInLawAllowed = allowedParent.includes("1PIL");

    // Check if Mother-In-Law is allowed and if the user is Mother-In-Law
    if (isParentInLawAllowed && addUser === "Mother-In-Law") {
      let isMotherInLawPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return data.relationWithEmployee === "Mother-In-Law";
        }
      );

      if (isMotherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentInLawAllowed && addUser === "Mother-In-Law") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father-In-Law is allowed and if the user is Father-In-Law
    if (isParentInLawAllowed && addUser === "Father-In-Law") {
      let isFatherInLawPresentInMember = policyBeingWorkedOn.members.some(
        (data: any) => {
          return data.relationWithEmployee === "Father-In-Law";
        }
      );

      if (isFatherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentInLawAllowed && addUser === "Father-In-Law") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }
    if (addUser === "Sister" || addUser === "Brother") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }
    return validationFlag;
  };
  const getYearsDiff = (dob: Date) => {
    const today = new Date();
    const diffMilliseconds = today.getTime() - dob.getTime();
    const ageInYears = diffMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Consider leap years
    const roundedAge = Math.ceil(ageInYears);
    return roundedAge;
  };
  const checkLocation = (selectedPolicy: any) => {
    let isLoactionMatched = selectedPolicy.locations.some((data: any) => {
      return data.id === baseLocation.Location;
    });
    if (isLoactionMatched) {
      return true;
    } else {
      toast.warning("This policy is not for Employee's Location");
      return false;
    }
  };
  // Check for age bands
  const checkAllowedAgeBand = (data: any) => {
    let policy: any = policyBeingWorkedOn;
    const dobDate = new Date(data?.date.value);
    const ageInYears = getYearsDiff(dobDate);
    for (const band of policy?.ageBand) {
      let minAge = band.min.includes("Up to 1 Year") ? 0 : parseInt(band.min);
      let maxAge = band.max.includes("Years")
        ? parseInt(band.max)
        : Number.MAX_SAFE_INTEGER;

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return true;
      }
    }

    toast.warning("This age band not allowed in this policy");
    return false;
  };

  // Check for allowed status in policy
  const checkAllowedStatus = () => {
    let existStatusCount = 0;
    policyBeingWorkedOn.members.forEach((employee: any) => {
      if (
        employee.enrolment_status === 40 ||
        employee.relationWithEmployee === 41
      ) {
        existStatusCount++;
      }
    });

    if (
      policyBeingWorkedOn.policySettings.maxMemberPerFamily > existStatusCount
    ) {
      return true;
    } else {
      toast.warning(
        "Member's Status is not allowing you to add in this Policydfsf"
      );
      return false;
    }
  };

  return (
    <SlidingPanel type={"right"} isOpen={open_status} size={75}>
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3 text-left">Add Member</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-2">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
                setFormValue({
                  fullName: { value: "", warning: false },
                  relationEmployee: { value: "", warning: false },
                  date: { value: null, warning: false },
                  familyMember: { value: "", warning: false },
                });
                setToggle(true);
              }}
            />
          </Grid>
          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ paddingTop: "0" }}
        >
          <Grid xs={12} className="radio_button no-border">
            <>
              <RadioGroup row defaultValue="existing">
                <FormControlLabel
                  value="existing"
                  control={<Radio />}
                  label="Choose from existing family member"
                  onClick={handleClicktoogle}
                />
                <FormControlLabel
                  // checked
                  value="new"
                  control={<Radio />}
                  label="Add new member"
                  onClick={handleClicked}
                />
              </RadioGroup>
            </>
          </Grid>
          <Grid xs={12}>
            <div className="scrollable_area ">
              <Grid container spacing={3}>
                {toggle === true ? (
                  <>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Family Member"
                        value={formValue.familyMember.value}
                        attrName={["formValue", "familyMember"]}
                        value_update={updateMasterState}
                        dropdown_data={selectFamily_data}
                        warn_status={formValue.familyMember.warning}
                        error_messg="Select RelationShip"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Relationship with Employee
                        <span>{formValue.relationEmployee.value}</span>
                      </p>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Full Name member"
                        value={formValue.fullName.value}
                        attrName={["formValue", "fullName"]}
                        value_update={updateMasterState}
                        warn_status={formValue.fullName.warning}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Relationship with employee"
                        value={formValue.relationEmployee.value}
                        attrName={["formValue", "relationEmployee"]}
                        value_update={updateMasterState}
                        dropdown_data={relationEmployees_data}
                        warn_status={formValue.relationEmployee.warning}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField"
                        title="Date of Birth"
                        value={formValue.date.value}
                        attrName={["formValue", "date"]}
                        value_update={updateMasterState}
                        error_message="Please enter Date of Birth"
                        warn_status={formValue.date.warning}
                        max_date={new Date()}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Grid>

          <Grid xs={12} className="footer_sec">
            <Link className="save_btn" onClick={formSubmit}>
              Submit
            </Link>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default AddMember;
