import useIsMobile from "../../../../Supporting files/MobileProvider";
import "./MNotAllowed.scss";

const MNotAllowed = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && (
        <div className="MNotAllowedContainer">
          <div>
            <h1>Attention ! View Unavailable on Mobile Browsers</h1>
            <p>
              {" "}
              This platform is not supported on mobile browsers. Please access
              it via a desktop browser for optimal functionality. Thank you for
              your understanding.
            </p>
            <img
              src="images/Mnot-allowed-girl.svg"
              alt="girl"
              className="mt-6"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MNotAllowed;
