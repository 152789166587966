import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../APIVar/EmployerUrlConstant";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import SpocTable from "../../../../../Components/Broker/SPOCTable/SpocTable";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { EMPLOYER_POLICIES_SERVICES } from "../../../../../Services/EmployerServices/Policies/PoliciesServices";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import {
  date_time_format,
  formatAmountWithComma,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  isIntegratedTpa,
  updateTimeFormat,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import { TPA_TYPE } from "../../../../../Services/Enum/tpaType";

interface Props {
  scrollRef?: string;
  cdBalanceState: Function;
}

const PolicyDetailOverViewTab: React.FC<Props> = ({
  scrollRef,
  cdBalanceState,
}) => {
  const { timeZone, dateFormat } = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation);

  const { formatCurrency } = useFormatCurrency();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [attachment, setAttachment] = useState(false);
  const [overviewData, setOverviewData] = useState<any>({});
  const [sectionStatus, SetSectionStatus] = useState<string>("policyDetails");
  const policyDetails = useRef(null);
  const insurerDetails = useRef(null);
  const organizationDetails = useRef(null);
  const servicingDetails = useRef(null);
  const attatchmentsdetails = useRef(null);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [showLoader, setShowLoader] = useState(true);
  const [tpaName, settpaName] = useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
  
    let timeoutId: any;
 
  if (timeoutId) {
      clearTimeout(timeoutId);
    }
 
    const delayedApiCall = () => {
      get_policy_overview_tab(id ?? "");
    }
 
    timeoutId = setTimeout(delayedApiCall, 900);
    setShowLoader(true);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };


  
  }, []);

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const get_policy_overview_tab = (id: any) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      cdBalanceState(res.data.data[0].policyDetails?.cdBalance);
      setOverviewData(res.data.data[0]);
      settpaName(res.data.data[0].tpa?.name);
      setPolicyNumber(res.data.data[0].policyCase?.policyNumber)
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    EMPLOYER_POLICIES_SERVICES.get_policy_detail_view(
      `${EMPLOYER_URL_CONSTANTS.get_policy_detail_view}${id}&tab=OVERVIEW`,
      onSuccess,
      onError
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "disabled") {
    } else if (attrName === "attachment_deleted") {
      get_policy_overview_tab(id);
    } else {
      attrName(value);
    }
  };

  return (
    <>
      {showLoader && (<NewLoader />)}

      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{ maxWidth: "260px", position: "relative", top: "-85px" }}
        >
          <Grid
            xs
            className="leftSection h-100"
            style={{ position: "fixed" }}
          >
            <Link
              className={
                sectionStatus === "policyDetails"
                  ? "active sectionLink policyDetails"
                  : "sectionLink policyDetails"
              }
              onClick={() => {
                scrollToSection(policyDetails);
                SetSectionStatus("policyDetails");
              }}
            >
              <span> Policy Details</span>
            </Link>
            {overviewData?.insurer && (
              <Link
                className={
                  sectionStatus === "insurerDetails"
                    ? "active sectionLink insurerDetails"
                    : "sectionLink insurerDetails"
                }
                onClick={() => {
                  scrollToSection(insurerDetails);
                  SetSectionStatus("insurerDetails");
                }}
              >
                <span> Insurer Details</span>
              </Link>
            )}
            <Link
              className={
                sectionStatus === "organizationDetails"
                  ? "active sectionLink organizationDetails"
                  : "sectionLink organizationDetails"
              }
              onClick={() => {
                scrollToSection(organizationDetails);
                SetSectionStatus("organizationDetails");
              }}
            >
              <span>Organization Details</span>
            </Link>
            {/* <Link
              className={
                sectionStatus === "servicingDetails"
                  ? "active sectionLink servicingDetails"
                  : "sectionLink servicingDetails"
              }
              onClick={() => {
                scrollToSection(servicingDetails);
                SetSectionStatus("servicingDetails");
              }}
            >
              <span>Servicing Details</span>
            </Link> */}
            <Link
              className={
                sectionStatus === "attatchmentsdetails"
                  ? "active sectionLink attatchmentsdetails"
                  : "sectionLink attatchmentsdetails"
              }
              onClick={() => {
                handleClicks(sectionStatus);
                scrollToSection(attatchmentsdetails);
                SetSectionStatus("attatchmentsdetails");
              }}
            >
              <span>Attatchments</span>
            </Link>
          </Grid>
        </Grid>
        <Grid xs>
          <Box marginBottom="12px">
            <Grid container spacing={3} paddingTop={0}>
              <Grid xs={9} paddingTop={0}        >
                <div className="client_info_section">
                  <p className="client_info_p freshPolicy md_width">
                    Fresh Policy
                  </p>
                  <p className="client_info_p empID md_width">
                    Employees :{" "}
                    <span>
                      {overviewData.employeeCount
                        ? overviewData.employeeCount
                        : 0}
                    </span>
                  </p>
                  <p className="client_info_p dependents md_width">
                    Dependents:{" "}
                    <span>
                      {overviewData.dependendsCount
                        ? overviewData.dependendsCount
                        : 0}
                    </span>
                  </p>
                  {overviewData?.productType?.name === "Group Term" ? ("") : (
                    <p className="client_info_p totalEmployees md_width mr-0">
                      Lives:{" "}
                      <span>
                        {overviewData.totalLives
                          ? overviewData.totalLives
                          : 0}
                      </span>
                    </p>
                  )}

                </div>
              </Grid>
              <Grid xs={3} className="align-self-center text-right">
                <Link
                  className="networkhosplist"
                  onClick={() => {
                    if (isIntegratedTpa(tpaName)) {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=${tpaName}&policyNumber=${policyNumber}`, "_blank");
                    } else {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=static&policyNumber=${policyNumber}`, "_blank");
                    }
                  }}
                >
                  Network Hospital List
                </Link>{" "}
              </Grid>
            </Grid>
          </Box>
          <Box className="detailSection" ref={policyDetails} onMouseEnter={() => {
            SetSectionStatus("policyDetails");
          }}>
            <div className="sectionTitle">
              <h4>Policy Details</h4>
            </div>
            <Grid container spacing={3}>
              <Grid md={5} lg={4} xl={3}>
                <div className="planname">
                  <p>
                    <span className="group_health"></span>
                    {overviewData.productType
                      ? overviewData.productType.name
                      : ""}
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Premium"
                  value={
                    formatCurrency(overviewData?.premiumDetails?.grossPremium)
                    // formatCurrencyAccToUser(
                    //   overviewData?.premiumDetails?.grossPremium,
                    //   getCurrencyCodeRegex(
                    //     userState.localInformation.currency
                    //   ),
                    //   userState.localInformation.countryCurrencyCode,
                    //   0)
                  }
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="CD Balance"
                  value={
                    formatCurrency(overviewData?.policyDetails?.cdBalance)
                    //     formatCurrencyAccToUser(
                    // overviewData?.policyDetails?.cdBalance,
                    // getCurrencyCodeRegex(
                    // userState.localInformation.currency
                    // ),
                    // userState.localInformation.countryCurrencyCode,
                    // 0 )
                  }
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={12}>
                <p className="net_premium">
                  Net Premium -{" "}
                  {formatCurrencyAccToUser(
                    overviewData?.premiumDetails?.netPremium,
                    getCurrencyCodeRegex(
                      userState.localInformation.currency
                    ),
                    userState.localInformation.countryCurrencyCode,
                    0
                  )}{" "}
                  | Tax-{" "}
                  {formatCurrencyAccToUser(
                    overviewData?.premiumDetails?.taxAmount,
                    getCurrencyCodeRegex(
                      userState.localInformation.currency
                    ),
                    userState.localInformation.countryCurrencyCode,
                    0
                  )}{" "}
                  {""}(
                  {overviewData.premiumDetails &&
                    overviewData?.premiumDetails?.taxPercentage?.map(
                      (data: any, index: number) => (
                        <span>
                          {data.label}
                          {overviewData?.premiumDetails?.taxPercentage
                            .length -
                            1 ===
                            index
                            ? ""
                            : ";" + " "}
                        </span>
                      )
                    )}
                  )
                </p>
              </Grid>

              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Policy Start Date"
                  value={date_time_format(
                    overviewData?.policyDetails?.startDate,
                    dateFormat, timeZone
                  )}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  error_messg="Please enter Start Date"
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Policy End Date"
                  value={date_time_format(
                    overviewData?.policyDetails?.endDate,
                    dateFormat, timeZone
                  )}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  error_messg="Please enter End Date"
                  warn_status={false}
                />
              </Grid>
            </Grid>
          </Box>
          {overviewData?.insurer && (
            <Box className="detailSection" ref={insurerDetails} onMouseEnter={() => {
              SetSectionStatus("insurerDetails");
            }}>
              <div className="sectionTitle">
                <h4>Insurer Details</h4>
              </div>
              <Grid container spacing={3} alignItems="center">
                <Grid
                  sm={4}
                  md={4}
                  lg={3}
                  xl={2}
                  className="text-center p-0"
                >
                  <img
                    src={
                      overviewData?.insurer
                        ? overviewData?.insurer[0]?.insurerBasicInfo.logo
                        : ""
                    }
                    alt=""
                    className="insurer_logo"
                  />
                </Grid>
                <Grid sm={4} md={4} lg={4} xl={4}>
                  <RKTextField
                    class_name="inputField"
                    title="Insurer Company Name"
                    value={overviewData?.insurer[0]?.insurerBasicInfo.name}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid sm={4} md={4} lg={4} xl={4}>
                  <RKTextField
                    class_name="inputField"
                    title="Insurer Company Office"
                    value={overviewData?.insurerDetails?.office}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="subHeading">Specific Person of Contact</h5>
                  <div className="sectionTitle spoc_section_title">
                    {/* <div className="actionBtns">
                <Link
                className="blueBtn addfile"
                // onClick={() => {
                  //   setOpenContactForm(true);
                  // }}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Add Contact
                </Link>
                
                <Link
                  className="redBtn delete"
                  // onClick={() => {
                  //   setRemoveCategory(true);
                  // }}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </Link>
              </div> */}
                  </div>
                </Grid>
              </Grid>
              <Box>
                <SpocTable
                  data={
                    overviewData.insurer
                      ? overviewData?.insurer[0]?.location
                      : []
                  }
                />
              </Box>
            </Box>
          )}

          <Box className="detailSection" ref={organizationDetails} onMouseEnter={() => {
            SetSectionStatus("organizationDetails");
          }}>
            <div className="sectionTitle">
              <h4>Organization Details</h4>
            </div>
            <Grid container spacing={3}>
              <Grid sm={12} style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="client_organization">
                  <h6>
                    Organization Name
                    <span>
                      {overviewData?.organisationDetails?.organisationName}
                    </span>
                  </h6>
                </div>
                <div className="client_organization">
                  <h6>
                    Client ID{" "}
                    <span>
                      {overviewData?.organisationDetails?.clientId}
                    </span>
                  </h6>
                </div>

                <div className="client_organization">
                  <h6>
                    Client Location
                    <span>
                      {overviewData?.organisationDetails?.location?.map((data: any) => {
                        let loc: string = data.name;
                        return loc;
                      }).join(", ")}
                    </span>
                  </h6>
                </div>
                <div className="client_organization">
                  <h6>
                    Email<span>{overviewData?.organisationDetails?.organisationEmail}</span>
                  </h6>
                </div>
                <div className="client_organization">
                  <h6>
                    Contact No.
                    <span>{overviewData?.organisationDetails?.mobile}</span>
                  </h6>
                </div>
              </Grid>
            </Grid>
          </Box>
          {/* <Box className="detailSection" ref={servicingDetails}>
            <div className="sectionTitle">
              <h4>Servicing Details</h4>
            </div>
            <Grid container spacing={3}>
              <Grid xs={3}>
                <RKTextField
                  class_name="inputField"
                  title="Relationship Manager"
                  value={overviewData?.servicesDetail?.relationshipManager}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                  error_messg="Please select client"
                />
              </Grid>
              <Grid xs={3}>
                <RKTextField
                  class_name="inputField"
                  title="Sales Person"
                  value={overviewData?.servicesDetail?.salesPerson}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                  error_messg=" select client"
                />
              </Grid>
              <Grid xs={3}>
                <RKTextField
                  class_name="inputField"
                  title="Servicing Partner"
                  value={overviewData?.servicesDetail?.servicingPerson}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                  error_messg="Please select client"
                />
              </Grid>
              <Grid xs={3}>
                <RKTextField
                  class_name="inputField"
                  title="TPA"
                  value={overviewData?.servicesDetail?.tpa}
                  attrName={"disabled"}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            </Grid>
          </Box> */}
          <Box className="detailSection" ref={attatchmentsdetails} onMouseEnter={() => {
            SetSectionStatus("attatchmentsdetails");
          }}>
            <div className="sectionTitle">
              <h4>Attatchments</h4>
              {/* <div className="actionBtns"></div> */}
            </div>
            <Attachment
              open_status={attachment}
              attrName={setAttachment}
              value_update={updateMasterState}
              dataArray={overviewData?.attachments}
              deleteurl={POLICIES_SERVICES.delete_attachment_policy}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyDetailOverViewTab;
