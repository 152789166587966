//used
import AddIcon from "@mui/icons-material/Add";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import {
  EmployeeProfileObj,
  Family,
  Property,
} from "../../../../Services/DTO/Employee/EmployeeDTO";
import { PROFILE_SERVICE } from "../../../../Services/Employee/ProfileService";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import { SUPPORT_SERVICES } from "../../../../Services/Support/SupportServices";
import { useAppSelector } from "../../../../Store/hooks";
import {
  date_time_format,
  uploadImage,
} from "../../../../Supporting files/HelpingFunction";
import AddProperty from "../../Broker/Employees/EmployeeDetails/AddPopup/AddProperty";
import AddVehcile from "../../Broker/Employees/EmployeeDetails/AddPopup/AddVehicle";
import EditProperty from "../../Broker/Employees/EmployeeDetails/EditPopup/Edit Property";
import EditFamily from "../../Broker/Employees/EmployeeDetails/EditPopup/EditFamily";
import AddFamilyMembers from "./AddFamilyMember/AddFamilyMember";
import "./Profile.scss";
import "./VehicleBox/VehicleBox.scss";
// import EditVehcile from "../../Broker/Employees/EmployeeDetails/EditPopup/EditVehcile";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import EditVehicle from "../../Broker/Employees/EmployeeDetails/EditPopup/EditVehicle";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

function Profile() {
  const navigate = useNavigate();
  const [editVehicle, setEditVehicle] = useState(false);
  const { userType, id } = useAppSelector((state) => state.userDetailsSlice);
  const [addProperty, setAddProperty] = useState(false);
  const [editProperty, setEditProperty] = useState(false);
  const [selectedPropertyData, setSelectedPropertyData] = useState<any>({});
  const [property_data, setProperty_Data] = useState<any[]>([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState<any>({});
  const [addNewMember, setaddNewMember] = useState(false);
  const [addNewVehicle, setaddNewVehicle] = useState(false);
  const [hideDataStatus, setHideDataStatus] = useState(false);
  const [isDep, setIsDep] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [deleteFamily, setDeleteFamily] = useState(false);
  const [selectedFamilyData, setSelectedFamilyData] = useState<any>({});
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const userState = useAppSelector((state) => state.userDetailsSlice);
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const [profileData, setProfileData] = useState<EmployeeProfileObj>({
    "Contact Number": "",
    "Date Of Birth": "",
    "Date Of Joining": "",
    "First Name": "",
    "Last Name": "",
    "Member Type": "",
    "Office Contact Number": "",
    "Official Email": "",
    "Organization Employee ID": "",
    "Relationship With Employee": "",
    "Salary Bracket": "",
    Client: "",
    createdAt: "",
    Department: "",
    Designation: "",
    Email: "",
    photo: "",
    employeeId: "",
    family: [
      { preFix: "", _id: "", fullName: "", relationWithEmployee: "", dob: "" },
    ],
    Location: "",
    personalAddress: {
      street: "",
      area: "",
      country: "",
      state: "",
      city: "",
      zip: "",
    },
    Gender: "",
    hide: "",
    Salary: "",
    Title: "",
  });
  const [memberList, setMemberList] = useState<Family[]>([]);
  const [propertyList, setPropertyList] = useState<Property[]>([
    { title: "", class_name: "" },
  ]);
  const [filename, setFilename] = useState<string>("");
  const [supportTicket, setSupportTicket] = useState<any>({ logo: "" });
  const [showLoader, setShowLoader] = useState(true);
  const { dateFormat, timeZone } = useAppSelector(
    (state) => state.userDetailsSlice.localInformation
  );
  // const memberClick = (index: number) => {
  //   setSelectedMember(index);
  // };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setaddNewMember) {
      setaddNewMember(value);
      setHideDataStatus(value);
    } else {
      attrName(value);
    }
  };

  const get_profile = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setProfileData(res.data.data[0]);
      let arr: Family[] = [];
      res.data.data[0].family.map((member: any) => {
        let obj: Family = {
          fullName: member.fullName,
          // dob: formatDate(member.dob),
          dob: date_time_format(member.dob, dateFormat, timeZone),
          relationWithEmployee: member.relationWithEmployee,
          class_name: member.relationWithEmployee?.toLowerCase(),
          _id: member._id,
          is_dep: member.is_dep ?? false,
          dependentId: member.dependentId,
        };
        arr.push(obj);
      });
      setMemberList(arr);

      // default selected 1st item
      if (arr?.length > 0) {
        setSelectedFamilyData(arr[0]);
      }
      setSelectedMemberIndex(0);
      setIsDep(arr[0]?.is_dep ?? false);

      let propertyArr: Property[] = [];
      res.data.data[0].propertyData.map((property: Property) => {
        let obj: Property = {
          title: property.propertyType || "",
          class_name:
            property.propertyType === "Rented"
              ? property.propertyType?.toLowerCase()
              : "independent",
        };
        propertyArr.push(obj);
      });
      setPropertyList(propertyArr);
    };
    const onError = (err: any) => {
      console.log("true", err);
    };
    const url = EMPLOYEE_URL_CONSTANTS.get_employee_profile;
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };
  let timeoutId: any;

  useEffect(() => {
    if (addNewMember === false) {
     


      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      const delayedApiCall = () => {
        get_profile();
      };
  
      timeoutId = setTimeout(delayedApiCall, 900);
  
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };

      
    }
  }, [addNewMember]);

  const formatDate = (dateString: string) => {
    //
    let sman = new Date(dateString);
    const str = dateString.split("T")[0];
    const date = new Date(str);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  function calculateDaysToExpiry(expiryDate: string) {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  }

  const submitSupportTicket = () => {
    const onSuccess = (res: any) => {
      toast.success("Profile Pic successfully!");
      setSupportTicket({
        logo: "",
      });
      get_profile();
    };

    const onError = (err: any) => {
      console.log(err);
      toast.error("Error submiting support ticket");
    };

    SUPPORT_SERVICES.update_employee_pic(supportTicket, onSuccess, onError);
  };

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      await uploadImage(file, (base64Image: any, base64String: any) => {
        setFilename(file.name);
        setSupportTicket((prev: any) => (prev.logo = base64Image));
        submitSupportTicket();
      });
    }
  };

  const get_property_detail = (id: string) => {
    const onSuccess = (res: any) => {
      setProperty_Data(res.data.data);
      setSelectedPropertyData(res.data.data[0]);
      setSelectedProperty(res.data.data[0]._id);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    PROFILE_SERVICE.get_property_detail_list(
      onSuccess,
      onError,
      id,
      userType?.toLowerCase()
    );
  };
  let timeout: any;

  useEffect(() => {
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_property_detail(id);

    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);


  const deleteFamilyMemberHandler = (memberId: string) => {
    // console.log('we are deleting ', memberId)
    const onSuccess = (res: any) => {
      console.log('checking on success', res)
      get_profile()
      toast.success('Family member removed successfully')
    }
    const onError = (res: any) => {
      console.log('logging out errors', res)
    }
    PROFILE_SERVICE.deleteFamilyMemberApi(memberId, onSuccess, onError)
  }

  const deletePropertyHandler = (propertyId: string) => {
    const onSuccess = (res: any) => {
      get_property_detail(id)
      toast.success('Property removed successfully')
    }
    const onError = (res: any) => {
      console.log('logging out errors', res)
    }
    PROFILE_SERVICE.deletePropertyApi(propertyId, onSuccess, onError)
  }
  const deleteVehicleHandler = (vehicleId: string) => {
    const onSuccess = (res: any) => {
      console.log('checking vehical detail', vehicleId)
      toast.success('Vehicle removed successfully')
      get_profile()
    }
    const onError = (res: any) => {
      console.log('logging out errors', res)
    }
    PROFILE_SERVICE.deleteVehicleApi(vehicleId, onSuccess, onError)
  }

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid xs className="profileWrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box className="pageTitle">
              <h2>
                <span>Your</span> Profile
              </h2>
              <p>Information about your life, work, family, interests, etc.</p>
            </Box>
          </Grid>
          {/* Personal Details */}
          <Grid xs={12} display="flex" alignItems="flex-start">
            <Box className="personalInfo">
              <div className="myimg">
                {/* <PersonIcon className="icon" /> */}
                <img src={profileData?.photo} />
              </div>
              <div className="myDeails">
                <Link className="camera">
                  <PhotoCameraOutlinedIcon />
                  <input
                    accept="application/pdf, image/*"
                    multiple
                    type="file"
                    style={{ zIndex: "99" }}
                    onChange={handleFileUpload}
                  />
                </Link>
                <h4>
                  {profileData?.["First Name"] +
                    " " +
                    profileData?.["Last Name"]}
                </h4>
                <p>{profileData?.employeeId}</p>
                <p>{profileData?.Designation}</p>
              </div>
            </Box>
            <Box className="personalDetails">
              <ul>
                <li className="dob">
                  {moment(profileData?.["Date Of Birth"]).format("MMM D, YYYY")}
                  {/* {formatDate(profileData["Date Of Birth"])} */}
                </li>
                <li className={profileData?.Gender?.toLowerCase()}>
                  {profileData?.Gender}
                </li>
                {/* <li className="marital">Married</li> */}
                <li className="address">
                  {profileData?.personalAddress.street +
                    " " +
                    profileData?.personalAddress.area +
                    " " +
                    profileData?.personalAddress.city +
                    " " +
                    profileData?.personalAddress.state +
                    " " +
                    profileData?.personalAddress.country +
                    " " +
                    profileData?.personalAddress.zip}
                </li>
              </ul>
            </Box>

            <Box className="familyMembers">
              <h4>Family Members</h4>
              <ul>
                <li
                  className="addMore"
                  onClick={() => {
                    updateMasterState(setaddNewMember, true);
                  }}
                >
                  <AddIcon />
                </li>
                {memberList?.length > 0 ? (
                  memberList?.map((item, index) => {
                    let cls = item.class_name;
                    if (item.class_name === "father-in-law") {
                      cls = "father";
                    } else if (item.class_name === "mother-in-law") {
                      cls = "mother";
                    }
                    return (
                      <li
                        key={index}
                        className={`${cls} ${
                          selectedMemberIndex === index ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedFamilyData(item);
                          setSelectedMemberIndex(index);
                          setIsDep(item.is_dep ?? false);
                        }}
                      ></li>
                    );
                  })
                ) : (
                  <span>No Family Member added yet</span>
                )}
              </ul>
              {memberList?.length > 0 && (
                <Box className="memberDetails" style={{border: "2px solid red"}}>
                  <span>{memberList[selectedMemberIndex]?.fullName}</span>{" "}
                  <span>
                    {memberList[selectedMemberIndex]?.relationWithEmployee}
                  </span>{" "}
                  <span>{memberList[selectedMemberIndex]?.dob}</span>
                  <span>
                    {memberList[selectedMemberIndex]?.dependentId ===
                    "undefined-undefined"
                      ? ""
                      : memberList[selectedMemberIndex]?.dependentId}
                  </span>
                  <Link
                    className="blueBtn edit"
                    onClick={() => {
                      if (
                        selectedFamilyData.dependentId &&
                        selectedFamilyData.dependentId !== "undefined-undefined"
                      ) {
                        return toast.warn(
                          "Policy is assigned to this member so you can't edit this member."
                        );
                      } else if (selectedFamilyData?._id) {
                        setEditFamily(true);
                      } else {
                        toast.error("Select Member to Edit");
                      }
                    }}
                  >
                    Edit
                  </Link>
                  <Link
                    className="blueBtn edit"
                    onClick={() => {
                      if (
                        selectedFamilyData.dependentId &&
                        selectedFamilyData.dependentId !== "undefined-undefined"
                      ) {
                        return toast.warn(
                          "Policy is assigned to this member so you can't delete this member."
                        );
                      } else if (selectedFamilyData?._id) {
                        // setEditFamily(true);
                        deleteFamilyMemberHandler(selectedFamilyData?._id)
                      } else {
                        toast.error("Select Member to delete");
                      }
                    }}
                  >
                    delete
                  </Link>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <Box className="email_data">
              <Grid container spacing={3}>
                <Grid xs={12} xl={6} className="grids">
                  <img src="images/email_phone.svg" alt="" />
                  <p className="valP" style={{ wordBreak: "break-all" }}>
                    {profileData?.Email}
                  </p>
                </Grid>
                <Grid xs={12} xl={6} className="grids">
                  <img src="images/dial_phone.svg" alt="" />
                  <p className="valP">{profileData?.["Contact Number"]}</p>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <div className="salary_details">
              <div className="prof_detail mb-3">
                <img src="./images/emp_prof_detail.svg" alt="" height={30} />
                <p className="headP">Professional Detail</p>
              </div>
              <Box padding={0}>
                <Grid container padding={0} rowGap={2}>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Salary Bracket</p>
                    <p className="valP">{profileData?.["Salary Bracket"]}</p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Office Mobile No</p>
                    <p className="valP">
                      {profileData?.["Office Contact Number"]}
                    </p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Organization Employee ID</p>
                    <p className="valP">
                      {profileData?.["Organization Employee ID"]}
                    </p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Date of Joining</p>
                    <p className="valP">
                      {date_time_format(
                        profileData?.["Date Of Joining"],
                        user_type_dateFormat,
                        userState?.localInformation?.timeZone
                      )}
                      {/* {formatDate(profileData?.["Date Of Joining"])} */}
                    </p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Office Email ID</p>
                    <p className="valP">{profileData?.["Official Email"]}</p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Department</p>
                    <p className="valP">{profileData?.Department}</p>
                  </Grid>
                  <Grid xs={12} lg={6} xl={4}>
                    <p className="headP">Designation</p>
                    <p className="valP">{profileData?.Designation}</p>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div style={{ textAlign: "end", maxWidth: "630px" }}>
              <Link
                className="blueBtn edit"
                onClick={() => {
                  navigate(`${EMPLOYEE_ALL_ROUTES.EMPLOYEE_DETAIL}?id=${id}`);
                }}
              ></Link>
            </div>
          </Grid>
        </Grid>

        {/* Add Vehicles */}
        <Grid container spacing={3}>
          <Grid xs={8}>
            <Box className="subHeading mb-4">
              <h3>
                <span>Vehicles</span>
              </h3>
              <p>
                List your vehicles here, so that we can remind you for the
                insurance policy renewal.
              </p>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box textAlign={"end"}>
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={() => {
                  if (selectedVehicleData._id) {
                    // setEditVehicle(true);
                    deleteVehicleHandler(selectedVehicleData._id)
                  } else {
                    toast.error("Select id");
                  }
                }}
              >
                Delete
              </Link>
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={() => {
                  if (selectedVehicleData._id) {
                    setEditVehicle(true);
                  } else {
                    toast.error("Select id");
                  }
                }}
              >
                Edits
              </Link>
              <Link
                className="blueBtn addfile"
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => setaddNewVehicle(true)}
              >
                Add Vehicle
              </Link>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
              <Grid container columnSpacing={2}>
                {profileData?.vehicleData?.map((vehicle: any) => {
                  let cls = vehicle?._id === selectedVehicleData?._id;
                  return (
                    <Grid xs={3}>
                      <Box
                        className={`vehicleDetailBox ${cls ? "active" : ""} `}
                        onClick={() => setSelectedVehicleData(vehicle)}
                      >
                        <div className="regno">
                          {vehicle.registrationNumber}
                        </div>
                        <div className="noofDays">
                          <AccessAlarmOutlinedIcon />{" "}
                          {calculateDaysToExpiry(
                            vehicle.policyExpireDate
                          ).toString() +
                            " " +
                            "days"}
                        </div>
                        <div className="myVehicle">
                          <div
                            className={
                              vehicle.type === "Car" ? "carIcon" : "bikeIcon"
                            }
                          ></div>
                          <div className="vehicleInfo">
                            <h5>{vehicle.makeModal}</h5>
                            <p>
                              {vehicle.fuelType},{vehicle.variant}
                            </p>
                            <p>{vehicle.previousInsurer}</p>
                          </div>
                        </div>
                        {calculateDaysToExpiry(vehicle.policyExpireDate) <
                          30 && (
                          <Link className="renew">
                            <AutorenewOutlinedIcon /> Renew
                          </Link>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* Add Property */}
        <Grid container spacing={3}>
          <Grid xs={8}>
            <Box className="subHeading mb-4">
              <h3>
                <span>House/Property</span>
              </h3>
              <p>Which type of property do you have?</p>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box textAlign={"end"}>
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={() => {
                  if (selectedPropertyData?._id) {
                    deletePropertyHandler(selectedPropertyData?._id)
                  } else {
                    toast.error("Select Property");
                  }
                }}
              >
                Delete
              </Link>
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={() => {
                  if (selectedPropertyData?._id) {
                    setEditProperty(true);
                  } else {
                    toast.error("Select Property");
                  }
                }}
              >
                Edit
              </Link>
              <Link
                className="blueBtn addfile"
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => setAddProperty(true)}
              >
                Add Property
              </Link>
            </Box>
          </Grid>
          <Grid xs={12}>
            <ul className="propertyType">
              {property_data.map((item: any, index: number) => {
                let cls =
                  item?.buildingType === "MultiStory"
                    ? "rented"
                    : "independent";
                return (
                  <li
                    key={index}
                    className={
                      selectedProperty === item._id
                        ? `${cls} active`
                        : `${cls} `
                    }
                    onClick={() => {
                      setSelectedPropertyData(item);
                      setSelectedProperty(item._id);
                    }}
                  >
                    {item.propertyType}
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid container spacing={3}>
            {selectedPropertyData?._id ? (
              <Grid xs={12}>
                <ul className="property_details">
                  <li>
                    <p>
                      Property Type
                      <span>{selectedPropertyData.buildingType}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Property Age
                      <span>{selectedPropertyData.propertyAge}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Building/Street Name
                      <span>{selectedPropertyData.streetName}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Area Name<span>{selectedPropertyData.areaName}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Country<span>{selectedPropertyData.countryName}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      State<span>{selectedPropertyData.stateName}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      City<span>{selectedPropertyData.city}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Zip Code<span>{selectedPropertyData.zipCode}</span>
                    </p>
                  </li>
                </ul>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <AddVehcile
          open_status={addNewVehicle}
          attrName={setaddNewVehicle}
          value_update={updateMasterState}
          get_profile={get_profile}
        />
        <EditVehicle
          attrName={setEditVehicle}
          open_status={editVehicle}
          value_update={updateMasterState}
          data={selectedVehicleData}
          get_profile={get_profile}
        />
        <AddProperty
          attrName={setAddProperty}
          open_status={addProperty}
          value_update={updateMasterState}
          get_profile={get_profile}
          get_property_detail={get_property_detail}
        />
        <EditProperty
          open_status={editProperty}
          attrName={setEditProperty}
          value_update={updateMasterState}
          data={selectedPropertyData}
          get_property_detail={get_property_detail}
          // selectedProperty={selectedProperty}
          // setSelectedProperty={setSelectedProperty}
        />
        <EditFamily
          open_status={editFamily}
          attrName={setEditFamily}
          value_update={updateMasterState}
          data={selectedFamilyData}
          get_profile={get_profile}
          addedMemberData={memberList}
        />
        <AddFamilyMembers
          open_status={addNewMember}
          attrName={setaddNewMember}
          value_update={updateMasterState}
          api_url={EMPLOYEE_URL_CONSTANTS.add_profile_member}
          memberList={memberList}
        />
      </Grid>
    </>
  );
}

export default Profile;
