import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { employeeDTO, locationDTO, } from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import { convertMultipleNumbersToCurrencyFormat, formatCurrencyAccToUser, getCurrencyCodeRegex, get_city_list_via_name, get_country_list, get_state_list, validateEmail, validateMobileNumber, validatePincode, validateUrl, } from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../../formLayout.scss";

function EditEmployee() {
  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const [formData, setFormData] = useState<employeeDTO[]>([]);
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string }>>([]);
  const [attachedClient, setattachedClient] = useState<string>("");
  const [stateData, setStateData] = useState<Array<{ label: string; id: string }>>([]);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [cityData, setCityData] = useState<Array<{ label: string; id: string }>>([]);
  const [selectedClientId, setselectedClientId] = useState<any>([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [personalAddress, setPersonalAddress] = useState<locationDTO>({
    street: "",
    area: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    warnStreet: false,
    warnArea: false,
    warnCountry: false,
    warnState: false,
    warnCity: false,
    warnZip: false,
  });
  const [uploadFile, setUploadFile] = useState(false);
  const [addClass, setAddClass] = useState<boolean>(false);
  const [clientIdData, setClientIdData] = useState<Array<{ label: string; id: string }>>([]);
  const [locationIdData, setLocationIdData] = useState<Array<{ label: string; id: string }>>([]);
  const [selectedMember, setselectedMember] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState({ label: "", id: "" });
  const [selectedState, setSelectedState] = useState({ label: "", id: "" });
  const [selectedCity, setSelectedCity] = useState({ label: "", id: "" });
  const [id, setId] = useState("");
  const [departmentDropDownData, setdepartmentDropDownData] = useState<Array<{ label: string; id: string }>>([]);
  const [designationDropDowndata, setdesignationDropDowndata] = useState<Array<{ label: string; id: string }>>([]);
  const [salaryDropDowndata, setsalaryDropDowndata] = useState<Array<{ label: string; id: string }>>([]);
  const [salaryDropDowndataOriginal, setsalaryDropDowndataOriginal] = useState<Array<{ label: string; id: string }>>([]);
  const [salaryBracketPath, setsalaryBracketPath] = useState<any[]>([]);
  const [salaryPath, setsalaryPath] = useState<any[]>([]);
  const AddressDetail = useRef(null);
  const [sectionStatus, SetSectionStatus] = useState<any>("Member Type Details");
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Member Type Info");
  const [isPolicyAssigned, setIsPolicyAssigned] = useState(false);
  const [empDob, setEmpDob] = useState<any>();
  const [empDoj, setEmpDoj] = useState<any>();

  const scrollToSection = (elementRef: any) => { elementRef.current?.scrollIntoView({ behavior: "smooth" }); };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    setId(id ?? "");
    get_country_list((cb: any) => {
      setCountryData(cb);
    });

    get_employee_layout(id ?? "");
    get_client_list();
  }, []);

  useEffect(() => {
    get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
      setCityData(cb);
    });
    get_state_list(selectedCountry, (cb: any) => {
      setStateData(cb);
    });
  }, [selectedState, selectedCountry]);

  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setClientIdData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.get_client_list(userState?.userType?.toLowerCase(), onSuccess, onError);
  };
  // get form layout dynamically
  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      let memberName: any;
      let response = res.data.data[0].layout;
      getClientList(res.data.data[0].attachedClient)
      setIsPolicyAssigned(res.data.data[0].policy.length > 0 ? true : false)
      setattachedClient(res.data.data[0].attachedClient);
      get_dep_designation_salary(
        res.data.data[0]?.attachedClient,
        "DEPARTMENTS"
      );
      get_dep_designation_salary(
        res.data.data[0]?.attachedClient,
        "DESIGNATIONS"
      );
      get_dep_designation_salary(
        res.data.data[0]?.attachedClient,
        "SALARY_BRACKETS"
      );
      setSelectedCountry({
        label: res.data.data[0].personalAddress,
        id: res.data.data[0].personalAddress.country,
      });
      setSelectedState({
        label: res.data.data[0].personalAddress,
        id: res.data.data[0].personalAddress.state,
      });
      setSelectedCity({
        label: res.data.data[0].personalAddress,
        id: res.data.data[0].personalAddress.city,
      });

      setPersonalAddress(res.data.data[0].personalAddress);
      let newData = response.map(
        (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection: any, dataSubsectionIndex: any) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow: any, dataRowIndex: any) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map(
                    (dataColumn: any, dataColumnIndex: any) => {
                      let newColumn = { ...dataColumn };
                      newColumn.children = dataColumn.children;
                      newColumn.children = dataColumn.children.map(
                        (dataInput: any, dataInputIndex: any) => {
                          if (dataInput?.field_lable === "Salary") {
                            setsalaryPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }

                          if (dataInput?.field_lable === "Member Type") {
                            memberName = dataInput.value;
                            setselectedMember(dataInput.value);
                          }
                          if (dataInput?.field_lable.toLowerCase() === "date of birth") {
                            setEmpDob(dataInput.value);
                          }
                          if (dataInput?.field_type === "searchClient") {
                            setselectedMember(dataInput.value);
                          }
                          if (dataInput?.field_lable.toLowerCase() === "date of joining") {
                            setEmpDoj(dataInput.value);
                          }
                          if (memberName === "Employee") {
                            if (dataInput?.field_lable === "Primary Employee") {
                              return {
                                ...dataInput,
                                field_lable: "hide",
                                field_type: "hide",
                              };
                            }
                          }
                          if (memberName === "Employee") {
                            if (
                              dataInput?.field_lable ===
                              "Relationship With Employee"
                            ) {
                              return {
                                ...dataInput,
                                pickliststep: [
                                  {
                                    field_name: "Self",
                                    label: "Self",
                                    value: "Self",
                                  },
                                ],
                              };
                            }
                          }
                          if (dataInput?.field_lable === "Salary Bracket") {
                            setsalaryBracketPath([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          if (dataInput.field_type === "hideid") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          } else {
                            return { ...dataInput };
                          }
                        }
                      );
                      return newColumn;
                    }
                  );
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );
      const updatedItems = newData;
      if (memberName === "Dependent") {
        updatedItems[1]?.children.splice(1, 1);
      }
      setFormData(updatedItems);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.employee_detail_view_layout(id, onSuccess, onError);
  };

  const getClientList = (value: any) => {
    const onSuccess = (res: any) => {
      setLocationIdData(res.data.data);
    };
    const onError = (err: any) => {    };
    EMPLOYEE_SERVICES.get_location_list(
      onSuccess,
      onError,
      value
    );
  }

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "personalAddress") {
      setPersonalAddress({
        ...personalAddress,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
      if (key === "country") {
        setSelectedCountry(value);
        setPersonalAddress({
          ...personalAddress,
          country: value.label,
          state: "",
          city: "",
          warnCountry: false
        });
        setSelectedState({ label: "", id: "" });
        setSelectedCity({ label: "", id: "" });
      } else if (key === "state") {
        setSelectedState(value);
        setPersonalAddress({
          ...personalAddress,
          state: value.label,
          city: value.label === "N.A." ? "N.A." : "",
          warnState: false
        });
        setSelectedCity({ label: "", id: "" });
      } else if (key === "city") {
        setSelectedCity(value);
      }
    } else {
      // if (attrName === "notes_new_data") {
      //   setNotesData((notesData) => [
      //     ...notesData,
      //     { name: "Umesh", date: new Date(), description: value },
      //   ]);
      // } else
      if (attrName === setPostQueryForm) {
        attrName(value);
      } else {
        let data: employeeDTO[] = formData;
        //For required fields

        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].required === true &&
          value != null &&
          value.length === 0
        ) {
          data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3]].children[attrName[4]].error = true;
        } else {
          data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3]].children[attrName[4]].error = false;
        }
        //update value
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_lable ===
          "Designation" ||
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_lable ===
          "Department"
        ) {
          data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3]].children[attrName[4]].value = value.label;
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_lable ===
          "Client"
        ) {
          data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3]].children[attrName[4]].value = value.label;
          get_dropdown_data(value.label);
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type === "salary"
        ) {
          data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3]].children[attrName[4]].value = value.label;
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
          "searchClient"
        ) {

          setattachedClient(value.id);
          get_dep_designation_salary(value.id, "DEPARTMENTS");
          get_dep_designation_salary(value.id, "DESIGNATIONS");
          get_dep_designation_salary(value.id, "SALARY_BRACKETS");
          getClientList(value.id)
        } else if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
          "salaryInput"
        ) {
          data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
            salaryBracketPath[2]
          ].children[salaryBracketPath[3]].children[
            salaryBracketPath[4]
          ].value = "";
          for (var i = 0; i < salaryDropDowndataOriginal.length; i++) {
            var range: any = salaryDropDowndataOriginal[i].label.split(" ");
            var min: any = null;
            var max: any = null;
            if (range[0] === "Below") {
              min = Number.NEGATIVE_INFINITY;
              max = parseInt(range[1]);
            } else if (range[0] === "Above") {
              min = parseInt(range[1]);
              max = Number.POSITIVE_INFINITY;
            } else {
              var rangeValues = range[0].split("-");
              min = parseInt(rangeValues[0]);
              max = parseInt(rangeValues[1]);
            }

            // Check if the input number is within the range
            if (value >= min && value <= max) {
              var slab = salaryDropDowndata[i].label;
              data[salaryBracketPath[0]].children[
                salaryBracketPath[1]
              ].children[salaryBracketPath[2]].children[
                salaryBracketPath[3]
              ].children[salaryBracketPath[4]].value = slab;

              break;
            }
          }
        }
        setFormData([...data]);
      }
    }
  };

  // form submission of contact
  const detail_form_submit = () => {
    // set form error for required true field
    let hasError = false;
    let newData = formData.map((dataMainsection: employeeDTO) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection.children.map(
        (dataSubsection) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection.children.map((dataRow) => {
            let newRow = { ...dataRow };
            newRow.children = dataRow.children.map((dataColumn) => {
              let newColumn = { ...dataColumn };
              newColumn.children = dataColumn.children.map((dataInput) => {
                if (
                  dataInput.required === true &&
                  dataInput.value.length === 0
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.characterLimit &&
                  dataInput.value.length > dataInput.characterLimit
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "email"
                ) {
                  if (validateEmail(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "email") {
                  if (
                    validateEmail(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (dataInput.field_type === "phone") {
                  if (validateMobileNumber(dataInput.value)) {
                    return { ...dataInput, error: false };
                  } else {
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "url"
                ) {
                  if (validateUrl(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "url") {
                  if (
                    validateUrl(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "address"
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else {
                  return { ...dataInput, error: false };
                }
              });
              return newColumn;
            });
            return newRow;
          });
          return newSubsection;
        }
      );
      return newMainSection;
    });
    setFormData(newData);
    let dataAddress: any = personalAddress;
    dataAddress.warnStreet = personalAddress.street === "" ? true : false;
    dataAddress.warnArea = personalAddress.area === "" ? true : false;
    dataAddress.warnCountry = selectedCountry && selectedCountry.label === "" || selectedCountry.label === "N.A." ? true : false;
    dataAddress.warnState = selectedState && selectedState.label === "" || selectedState.label === "N.A." ? true : false;
    dataAddress.warnCity = selectedCity && selectedCity.label === "" || selectedCity.label === "N.A." ? true : false;
    dataAddress.warnZip = !validatePincode(personalAddress.zip === null ? "" : personalAddress.zip.toString());
    setPersonalAddress({ ...dataAddress });

    // api call code
    if (
      hasError === false &&
      personalAddress.warnStreet === false &&
      personalAddress.warnArea === false &&
      personalAddress.warnCountry === false &&
      personalAddress.warnState === false &&
      personalAddress.warnCity === false &&
      personalAddress.warnZip === false
    ) {

      let params = {
        layout: formData,
        personalAddress: {
          street: personalAddress.street,
          area: personalAddress.area,
          country: personalAddress.country,
          state: personalAddress.state,
          city: personalAddress.city,
          zip: personalAddress.zip,
        },
        policies: [],
        attachments: [],
        attachedClient: attachedClient,
      };
      const onSuccess = (res: any) => {
        // alert("Data added successfully");
        navigate(ALL_ROUTES.EMPLOYEE);
      };
      const onError = (err: any) => {
        console.log("errr", err);
      };
      EMPLOYEE_SERVICES.update_detail_global_employee(
        params,
        onSuccess,
        onError,
        id
      );
    }
  };

  const get_dropdown_data = (clientId: any) => {
    const onSuccess = (res: any) => {
      setselectedClientId(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.get_dropdown_data(onSuccess, onError, clientId);
  };

  const get_dep_designation_salary = (clientid: any, type: any) => {
    const onSuccess = (res: any) => {
      if (type === "DEPARTMENTS") {
        setdepartmentDropDownData(res.data.data[0].data);
      } else if (type === "DESIGNATIONS") {
        setdesignationDropDowndata(res.data.data[0].data);
      } else if (type === "SALARY_BRACKETS") {
        let NewArr: any = [];
        setsalaryDropDowndataOriginal(res.data.data[0].data);

        res.data.data[0].data.map((inpuData: any, index: any) => {
          let LastIndex: boolean = false;
          if (res.data.data[0].data.length - 1 === index) {
            LastIndex = true;
          }
          NewArr.push(
            convertMultipleNumbersToCurrencyFormat(
              inpuData.label,
              getCurrencyCodeRegex(userState.localInformation.currency),
              userState.localInformation.countryCurrencyCode,
              true,
              index,
              LastIndex
            )
          );
        });
        let dropDownData: any = [];

        NewArr.map((data: any) => {
          dropDownData.push({ id: data, label: data });
        });

        setsalaryDropDowndata(dropDownData);
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      userState.userType.toLowerCase(),
      clientid,
      type
    );
  };
  // for scrolling
  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  
  return (
    <Box className="layoutWrapper">
      <BulkUploadForm
        open_status={uploadFile}
        attrName={setUploadFile}
        value_update={updateMasterState}
      />
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link className="backStep"
            onClick={() => {
              navigate(ALL_ROUTES.EMPLOYEE);
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Edit {selectedMember}</h3>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >           <div>
            <Tooltip title="Indivisual Edit">
              <Link
                className="actionLinks"
                onClick={() => {
                  navigate(`${ALL_ROUTES.EMPLOYEE_DETAILS_VIEW}?id=${id}`);
                }}
              >
                <BorderColorIcon />
              </Link>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              {formData.map((dataMainSection: employeeDTO, index: number) => (
                <>
                  <Link
                    className={
                      sectionStatus === dataMainSection.name
                        ? `active sectionLink`
                        : "sectionLink personalDetailsSection"
                    }
                    onClick={() => {
                      handleClicks(dataMainSection.name);
                      setAddClass(true);
                      SetSectionStatus(
                        dataMainSection.name === undefined
                          ? ""
                          : dataMainSection.name
                      );
                    }}
                  >
                    <span>{dataMainSection.name}</span>
                  </Link>
                  {dataMainSection.children.map((dataSubsection) => (
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subsectionStatus === dataSubsection.name
                              ? `inner_link active`
                              : `inner_link`
                          }
                          onClick={() => {
                            handleClicks(dataSubsection.name);
                            SetsubSectionStatus(dataSubsection.name);
                            SetSectionStatus(
                              dataMainSection.name === undefined
                                ? ""
                                : dataMainSection.name
                            );
                          }}
                        >
                          {dataSubsection.name}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </>
              ))}
              <Link
                className={
                  sectionStatus === "AddressDetail"
                    ? "active sectionLink AddressDetail"
                    : "sectionLink AddressDetail"
                }
                onClick={() => {
                  scrollToSection(AddressDetail);
                  SetSectionStatus("AddressDetail");
                }}
              >
                <span>Address Detail</span>
              </Link>
            </Grid>
            <Grid xs className="rightSection">
              {formData.map(
                (dataMainSection: employeeDTO, indexMainSection: number) => (
                  // Main section
                  <Box onMouseEnter={() => { SetSectionStatus(dataMainSection.name) }} className="detailSection">
                    <div id={dataMainSection.name}>
                      <div className="sectionTitle">
                        <h4>{dataMainSection.name}</h4>
                      </div>
                      {dataMainSection.children.map(
                        (dataSubsection, indexSubsection) => (
                          // Sub Section
                          <Grid onMouseEnter={() => { SetsubSectionStatus(dataSubsection.name) }} container>
                            <Grid xs={12}>
                              <h5
                                id={dataSubsection.name}
                                key={dataSubsection.type}
                                className="subHeading"
                              >
                                {dataSubsection.name}
                              </h5>
                              {dataSubsection.children.map(
                                (dataRow, indexRow) => (
                                  // Row
                                  <Grid
                                    container
                                    className="subRow"
                                    spacing={3}
                                  >
                                    {dataRow.children.map(
                                      (dataColumn, indexColumn) => (
                                        // Columns created
                                        <Grid xs className="subColumn">
                                          {dataColumn.children.map(
                                            (dataInput, indexInput) => (
                                              // input Component will be here
                                              <form>
                                                {dataInput.field_lable ===
                                                  "Member Type" ? (
                                                  <RKTextField
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 16
                                                        : dataInput.characterLimit
                                                    }
                                                    disabled
                                                  />
                                                ) : dataInput.field_type ===
                                                  "singleselect" ? (
                                                  <SelectDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    dropdown_data={
                                                      dataInput.pickliststep
                                                        ? dataInput.pickliststep
                                                        : []
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                  />
                                                ) : dataInput.field_lable ===
                                                  "Primary Employee" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={selectedClientId}
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "department" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    disabled={isPolicyAssigned}
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={
                                                      departmentDropDownData
                                                    }
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select Department"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "designation" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    disabled={isPolicyAssigned}
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={
                                                      designationDropDowndata
                                                    }
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select Designation"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "salaryInput" ? (
                                                  <RKTextField
                                                    validation_type="numeric"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={formatCurrencyAccToUser(
                                                      dataInput.value,
                                                      getCurrencyCodeRegex(
                                                        userState
                                                          .localInformation
                                                          .currency
                                                      ),
                                                      userState.localInformation
                                                        .countryCurrencyCode,
                                                      0
                                                    )}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.maxDigits
                                                        ? 12
                                                        : dataInput.maxDigits
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "salary" ? (
                                                  <>
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={salaryDropDowndata}
                                                      warn_status={
                                                        dataInput.error ===
                                                          undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      disabled={
                                                        formData[salaryPath[0]]
                                                          ?.children[
                                                          salaryPath[1]
                                                        ]?.children[
                                                          salaryPath[2]
                                                        ]?.children[
                                                          salaryPath[3]
                                                        ]?.children[
                                                          salaryPath[4]
                                                        ].value
                                                          ? true
                                                          : false
                                                      }
                                                      error_message="Please select Salary Bracket"
                                                    />
                                                  </>
                                                ) : dataInput.field_type ===
                                                  "searchClient" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={clientIdData}
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message={
                                                      "Select Client"
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "searchLocation" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={locationIdData}
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message={
                                                      "Select Client"
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "phone" ? (
                                                  <RKTextField
                                                    validation_type="numeric"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      validateMobileNumber(
                                                        dataInput.value
                                                      ) === false
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 16
                                                        : dataInput.characterLimit
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "email" ? (
                                                  <RKTextField
                                                    validation_type="email"
                                                    type="email"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : validateEmail(
                                                          dataInput.value
                                                        ) === false
                                                          ? `Enter a valid email Id`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 50
                                                        : dataInput.characterLimit
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "datepicker" ? (
                                                  <DatePicker
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    max_date={
                                                      dataInput.field_lable?.toLowerCase() ===
                                                        "date of birth" ||
                                                      dataInput.field_lable?.toLowerCase() ===
                                                        "date of joining"
                                                        ? new Date()
                                                        : null
                                                    }
                                                    min_date={
                                                      dataInput.field_lable?.toLowerCase() ===
                                                        "date of joining" ? 
                                                        new Date(empDob)
                                                        : dataInput.field_lable?.toLowerCase() ===
                                                            "last day of working" &&
                                                          empDoj
                                                        ? new Date(empDoj)
                                                        : dataInput.field_lable?.toLowerCase() ===
                                                          "last day of working"
                                                        ? new Date()
                                                        : null
                                                    }
                                                    value={
                                                      dataInput.value || null
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    error_message="Enter a Valid Date"
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "searchselect" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    data={
                                                      dataInput.pickliststep
                                                        ? dataInput.pickliststep
                                                        : []
                                                    }
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message={
                                                      "Select the value"
                                                    }
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "checkbox" ? (
                                                  <CheckBox
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    name={dataInput.field_lable}
                                                    id={dataInput._id}
                                                    value={Boolean(
                                                      dataInput.value
                                                    )}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    default_check={true}
                                                  />
                                                ) : dataInput.field_type ===
                                                  "number" ? (
                                                  <RKTextField
                                                    validation_type="numeric"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    disabled={
                                                      dataInput.field_lable ===
                                                      "Salary"
                                                    }
                                                    value={
                                                      dataInput.field_lable ===
                                                        "Salary"
                                                        ? formatCurrency(
                                                          dataInput.value
                                                        )
                                                        : dataInput.value
                                                    }
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.maxDigits
                                                        ? 12
                                                        : dataInput.maxDigits
                                                    }
                                                  />
                                                ) : dataInput.field_type ==
                                                  "currency" ? (
                                                  <CurrencyTextField
                                                    validation_type="numeric"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} Limit`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.maxDigits
                                                        ? 12
                                                        : dataInput.maxDigits
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "multiselect" ? (
                                                  <MultipleSearchDropdown
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    multiple_data={
                                                      dataInput.pickliststep
                                                        ? dataInput.pickliststep
                                                        : []
                                                    }
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    error_messg={
                                                      "Select the value"
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "multiline" ? (
                                                  <TextAreaField
                                                    specialcharacter={
                                                      !dataInput.speacialChar
                                                        ? false
                                                        : dataInput.speacialChar
                                                    }
                                                    alphanumeric={
                                                      !dataInput.alphaNumeric
                                                        ? false
                                                        : dataInput.alphaNumeric
                                                    }
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    warn_status={
                                                      dataInput.error ===
                                                        undefined
                                                        ? false
                                                        : dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} charcter`
                                                          : ""
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 30
                                                        : dataInput.characterLimit
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "url" ? (
                                                  <RKTextField
                                                    validation_type="url"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : validateUrl(
                                                          dataInput.value
                                                        ) === false
                                                          ? `Enter a valid Url`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 50
                                                        : dataInput.characterLimit
                                                    }
                                                  />
                                                ) : dataInput.field_type ===
                                                  "country" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title="Country"
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={countryData}
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select country"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "state" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title="State"
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={stateData}
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select state"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "city" ? (
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title="City"
                                                    value={dataInput.value}
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={cityData}
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    error_message="Please select city"
                                                  />
                                                ) : dataInput.field_type ===
                                                  "hide" ? null : (
                                                  <RKTextField
                                                    specialcharacter={
                                                      !dataInput.speacialChar
                                                        ? false
                                                        : dataInput.speacialChar
                                                    }
                                                    alphanumeric={
                                                      !dataInput.alphaNumeric
                                                        ? false
                                                        : dataInput.alphaNumeric
                                                    }
                                                    validation_type="name"
                                                    class_name="inputField"
                                                    title={
                                                      dataInput.field_lable
                                                    }
                                                    value={dataInput.value}
                                                    info_status={
                                                      dataInput.toolTip
                                                    }
                                                    info_message={
                                                      dataInput.tipText
                                                    }
                                                    attrName={[
                                                      indexMainSection,
                                                      indexSubsection,
                                                      indexRow,
                                                      indexColumn,
                                                      indexInput,
                                                      "value",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      dataInput.error
                                                    }
                                                    error_messg={
                                                      dataInput.value.length ===
                                                        0
                                                        ? `Enter ${dataInput.field_lable}`
                                                        : dataInput.characterLimit &&
                                                          dataInput.value
                                                            .length >
                                                          dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} charcter`
                                                          : ""
                                                    }
                                                    required={
                                                      dataInput.required
                                                    }
                                                    charcterlimit={
                                                      !dataInput.characterLimit
                                                        ? 30
                                                        : dataInput.characterLimit
                                                    }
                                                  />
                                                )}
                                              </form>
                                            )
                                          )}
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )
                      )}
                    </div>
                  </Box>
                )
              )}
              <Box onMouseEnter={() => { SetSectionStatus("AddressDetail") }} className="detailSection" ref={AddressDetail}>
                <div className="sectionTitle">
                  <h4>Personal Address Details</h4>
                </div>
                <div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Personal Address Details</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Building/Street Name"
                        value={personalAddress.street}
                        attrName={["personalAddress", "street"]}
                        value_update={updateMasterState}
                        warn_status={personalAddress.warnStreet}
                        required={true}
                      />
                    </Grid>

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Area Name"
                        value={personalAddress.area}
                        attrName={["personalAddress", "area"]}
                        value_update={updateMasterState}
                        warn_status={personalAddress.warnArea}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Country"
                        value={personalAddress.country}
                        attrName={["personalAddress", "country"]}
                        value_update={updateMasterState}
                        data={countryData}
                        warn_status={personalAddress.warnCountry}
                        required={true}
                        error_message="Select Country"
                      />
                    </Grid>

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="State"
                        value={personalAddress.state}
                        attrName={["personalAddress", "state"]}
                        value_update={updateMasterState}
                        data={stateData}
                        warn_status={personalAddress.warnState}
                        error_message="Select state"
                        required={true}
                      />
                    </Grid>

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="City"
                        value={personalAddress.city}
                        attrName={["personalAddress", "city"]}
                        value_update={updateMasterState}
                        data={cityData}
                        warn_status={personalAddress.warnCity}
                        error_message="Select City"
                        required={true}
                      />
                    </Grid>

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Pincode"}
                        value={personalAddress.zip}
                        attrName={["personalAddress", "zip"]}
                        value_update={updateMasterState}
                        warn_status={personalAddress.warnZip}
                        required={true}
                        charcterlimit={6}
                        validation_type={"numeric"}
                        error_messg="Enter valid Pincode"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="outlined"
            className="borderBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={detail_form_submit}
            variant="contained"
            className="submitBtn"
          >
            Update
          </Button>
        </Grid>
      </Grid>
      <PostQueryForm
        attrName={"postQueryForm"}
        open_status={postQueryForm}
        value_update={updateMasterState}
      />

      <Modal open={deleteStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setDeleteStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are about to Delete this Attatchment.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default EditEmployee;
