import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../../Services/EmployerServices/Settings/SetingServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import "../../NewSettings.scss";

function OrganisationDetails() {
  const navigate = useNavigate();
  const [organisationDetails, setorganisationDetails] = useState<any>({});
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const [showLoader, setShowLoader] = useState(true);

  const organisation_details = () => {
    const onSuccess = (res: any) => {
      setorganisationDetails(res.data.data);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      console.log("false", err);
      setShowLoader(false);
    };
    let url = userDetails.parentsId
      ? `${EMPLOYER_URL_CONSTANTS.get_employer_organisation_list}?createdBy=${userDetails.createdBy}`
      : EMPLOYER_URL_CONSTANTS.get_employer_organisation_list;
    // Call the EMPLOYER_SETTING_SERVICES function to get the organization data
    setShowLoader(true);
    EMPLOYER_SETTING_SERVICES.get_organisation_data(url, onSuccess, onError);
  };
  
  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      organisation_details();
    }
    timeoutId = setTimeout(delayedApiCall, 900);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="settingMenuWrapper">
        <Grid container spacing={3}>
          <Grid xs={4} md={4} lg={4}>
            <div className="logosection pt-13">
              <img
                alt=""
                className="companylogo"
                src={organisationDetails?.logo || "clientstaticlogo.jpg"}
                style={{ width: "100%", objectFit: "contain" }}
                onError={(e: any) => {
                  e.target.src = "clientstaticlogo.jpg";
                }}
              />
              <h5>{organisationDetails?.Organisation}</h5>
            </div>

            <div className="logosection">
              <h6>Organization Details</h6>
              <div className="Companydetail">
                <div className="text-left">
                  <p>Industry</p>
                  <h6>{organisationDetails?.Industry}</h6>
                </div>
                <div className="text-right">
                  <p>Sub-Industry</p>
                  <h6>{organisationDetails?.["Sub-Industry"]}</h6>
                </div>
              </div>
              <div className="Companydetail">
                <div className="text-left">
                  <p>Website URL</p>
                  <h6>{organisationDetails?.["Website URL"]}</h6>
                </div>
                <div className="text-right">
                  <p>Tax ID</p>
                  <h6>{organisationDetails?.["Tax ID"]}</h6>
                </div>
              </div>
              <div className="Companydetail">
                <div className="text-left">
                  <p>Fiscal Year</p>
                  <h6>{organisationDetails?.fiscalyear}</h6>
                </div>
                <div className="text-right">
                  <p>Company ID</p>
                  <h6>{organisationDetails?.["Company Id"]}</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={8} md={8} lg={8}>
            <div className="right_section">
              <Grid container spacing={3}>
                <Grid xs={12} className="text-left">
                  <h5>
                    Chief Contacts
                    <span>
                      You can use the following details to contact regarding
                      any query about this application.
                    </span>
                  </h5>
                </Grid>
                <Grid xs={4} className="text-left">
                  <p>Name</p>
                  <h6>{organisationDetails["Chief Contact Name"]}</h6>
                </Grid>
                <Grid xs={4}>
                  <p>Designation</p>
                  <h6>{organisationDetails["Chief Contact Designation"]}</h6>
                </Grid>
                <Grid xs={4}>
                  <p>Email ID</p>
                  <h6>{organisationDetails?.["Organization Email"]}</h6>
                </Grid>
              </Grid>
            </div>
            <div className="right_section">
              <Grid container spacing={3}>
                <Grid xs={12} className="text-left">
                  <h5>
                    Locale Information
                    <span>This is your locale information.</span>
                  </h5>
                </Grid>
                <Grid xs={3} className="text-left">
                  <p>Country/Region</p>
                  <h6>{userDetails?.localInformation?.country}</h6>
                </Grid>
                <Grid xs={5}>
                  <p>Time Zone</p>
                  <h6>{userDetails?.localInformation?.timeZone}</h6>
                </Grid>
                <Grid xs={4}>
                  <p>Currency (Base Currency)</p>
                  <h6>{userDetails?.localInformation?.currency}</h6>
                </Grid>
                <Grid xs={3}>
                  <p>Language</p>
                  <h6>English</h6>
                </Grid>
                <Grid xs={5}>
                  <p>Date Format</p>
                  <h6>{userDetails?.localInformation?.dateFormat}</h6>
                </Grid>
                {/* <Grid xs={4}>
                <p>Report Basis</p>
                <h6>{organisationDetails?.reportBasis}</h6>
              </Grid> */}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="address_section">
              <Grid container spacing={3}>
                <Grid xs={12} className="text-left">
                  <h5>
                    Address Details
                    <span>This is your organisation registered address.</span>
                  </h5>
                </Grid>
                <Grid xs={4} className="text-left">
                  <p>Business Location</p>
                  <h6>
                    {organisationDetails?.State},{organisationDetails?.City}
                  </h6>
                </Grid>
                <Grid xs={4}>
                  <p>Address</p>
                  <h6>
                    {organisationDetails?.["Building/Street Name"]},
                    {organisationDetails?.["Area Name"]}
                  </h6>
                </Grid>
                <Grid xs={4}>
                  <p>Pincode</p>
                  <h6>{organisationDetails?.Pincode}</h6>
                </Grid>
                {/* <Grid xs={4}>
                    <p>Phone Number</p>
                    <h6>
                      {organisationDetails?.["Organization Phone Number"]}
                    </h6>
                  </Grid> */}
                <Grid xs={4}>
                  <p>Mobile Number</p>
                  <h6>
                    {organisationDetails?.["Organization Phone Number"]}
                  </h6>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {/* Footer Section */}
      </Box>
    </>
  );
}

export default OrganisationDetails;
