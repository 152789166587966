import GridViewIcon from "@mui/icons-material/GridView";
import { Box, Button, Grid, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/Broker/Home/Header/Header";
import SideNavBar from "../Components/Broker/Home/SideNavBar/SideNavBar";
import KeyboardShotcuts from "../Components/KeyboardShotcuts/KeyboardShotcuts";
import Claimassistant from "../Pages/Desktop/Broker/Claimassistant/Claimassistant";
import AddNewClient from "../Pages/Desktop/Broker/Clients/AddNewClient/AddNewClient";
import ClientDetails from "../Pages/Desktop/Broker/Clients/ClientDetails/ClientDetails";
import Clients from "../Pages/Desktop/Broker/Clients/Clients";
import EditClient from "../Pages/Desktop/Broker/Clients/EditClient/EditClient";
import Community from "../Pages/Desktop/Broker/Community/Community";
import AddNewContact from "../Pages/Desktop/Broker/Contacts/AddNewContact/AddNewContact";
import ContactDetail from "../Pages/Desktop/Broker/Contacts/ContactDetail/ContactDetail";
import Contacts from "../Pages/Desktop/Broker/Contacts/Contacts";
import EditContact from "../Pages/Desktop/Broker/Contacts/EditContact/EditContact";
import CustomModule from "../Pages/Desktop/Broker/CustomModules/CustomModule";
import Dashboard from "../Pages/Desktop/Broker/Dashboard/Dashboard";
import AddNewEmployee from "../Pages/Desktop/Broker/Employees/AddNewEmployee/AddNewEmployee";
import EditEmployee from "../Pages/Desktop/Broker/Employees/EditEmployee/EditEmployee";
import EmployeeDetails from "../Pages/Desktop/Broker/Employees/EmployeeDetails/EmployeeDetails";
import Employees from "../Pages/Desktop/Broker/Employees/Empolyees";
import Endosement from "../Pages/Desktop/Broker/Endorsement/Endorsement";
import "../Pages/Desktop/Broker/HomeBroker/HomeBroker.scss";
import AddNewPolicy from "../Pages/Desktop/Broker/Policies/AddNewPolicy/AddNewPolicy";
import EditPolicy from "../Pages/Desktop/Broker/Policies/EditPolicy/EditPolicy";
import Policies from "../Pages/Desktop/Broker/Policies/Policies";
import PolicyDetail from "../Pages/Desktop/Broker/Policies/PolicyDetail/PolicyDetail";
import PolicyRenewal from "../Pages/Desktop/Broker/PolicyRenewal/PolicyRenewal";
import GroupCriticalIllness from "../Pages/Desktop/Broker/ProductConfig/GroupCriticalIllness/GroupCriticalIllness";
import GroupHealth from "../Pages/Desktop/Broker/ProductConfig/GroupHealth/GroupHealth";
import GroupPersonalAccident from "../Pages/Desktop/Broker/ProductConfig/GroupPersonalAccident/GroupPersonalAccident";
import GroupTerm from "../Pages/Desktop/Broker/ProductConfig/GroupTerm/GroupTerm";
import GroupTravelInsurance from "../Pages/Desktop/Broker/ProductConfig/GroupTravelInsurance/GroupTravelInsurance";
import AddNewInsurer from "../Pages/Desktop/Broker/ProductConfig/Insurers/AddNewInsurer/AddNewInsurer";
import InsurerDetails from "../Pages/Desktop/Broker/ProductConfig/Insurers/AddNewInsurer/InsurerDetails/InsurerDetails";
import EditInsurer from "../Pages/Desktop/Broker/ProductConfig/Insurers/EditInsurer/EditInsurer";
import Insurers from "../Pages/Desktop/Broker/ProductConfig/Insurers/Insurers";
import Settings from "../Pages/Desktop/Broker/ProductConfig/Settings/Settings";
import SuperTopUp from "../Pages/Desktop/Broker/ProductConfig/SuperTopUp/SuperTopUp";
import AddVoluntaryPlan from "../Pages/Desktop/Broker/ProductConfig/VoluntaryPlans/AddVoluntaryPlan/AddVoluntaryPlan";
import VoluntaryDetails from "../Pages/Desktop/Broker/ProductConfig/VoluntaryPlans/VoluntaryDetails/VoluntaryDetails";
import VoluntaryPlans from "../Pages/Desktop/Broker/ProductConfig/VoluntaryPlans/VoluntaryPlans";
import Report from "../Pages/Desktop/Broker/Report/Report";
import EditPersonalDetails from "../Pages/Desktop/Broker/Settings/GeneralSettings/PersonalSettings/EditPersonalDetails/EditPersonalDetails";
import CreateBranch from "../Pages/Desktop/Broker/Settings/OrganisationSettings/Branches/CreateBranch/CreateBranch";
import EditBranch from "../Pages/Desktop/Broker/Settings/OrganisationSettings/Branches/EditBranch/EditBranch";
import EditOrganisationDetails from "../Pages/Desktop/Broker/Settings/OrganisationSettings/OrganisationDetails/EditOrganisationDetails/EditOrganisationDetails";
import {
  default as SettingModuleForm,
  default as SettingModulesForm,
} from "../Pages/Desktop/Broker/Settings/SettingModulesForm/SettingModulesForm";
import SettingsMain from "../Pages/Desktop/Broker/Settings/Settings";
import CreateRole from "../Pages/Desktop/Broker/Settings/UserAndControl/CreateRole/CreateRole";
import CreateUsers from "../Pages/Desktop/Broker/Settings/UserAndControl/CreateUsers/CreateUsers";
import EditCreateRole from "../Pages/Desktop/Broker/Settings/UserAndControl/EditCreateRole/EditCreateRole";
import EditCreateUsers from "../Pages/Desktop/Broker/Settings/UserAndControl/EditCreateUser/EditCreateUser";
import TaskDetail from "../Pages/Desktop/Broker/TaskManagement/TaskDetail/TaskDetail";
import TaskManagement from "../Pages/Desktop/Broker/TaskManagement/TaskManagement";
import TrendingQuestions from "../Pages/Desktop/Broker/TrendingQuestions/TrendingQuestions";
import Notifications from "../Pages/Desktop/Common/Notifications/Notifications";
import Support from "../Pages/Desktop/Broker/Support/Support";
import { useAppSelector } from "../Store/hooks";
import { ALL_ROUTES } from "./all_routes";
import AddNewQuotes from "../Pages/Desktop/Broker/Quotes/AddNewQuotes/AddNewQuotes";
import QuotesDetail from "../Pages/Desktop/Broker/Quotes/QuotesDetail/QuotesDetail";
import Quotes from "../Pages/Desktop/Broker/Quotes/Quotes";
import BulkUploadPage from "../Components/BulkUpload/BulkUploadPage";
import BulkUploadList from "../Components/BulkUpload/BulkUploadList/BulkUploadList";
import MapFieldsConfirmation from "../Components/BulkUpload/MapFieldsConfirmation/MapFieldsConfirmation";
import MapFields from "../Components/BulkUpload/MapFields/MapFields";
import EditQuotes from "../Pages/Desktop/Broker/Quotes/EditQuotes/EditQuotes";
import ClaimAssistantDetail from "../Pages/Desktop/Broker/Claimassistant/ClaimAssistantDetail/ClaimAssistantDetail";
import EndorsementDetail from "../Pages/Desktop/Broker/Endorsement/EndorsementDetail/EndorsementDetail";
import Tpa from "../Pages/Desktop/Broker/ProductConfig/Tpa/Tpa";
import AddNewTpa from "../Pages/Desktop/Broker/ProductConfig/Tpa/AddNewTpa/AddNewTpa";
import TpaDetails from "../Pages/Desktop/Broker/ProductConfig/Tpa/AddNewTpa/InsurerDetails/TpaDetails";
import EditTpa from "../Pages/Desktop/Broker/ProductConfig/Tpa/EditTpa/EditTpa";
import EndorsementDetailMemberAddition from "../Pages/Desktop/Broker/Endorsement/EndorsementDetail/EndorsmentMemberAddition";
import NetworkHospital from "../Components/Broker/NetworkHospital/NetworkHospital";
import FullPageLoader from "../Components/Loader/FullPageLoader";
import NewLoader from "../Components/NewLoader/NewLoader";
import EndorsementMassUpdate from "../Components/Broker/Endorsment/EndorsementMassUpdate/EndorsemenrMassUpdate";

function Broker_Routes() {
  const location = useLocation();
  const [shortcutOpen, setShortcutOpen] = useState(false);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const navigate = useNavigate();

  // const expireTime = parseFloat(userDetails.expireIn.slice(0, 2));
  const expireTime = 20;

  useEffect(() => {
    const intervalId = setInterval(() => {
      sessionStorage.clear();
      window.location.reload();
    }, expireTime * 60 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const [allBrokerRoutes, setAllBrokerRoutes] = useState<Array<{
    path: ALL_ROUTES;
    element: any;
    add_permission: boolean;
    view_permission: boolean;
  }>>([]);

  const full_page_routes = [
    ALL_ROUTES.BULK_ASSIGN,
    ALL_ROUTES.ADD_NEW_QUOTES,
    ALL_ROUTES.ADD_NEW_CLIENT,
    ALL_ROUTES.ADD_EMPLOYEE,
    ALL_ROUTES.BULK_UPLOAD_PAGE,
    ALL_ROUTES.BULK_UPLOAD_LIST,
    ALL_ROUTES.TASK_MANGEMENT,
    ALL_ROUTES.SETTING,
    ALL_ROUTES.ADD_NEW_CONTACT,
    ALL_ROUTES.EDIT_CONTACT,
    ALL_ROUTES.EMPLOYEE_DETAILS_VIEW,
    ALL_ROUTES.CONTACT_DETAIL_VIEW,
    ALL_ROUTES.CLIENT_DETAIL_VIEW,
    ALL_ROUTES.EDIT_CLIENT,
    ALL_ROUTES.EDIT_EMPLOYEE,
    ALL_ROUTES.ADD_NEW_INSURER,
    ALL_ROUTES.ADD_NEW_TPA,
    ALL_ROUTES.INSURER_DETAILS,
    ALL_ROUTES.TPA_DETAILS,
    ALL_ROUTES.SETTING_MODULE_FORM,
    ALL_ROUTES.CUSTOM_MODULES,
    ALL_ROUTES.EDIT_USER,
    ALL_ROUTES.EDIT_ACCESS_CONTROL,
    ALL_ROUTES.CREATE_USER,
    ALL_ROUTES.CREATE_ACCESS_CONTROL,
    ALL_ROUTES.MAP_FIELDS,
    ALL_ROUTES.ADD_BRANCH,
    ALL_ROUTES.EDIT_BRANCH,
    ALL_ROUTES.MAP_FILED_CONFIRMATION,
    ALL_ROUTES.POLICY_DETAILS,
    ALL_ROUTES.ADD_NEW_POLICY,
    ALL_ROUTES.EDIT_POLICY,
    ALL_ROUTES.EDIT_QUOTES,
    ALL_ROUTES.EDIT_ORG_DETAILS,
    ALL_ROUTES.QUOTE_DETAIL,
    ALL_ROUTES.CLAIM_DETAIL,
    ALL_ROUTES.EDIT_INSURER,
    ALL_ROUTES.EDIT_TPA,
    ALL_ROUTES.ENDORSEMENT_DETAILS,
    ALL_ROUTES.NETWORK_HOSPITAL,
    ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION,
    ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS
  ];

  const [shortcutDisable, setShortcutDisable] = useState<boolean>(false);

  useEffect(() => {
    const routes: Array<{
      path: ALL_ROUTES;
      element: any;
      add_permission: boolean;
      view_permission: boolean;
    }> = [
        {
          path: ALL_ROUTES.ADD_NEW_QUOTES,
          element: <AddNewQuotes />,
          add_permission: BROKER?.Quotes?.add === undefined ? false : BROKER.Quotes.add,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_CLIENT,
          element: <AddNewClient />,
          add_permission:
            BROKER?.Clients?.add === undefined ? false : BROKER.Clients.add,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_EMPLOYEE,
          element: <AddNewEmployee />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_BRANCH,
          element: <CreateBranch />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_BRANCH,
          element: <EditBranch />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.BULK_UPLOAD_PAGE,
          element: <BulkUploadPage />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.BULK_UPLOAD_LIST,
          element: <BulkUploadList />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.TASK_MANGEMENT,
          element: <TaskManagement intiateClaim={false} />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.SETTING,
          element: <SettingsMain />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.SETTING_MODULE_FORM,
          element: <SettingModulesForm />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_CONTACT,
          element: <AddNewContact />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.NETWORK_HOSPITAL,
          element: <NetworkHospital />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_CONTACT,
          element: <EditContact />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EMPLOYEE_DETAILS_VIEW,
          element: <EmployeeDetails />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CONTACT_DETAIL_VIEW,
          element: <ContactDetail />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CLIENT_DETAIL_VIEW,
          element: <ClientDetails />,
          view_permission:
            BROKER?.Clients?.view === undefined ? false : BROKER.Clients.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_CLIENT,
          element: <EditClient />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.EDIT_EMPLOYEE,
          element: <EditEmployee />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_INSURER,
          element: <AddNewInsurer />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_TPA,
          element: <AddNewTpa />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.INSURER_DETAILS,
          element: <InsurerDetails />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.TPA_DETAILS,
          element: <TpaDetails />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CUSTOM_MODULES,
          element: <CustomModule />,
          view_permission: BROKER?.Module?.view || false,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.EDIT_USER,
          element: <EditCreateUsers />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CREATE_USER,
          element: <CreateUsers />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_ACCESS_CONTROL,
          element: <EditCreateRole />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.CREATE_ACCESS_CONTROL,
          element: <CreateRole />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.EDIT_ORG_DETAILS,
          element: <EditOrganisationDetails />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.POLICY_DETAILS,
          element: <PolicyDetail />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_POLICY,
          element: <AddNewPolicy />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_POLICY,
          element: <EditPolicy />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.DASHBOARD,
          element: <Dashboard />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.QUOTES,
          element: <Quotes />,
          view_permission:
            BROKER?.Quotes?.view === undefined ? false : BROKER.Quotes.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_QUOTES,
          element: <EditQuotes />,
          view_permission: true,
          add_permission: true,
        },

        {
          path: ALL_ROUTES.POLICIES,
          element: <Policies />,
          view_permission:
            BROKER?.Policies?.view === undefined ? false : BROKER.Policies.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES?.POLICY_RENEWAL,
          element: <PolicyRenewal />,
          view_permission:
            BROKER?.Renewals?.view === undefined ? false : BROKER?.Renewals?.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CLIENTS,
          element: (
            <Clients
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.Clients?.view === undefined ? false : BROKER.Clients.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CONTACTS,
          element: (
            <Contacts
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.Contact?.view === undefined ? false : BROKER.Contact.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.EMPLOYEE,
          element: <Employees />,
          view_permission:
            BROKER?.Employees?.view === undefined ? false : BROKER.Employees.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH,
          element: (
            <GroupHealth
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_SUPER_TOPUP,
          element: (
            <SuperTopUp
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM,
          element: (
            <GroupTerm
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT,
          element: (
            <GroupPersonalAccident
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS,
          element: <GroupCriticalIllness />,
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL,
          element: <GroupTravelInsurance />,
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_VOLUNTARY_PLANS,
          element: <VoluntaryPlans />,

          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_INSURERS,
          element: <Insurers />,
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_TPA,
          element: (
            <Tpa
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.PRODUCT_CONFIG_SETTINGS,
          element: <Settings />,
          view_permission:
            BROKER?.ProductConfigurator?.view === undefined
              ? false
              : BROKER.ProductConfigurator.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ENDORSEMENT,
          element: (
            <Endosement
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          view_permission:
            BROKER?.Endorsement?.view === undefined
              ? false
              : BROKER.Endorsement.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ENDORSEMENT_DETAILS,
          element: <EndorsementDetail />,
          view_permission:
            BROKER?.Endorsement?.view === undefined
              ? false
              : BROKER.Endorsement.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION,
          element: <EndorsementDetailMemberAddition />,
          view_permission:
            BROKER?.Endorsement?.view === undefined
              ? false
              : BROKER.Endorsement.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS,
          element: <EndorsementMassUpdate />,
          view_permission:
            BROKER?.Endorsement?.view === undefined
              ? false
              : BROKER.Endorsement.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.CLAIM_DETAIL,
          element: <ClaimAssistantDetail />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.CLIAM_ASSISTANT,
          element: (
            <Claimassistant
              shortcutDisable={shortcutDisable}
              setShortcutDisable={setShortcutDisable}
            />
          ),
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.TRENDING_QUESTION,
          element: <TrendingQuestions />,
          add_permission: true,
          view_permission: true,
        },

        {
          path: ALL_ROUTES.COMMUNITY,
          element: <Community />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.REPORT,
          element: <Report />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.SUPPORT,
          element: <Support />,
          view_permission:
            BROKER?.Support?.view === undefined ? false : BROKER.Support.view,
          add_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_VOLUNTRY_PLAN,
          element: <AddVoluntaryPlan />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.VOLUNTRY_DETAILS,
          element: <VoluntaryDetails />,
          add_permission: true,
          view_permission: true,
        },
        // {
        //   path: ALL_ROUTES.EDIT_PERSONAL_DETAILS,
        //   element: <EditPersonalDetails />,
        //   add_permission: true,
        //   view_permission: true,
        // },

        {
          path: ALL_ROUTES.NOTIFICATION,
          element: <Notifications />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.ADD_NEW_QUOTES,
          element: <AddNewQuotes />,
          add_permission: true,
          view_permission: true,
        },

        {
          path: ALL_ROUTES.QUOTE_DETAIL,
          element: <QuotesDetail />,
          add_permission: true,
          view_permission: true,
        },

        {
          path: ALL_ROUTES.SETTING,
          element: <SettingsMain />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.TASK_DETAIL,
          element: <TaskDetail />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.MAP_FIELDS,
          element: <MapFields />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.MAP_FILED_CONFIRMATION,
          element: <MapFieldsConfirmation />,
          add_permission: true,
          view_permission: true,
        },

        {
          path: ALL_ROUTES.EDIT_INSURER,
          element: <EditInsurer />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.EDIT_TPA,
          element: <EditTpa />,
          add_permission: true,
          view_permission: true,
        },
        {
          path: ALL_ROUTES.SETTING_MODULE_FORM,
          element: <SettingModuleForm />,
          add_permission: true,
          view_permission: true,
        },
      ];

    setAllBrokerRoutes(routes);
  }, [userDetails.userType, BROKER]);

  function updateMasterState(attrName: string, value: any) {
    switch (attrName) {
      case "shortcutOpen":
        setShortcutOpen(value);
        break;
      default:
        console.log(attrName, value);
        break;
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosed = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {full_page_routes.includes(location.pathname as ALL_ROUTES) ? (
        <Routes>
          {allBrokerRoutes.map((data, index) =>
            data.view_permission && data.add_permission &&
              full_page_routes.includes(data.path) ? (
              <Route path={data.path} element={data.element} />
            ) : null
          )}
        </Routes>
      ) : (
        <Box className="dashboardWrapper">
          {!shortcutDisable ? (
            <>
              <Button
                className="shortcutBtn"
                onClick={() => {
                  setShortcutOpen(true);
                }}
              />
              <KeyboardShotcuts
                openStatus={shortcutOpen}
                attrName="shortcutOpen"
                valueUpdate={updateMasterState}
              />
            </>
          ) : null}

          <Button
            className="quickLaunch"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClicked}
          >
            <GridViewIcon />
          </Button>
          <Menu
            className="showMenuBtns"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClosed}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_CLIENT);
                handleClosed();
              }}
            >
              Add Client
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ALL_ROUTES.ADD_EMPLOYEE);
                handleClosed();
              }}
            >
              Add Member
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_QUOTES);
                handleClosed();
              }}
            >
              Add Quote
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_POLICY);
                handleClosed();
              }}
            >
              Add Policy
            </MenuItem>
          </Menu>
          <Header />
          <Grid container spacing={3}>
            <SideNavBar />
            {/* <NewLoader /> */}
            <Grid className="rightSection" item xs>
              <Routes>
                {allBrokerRoutes.map((data, index) =>
                  data.view_permission && data.add_permission ? (
                    <Route path={data.path} element={data.element} />
                  ) : null
                )}
              </Routes>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
export default Broker_Routes;
