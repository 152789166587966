import React, { useEffect, useState } from "react";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import SlidingPanel from "react-sliding-side-panel";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { AddKycDTO } from "../../../../../../Services/DTO/ProfileDTO";
import { PROFILE_SERVICE } from "../../../../../../Services/Employee/ProfileService";
import TextAreaField from "../../../../../../Supporting files/TextAreaField/TextAreaField";
import { allowFileExtention, uploadImage } from "../../../../../../Supporting files/HelpingFunction";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../Store/hooks";
import { RootState } from "../../../../../../Store/Store";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  id: string;
  apiUrl: string;
}

const AddFamilyKyc: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  id,
  apiUrl,
}) => {
  const usertypeinfo = useAppSelector((state: RootState) => state.userDetailsSlice.userType);

  const [family_kyc, setFamily_kyc] = useState<any>({
    id: id,
    selectDocument: "",
    description: "",
    doc: "",
    warnSelectDocument: false,
    warnDescription: false,
    warnDob: false,
  });
  const [displayData, setDisplayData] = useState<{
    doc: any;
    warning: boolean;
    doc_name?: string;
  }>({ doc: "", warning: false, doc_name: "" });
  const selectDocument_data = [
    { key: "Passport", value: "Passport" },
    { key: "Adhar Card", value: "Adhar Card" },
    { key: "Driving Licence", value: "Driving Licence" },
    { key: "Voter Card", value: "Voter Card" },
    { key: "PanCard", value: "PanCard" },
  ];

  useEffect(() => {
    setFamily_kyc({
      id: id,
      selectDocument: "",
      description: "",
      doc: "",
      warnSelectDocument: false,
      warnDescription: false,
      warnDob: false,
    });
    setDisplayData({ doc: "", warning: false, doc_name: "" })
  }, [open_status]);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    if (mainState === "family_kyc") {
      setFamily_kyc({
        ...family_kyc,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
    let data: any = family_kyc;
    data.warnSelectDocument = family_kyc.selectDocument.length === 0 ? true : false;
    setFamily_kyc({ ...data });
    let dataDoc: any = displayData;
    dataDoc.warning = displayData.doc.length === 0 ? true : false;
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    if (data.warnSelectDocument === false && dataDoc.warning === false) {
      let param = {
        employeeId: id,
        kyc: {
          memberId: family_kyc.id,
          doc_type: family_kyc.selectDocument,
          doc: displayData.doc,
          description: family_kyc.description,
          time: new Date().getTime().toString(),
          date: new Date(),
          doc_name: displayData.doc_name,
        },
      };
      let paramsKyc = {
        employeeId: id,
        doctType: family_kyc.selectDocument,
        doc: displayData.doc,
        description: family_kyc.description,
        date: new Date(),
        time: new Date().getTime().toString(),
        doc_name: displayData.doc_name,
      };

      const onSuccess = (res: any) => {
        value_update(attrName, false);
        setFamily_kyc({
          selectDocument: "",
          description: "",
          doc: "",
          warnSelectDocument: false,
          warnDescription: false,
          warnDob: false,
        });
        setDisplayData({ doc: "", warning: false, doc_name: "" })
      };
      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
      };
      PROFILE_SERVICE.create_family_kyc_detail(
        family_kyc.id === "" ? paramsKyc : param,
        onSuccess,
        onError,
        usertypeinfo.toLocaleLowerCase(),
      );
    }
  };

  return (
    <Box>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      // backdropClicked={() => setAddKyc(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Upload KYC Document</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setFamily_kyc({
                    selectDocument: "",
                    description: "",
                    doc: "",
                    warnSelectDocument: false,
                    warnDescription: false,
                    warnDob: false,
                  });
                  setDisplayData({ doc: "", warning: false, doc_name: "" })
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Select Document"
                    value={family_kyc.selectDocument}
                    attrName={["family_kyc", "selectDocument"]}
                    value_update={updateMasterState}
                    dropdown_data={selectDocument_data}
                    warn_status={family_kyc.warnSelectDocument}
                    error_messg="Select Document"
                  />
                </Grid>
                <Grid xs={12} className="mb-4 ">
                  <TextAreaField
                    class_name="inputField"
                    title="Select Document"
                    value={family_kyc.description}
                    attrName={["family_kyc", "description"]}
                    value_update={updateMasterState}
                    warn_status={family_kyc.warnDescription}
                    error_messg="Select Document"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} className="mb-5">
                <Grid xs={10} className="inputField uploadFile">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Upload .xl .csv .xls .pdf file"
                    variant="outlined"
                    className="textarea"
                    value={displayData.doc_name}
                    disabled
                  />
                </Grid>
                <Grid xs={2}>
                  <Button
                    variant="contained"
                    className="browsebtn"
                    component="label">
                    Browse
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      style={{ zIndex: "99" }}
                      onChange={(e: any) => {
                        const file = e.target.files[0];
                        const allowExt = allowFileExtention(file)
                        if(!allowExt){
                          toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                          return (e.target.value = null);
                        }
                        uploadImage(file, (base64Image: any, base64String: any) => {
                          setDisplayData((prevDisplayData: any) => ({
                            ...prevDisplayData,
                            doc: base64Image,
                            doc_name: file.name,
                          }));
                        }
                        );
                      }}
                    />
                  </Button>
                  {displayData.warning === true && (
                    <span className="textAreaerror" style={{ color: "red" }}>
                      Upload File
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={form_submit}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </SlidingPanel>
      {/* <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="attatchment_table" style={{ overflow: "hidden" }}>
              <ul className="heading">
                <li>File Name</li>
                <li>Document Type</li>
                <li>Description</li>
                <li>Date</li>
                <li>Time</li>
                <li>Actions</li>
              </ul>

              {kyc_table_data.map((data, index) => (
                <ul className="subheading">
                  <li>{data.file_name}</li>
                  <li>{data.document_type}</li>
                  <li>{data.description}</li>
                  <li>
                    <p className="date">{data.Date}</p>
                  </li>
                  <li>
                    <p className="time">{data.time}</p>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Link
                      className="blueBtn download ml-0"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>
                    <Link
                      className="redBtn delete"
                      onClick={() => {
                        setDeleteStatus(true);
                      }}
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal open={deleteStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                
                className="close-button"
                onClick={() => {
                  setDeleteStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link  className="md-link" underline="none">
                You are about to Delete this Kyc Document.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
    </Box>
  );
};

export default AddFamilyKyc;
