import React, { useEffect, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import "../Supporting.scss";

interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: Function;
  warn_status?: boolean;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  password_status?: boolean;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  calledFrom?: string | null;
}

const UGEmailInput: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  required,
  disabled,
  calledFrom,
}) => {

  const [emails, setEmails] = useState<string[] | []>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(true);
  const handleFocus = () => { setIsFocused(true); };
  const handleBlur = () => { setIsFocused(false); };
  const [data, setData] = useState<Array<string>>([]);

  useEffect(() => {
    if (value?.length > 0) {
      if (calledFrom === "Salary Brackets") {
        setData(`${value}`.split("|"));
      } else {
        setData(`${value}`.split(","));
      }
    }
  }, [value]);

  useEffect(() => {
    let data: any;
    if (typeof value === 'object') {
      data = value;
    } else {
      data = value?.split(",")
    }
    setEmails(value.length > 0 ? data : [])
  }, [])

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}
    onKeyDown={(e) => {
      // Prevent the user from entering "<" or ">"
      if (e.key === "<" || e.key === ">") {
        e.preventDefault();
      }
    }}
      className={`inputField react-multiemail-box ${isFocused || emails.length > 0 ? "focused" : ""} ${disabled ? "not_allowed" : ""}`}>
      <ReactMultiEmail
        emails={emails}
        validateEmail={(email) => {
          return true;
        }}
        onChange={(_emails: string[]) => {
          let newEmail = _emails?.filter(data => data !== "undefined")
          value_update(attrName, newEmail);
          setEmails(newEmail);
          setIsFieldEmpty(newEmail.length === 0);
        }}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void
        ) => {
          return (
            <div className="ug_email" data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      <p className={`label ${isFocused || emails.length > 0 ? "focused" : ""}`}>
        {title}
      </p>
    </div>
  );
};

export default UGEmailInput;